import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { i18nReducer } from 'react-redux-i18n'
import { combineReducers } from 'redux'

import { mapReducer } from '../components/mapShared/reducers'
import { speedReducer } from '../components/mapShared/setSpeed/reducers'
import { loginReducer } from '../pages/loginPage/reducers'
import { routeSelectionReducer } from '../pages/routeSelectionPage/reducers'
import { locationReducer } from '../routes/reducers'

const rootReducer = (history: History) =>
  combineReducers({
    currentUser: loginReducer,
    router: connectRouter(history),
    speed: speedReducer,
    userLocation: locationReducer,
    routeSelection: routeSelectionReducer,
    i18n: i18nReducer,
    map: mapReducer
  })

export default rootReducer
