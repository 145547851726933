import React, { useState } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'

import GoalIcon from '../icons/goal.svg'
import StartIcon from '../icons/start.svg'

interface IProps {
  fromCoordinates?: [number, number] | undefined
  toCoordinates?: [number, number] | undefined
}

interface IIconlayer extends IProps {
  specificIcon: string
  category: string
  coordinates?: [number, number]
}

interface ICreateIcon {
  title: string
  image: string
}

const StartEndLayer = (props: IProps) => {
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)

  const create = createIcons([
    { image: StartIcon, title: 'fromLayer' },
    { image: GoalIcon, title: 'toLayer' }
  ])
  Promise.all(create.map(item => item[1]).map(imageIsLoaded)).then(() => {
    setLoadedIcon(true)
  })
  return (
    <>
      {loadedIcon && (
        <>
          <StartEndIcons
            {...props}
            specificIcon={create[0]}
            category="fromLayer"
            coordinates={props.fromCoordinates}
          />
          <StartEndIcons
            {...props}
            specificIcon={create[1]}
            coordinates={props.toCoordinates}
            category="toLayer"
          />
        </>
      )}
    </>
  )
  function imageIsLoaded(image: HTMLImageElement) {
    return new Promise((resolve: any) => {
      image.onload = () => resolve()
      image.onerror = () => resolve()
    })
  }
  function createIcons(images: ICreateIcon[]) {
    const allImages: any[] = []
    images.forEach(item => {
      const image = new Image(40, 40)
      image.src = item.image
      allImages.push([item.title, image])
    })
    return allImages
  }
}

const StartEndIcons = ({ category, specificIcon, coordinates }: IIconlayer) => {
  const defaultLayout = {
    'icon-image': category,
    'icon-allow-overlap': true,
    'icon-rotate': ['get', 'rotation'],
    'icon-ignore-placement': true
  }
  if (!coordinates) {
    return null
  }
  return (
    <Layer
      type="symbol"
      id={category}
      source={category}
      layout={defaultLayout}
      images={specificIcon}
      aboveLayerId="routeitem-marker"
    >
      <Feature coordinates={coordinates} />
    </Layer>
  )
}

export default StartEndLayer
