import * as React from 'react'
import Modal from 'react-modal'

import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import DefaultTransition from '../shared/defaultTransition/DefaultTransition'
import InactiveRouteSelect from './inactiveRouteSelect/InactiveRouteSelect'
import LocationSelect from './locationSelect/LocationSelect'
import RouteOptionsSelect from './routeOptionsSelect/RouteOptionsSelect'
import RouteSelectForm from './routeSelectForm/RouteSelectForm'

import {
  IRouteSearchProps,
  RouteSelectionFields,
  INavigationLocationNode
} from '../../@types/types'
import { IRouteLocation, IRoute } from '../../services/TeqplayApiService/TeqplayApi'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { IFullPosition, ILocationPermissions } from '../geoLocationWatcher/GeoLocationWatcherTypes'

import { I18n } from 'react-redux-i18n'
import { customModalStyles } from '../../utils/constants'
import './RouteSelect.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  routeSelectionFormActive: boolean
  routeSelectionFormFieldActive: boolean
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  navigationLocations: INavigationLocationNode[]
  routeSuggestions: IRoute[] | null
  selectedRouteSuggestion: IRoute | null
  teqplayApiService: TeqplayApiService
  userName: string
  activePage: string
  currentLocation: IFullPosition | null
  departureTime: number
  locale: string
  locationPermissions: ILocationPermissions
  cruiseSpeed: number

  searchAvailableRoutes: () => void

  setrouteSelectionFormActive: () => void
  setRouteSelectionInactive: () => void
  setDepartureTime: (arrivalDate: Date) => void
  startRoute: (route: IRoute) => void

  setrouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setRouteSelectionFieldInactive: () => void
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  resetAvailableRoutes: () => void
  setCruiseSpeed: (speed: number) => void

  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void

  logout: () => void
}

interface IState {
  showDisclaimer: boolean
}

class RouteSelect extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      showDisclaimer: false
    }
  }

  public componentDidMount() {
    const lastSeenMessage = loadFromLocalStorage('WATERSPORT-LAST_SEEN_DISCLAIMER', 0)
    const now = new Date().valueOf()
    const DAY_IN_MS = 86400000

    if (now - lastSeenMessage > DAY_IN_MS) {
      this.setState({ showDisclaimer: true })
    }
  }

  public render() {
    return (
      <>
        <Modal
          style={customModalStyles}
          className="modal disclaimer"
          isOpen={
            this.state.showDisclaimer &&
            this.props.routeSelectionFormActive === true &&
            this.props.routeSelectionFormFieldActive === false
          }
        >
          <div className="disclaimer-wrapper">
            <h2>{I18n.t('disclaimer.title')}</h2>
            <p>{I18n.t('disclaimer.body')}</p>
            <button
              className="button login-button large"
              onClick={() => this.handleSeenDisclaimer()}
            >
              {I18n.t('affirm')}
            </button>
          </div>
        </Modal>
        <DefaultTransition
          active={this.props.routeSelectionFormActive === false && !this.props.routeSuggestions}
          offset={300}
        >
          {props => (
            <div className="content-wrapper-top" style={props}>
              <InactiveRouteSelect
                setrouteSelectionFormActive={this.props.setrouteSelectionFormActive}
                userName={this.props.userName}
                activePage={this.props.activePage}
                logout={this.props.logout}
                isAnonymous={this.props.teqplayApiService.isAnonymousUser()}
              />
            </div>
          )}
        </DefaultTransition>

        <DefaultTransition
          active={
            this.props.routeSelectionFormActive === true &&
            this.props.routeSelectionFormFieldActive === false
          }
          slideFrom="left"
          offset={300}
          forceRenderOnHide={!this.props.routeSuggestions}
        >
          {props => (
            <div className="content-wrapper-top" style={props}>
              <RouteSelectForm
                key="routeSelectForm"
                searchAvailableRoutes={this.props.searchAvailableRoutes}
                routeSelection={this.props.routeSelection}
                setRouteSelectionInactive={this.props.setRouteSelectionInactive}
                setrouteSelectionFormFieldActive={this.props.setrouteSelectionFormFieldActive}
                setViaRoutes={this.props.setViaRoutes}
                setFromLocation={this.props.setFromLocation}
                setToLocation={this.props.setToLocation}
                setDepartureTime={this.props.setDepartureTime}
                teqplayApiService={this.props.teqplayApiService}
                startRoute={this.props.startRoute}
                currentLocation={this.props.currentLocation}
                departureTime={this.props.departureTime}
                cruiseSpeed={this.props.cruiseSpeed}
                setCruiseSpeed={this.props.setCruiseSpeed}
              />
            </div>
          )}
        </DefaultTransition>

        <DefaultTransition
          active={
            this.props.routeSelectionFormActive === true &&
            this.props.routeSelectionFormFieldActive === true
          }
          slideFrom="left"
          offset={300}
        >
          {props => (
            <div className="content-wrapper-top" style={props}>
              <LocationSelect
                selectedField={this.props.selectedField}
                selectedViaRouteIndex={this.props.selectedViaRouteIndex}
                routeSelection={this.props.routeSelection}
                navigationLocations={this.props.navigationLocations}
                setRouteSelectionFieldInactive={this.props.setRouteSelectionFieldInactive}
                setFromLocation={this.props.setFromLocation}
                setToLocation={this.props.setToLocation}
                setViaRoutes={this.props.setViaRoutes}
                currentLocation={this.props.currentLocation}
                teqplayAPIService={this.props.teqplayApiService}
                locale={this.props.locale}
              />
            </div>
          )}
        </DefaultTransition>

        {this.props.routeSuggestions && (
          <RouteOptionsSelect
            routeSelection={this.props.routeSelection}
            routeSuggestions={this.props.routeSuggestions}
            selectedRouteSuggestion={this.props.selectedRouteSuggestion}
            resetAvailableRoutes={this.props.resetAvailableRoutes}
            setSelectedRouteSuggestion={this.props.setSelectedRouteSuggestion}
            startRoute={this.props.startRoute}
            teqplayApiService={this.props.teqplayApiService}
            locale={this.props.locale}
            locationPermissions={this.props.locationPermissions}
            departureTime={this.props.departureTime}
          />
        )}
      </>
    )
  }

  public handleSeenDisclaimer() {
    saveToLocalStorage('WATERSPORT-LAST_SEEN_DISCLAIMER', new Date().valueOf())
    this.setState({ showDisclaimer: false })
  }
}

export default RouteSelect
