import { Location } from 'history'
import * as React from 'react'

import SideMenu from '../../components/sideMenu/SideMenu'
import './CustomPage.scss'

interface IProps {
  children: React.ReactChild
  location: Location<any>
  userName: string
  onLogout: () => void
  isAnonymous: boolean

  disableStyling?: boolean
}

const CustomPageWrapper: React.FunctionComponent<IProps> = ({
  children,
  location,
  userName,
  onLogout,
  disableStyling,
  isAnonymous
}) => {
  return (
    <div className={`custom-page-wrapper${disableStyling ? ' disableStyling' : ''}`}>
      <div className="custom-page-header">
        <SideMenu
          activePage={location.pathname}
          userName={userName}
          logout={onLogout}
          isAnonymous={isAnonymous}
        />
      </div>
      <div className="custom-page">
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default CustomPageWrapper
