import React, { useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { INewUser } from '../../services/TeqplayApiService/TeqplayApi'
import { confirmPopup } from '../shared/confirmPopup/ConfirmPopup'
import {
  areShipDimensionsCorrectlyFilledIn,
  showDimensionsErrorMessage
} from '../shared/dimensionUtils'
import InputField from '../shared/inputField/InputField'

import './RegisterForm.scss'

interface IProps {
  handleRegister: (newUser: INewUser) => void
  errorMessage: string | null
  resetErrorMessage: () => void
}

const RegisterForm: React.FunctionComponent<IProps> = props => {
  const scrollableDiv = React.createRef<HTMLDivElement>()
  const [customError, setCustomError] = useState<string | undefined>(undefined)
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [passwordPeek, setPasswordPeek] = useState<boolean>(false)
  const [shipName, setShipName] = useState('')
  const [shipType, setShipType] = useState<string | undefined>(undefined)
  const [length, setLength] = useState<string | undefined>('')
  const [width, setWidth] = useState<string | undefined>('')
  const [height, setHeight] = useState<string | undefined>('')
  const [draught, setDraught] = useState<string | undefined>('')
  const [validationWarningsActive, setValidationWarningsActive] = useState(false)

  const shipTypeOptions = [
    'MOTOR_YACHT',
    'SPEEDBOAT',
    'SAILING',
    'SAILING_WITH_MOTOR',
    'SPORT_FISHERMEN_VESSEL',
    'SAILING_MOTOR_BIG',
    'OTHER_RECREATIONAL_VESSEL'
  ].map(option => ({ value: option, label: I18n.t('shipTypes.' + option) }))

  const validation = {
    emailAddress:
      emailAddress.length > 0 && emailAddress.includes('.') && emailAddress.includes('@'),
    password: password.length > 0,
    repeatPassword: repeatPassword.length > 0,
    shipName: shipName.length > 0,
    shipType: shipType !== undefined
  }

  return (
    <div className="register-form login-container">
      <div className="login-form-inner">
        <div className="login-form-wrapper" ref={scrollableDiv}>
          <Link className="button back-button" to="/login">
            <i className="icon-left-big" />
            <Translate value="register.backToLogin" />
          </Link>
          <div className="login-form-inner-container">
            <div className="login-header">
              <h2>{I18n.t('appTitle')}</h2>
              <p className="welcome-message">{I18n.t('register.createAccount')}</p>
            </div>
            <form onSubmit={submitForm}>
              {(customError || props.errorMessage) && (
                <span className="error-message">{customError || props.errorMessage}</span>
              )}

              <InputField
                label={I18n.t('register.emailAddress')}
                value={emailAddress}
                onChange={(e: React.ChangeEvent<any>) => {
                  handleOnChange(() => setEmailAddress(e.target.value))
                }}
                name="emailAddress"
                id="username"
                required
                autoFocus={true}
                autoCapitalize="off"
                type="email"
              />

              <InputField
                label={I18n.t('register.shipName')}
                value={shipName}
                name="shipName"
                onChange={(e: React.ChangeEvent<any>) => setShipName(e.target.value)}
                required
                autoCapitalize="off"
                type="text"
              />

              <div className="input-wrapper">
                <label>{I18n.t('register.shipType')}*</label>
                <Select<{ value: string; label: string }>
                  value={shipTypeOptions.find(st => st.value === shipType)}
                  onChange={option => {
                    if (option) {
                      handleShipTypeSelect(option as unknown as { label: string; value: string })
                    }
                  }}
                  options={shipTypeOptions}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder={''}
                  isSearchable={false}
                />
              </div>

              <div className="input-wrapper-padded">
                <InputField
                  label={I18n.t('register.password')}
                  value={password}
                  type={passwordPeek ? 'text' : 'password'}
                  name="password"
                  onChange={(e: React.ChangeEvent<any>) =>
                    handleOnChange(() => setPassword(e.target.value))
                  }
                  required
                  autoCapitalize="off"
                />

                <div
                  className="button peek-password"
                  onClick={() => setPasswordPeek(!passwordPeek)}
                >
                  <i className={`icon-${passwordPeek ? 'eye' : 'eye-off'}`} />
                </div>
              </div>

              <div className="input-wrapper-padded no-button">
                <InputField
                  label={I18n.t('register.repeatPassword')}
                  value={repeatPassword}
                  name="repeatPassword"
                  type={passwordPeek ? 'text' : 'password'}
                  onChange={(e: React.ChangeEvent<any>) =>
                    handleOnChange(() => setRepeatPassword(e.target.value))
                  }
                  required
                  autoCapitalize="off"
                />
              </div>

              <h3>{I18n.t('register.dimensionsHead')}</h3>

              <div className="input-wrapper">
                <label htmlFor={'length'}>
                  {I18n.t('register.length')}
                  <span>*</span>
                </label>
                <input
                  name="length"
                  step="any"
                  type="text"
                  value={length}
                  className="textfield"
                  placeholder={'0'}
                  autoCapitalize="off"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setValidationActive(validationWarningsActive)
                    setLength(e.target.value.replace(',', '.'))
                  }}
                />

                {validationWarningsActive &&
                  showDimensionsErrorMessage(I18n.t('register.length'), length, 'field-error')}
              </div>

              <div className="input-wrapper">
                <label htmlFor={'width'}>
                  {I18n.t('register.width')}
                  <span>*</span>
                </label>
                <input
                  name="width"
                  step="any"
                  type="text"
                  value={width}
                  className="textfield"
                  placeholder={'0'}
                  autoCapitalize="off"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setValidationActive(validationWarningsActive)
                    setWidth(e.target.value.replace(',', '.'))
                  }}
                />

                {validationWarningsActive &&
                  showDimensionsErrorMessage(I18n.t('register.width'), width, 'field-error')}
              </div>

              <div className="input-wrapper">
                <label htmlFor={'height'}>
                  {I18n.t('register.height')}
                  <span>*</span>
                </label>
                <input
                  name="height"
                  step="any"
                  type="text"
                  value={height}
                  className="textfield"
                  placeholder={'0'}
                  autoCapitalize="off"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setValidationActive(validationWarningsActive)
                    setHeight(e.target.value.replace(',', '.'))
                  }}
                />

                {validationWarningsActive &&
                  showDimensionsErrorMessage(I18n.t('register.height'), height, 'field-error')}
              </div>

              <div className="input-wrapper">
                <label htmlFor={'draught'}>
                  {I18n.t('register.draught')}
                  <span>*</span>
                </label>
                <input
                  name="draught"
                  step="any"
                  type="text"
                  value={draught}
                  className="textfield"
                  placeholder={'0'}
                  autoCapitalize="off"
                  onChange={(e: React.ChangeEvent<any>) => {
                    setValidationActive(validationWarningsActive)
                    setDraught(e.target.value.replace(',', '.'))
                  }}
                />

                {validationWarningsActive &&
                  showDimensionsErrorMessage(I18n.t('register.draught'), draught, 'field-error')}
              </div>

              {(customError || props.errorMessage) && (
                <span className="error-message">{customError || props.errorMessage}</span>
              )}

              <input
                className="button login-button large"
                type="submit"
                value={I18n.t('register.submit')}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )

  function handleShipTypeSelect(shipTypeOption: { value: string; label: string }) {
    const shipTypeValue = shipTypeOption as { value: string; label: string }
    setShipType(shipTypeValue ? shipTypeValue.value : undefined)
  }

  function setValidationActive(validationActive: boolean) {
    if (validationActive !== true) {
      setValidationWarningsActive(true)
    }
  }

  function handleOnChange(callback: () => void) {
    props.resetErrorMessage()
    setCustomError(undefined)
    callback()
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setCustomError(undefined)

    let errorFound = false

    if (
      !emailAddress ||
      !shipName ||
      !shipType ||
      !password ||
      !repeatPassword ||
      !length ||
      !width ||
      !height ||
      !draught
    ) {
      setCustomError(I18n.t('register.fieldsRequired'))
      return
    }

    if (!validation.emailAddress) {
      setCustomError(I18n.t('register.emailInvalid'))
      return
    }

    if (repeatPassword !== password) {
      setCustomError(I18n.t('register.passwordsNotMatch'))
      return
    }

    const dimensionsCorrect = areShipDimensionsCorrectlyFilledIn(width, height, length, draught)
    if (!dimensionsCorrect) {
      setCustomError(I18n.t('register.pleaseFillRealistic'))
      errorFound = true
    }

    if (errorFound) {
      return
    }

    confirmPopup({
      title: I18n.t('register.gdpr.title'),
      message: <Translate value="register.gdpr.privacyBody" dangerousHTML />,
      className: 'gdpr-popup',
      cancelText: I18n.t('register.gdpr.deny'),
      confirmText: I18n.t('register.gdpr.accept'),
      confirmClassName: 'button large primary',
      cancelClassName: 'button large empty',
      onConfirm: () => {
        createAccount()
      },
      onCancel: () => {
        toast.error(I18n.t('register.gdpr.error'))
      }
    })
  }

  function createAccount() {
    if (
      !emailAddress ||
      !shipName ||
      !shipType ||
      !password ||
      !repeatPassword ||
      !length ||
      !width ||
      !height ||
      !draught
    ) {
      return
    }

    const parsedLength = parseFloat(length)
    const parsedWidth = parseFloat(width)
    const parsedHeight = parseFloat(height)
    const parsedDraught = parseFloat(draught)
    const newUser: INewUser = {
      emailAddress,
      userName: emailAddress,
      password,
      shipName,
      shipType,
      length: parsedLength,
      width: parsedWidth,
      height: parsedHeight,
      draught: parsedDraught
    }

    props.handleRegister(newUser)
  }
}

export default RegisterForm
