export const layersWithZoomLevelRestrictions = [
  {
    layerName: 'AISShips',
    minZoomLevel: 12
  },
  {
    layerName: 'public_berths',
    minZoomLevel: 12
  },
  {
    layerName: 'inlandHarbours',
    minZoomLevel: 11
  },
  {
    layerName: 'bridges',
    minZoomLevel: 11
  },
  {
    layerName: 'trailerSlope',
    minZoomLevel: 11
  },
  {
    layerName: 'kilometre',
    minZoomLevel: 11
  }
]
