import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import BridgeOpeningRequests from '../../bridgeOpeningRequests/BridgeOpeningRequests'
import BridgeDetails from '../../itemDetails/BridgeDetails'
import LockDetails from '../../itemDetails/LockDetails'
import NotificationDetails from '../../itemDetails/NotificationDetails'
import WatermeterDetails from '../../itemDetails/WatermeterDetails'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'
import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'

import { IRootProps } from '../../../@types/types'
import { usePrevious } from '../../../customHooks/usePrevious'
import {
  IBridgeDetails,
  IBridgeOpeningInfo,
  ILockDetails,
  INotificationDetails,
  IRouteItem,
  IWatchDogStatus,
  IWaterMeterDetails
} from '../../../services/TeqplayApiService/TeqplayApi'
import { formatTime } from '../../../utils/dates'
import { getIconNameByType } from '../../../utils/iconUtils'
import { sendExceptionToSentry } from '../../../utils/sentry'
import {
  IFullPosition,
  ILocationPermissions
} from '../../geoLocationWatcher/GeoLocationWatcherTypes'

import '../../itemDetails/ItemDetails.scss'
import './SelectedRouteItem.scss'

interface IProps {
  selectedRouteItem: IRouteItem | null
  teqplayApiService: TeqplayApiService
  bridgeOpeningInfo?: IBridgeOpeningInfo
  currentLocation: IFullPosition | null
  setSelectRouteItem: (routeItem: null) => void
  watchdogStatus: IWatchDogStatus
  locationPermissions: ILocationPermissions
}

const SelectedRouteItem = ({
  selectedRouteItem,
  teqplayApiService,
  bridgeOpeningInfo,
  currentLocation,
  setSelectRouteItem,
  watchdogStatus,
  locationPermissions
}: IProps) => {
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string
  const previousRouteItem = usePrevious(selectedRouteItem)

  const [selectedItemDetails, setSelectedItemDetails] = React.useState<
    IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails | null
  >(null)
  const [loadingItemDetails, setLoadingItemDetails] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isEqual(selectedRouteItem, previousRouteItem)) {
      setSelectedItemDetails(null)
      setLoadingItemDetails(false)
    }
  }, [selectedRouteItem, previousRouteItem])

  return (
    <DefaultTransition
      active={selectedRouteItem !== null ? true : false}
      slideFrom="bottom"
      offset={500}
    >
      {props => (
        <div style={props} className="selected-route-item-wrapper">
          {selectedRouteItem && (
            <div>
              <div className="">
                <div className="route-item-titles">
                  <div className={'icon-wrapper ' + selectedRouteItem.type.toLowerCase()}>
                    <span
                      className={`${getIconNameByType(
                        selectedRouteItem.type,
                        selectedRouteItem
                      )} item-icon ${selectedRouteItem.type.toLowerCase()}-color`}
                    />
                  </div>
                  <h2>{selectedRouteItem.name}</h2>
                  <span
                    className="icon-cancel-1 close-icon"
                    onClick={() => setSelectRouteItem(null)}
                  />
                </div>

                {selectedRouteItem.eta ||
                selectedRouteItem.telephone ||
                selectedRouteItem.vhfChannel ? (
                  <div className="basic-info">
                    {selectedRouteItem.canBePassed !== undefined &&
                      selectedRouteItem.canBePassed === false && (
                        <div className="bridge-warning">
                          {I18n.t('routeList.itemDetails.cannot_pass_bridge')}
                        </div>
                      )}
                    {selectedRouteItem.eta && (
                      <div className="info-item">
                        <span className="info-tag">{I18n.t('routeSelection.arrival')}</span>
                        <span className="info-span">
                          {formatTime(new Date(selectedRouteItem.eta))}
                        </span>
                      </div>
                    )}
                    {selectedRouteItem.telephone && (
                      <div className="info-item">
                        <span className="info-tag">
                          {I18n.t('routeList.itemDetails.phoneNumber')}
                        </span>
                        <span className="info-span">{selectedRouteItem.telephone}</span>
                      </div>
                    )}
                    {selectedRouteItem.vhfChannel && (
                      <div className="info-item">
                        <span className="info-tag">{I18n.t('routeList.itemDetails.vhf')}</span>
                        <span className="info-span vhf">{selectedRouteItem.vhfChannel}</span>
                      </div>
                    )}
                  </div>
                ) : null}

                <BridgeOpeningRequests
                  bridgeRouteItem={selectedRouteItem}
                  bridgeOpeningInfo={bridgeOpeningInfo}
                  teqplayApiService={teqplayApiService}
                  currentLocation={currentLocation}
                  watchdogStatus={watchdogStatus}
                  locationPermissions={locationPermissions}
                />
              </div>

              <div className="route-item-detailed">
                {selectedItemDetails ? (
                  <div
                    className="details-bar selected"
                    onClick={() => setSelectedItemDetails(null)}
                  >
                    <span className="icon-up-open collapse-icon" />
                  </div>
                ) : loadingItemDetails ? (
                  <div className="item-info-details loading">
                    <LoadingIndicator loading={true} />
                  </div>
                ) : (
                  <div
                    className="details-bar"
                    onClick={() =>
                      getRouteItemDetails(selectedRouteItem.type, selectedRouteItem.refUuid)
                    }
                  >
                    {I18n.t('routeList.itemDetails.moreInfo')}
                  </div>
                )}

                {selectedItemDetails &&
                  showRouteDetails(selectedRouteItem.type, selectedItemDetails)}
              </div>
            </div>
          )}
        </div>
      )}
    </DefaultTransition>
  )

  async function getRouteItemDetails(routeItemType: IRouteItem['type'], itemId: string) {
    if (itemId) {
      try {
        setLoadingItemDetails(true)
        const routeItemDetails = await fetchRouteItemDetails(routeItemType.toLowerCase(), itemId)
        setSelectedItemDetails(routeItemDetails)
      } catch (error) {
        sendExceptionToSentry(error)
      } finally {
        setLoadingItemDetails(false)
      }
    } else {
      toast.warn(I18n.t('routeSelection.noDetailsFoundWarning'))
    }
  }

  async function fetchRouteItemDetails(
    type: string,
    itemId: string
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    const itemDetails = teqplayApiService.fetchItemDetails(type, itemId)
    return itemDetails
  }

  function showRouteDetails(
    type: string,
    routeItemDetails: IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails
  ) {
    switch (type) {
      case 'BRIDGE':
        return (
          <BridgeDetails
            isExpanded={true}
            itemDetails={routeItemDetails as IBridgeDetails}
            locale={locale}
            currentLocation={currentLocation}
            watchdogStatus={watchdogStatus}
            teqplayApiService={teqplayApiService}
            locationPermissions={locationPermissions}
          />
        )
      case 'LOCK':
        return <LockDetails isExpanded={true} itemDetails={routeItemDetails as ILockDetails} />
      case 'WATERMETER':
        return (
          <WatermeterDetails
            isExpanded={true}
            itemDetails={routeItemDetails as IWaterMeterDetails}
          />
        )
      case 'NOTIFICATION':
        return (
          <NotificationDetails
            isExpanded={true}
            itemDetails={routeItemDetails as INotificationDetails}
          />
        )
      default:
        return null
    }
  }
}

export default SelectedRouteItem
