import { IRouteItem } from '../services/TeqplayApiService/TeqplayApi'

export function getIconNameByType(itemType: IRouteItem['type'], item?: IRouteItem) {
  switch (itemType) {
    case 'BRIDGE':
      return item && item.openingRequired ? 'icon-bridge-open' : 'icon-bridge-closed'
    case 'LOCK':
      return 'icon-lock'
    case 'NOTIFICATION':
      return 'icon-notification-bell'
    case 'WATERMETER':
      return 'icon-waves'
    case 'VHFSECTOR':
      return 'icon-vts-sector'
    default:
      return 'icon-location'
  }
}
