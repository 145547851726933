import * as React from 'react'
import Sidebar from 'react-sidebar'
import Menu from './menu/Menu'
import './SideMenu.scss'

interface IProps {
  activePage: string
  userName: string
  logout: () => void
  isAnonymous: boolean
}

interface IState {
  sidebarOpen: boolean
}

class SideMenu extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    sidebarOpen: false
  }

  public render() {
    return (
      <Sidebar
        sidebar={
          <Menu
            toggleSidebarOpen={this.toggleSidebarOpen}
            activePage={this.props.activePage}
            userName={this.props.userName}
            logout={this.props.logout}
            isAnonymous={this.props.isAnonymous}
          />
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.handleSetSidebarOpen}
        rootClassName="sidebar-root"
        sidebarClassName="sidebar"
        contentClassName="sidebar-content"
        overlayClassName="sidebar-overlay"
      >
        <div className="menu-select" onClick={this.toggleSidebarOpen}>
          <i className="icon-menu " />
        </div>
      </Sidebar>
    )
  }

  public handleSetSidebarOpen = () => {
    this.setState({ sidebarOpen: true })
  }

  public toggleSidebarOpen = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen })
  }
}

export default SideMenu
