const deLocale = {
  appTitle: 'Watersport',
  version: 'Version %{version}',
  save: 'Speichern',
  cancel: 'Abbrechen',
  affirm: 'Verstanden',
  select: 'Auswählen',
  generalError:
    'Leider ist ein Fehler aufgetreten, probieren Sie es später noch einmal oder kontaktieren Sie support@teqplay.nl.',
  now: 'Jetzt',
  yes: 'Ja',
  no: 'Nein',
  ok: 'OK',
  latitude: 'Breitengrad',
  longitude: 'Längengrad',

  units: {
    kmh: 'km/h',
    knots: 'Knoten',
    kilowatt: 'kW',
    amps: 'A'
  },

  locale: {
    selectLocale: 'Sprache',
    nl_NL: 'Niederländisch',
    en_GB: 'Englisch',
    de_DE: 'Deutsch',
    nl_FRL: 'Friesisch',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    de_DE_native: 'Deutsch',
    frl_NL_native: 'Frysk'
  },

  login: {
    anonymously: 'Weitermachen ohne Account',
    welcome: 'Willkommen! Melden Sie sich an, um fortzufahren.',
    email: 'E-Mail',
    password: 'Passwort',
    submit: 'Anmelden',
    noUser: 'Noch kein Benutzer:',
    policy: 'Privacy policy',
    registerLink: 'Registrieren',
    wrongCredentials: 'Falscher Benutzername oder falsches Passwort.',
    wrongAccountType: 'Ihr Accounttyp ist für die Watersport-App nicht geeignet',
    forgotPassword: 'Passwort vergessen?',
    forgotpassemail: 'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen',
    forgotpassconfim:
      'Wir haben Ihre Anfrage erhalten. Wir werden diese so schnell wie möglich bearbeiten und über die E-Mail Kontakt zu Ihnen aufnehmen.',
    send: 'Senden',
    newAppMessage:
      'Watersport wurde erneuert! Accounts, die vor Oktober 2020 erstellt wurden, können möglicherweise nicht mehr zur Anmeldung verwendet werden. Erstellen Sie einen neuen Account.'
  },
  // // Possibilty to add translations
  policy: {
    permission:
      'When you create an account for this Watersports app, you are granting permission for the processing of your data. We want you to understand what data we collect and how we use it.',
    storage:
      'When you sign up for a Watersports account, we store the account information you provide. This includes your vessel name, name, email address, and password. We also retain information about your location and your device data.',
    dataCollection:
      'When you use the Watersports app, we collect data to provide and improve our services. Your data is accessible to the Province of Friesland, other waterway authorities, and the app administrator, Teqplay. The purpose of using your data is to allow you, as a waterway user, to make the best possible use of the Frisian waterways. Your data is used to deliver our service and manage your user account.',
    privacyManagement:
      'You can manage your privacy in the Watersports app. You can access your data and delete your account at any time. After the termination of your account, personal data is immediately deleted. However, we retain location data for our statistics. Personal data is retained as long as you have an account.',
    dataProtection:
      "We protect your data and our applications against unauthorized use. That's why we encrypt many of our services with SSL and evaluate our approach to data collection, storage, and processing. Access to personal data is limited to employees and waterway authorities who need this data to provide our service.",
    lastUpdated:
      "Last updated: 26th October 2023 Teqplay ('us', 'we', or 'our') operates Watersport (the 'platform'). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the platform. We use your Personal Information only for providing and improving the platform. By using the platform, you agree to the collection and use of information in accordance with this policy.",
    personal:
      'Personal information: We collect some personal information that makes us able to separate one user from the other. This information is used to create your account in our systems and may include, but is not limited to your name, E-Mail address and mobile phone number',
    device:
      'Device information: We collect a specific set of device information. This information includes information about the hardware model, the version of the running operating system, unique device identifiers and the status of the wireless network.',
    account:
      'Account information: Communication is a core functionality of the platform. By sending you notifications we can let you know about important events that take place. In order to do this we need to know the Google account identifier that belongs to the Google account you use on your phone.',
    thirdParties: 'Third parties:',
    ageRestriction:
      'Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly collect personal information from anyone under 13.',
    cookies:
      "Like many websites and platforms, we use 'cookies' to collect information. We use these cookies to prevent you from having to log in each time you use the platform, for example.",
    security:
      'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
    changesToPrivacyPolicy:
      'we reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.',
    contactUs:
      'If you have any questions about this Privacy Policy, please contact us. For our contact details:',
    title: {
      children: 'Children',
      cookies: 'Cookies',
      security: 'Security',
      contactUs: 'Contact us',
      changes: 'Changes to this Privacy Policy',
      watersport: 'Watersport Privacy Policy'
    },
    back: 'Back'
  },

  anonymous: {
    notAllowed:
      'Zur Benutzung dieser Funktion müssen Sie sich anmelden. Klicken Sie auf "Account erstellen" und erstellen Sie einen kostenlosen Account',
    createAccount: 'Erstellen Sie einen Account',
    cancel: 'Später'
  },

  shipTypes: {
    select: 'Bootstyp auswählen',
    MOTOR_YACHT: 'Motoryacht',
    SPEEDBOAT: 'Speedboat',
    SAILING: 'Segelboot',
    SAILING_WITH_MOTOR: 'Segelboot mit Motor',
    SPORT_FISHERMEN_VESSEL: 'Boot für Angler',
    SAILING_MOTOR_BIG: 'Segel- oder Motorboot größer als 20m',
    OTHER_RECREATIONAL_VESSEL: 'Andere Freizeitboote'
  },

  register: {
    createAccount: 'Erstellen Sie einen Account',
    submit: 'Registrieren',
    done: 'Erfolgreich!',
    backToLogin: 'Zurück zur Anmeldung',
    emailAddress: 'E-Mail',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    phoneNumber: 'Telefonnummer',
    shipName: 'Name des Bootes',
    shipType: 'Bootstyp',
    length: 'Bootslänge (m)',
    width: 'Bootsbreite (m)',
    height: 'Bootshöhe (m)',
    draught: 'Tiefgang des Boots (m)',
    dimensionsHead: 'Bootsmaße',
    pleaseFillRealistic: 'Die Bootsmaße müssen zwischen 0 und 100 m sein',
    passwordsNotMatch: 'Die Passwörter stimmen nicht überein',
    fieldsRequired: 'Bitte alle Felder mit (*) ausfüllen',
    alreadyExists: 'Es wurde bereits ein Account für diese E-Mail-Adresse erstellt',
    emailInvalid: 'Die eingegebene E-Mail-Adresse ist ungültig',
    failedToFetch:
      'Verbindung zum Server kann nicht hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung.',
    serverError:
      'Leider ist ein unbekannter Fehler aufgetreten. Versuchen Sie es noch einmal oder kontaktieren Sie support@teqplay.nl',
    gainedAccessToVarenFriesland:
      "Ihr bereits erstellter Teqplay-Account hat jetzt Zugang zu Watersport. Melden Sie sich mit Ihren Zugangsdaten an oder fordern Sie über den Button 'Passwort vergessen' ein neues Passwort für dieselbe E-Mail-Adresse an.",

    gdpr: {
      title: 'Datenschutzerklärung',
      privacyBody:
        'Wenn Sie für diese Watersport-App einen Account erstellen, erklären Sie sich mit der Verarbeitung Ihrer Daten einverstanden. Es ist uns wichtig, dass Ihnen deutlich ist, welche Daten wir sammeln und wie wir diese verwenden.' +
        '<br /><br />Wenn Sie einen Watersport-Account erstellen, speichern wir die Accountdaten, die Sie angeben. Das sind der Name Ihres Bootes, Ihr Name, Ihre E-Mail-Adresse und Ihr Passwort. Wir speichern auch Ihre Standort- und Ihre Gerätdaten.' +
        '<br /><br />Wenn Sie die Watersport-App verwenden, sammeln wir Daten, um unsere Dienstleistungen anbieten und verbessern zu können.' +
        '<br /><br />Die Provinz Friesland, andere Wasserstraßenverwaltungen und der App-Manager Teqplay haben Zugang zu Ihren Daten. Wir verwenden Ihre Daten mit dem Ziel, Ihnen eine angenehme Benutzung der friesischen Wasserstraßen zu ermöglichen. Ihre Daten werden zum Liefern unserer Servicedienstleistungen und der Verwaltung Ihres Benutzer-Accounts verwendet.' +
        '<br /><br />In der Watersport-App können Sie Ihre Datenschutzangaben verwalten. Sie haben jederzeit Zugang zu Ihren eigenen Daten und können Ihren Account jederzeit löschen. Unmittelbar nach dem Löschen Ihres Account werden Ihre persönlichen Daten gelöscht. Ihre Standortdaten werden allerdings für unsere Statistiken gespeichert. Ihre persönlichen Daten werden gespeichert, solange Sie über einen Account verfügen.' +
        '<br /><br />Wir schützen Ihre und unsere Daten und Applikationen vor unbefugter Verwendung. Darum kodieren wir viele unserer Dienstleistungen mit SSL und evaluieren wir unsere Arbeitsweise in Bezug auf das Sammeln, Speichern und Verarbeiten von Daten. Der Zugang zu persönlichen Daten beschränkt sich auf unsere Mitarbeiter und die Verwalter von Wasserstraßen, die diese Daten zum Ausführen ihrer Dienstleistungen benötigen.' +
        '<br /><br />Bei Fragen zu diesem Haftungsausschluss können Sie Kontakt aufnehmen mit: provincie@fryslan.frl',
      accept: 'Zustimmen',
      deny: 'Ablehnen',
      error:
        'Sie müssen den Datenschutzbestimmungen zustimmen, um die Watersport-App nutzen zu können.',
      view: 'Bedingungen lesen',
      close: 'Schließen'
    }
  },

  routeSelection: {
    routeNumbered: 'Route %{routeNumber}',
    selectRoutePlaceholder: 'Wählen Sie Ihre Reise',
    startingPoint: 'Startpunkt',
    destinationPoint: 'Ziel',
    selectStartingPoint: 'Wählen Sie Ihr Ziel',
    selectDestination: 'Wählen Sie Ihren Startpunkt',
    selectViaPoint: 'Wählen Sie ein Zwischenziel',
    viaPoint: 'Zwischenziel',
    options: 'Optionen',
    calculateRoute: 'Route berechnen',
    arrival: 'Ankunft',
    departure: 'Abreise',
    searchOnLocation: 'Standortsuche',
    noOptions: 'Keine Standorte gefunden',
    searchAdvice: 'Standortsuche',
    currentLocation: 'Aktueller Standort',
    selectOnMap: 'Standort auf der Karte wählen',
    distance: 'Distanz',
    arrivalTime: 'Ankunftszeit',
    viewList: 'Listenwiedergabe',
    startRoute: 'Route starten',
    deleteRoute: 'Route löschen',
    saveRoute: 'Speichern',
    cancel: 'Abbrechen',
    list: 'Objekte',
    details: 'Info',
    map: 'Karte',
    notAllowedDimensions: 'Diese Route ist mit den angegebenen Bootsmaßen nicht befahrbar.',
    notAllowedBridge:
      'Sie können mit der angegebenen Bootshöhe nicht unter allen Brücken durchfahren.',
    noRoutesFoundWarning: 'Für diese Strecke wurden keine Routen gefunden',
    noDetailsFoundWarning: 'Für dieses Objekt sind keine Details verfügbar',
    routeDetails: 'Routeninformationen',
    routeStoredSucces:
      'Die Route wurde gespeichert. Beim Auswählen der Standorte sehen Sie die gespeicherten Routen.',
    showSavedRoutes: 'Gespeicherte Routen anzeigen',
    hideSavedRoutes: 'Gespeicherte Route ausblenden',
    fromTo: '%{from} nach %{to}',
    dateSaved: 'Gespeichert am',
    saveRouteTitle: 'Route speichern',
    noNameFilled: 'Geben Sie einen Namen ein, um die Route zu speichern',
    routeNameInput: 'Name der Route',
    selectionWarning: 'Wählen Sie einen Startpunkt und ein Ziel',
    pauseRoute: 'Route pausieren',
    resumeRoute: 'Route fortsetzen',
    noLocationWarning: 'Schalten Sie zum Starten der Route Ihr GPS ein.',
    shipDepth: 'Untiefste Stelle',
    shipHeight: 'Niedrigste Durchfahrtshöhe',
    maxWidth: 'Engste Stelle',
    waitingTime: 'Voraussichtliche Wartedauer',
    duration: 'Gesamtzeit',
    createGeolocation: '"%{value}"',
    node: '%{value} (Knoten)'
  },

  routeList: {
    itemDetails: {
      opening_required:
        'Für Ihr Boot ist eine Brückenöffnung erforderlich. Brückenöffnung selbst anfordern.',
      opening_required_bms_bridge:
        'Für Ihr Boot ist eine Brückenöffnung erforderlich. Diese wurde automatisch angefordert.',
      cannot_pass_bridge: 'Achtung! Ihr Boot kann nicht unter dieser Brücke durchfahren.',
      opening_requested: 'Eine Brückenöffnung wurde angefordert',
      opening_planned: 'Die Brücke wird in Kürze geöffnet',
      communication: 'Kommunikation',
      vhf: 'VHF',
      control_times: 'Betriebszeiten',
      yes: 'Ja',
      no: 'Nein',
      NAP: 'NAP (Normalnull in den Niederlanden)',
      opening: 'Öffnung',
      type: 'Typ',
      opening_width: 'Breite',
      width: 'Breite',
      length: 'Länge',
      closed_height: 'Höhe in geschlossenem Zustand',
      opened_height: 'Höhe in geöffnetem Zustand',
      telephone: 'Tel',
      lock_chamber: 'Schleusenbecken',
      max_width: 'Max. Breite (m)',
      threshold_outside: 'Schwelle unten/außen',
      threshold_inside: 'Schwelle oben/innen',
      address: 'Adresse zu Land',
      from: 'Von',
      to: 'bis',
      wholeDay: 'Ganzer Tag',
      bridge_type: {
        VST: 'Fest',
        DR: 'Drehbrücke',
        BC: 'Klappbrücke',
        OPH: 'Zugbrücke',
        HEF: 'Hebebrücke',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'Diese Brücke ist momentan geöffnet.',
        closed: 'Diese Brücke ist momentan geschlossen.',
        unknown: 'Der aktuelle Status dieser Brücke ist unbekannt.'
      },
      bridge_operating_rules: {
        today: 'Heute',
        tomorrow: 'Morgen',
        holiday: 'Feiertage',
        noService: 'Keine Bedienung'
      },
      bridgeRecommendation: {
        AFSC: 'Abstieg',
        AFVT: 'Talfahrt',
        BERP: 'Nur für die Handelsschifffahrt',
        BSCH: 'Geführte schifffahrt',
        BVAF: 'Nur Handelsschifffahrt auf Abruf',
        ETAA: 'Endzeit je nach Angebot',
        FACO: 'Optionale Öffnung',
        GEEN: 'Keine Operation',
        HAR: 'Nur Richtung Haringvliet',
        KAN: 'Nur Richtung Kanal',
        KONV: 'Konvoi',
        OPEN: 'Ist (allgemein) geöffnet',
        OPSC: 'Aufstieg',
        OPVT: 'Bergfahrt',
        RECR: 'Freizeitschifffahrt',
        STDU: 'Nur aus der Stadt hinaus',
        VERZ: 'Nur auf Abruf',
        ZELF: 'Selbstbedienung',
        ZUID: 'Nur südwärts'
      },
      no_items_found: 'Für dieses Objekt sind keine Details verfügbar',
      waitingTime: '%{waitingTime} voraussichtliche Wartedauer für folgende %{objectType}',
      note: 'Anmerkung',
      bridgeOpeningNote: 'Anmerkung zum Öffnen der Brücke',
      chamberNote: 'Anmerkung zum Schleusenbecken',
      waterLevel: 'Wasserstand',
      operable: 'Bedienbar',
      phoneNumber: 'Telefonnummer',
      moreInfo: 'Weitere Informationen'
    },
    objectTypes: {
      BRIDGE: 'Brücke',
      LOCK: 'Schleuse',
      START: 'Anfang',
      END: 'Ende',
      NOTIFICATION: 'Meldung',
      WATERMETER: 'Wassermesser',
      TRAFFIC_SIGN: 'Verkehrsschild',
      BOTTLENECK: 'Verengung',
      VHFSECTOR: 'VHF Sektor',
      UNKNOWN: 'unbekanntes Objekt'
    }
  },

  navigationPage: {
    destination: 'Ziel',
    arrival: 'Ankunft',
    next_object: 'Folgendes Objekt',
    no_user_location_warning: 'Keinen GPS-Standort gefunden',
    no_notifications: 'Es wurden keine Meldungen zum aktuellen Standort gefunden',
    list_view: 'Liste',
    notifications: 'Benachrichtigungen',
    ship_speed: 'Geschwindigkeit',
    route_paused_warning: 'Route pausiert',
    stop_route_confirmation: 'Sind Sie sicher, dass Sie die Route stoppen möchten?',
    pause_route: 'Route pausieren',
    stop_route: 'Route stoppen',
    resume_route: 'Route fortsetzen'
  },

  modalSelectPreviousRoute: {
    question: 'Möchten Sie Ihre vorige Route fortsetzen?',
    yes: 'Ja',
    no: 'Nein'
  },

  pages: {
    welcome: 'Willkommen',
    feedback: 'Meldung machen',
    navigation: 'Navigation',
    myProfile: 'Mein Profil',
    logout: 'Ausloggen',
    loginLink: 'Anmelden',
    register: 'Konto anmelden',
    policy: 'Privacy Policy'
  },

  map: {
    followButton: 'Schiff zentrieren',
    speedButton: 'Geschwindigkeit einstellen',
    activelayers: 'Kartenebenen auswählen',

    category: {
      categoryBerths: 'Liegeplätze',
      categoryFairway: 'Wasserwege',
      categoryElements: 'Elemente',
      maptype: 'Karte'
    },

    crossings: 'VDJS-Knotenpunkten',
    public_berths: 'Öffentliche Liegeplätze',
    berthsOutside: 'Liegeplätze Außenbereich',
    fairways: 'Wasserwege',
    CEMTClass: 'CEMT Klasse',
    fairwayDepth: 'Tiefe',
    recreationalClass: 'Freizeitklasse',
    electricRoutes: 'Elektrische Routen',
    contractDepth: 'Verbindungstiefe',
    levelDepth: 'Niveautiefe',
    inlandHarbours: 'Yachthäfen',
    chargingStations: 'Ladestationen',
    bridges: 'Brücken',
    kilometre: 'Kilometerstand',
    trailerSlope: 'Slipanlagen',
    wasteWaterStations: 'Schmutzwasser im Hafen',
    nodes: 'Knoten',
    winterRestAreas: 'Vogelruhebereich',
    AISShips: 'Schifffahrt',
    fastRoutes: 'Schnellfahrtzone',
    fastRoutesNoInfo: 'Keine Informationen verfügbar',

    speed: {
      title: 'Zur Berechnung der Ankunftszeit Geschwindigkeit einstellen',
      useShip: 'Bootsgeschwindigkeit verwenden',
      useCustom: 'Selbst einstellen',
      setRealisticSpeed: 'Stellen Sie eine Geschwindigkeit zwischen 1 und 50 km/h ein',
      saved: 'Die Änderung wurde gespeichert'
    },

    details: 'details',

    notification: {
      routeNotifications: 'Benachrichtigungen Wasserwege',
      tooMuchBerths: 'Zu viele Liegeplätze gefunden, zum Anzeigen der Liegeplätze bitte einzoomen.',
      approachVdjsHeader: 'VDJS Knotenpunkt',
      approachingVdjsCrossing:
        "Sie nähern sich Knotenpunkt %{crossingName}. Für diesen Knotenpunkt sind empfohlene Routen von 'Varen Doe je Samen' (VDJS) verfügbar.",
      approachingVdjsCrossingShort: 'Sie nähern sich Knotenpunkt %{crossingName}.',
      show: 'Auf der Karte anzeigen',
      channelNotificationHeader: 'Verkehrsreiche Wasserstraße',
      nearingChannelNotification:
        'Sie nähern sich %{channelName}. Passen Sie beim Fahren gut auf, hier ist viel Berufsschifffahrt unterwegs.',
      zoomInNotification: 'Zoomen Sie ein, um die folgenden Kartenebenen auf der Karte anzuzeigen:'
    }
  },

  myProfile: {
    title: 'Mein Profil',
    titleProfile: 'Profildaten',
    titlePasswordChange: 'Passwort ändern',
    titleShipDimensions: 'Bootsmaße',
    myShip: 'Mein Boot',
    password: 'Passwort',
    email: 'E-Mail',
    mobile: 'Handynummer',
    mmsi: 'MMSI',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    repeatNewPassword: 'Neues Passwort wiederholen',
    save: 'Speichern',
    newPasswordNoMatch: 'Die neuen Passwörter stimmen nicht überein',
    updatedPasswordSuccess: 'Ihr Passwort wurde gespeichert',
    wrongPassword: 'Das aktuelle Passwort ist falsch',
    tooWeak: 'Ihr neues Passwort ist zu schwach',
    titleDeleteAccount: 'Profil löschen',
    selectAShip: 'Wählen Sie ein Boot',
    updateProfileSuccess: 'Profil wurde aktualisiert',
    updateProfileFailed:
      'Leider ist beim Speichern Ihres Profils ein Fehler aufgetreten. Versuchen Sie es später noch einmal oder kontaktieren Sie support@teqplay.nl',
    areYouSureDeleteAccount:
      'Sind Sie sicher, dass Sie Ihr Profil löschen möchten? Diese Handlung kann nicht widerrufen werden.',
    removeSuccess: 'Profil wurde gelöscht',
    no: 'Nein',
    yes: 'Ja',
    yesDelete: 'Ja, löschen',
    dimensionsErrorMessage: '* Die Maße müssen zwischen 0 und 100 m liegen.',
    dimensionsWarningMessage: '* Fügen Sie einen Wert ein.',
    dimensionsSaveWarning: 'Die Bootsmaße wurden nicht korrekt eingegeben.',
    ship: {
      length: 'Länge (m)',
      width: 'Breite (m)',
      height: 'Höhe (m)',
      draught: 'Tiefgang des Boots (m)'
    },

    passwordTips: {
      title: 'Anforderungen an das Passwort',
      match: 'Passwörter sind gleich',
      noMatch: 'Passwörter sind nicht gleich',

      requirements: 'Passwort-Anforderungen',
      requirementsInfo:
        'Wir möchten, dass Ihr Passwort so sicher wie möglich ist, um Sie und Ihre Daten zu schützen. Deshalb haben wir die folgenden Anforderungen an Ihr Passwort.',

      tips: 'Tipps für ein sicheres Passwort',
      tipsCasing: 'Verwenden Sie sowohl Klein- als auch Großbuchstaben',
      tipsNumber: 'Verwenden Sie eine Ziffer',
      tipsSpecialCharacters: 'Verwenden Sie ein Sonderzeichen wie !@#$%',
      tipsLength: 'Verwenden Sie 8 oder mehr Zeichen',
      tipsInfo:
        'Wir möchten, dass Ihr Passwort so sicher wie möglich ist, um Sie und Ihre Daten zu schützen. Deshalb haben wir die folgenden Anforderungen an Ihr Passwort.',
      tipsUnique: 'Verwenden Sie ein eindeutiges Passwort, das Sie nicht für alles verwenden',
      tipsUniqueSubtitle:
        'Dies ist natürlich nicht erforderlich und wir können dies auch nicht wissen oder überprüfen, da Ihr Passwort verschlüsselt ist. Aber für die höchsten Sicherheitsstandards bitten wir Sie, ein eindeutiges Passwort zu erstellen.',
      tipsNoPersonalInfo:
        'Verwenden Sie keine leicht erratbaren persönlichen Informationen in Ihrem Passwort',
      tipsStoreSafely: 'Speichern Sie Ihre Kennwörter sicher',
      tipsStoreSafelySubtitle:
        'Wir empfehlen die Verwendung eines Passwort-Managers. Sie können sich das Passwort auch aufschreiben und an einem sicheren Ort aufbewahren.',

      allMet: 'Das ist ein sicheres Passwort, prima! 👍',
      abideToAll: 'Ihr neues Passwort entspricht nicht den Anforderungen'
    }
  },

  feedbackPage: {
    title: 'Meldung machen',
    description: 'Beschreibung',
    descriptionText: 'Fügen Sie der Meldung eine Beschreibung hinzu',
    location: 'Standort',
    locationText: 'Wählen Sie einen Ort auf der Karte, den Ihre Meldung oder Anmerkung betrifft.',
    photo: 'Foto',
    photoText: 'Fügen Sie ein Foto für den betreffenden Ort hinzu',
    uploadText: 'Fügen Sie ein Foto hinzu, indem Sie es ziehen oder darauf klicken',
    sendFeedback: 'Meldung absenden',
    warningMessage: 'Fügen Sie der Meldung eine Beschreibung hinzu',
    successMessage: 'Ihre Meldung wurde versendet, vielen Dank für Ihre Mitarbeit!',
    errorMessage:
      'Beim Versenden Ihrer Meldung ist ein Fehler aufgetreten, bitte entschuldigen Sie die Unannehmlichkeiten.',
    done: 'Fertig',
    chooseLocation: 'Hier klicken, um einen Standort auf der Karte wählen',
    noLocationKnown: 'Kein Standort bekannt',
    noEmergencies:
      'Diese Funktion ist nicht für Notfälle bestimmt, rufen Sie in Notfälle immer 112 an.'
  },

  mobile: {
    navigation_active: 'Navigation aktiv',
    navigation_active_destination: 'Navigation nach %{destination} aktiv',
    offline_title: 'Keine Verbindung',
    offline_text: 'Es besteht keine Verbindung, die Route wird pausiert.',
    high_speed_title: 'Sie fahren zu schnell',
    high_speed_text:
      'Fahren Sie noch? Sie fahren schneller als 80 km/h, stoppen Sie die Route, wenn Sie nicht mehr in der App fahren.'
  },

  mapLayers: {
    last_updated: 'Neuestes Update',
    past: 'vor',
    berthLayer: {
      width: 'Breite',
      length: 'Länge',
      draught: 'Tiefgang',
      berthName: 'Name des Liegeplatzes',
      owner: 'Verwalter',
      dangerousGoodsLevel: 'Kegel',
      mooringOptions: 'Anlegemöglichkeiten',
      mooringServices: 'Anlegedienstleistungen',
      mooringFunctions: 'Anlegefunktionen',
      mooringHourTimeLimit: 'Anlegedauer',
      shoreConnection: 'Landanschluss',
      sort: 'Typ',
      viewFotoURL: 'Foto vom Liegeplatz ansehen'
    },

    chargingStations: {
      city: 'Stadt',
      chargingPointAvailable: 'Ladestation verfügbar',
      publicAvailable: 'Öffentliche Ladestation',
      greenPower: 'Ökostrom',
      paymentMethod: 'Bezahlmethode',
      paymentMethods: {
        CASH: 'Bargeld',
        CARD: 'Kartenzahlung',
        CASH_AND_CARD: 'Bar und Karte',
        FREE: 'Kostenlos',
        UNKNOWN: 'Unbekannt'
      },
      comments: 'Anmerkungen',
      chargingPossibilities: 'Lademöglichkeiten',
      unavailable: 'Nicht verfügbar',
      connections: 'Anschlüsse'
    },

    inlandHarbours: {
      city: 'Stadt',
      shortStayPlaces: 'Liegeplätze für kurzen Aufenthalt',
      longStayPlaces: 'Liegeplätze für längeren Aufenthalt',
      suppliesFuel: 'Treibstoff erhältlich'
    },

    wasteWaterStations: {
      city: 'Stadt',
      address: 'Adresse',
      containsWasteWater: 'Enthält Schmutzwasser',
      bilge: 'Bilge',
      phone: 'Telefon'
    }
  },

  pushNotification: {
    buttonClose: 'OK'
  },

  geolocationStrings: {
    warning: 'Warnung',
    info: 'Information',
    deniedPermission: 'Die App hat keine Zustimmung zur Verwendung Ihres Standortes.',
    clickToRetry: 'Bitte klicken, um es nochmals zu versuchen',
    stillDenied:
      'Die App hat noch immer keine Zustimmung. Gehen Sie zu Einstellungen > Apps > Watersport > Rechte/Zustimmungen und stellen Sie Standortbestimmung ein.',
    clickToMinimize: 'Klicken Sie hier, um zu verkleinern',

    androidWhenActive: 'Standortbestimmung aktiv',
    androidChannelName: 'GPS Standortbestimmung aktiv',
    androidPermissionDeniedInfo: 'Ohne Standortbestimmung funktioniert die App nur eingeschränkt',

    whenInUse:
      'Zur Verwendung der Standortbestimmung im Hintergrund und um aktuelle Meldungen zu empfangen, müssen sie für diese App unter "Standort" einwilligen, diesen "Immer" zu verwenden.',
    instructions:
      'Zur Verwendung der Standortbestimmung im Hintergrund und um aktuelle Meldungen zu empfangen, müssen sie für diese App unter "Standort" einwilligen, diesen "Immer" zu verwenden.',
    cancelButton: 'Abbrechen',
    settingsButton: 'Einstellungen',
    iosTitleWhenNotEnabled: 'Standortbestimmung im Hintergrund ausgeschaltet',
    iosTitleWhenOff: 'Standortbestimmung ausgeschaltet',

    // backgroundPermissionRationale settings
    androidAllowAlwaysQuestion: 'Zugriff auf den Standort dieses Geräts im Hintergrund?',
    androidChangeAllTheTime: 'Ändern in "Immer zulassen"',

    errors: {
      0: 'Standort ist unbekannt',
      1: 'Standort kann aufgrund von Berechtigungsproblemen mit der App nicht abgerufen werden',
      2: 'Standort kann aufgrund eines Netzwerkfehlers nicht abgerufen werden',
      408: 'Standort kann nicht ermittelt werden, weil es zu lange gedauert hat',
      499: 'Die Standortbestimmung wurde abgebrochen'
    }
  },

  disclaimer: {
    title:
      'Bitte beachten Sie, dass sich die Watersport-App noch in der Entwicklungsphase befindet.',
    body:
      'Konzentrieren Sie sich aufs Fahren und befolgen Sie die Verkehrsregeln. ' +
      'Es kann sein, dass die Routen unvollständig oder inkorrekt sind. ' +
      'Es kann vorkommen, dass die Daten nicht immer in Echtzeit aktualisiert werden und die Standortgenauigkeit nicht immer stimmt. ' +
      'Teqplay, seine Auftraggeber und Datenanbieter haften nicht für Schäden, die durch die Verwendung dieser Applikation entstehen.',
    readMore: 'Weiterlesen',
    dontShowAgain: 'Zeige das nicht noch einmal',
    bridgesTitle2022: 'Brücken und Schleusen 2022',
    bridges2022:
      'Brücken und Schleusen Kornwerderzand: In der Freizeitsaison 2022 wird mit Rießenandrang gerechnet.'
  },

  prominentDisclosure: {
    title: 'Standort verwenden',
    approve: 'Einverstanden',
    reject: 'Nicht einverstanden',
    explanation: 'Diese App verwendet Standortdaten, um die folgenden Funktionen zu ermöglichen:',
    feature1: 'Navigieren auf einer Route',
    feature2: 'Berechnung von Routen von/zu Ihrem Standort',
    feature3: 'Berechnen von Ankunftszeiten während der Fahrt',
    feature4: 'Anfragen für Brückenöffnungen an Ihrem Standort',
    feature5: 'Anzeigen Ihres Standorts auf der Karte',
    background:
      'Die App kann diese Funktionen möglicherweise auch dann nutzen, wenn die App im Hintergrund läuft oder geschlossen ist.',
    notProperly:
      'Wenn Sie diese Berechtigungen nicht zulassen, funktioniert die App möglicherweise nicht wie vorgesehen.'
  },

  bridgeOpeningRequests: {
    tooFar:
      'Sie sind noch zu weit von der Brücke entfernt, um eine Öffnungsanfrage einreichen zu können. Sobald Sie sich im Anfragegebiet befinden, können Sie eine Brückenöffnung anfordern.',
    applyForRequest: 'Anmeldeanfrage einreichen',
    errorText: 'Fehler',
    error: 'Beim Einreichen Ihrer Öffnungsanfrage ist ein Fehler aufgetreten',
    canOpen: 'Für diese Brücke kann eine Brückenöffnung beantragt werden',
    onlyOnce: 'Diese Meldung wird nur einmal angezeigt.',
    previousRequest:
      'Sie haben für diese Brücke bereits %{amount}x eine Brückenöffnung angefordert. Das letzte Mal war um %{time}.',
    downloadApp:
      'Laden Sie die Watersport-App auf Ihr Handy oder Tablet, um eine Anfrage für diese Brücke zu stellen.',
    notHealthy:
      'Die Verbindung zum Brückenöffnungsanfragesystem ist fehlgeschlagen. Derzeit können keine Brückenöffnungen über die App beantragt werden.',
    needApproval:
      'Sie haben noch keine Genehmigung für die Standortnutzung erteilt, diese ist erforderlich, um Brückenöffnungen anzufordern.',
    retry: 'Erlauben',

    response: {
      BRIDGE_MAINTENANCE:
        'An dieser Brücke finden Wartungsarbeiten statt. ' +
        'Eine Bedienung ist derzeit nicht möglich. ' +
        'Wir entschuldigen uns für die Unannehmlichkeiten. ' +
        '\nFolgen Sie den Signalen auf der Brücke für weitere Anweisungen und/oder achten Sie auf Schifffahrts-Updates auf Twitter @ScheepvaartFRL',
      BRIDGE_ERROR:
        'Diese Brücke ist derzeit außer Betrieb. ' +
        'Eine Bedienung ist derzeit nicht möglich. ' +
        'Wir entschuldigen uns für die Unannehmlichkeiten. ' +
        'An einer Lösung wird gearbeitet. ' +
        '\nFolgen Sie den Signalen auf der Brücke für weitere Anweisungen und/oder achten Sie auf Schifffahrts-Updates auf Twitter @ScheepvaartFRL',
      NOT_OPERATED:
        'Ihre Anfrage liegt außerhalb der Betriebszeiten. Die aktuellen Betriebszeiten finden Sie unter www.fryslan.frl/brugbediening.',
      ACCEPT:
        'Vielen Dank für Ihre Anfrage. Wir werden die Brücke so schnell wie möglich bedienen.',
      TECH_ERROR:
        'Es ist ein technischer Fehler aufgetreten, bitte versuchen Sie es später noch einmal.'
    },

    responseTitle: {
      BRIDGE_MAINTENANCE: 'An dieser Brücke finden Wartungsarbeiten statt',
      BRIDGE_ERROR: 'Brücke ist derzeit außer Betrieb',
      NOT_OPERATED: 'Anfrage liegt außerhalb der Betriebszeiten',
      ACCEPT: 'Brückenöffnungsanfrage erfolgreich',
      TECH_ERROR: 'Fehler'
    },

    success: 'Brückenöffnungsanfrage erfolgreich',
    successDescription:
      'Vielen Dank für Ihre Anfrage. Wir werden die Brücke so schnell wie möglich bedienen.',
    viewTwitter: '@ScheepvaartFRL anzeigen'
  },

  bridges: {
    solid: 'Feste Brücke',
    openable: 'Bedienbare Brücke',
    openingRequestable: 'Brücke mit Registrierungsfunktion in App'
  }
}

export default deLocale
