export function findCenterPolygon(markers: [number, number]) {
  let lat = 0
  let lng = 0
  for (let i = 0; i < markers.length; ++i) {
    lat += markers[i][0]
    lng += markers[i][1]
  }
  lat /= markers.length
  lng /= markers.length
  return [lat, lng]
}
