import polyUtil from 'polyline-encoded'
import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { IRouteSearchProps } from '../../@types/types'
import { IFullPosition } from '../geoLocationWatcher/GeoLocationWatcherTypes'
import { IRoute } from '../../services/TeqplayApiService/TeqplayApi'

interface IDottedLine {
  routeSelection: IRouteSearchProps
  routeSuggestion: IRoute
  currentLocation: IFullPosition | null
}

const DottedLine = ({ routeSelection, routeSuggestion, currentLocation }: IDottedLine) => {
  const toLocation = [
    routeSelection?.toLocation?.coordinates?.lng,
    routeSelection.toLocation?.coordinates?.lat
  ]
  const fromLocation = routeSelection?.fromLocation?.coordinates
    ? [routeSelection.fromLocation.coordinates?.lng, routeSelection.fromLocation.coordinates?.lat]
    : [currentLocation?.location?.longitude, currentLocation?.location?.latitude]
  const first = polyUtil.decode(routeSuggestion.polyLine)[0]
  const last = polyUtil.decode(routeSuggestion.polyLine).slice(-1)[0]
  const coordinates = [
    [first.reverse(), fromLocation],
    [last.reverse(), toLocation]
  ]

  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: coordinates.map(coordinate => {
          return {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coordinate
            }
          }
        })
      }}
      lineLayout={{
        'line-join': 'round',
        'line-cap': 'round'
      }}
      linePaint={{
        'line-color': '#a5a5a5',
        'line-width': 3,
        'line-dasharray': [0, 2]
      }}
    />
  )
}

export default DottedLine
