import { IChannelCrossing } from '../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import { margrietKanaalInnerPolygon, margrietKanaalOuterPolygon } from './margrietKanaal'

export const frieslandChannels: IChannelCrossing[] = [
  {
    name: 'Prinses Margrietkanaal',
    innerPolygon: margrietKanaalInnerPolygon,
    outerPolygon: margrietKanaalOuterPolygon
  }
]
