import React, { useState } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import BridgeLayer from '../../mapShared/layers/BridgeLayer'
import RouteItemIcon from './icons/RouteItemIcon.svg'

import {
  IRouteItemBridge,
  IBridgeOpeningInfo,
  ISelectedRoute,
  IRouteItem
} from '../../../services/TeqplayApiService/TeqplayApi'
import {
  IFullPosition,
  ILocationPermissions
} from '../../geoLocationWatcher/GeoLocationWatcherTypes'

interface ILayer {
  teqplayApiService: TeqplayApiService
  currentUserLocation?: IFullPosition | null
  locationPermissions: ILocationPermissions
  navigationRoute: ISelectedRoute
  bridgeOpenings?: IBridgeOpeningInfo[]
  selectedRouteItem?: IRouteItem | null
  setSelectedRouteItem: (item: IRouteItem) => void
  moveFinishLayersToTop: () => void
  mapCursor: HTMLCanvasElement | undefined
}

const defaultLayout = {
  'icon-image': 'routeItem',
  'icon-allow-overlap': true,
  'icon-ignore-placement': true
}

export const RouteItemMarkers = (props: ILayer) => {
  const [loaded, setLoaded] = useState(false)
  const image = new Image(40, 40)
  image.onload = () => {
    setLoaded(true)
    /* When loaded prio the start + end layers */
    props.moveFinishLayersToTop()
  }
  image.src = RouteItemIcon
  const specificIcon = ['routeItem', image]
  if (!loaded) {
    return null
  }
  return (
    <>
      <Layer
        type="symbol"
        minZoom={7}
        id="routeitem-marker"
        layout={defaultLayout}
        images={specificIcon}
      >
        {props.navigationRoute.route.routeItems
          .filter(item => item.type !== 'BRIDGE')
          .map(item => {
            return (
              <Feature
                coordinates={item.location[0].coordinates}
                onClick={() => props.setSelectedRouteItem(item)}
                onMouseEnter={() =>
                  props.mapCursor ? (props.mapCursor.style.cursor = 'pointer') : ''
                }
                onMouseLeave={() => (props.mapCursor ? (props.mapCursor.style.cursor = '') : '')}
                key={item._id}
              />
            )
          })}
      </Layer>
      <BridgeLayer
        teqplayApiService={props.teqplayApiService}
        currentUserLocation={props.currentUserLocation}
        cursor={props.mapCursor}
        bridgeOpenings={props.bridgeOpenings}
        routeBridges={
          props.navigationRoute.route.routeItems.filter(
            item => item.type === 'BRIDGE'
          ) as IRouteItemBridge[]
        }
        setSelectedRouteItem={props.setSelectedRouteItem}
        selectedRouteItem={props.selectedRouteItem}
        locationPermissions={props.locationPermissions}
      />
    </>
  )
}
