import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

import bridgesOperableIcon from '../../icons/bridges_operable_fill.svg'
import bridgesNotOperableIcon from '../../icons/bridges_not_operable_fill.svg'
import bridges_operable_app from '../../icons/bridges_operable_app.svg'

interface IProps {
  activeLayers: string[]
}

const BridgeLayerLegend: React.FunctionComponent<IProps> = props => {
  const [isCollapsed, setCollapsed] = useState(false)

  if (!props.activeLayers.includes('bridges')) {
    return null
  }

  if (isCollapsed) {
    return (
      <div
        className="bridges-legend-button icon-right-open"
        onClick={() => {
          setCollapsed(!isCollapsed)
        }}
      />
    )
  } else {
    return (
      <div
        className="bridges-legend-wrapper"
        onClick={() => {
          setCollapsed(!isCollapsed)
        }}
      >
        <div className="icon-left-open legend-close-button" />
        <div className="bridges-legend-content">
          <div className="bridge-row">
            <img src={bridgesNotOperableIcon} alt="Not Operable Bridge" />
            <div className="row">{I18n.t('bridges.solid')}</div>
          </div>
          <div className="bridge-row">
            <img src={bridgesOperableIcon} alt="Operable Bridge" />
            <div className="row">{I18n.t('bridges.openable')}</div>
          </div>
          <div className="bridge-row">
            <img src={bridges_operable_app} alt="opening Requestable Bridge" />
            <div className="row">{I18n.t('bridges.openingRequestable')}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BridgeLayerLegend
