import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'

import { IRootProps } from '../../../../../@types/types'
import { IRoute } from '../../../../../services/TeqplayApiService/TeqplayApi'
import { formatDateTime } from '../../../../../utils/dates'
import './SavedRoute.scss'

interface IProps {
  name: string
  route: IRoute
  date: number
  startRoute: (route: IRoute) => void
  deleteRoute: () => void
}

const SavedRoute: React.FunctionComponent<IProps> = props => {
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string

  return (
    <li className="saved-route-wrapper">
      <div className="info-wrapper">
        <h3 className="route-title">{props.name}</h3>

        <div className="route-property">
          <Translate value="routeSelection.distance" className="property-title" />
          <span className="property-value">{props.route.distance.toFixed(0)} km</span>
        </div>
        <div className="route-property">
          <Translate value="routeSelection.dateSaved" className="property-title" />
          <span className="property-value">{formatDateTime(props.date, locale)}</span>
        </div>
      </div>

      <div className="buttons-wrapper">
        <button
          className="button secondary large right"
          onClick={() => props.startRoute(props.route)}
        >
          {I18n.t('routeSelection.startRoute')}
        </button>
        <button className="button danger large right" onClick={() => props.deleteRoute()}>
          {I18n.t('routeSelection.deleteRoute')}
        </button>
      </div>
    </li>
  )
}

export default SavedRoute
