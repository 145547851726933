import * as Sentry from '@sentry/browser'
import { IUserAuth } from '../../services/TeqplayApiService/TeqplayApi'

// Set inital login state
const initialState = {
  userName: null,
  token: null,
  timestamp: null,
  refreshToken: null,
  teqplayId: null
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function loginReducer(state = initialState, action: { type: string; userAuth: IUserAuth }) {
  switch (action.type) {
    case 'SET_AUTH': {
      Sentry.configureScope(scope =>
        scope.setUser({
          id: action.userAuth.teqplayId || undefined,
          username: action.userAuth.userName || undefined,
          token: action.userAuth.token || undefined,
          refreshToken: action.userAuth.refreshToken || undefined,
          timestamp: Date.now()
        })
      )

      return Object.assign({}, state, {
        userName: action.userAuth.userName,
        token: action.userAuth.token,
        refreshToken: action.userAuth.refreshToken,
        timestamp: Date.now(),
        teqplayId: action.userAuth.teqplayId
      })
    }
    case 'CLEAR_AUTH': {
      Sentry.configureScope(scope =>
        scope.setUser({
          username: state.userName || undefined,
          loggedOut: true
        })
      )

      return Object.assign({}, state, initialState)
    }

    default:
      return state
  }
}
