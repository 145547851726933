import cloneDeep from 'lodash/cloneDeep'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'

import {
  IRouteSearchProps,
  RouteSelectionFields,
  INavigationLocationNode
} from '../../../@types/types'
import {
  IGeocodedLocation,
  IlatLng,
  IRouteLocation
} from '../../../services/TeqplayApiService/TeqplayApi'
import { sendMessageToSentry } from '../../../utils/sentry'
import { IFullPosition } from '../../geoLocationWatcher/GeoLocationWatcherTypes'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import './LocationSelect.scss'

interface IProps {
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  routeSelection: IRouteSearchProps
  navigationLocations: INavigationLocationNode[]
  currentLocation: IFullPosition | null
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setRouteSelectionFieldInactive: () => void
  teqplayAPIService: TeqplayApiService
  locale: string
}

interface IState {
  selectionModeActive: 'currentLocation' | 'mapSelection'
  input: string
  navigationLocations: INavigationLocationNode[]
  loading: boolean
}

interface ILabelValue {
  label: string
  value: string
}

const selectStyles = {
  container: (styles: any) => ({ ...styles, width: '100%' }),
  control: (styles: any) => ({
    ...styles,
    padding: '5px 10px 5px 40px',
    backgroundColor: '#fff;',
    border: 'none',
    borderRadius: ' 0px',
    fontSize: '14px',
    width: '100%',
    fontWeight: '700'
  }),
  option: (styles: any) => ({ ...styles, fontSize: '14px', fontWeight: '700', color: '#525252' }),
  input: (styles: any) => ({ ...styles, color: '#0155a5' }),
  menuList: (styles: any) => ({ ...styles, borderRadius: '0px' })
}

const MIN_SEARCH_LENGTH = 2
class LocationSelect extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    selectionModeActive: 'currentLocation',
    input: '',
    navigationLocations: this.props.navigationLocations.sort().slice(0, 30),
    loading: false
  }

  public render() {
    return (
      <div className="route-select-wrapper location-select-wrapper">
        <div className="menu-select close-button">
          <span onClick={this.props.setRouteSelectionFieldInactive}>
            <i className="icon-left-big" />
          </span>
        </div>

        <CreatableSelect<ILabelValue>
          createOptionPosition={'first'}
          autoFocus
          placeholder={I18n.t('routeSelection.searchOnLocation')}
          classNamePrefix="location-search selector"
          formatCreateLabel={value => I18n.t('routeSelection.createGeolocation', { value })}
          options={this.state.navigationLocations.map(x => ({ label: x.name, value: x._id }))}
          className="location-search"
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          noOptionsMessage={({ inputValue }) =>
            inputValue && inputValue.length >= MIN_SEARCH_LENGTH
              ? I18n.t('routeSelection.noOptions')
              : I18n.t('routeSelection.searchAdvice')
          }
          styles={selectStyles}
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={option => {
            if (option) {
              this.selectNavigationOption(option as unknown as ILabelValue)
            }
          }}
          loadingMessage={o => I18n.t('routeSelection.searchingFor', { placeName: o.inputValue })}
          isLoading={this.state.loading}
          value={{ value: this.state.input, label: this.state.input }}
          onInputChange={input => this.getOptions(input)}
          onCreateOption={(option: string) => this.selectGeolocatableOption(option)}
          inputId="location-select-input"
          id="location-select-creatable"
          maxMenuHeight={200} // needed for mobile landscape
        />

        <div className="location-select-buttons-wrapper">
          <button
            className={`button current-location-button ${
              !this.props.currentLocation ? 'disabled' : ''
            } ${this.state.selectionModeActive === 'currentLocation' ? 'active' : 'inactive'}`}
            type="button"
            onClick={this.selectCurrentLocation}
          >
            <i className="icon-location-1" />
            {I18n.t('routeSelection.currentLocation')}
          </button>

          <button
            className={`button map-location-button ${
              this.state.selectionModeActive === 'mapSelection' ? 'active' : 'inactive'
            }`}
            type="button"
            onClick={() => {
              this.setState({ selectionModeActive: 'mapSelection' })
            }}
          >
            <i className="icon-map-o" />
            {I18n.t('routeSelection.selectOnMap')}
          </button>
        </div>
      </div>
    )
  }

  public getOptions = (input: string) => {
    let optionsFiltered: INavigationLocationNode[] = []
    const addNode = this.props.navigationLocations.map(i => ({
      ...i,
      name: i.isNode ? I18n.t('routeSelection.node', { value: i.name }) : i.name
    }))
    if (input.length >= MIN_SEARCH_LENGTH) {
      const searchString = input
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, '')
        .trim()
      optionsFiltered = addNode.filter((location: INavigationLocationNode) => {
        return (
          location.name
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, '')
            .trim()
            .match(searchString) ||
          (location.city &&
            location.city
              .toLowerCase()
              .replace(/[^a-z0-9-]/g, '')
              .trim()
              .match(searchString))
        )
      })
    }
    return this.setState({ navigationLocations: optionsFiltered.slice(0, 30) })
  }

  public selectCurrentLocation = () => {
    const currentLocation = this.props.currentLocation
    if (currentLocation && currentLocation.location) {
      this.setState({ selectionModeActive: 'currentLocation' })

      const location: IRouteLocation = {
        displayName: I18n.t('routeSelection.currentLocation'),
        coordinates: null,
        isCurrentLocation: true
      }
      this.selectLocation(location)
    }
  }

  public selectNavigationOption = (labelValue: ILabelValue) => {
    const selectedOption = this.props.navigationLocations.find(x => x._id === labelValue.value)
    if (selectedOption) {
      const latLng: IlatLng = {
        lat: selectedOption.location.coordinates[1],
        lng: selectedOption.location.coordinates[0]
      }
      const location: IRouteLocation = {
        displayName: selectedOption.name,
        coordinates: latLng
      }
      this.selectLocation(location)
    } else {
      this.selectGeolocatableOption(labelValue.label)
    }
  }

  public selectGeolocatableOption = async (value: string) => {
    // Converts every first letter of a word to a capital letter
    // e.g. : "den bosch" -> "Den Bosch"
    const displayName = value
      .split(' ')
      .map(w => (w.length > 1 ? w[0].toUpperCase() + w.slice(1, w.length) : w))
      .join(' ')
    this.setState({ input: displayName })
    const geocodedLocation = await this.retrieveGeocodedLocation(displayName)

    this.selectLocation({
      displayName: geocodedLocation?.name
        ? !geocodedLocation.name.toLowerCase().trim().includes(displayName.toLowerCase().trim())
          ? `${geocodedLocation.name} (${displayName})`
          : geocodedLocation.name
        : displayName,
      coordinates: geocodedLocation?.location.coordinates
        ? {
            lat: geocodedLocation?.location.coordinates[1],
            lng: geocodedLocation?.location.coordinates[0]
          }
        : null,
      isCurrentLocation: false,
      isGeocoded: true
    })
  }

  public selectLocation = (location: IRouteLocation) => {
    switch (this.props.selectedField) {
      case 'FROM_LOCATION':
        this.props.setFromLocation(location)
        break
      case 'TO_LOCATION':
        this.props.setToLocation(location)
        break
      case 'VIA_ROUTES':
        if (this.props.selectedViaRouteIndex !== null) {
          const viaRoutes = cloneDeep(this.props.routeSelection.viaRoutes)
          viaRoutes[this.props.selectedViaRouteIndex] = location
          this.props.setViaRoutes(viaRoutes)
        }
        break
      default:
        break
    }
  }

  public retrieveGeocodedLocation = async (
    placeName: string
  ): Promise<IGeocodedLocation | null> => {
    // TO-DO: Add options to menu selection screen upon typing?
    // Might be expensive money wise though calling the API this often
    try {
      this.setState({ loading: true })
      const locations = await this.props.teqplayAPIService.getGeocodedLocation(
        placeName,
        this.props.locale
      )

      if (locations.length === 0) {
        toast.error(I18n.t('routeSelection.notFound'))
        return null
      } else {
        return locations[0]
      }
    } catch (error) {
      if (error.message === 'The placeName has too much characters') {
        toast.error(I18n.t('routeSelection.notFound'))
      }

      sendMessageToSentry(error)
      return null
    } finally {
      this.setState({ loading: false })
    }
  }
}

export default LocationSelect
