import cloneDeep from 'lodash/cloneDeep'
import { Dispatch } from 'redux'
import { IShipLocationUpdate } from '../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import {
  IBridgeOpeningInfo,
  ISelectedRoute,
  SpeedTypes
} from '../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { mergeTimeUpdatesInSelectedRoute } from '../../utils/routeUtils'
import { sendExceptionToSentry } from '../../utils/sentry'

export function setNavigationRoute(route: ISelectedRoute) {
  return {
    type: 'SET_NAVIGATION_ROUTE',
    route
  }
}

export function setBridgeOpenings(bridgeOpenings: IBridgeOpeningInfo[]) {
  return {
    type: 'SET_BRIDGE_OPENINGS',
    bridgeOpenings
  }
}

export function clearNavigationRoute() {
  return {
    type: 'SET_NAVIGATION_ROUTE',
    route: null
  }
}

export async function updateNavigationRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  speedType: SpeedTypes,
  cruiseSpeed: number
) {
  try {
    const selectedRoute = await teqplayApiService.updateSelectedRoute(speedType, cruiseSpeed)
    dispatch(setNavigationRoute(selectedRoute))
  } catch (err) {
    sendExceptionToSentry(err)
  }
}

export async function checkAndSetActiveRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  redirectToHomePage: () => void
): Promise<ISelectedRoute | null> {
  try {
    const selectedRoute = await teqplayApiService.getSelectedRoute()
    if (selectedRoute.paused === true) {
      await teqplayApiService.resumeRoute()
      selectedRoute.paused = false
    }

    await dispatch(setNavigationRoute(selectedRoute))
    updateBridgeOpenings(dispatch, teqplayApiService)

    return selectedRoute
  } catch (err) {
    if (err.status === 404) {
      dispatch(clearNavigationRoute())
      redirectToHomePage()
    } else {
      sendExceptionToSentry(err)
    }
    return null
  }
}

export async function updateBridgeOpenings(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService
) {
  try {
    const bridgeOpenings = await teqplayApiService.getAllFrisianBridgeOpeningList()
    await dispatch(setBridgeOpenings(bridgeOpenings))
  } catch (error) {
    sendExceptionToSentry(error)
  }
}

export async function updateRouteEta(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute,
  currentLocation: IShipLocationUpdate | null,
  redirectToHomePage: () => void
) {
  // Perform location updation first
  try {
    if (currentLocation) {
      const location = cloneDeep(currentLocation)
      location.timeLastUpdate = new Date().getTime()
      await teqplayApiService.setCurrentPosition(location)
    }

    // Check selectedRoute ETA update
    const updatedEtaObject = await teqplayApiService.getSelectedRouteEtaUpdate()

    if (updatedEtaObject) {
      const updatedSelectedRoute = mergeTimeUpdatesInSelectedRoute(updatedEtaObject, selectedRoute)
      dispatch(setNavigationRoute(updatedSelectedRoute))
    }
  } catch (err) {
    if (err.status === 404) {
      console.error(`Route cancelled not by current user, stopping inside frontend`)
      dispatch(clearNavigationRoute())
      redirectToHomePage()
    } else {
      sendExceptionToSentry(err)
    }
  }
}

export async function pauseCurrentRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute
) {
  const route = cloneDeep(selectedRoute)
  route.paused = true
  try {
    await teqplayApiService.pauseRoute()
  } catch (err) {
    console.warn(err)
  }
  dispatch(setNavigationRoute(route))
}

export async function resumeCurrentRoute(
  dispatch: Dispatch,
  teqplayApiService: TeqplayApiService,
  selectedRoute: ISelectedRoute
) {
  const route = cloneDeep(selectedRoute)
  route.paused = false
  try {
    await teqplayApiService.resumeRoute()
  } catch (err) {
    console.warn(err)
  }
  dispatch(setNavigationRoute(route))
}

export async function stopCurrentRoute(dispatch: Dispatch, teqplayApiService: TeqplayApiService) {
  try {
    await teqplayApiService.stopRoute()
  } catch (err) {
    console.warn(err)
  }

  dispatch(clearNavigationRoute())
}
