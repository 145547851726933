import { MapTypes } from '../@types/types'
import { MAP_BOX_DEFAULT } from './constants'

export function mapTypeToTileURL(mapType: MapTypes) {
  switch (mapType) {
    case 'SATELLITE':
      return 'mapbox://styles/teqplay/ckn7fkico194t17p469ant1gn'
    case 'ECDIS':
      return 'mapbox://styles/teqplay/cj6g3sz1633cq2rnuwvwjfuvx'
    case 'OPENSTREETMAP':
      return 'mapbox://styles/teqplay/cj8d3imtl8qtr2rl0sneu4p54'
    case 'DEFAULT':
    default:
      return MAP_BOX_DEFAULT
  }
}
