import * as MapboxGl from 'mapbox-gl'
import { checkValidJSON } from './checkValidJSON'

export function mapClickedLayer(
  map: MapboxGl.Map,
  evt: MapboxGl.MapMouseEvent | any
): { [name: string]: any } | null {
  const clickedFeature = evt.point ? map.queryRenderedFeatures(evt.point) : []
  if (
    clickedFeature &&
    clickedFeature[0] &&
    clickedFeature[0].properties &&
    map.queryRenderedFeatures(evt.point, {
      layers: [clickedFeature[0].layer.id]
    })[0]?.properties
  ) {
    /* This is a fix for a current issue https://github.com/mapbox/mapbox-gl-js/issues/2434
    Since Mapbox can't have nested properties
    */
    const layers = map.queryRenderedFeatures(evt.point, {
      layers: [clickedFeature[0].layer.id]
    })[0]?.properties
    const fixedObject = {}
    for (var key in layers) {
      if (layers.hasOwnProperty(key)) {
        fixedObject[key] = checkValidJSON(layers[key]) ? JSON.parse(layers[key]) : layers[key]
      }
    }
    return fixedObject
  } else {
    return []
  }
}
