import React, { useState } from 'react'
import {
  CEMT_CLASS_VALUES,
  getCemtLegendValues,
  getDepthLegendValues,
  getRecreationalClassLegendValues
} from './routeNetworkUtils'

interface IProps {
  activeLayers: string[]
}

const RouteNetworkLegend: React.FunctionComponent<IProps> = props => {
  const [isCollapsed, setCollapsed] = useState(false)

  if (
    !props.activeLayers.includes('CEMTClass') &&
    !props.activeLayers.includes('fairwayDepth') &&
    !props.activeLayers.includes('recreationalClass')
  ) {
    return null
  }

  if (isCollapsed) {
    return (
      <div
        className={`route-network-legend-button icon-left-open`}
        onClick={() => {
          setCollapsed(!isCollapsed)
        }}
      />
    )
  } else {
    return (
      <div
        className="route-network-legend-wrapper"
        onClick={() => {
          setCollapsed(!isCollapsed)
        }}
      >
        <div className="icon-right-open legend-close-button" />
        <div className={`route-network-legend`}>{createLegendContent()}</div>
      </div>
    )
  }

  function createLegendContent() {
    if (props.activeLayers.includes('CEMTClass')) {
      return (
        <>
          {CEMT_CLASS_VALUES.map((cemtClass, index) => {
            return (
              <div className="route-legend-item" key={'cemtClass' + index}>
                <span
                  style={{ backgroundColor: getCemtLegendValues(cemtClass).color }}
                  className="color-indicator"
                />
                <span className="legend-tag">{getCemtLegendValues(cemtClass).displayName}</span>
              </div>
            )
          })}
          <div className="route-legend-item" key={'cemtClass unknown'}>
            <span
              style={{ backgroundColor: getCemtLegendValues().color }}
              className="color-indicator"
            />
            <span className="legend-tag">{getCemtLegendValues().displayName}</span>
          </div>
        </>
      )
    } else if (props.activeLayers.includes('fairwayDepth')) {
      const depthLegendValues = getDepthLegendValues()
      return (
        <>
          {depthLegendValues.map((depthLegendValue, index) => {
            return (
              <div className="route-legend-item" key={'depthLegendValue' + index}>
                <span
                  style={{ backgroundColor: depthLegendValue.color }}
                  className="color-indicator"
                />
                <span className="legend-tag">{depthLegendValue.displayName}</span>
              </div>
            )
          })}
        </>
      )
    } else if (props.activeLayers.includes('recreationalClass')) {
      const recreationalClassLegendValues = getRecreationalClassLegendValues()
      return (
        <>
          {recreationalClassLegendValues.map((recreationalValue, index) => {
            return (
              <div className="route-legend-item" key={'depthLegendValue' + index}>
                <span
                  style={{ backgroundColor: recreationalValue.color }}
                  className="color-indicator"
                />
                <span className="legend-tag">{recreationalValue.displayName}</span>
              </div>
            )
          })}
        </>
      )
    } else {
      return null
    }
  }
}

export default RouteNetworkLegend
