import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { IBridgeDetails } from '../../../services/TeqplayApiService/TeqplayApi'
import { keysrt } from '../../../utils/general'

interface IProps {
  itemDetails: IBridgeDetails
}

const BridgeDrawing: React.FunctionComponent<IProps> = props => {
  const itemDetails = props.itemDetails

  if (itemDetails && itemDetails.bridgeOpening && itemDetails.bridgeOpening.length > 0) {
    return itemDetails.bridgeOpening.length > 0 && itemDetails.bridgeOpening[0].heightClosed ? (
      <div
        className={`detailed-bridge-wrapper${itemDetails.bridgeOpening.length > 3 ? ' large' : ''}`}
      >
        {itemDetails.waterLevel ? (
          <div className={`detailed-bridge waterlevel`}>
            <div className="value">
              {itemDetails.waterLevel ? Math.round(itemDetails.waterLevel.heightInCm) / 100 : null}{' '}
              m [{I18n.t('routeList.itemDetails.NAP')}]
            </div>
            <div className="line" />
          </div>
        ) : null}
        {itemDetails.bridgeOpening.sort(keysrt('number')).map((opening, key) => {
          const meterInPixels = 5 // 1m in pixels
          const bridgePixelHeight = opening.heightClosed ? opening.heightClosed * meterInPixels : 25
          const extraOpeningHeight = opening.heightOpened
            ? opening.heightOpened * meterInPixels
            : 10

          return (
            <div className={`detailed-bridge ${opening.type}`} key={key}>
              <div className="bridge edge" />
              <div className="bridge inner">
                <div
                  className="parts"
                  style={{ transform: `translate(0, ${-bridgePixelHeight}px)` }}
                >
                  {opening.type === 'HEF' && opening.heightOpened ? (
                    <div
                      className="openedHeight"
                      style={{
                        height:
                          bridgePixelHeight + extraOpeningHeight > 175
                            ? 175 - (bridgePixelHeight + 29 + (itemDetails.waterLevel ? 25 : 0))
                            : extraOpeningHeight - bridgePixelHeight - 29,
                        lineHeight:
                          bridgePixelHeight + extraOpeningHeight > 175
                            ? (175 - (bridgePixelHeight + 29 + (itemDetails.waterLevel ? 25 : 0))) /
                                2 +
                              'px'
                            : (extraOpeningHeight - bridgePixelHeight - 29) / 2 + 'px'
                      }}
                    >
                      {opening.heightOpened} m, [{itemDetails.referencelevel}]
                    </div>
                  ) : null}
                  <div
                    className="closedHeight"
                    style={{
                      height: bridgePixelHeight + 29 - (itemDetails.waterLevel ? 25 : 0),
                      lineHeight:
                        (bridgePixelHeight + 29 - (itemDetails.waterLevel ? 25 : 0)) / 2 + 'px'
                    }}
                  >
                    {opening.heightClosed
                      ? opening.heightClosed + 'm, [' + itemDetails.referencelevel + ']'
                      : ''}
                  </div>
                  <div className="left" />
                  <div className="right" />
                  <div className="bridgeWidth">{opening.width || '?'} m</div>
                </div>
                {itemDetails.waterLevel ? <div className="water" /> : null}
              </div>
            </div>
          )
        })}
        <div className="detailed-bridge end">
          <div className="bridge edge" />
        </div>
      </div>
    ) : null
  } else {
    return null
  }
}

export default BridgeDrawing
