import { isAfter, subMinutes } from 'date-fns'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { IFullPosition } from '../../geoLocationWatcher/GeoLocationWatcherTypes'

interface IProps {
  userLocation: IFullPosition | null
}

const ShipSpeedBox: React.FC<IProps> = props => {
  const [isKmhActive, setKmhActive] = useState(true)
  const userLocation = props.userLocation
  const activityThresholdMinutes = 2

  // to knots
  const toKnotsMultiplier = 1.94384449
  const toKilometersPerHourMultiplier = 3.6

  const hasRecentLocationUpdate =
    userLocation &&
    isAfter(userLocation.timeLastUpdate, subMinutes(new Date(), activityThresholdMinutes))

  const unitSuffix = isKmhActive ? ` ${I18n.t('units.kmh')}` : ` ${I18n.t('units.knots')}`

  if (
    userLocation &&
    userLocation.timeLastUpdate &&
    hasRecentLocationUpdate &&
    userLocation.speedOverGround &&
    userLocation.speedOverGround >= 0
  ) {
    return (
      <div className="nav-box heading-box" onClick={() => setKmhActive(!isKmhActive)}>
        {Math.round(
          userLocation.speedOverGround *
            (isKmhActive ? toKilometersPerHourMultiplier : toKnotsMultiplier)
        )}
        {unitSuffix}
      </div>
    )
  } else {
    return (
      <div className="nav-box heading-box" onClick={() => setKmhActive(!isKmhActive)}>
        0 {unitSuffix}
      </div>
    )
  }
}

export default ShipSpeedBox
