import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import Slider from 'react-slick'
import ReactTooltip from 'react-tooltip'

import { IRoute } from '../../../../services/TeqplayApiService/TeqplayApi'
import { formatDateTimeShort } from '../../../../utils/dates'

interface IProps {
  routeSuggestions: IRoute[]
  listViewActive: boolean
  selectedRouteSuggestion: IRoute | null
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  toggleListView: () => void
  toggleRouteDetails: () => void
  locale: string
}

class RouteOptionsList extends React.PureComponent<IProps> {
  public slider: Slider | null = null

  public componentDidUpdate(prevProps: IProps) {
    if (
      !isEqual(prevProps.selectedRouteSuggestion, this.props.selectedRouteSuggestion) &&
      this.slider
    ) {
      this.setSelectedSlide()
    }
  }

  public render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      className: 'center',
      centerMode: true,
      centerPadding: '60px',
      responsive: [
        {
          breakpoint: 900,
          settings: {
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerPadding: '60px'
          }
        }
      ]
    }

    return (
      <div className="route-options-list">
        <Slider
          {...settings}
          ref={slider => (this.slider = slider)}
          afterChange={index =>
            this.props.setSelectedRouteSuggestion(this.props.routeSuggestions[index])
          }
          focusOnSelect={true}
        >
          {this.props.routeSuggestions.map((routeSuggestion, index) => {
            const routeNotAccessible =
              !routeSuggestion.canPassBridges || !routeSuggestion.shipAllowed
            return (
              <div
                className={`route-option-wrapper ${routeNotAccessible && 'option-not-accessible'}`}
                key={'route-option' + index}
              >
                <div className={'route-option'}>
                  <h4 className="option-title">
                    {I18n.t('routeSelection.routeNumbered', { routeNumber: index + 1 })}
                  </h4>

                  <div className="option-details">
                    <h3>{routeSuggestion.distance.toFixed(0)}km</h3>
                    <p>
                      <Translate value="routeSelection.arrivalTime" className="property-title" />{' '}
                      {formatDateTimeShort(routeSuggestion.eta, this.props.locale)}
                    </p>
                  </div>

                  {routeNotAccessible && (
                    <>
                      <span
                        className="not-allowed icon-attention"
                        data-tip
                        data-for="shipNotAllowed"
                      />
                      <ReactTooltip
                        id="shipNotAllowed"
                        place="bottom"
                        effect="solid"
                        offset={{ left: 100, bottom: -10 }}
                        className="warning-tooltip"
                      >
                        {routeSuggestion.shipAllowed ? (
                          <Translate value="routeSelection.notAllowedBridge" />
                        ) : (
                          <Translate value="routeSelection.notAllowedDimensions" />
                        )}
                      </ReactTooltip>
                    </>
                  )}
                </div>
                <div className="list-buttons">
                  <div className="single-button" onClick={this.props.toggleRouteDetails}>
                    <i className="icon-info-1" /> {I18n.t('routeSelection.details')}
                  </div>
                  <div className="single-button" onClick={this.props.toggleListView}>
                    {this.props.listViewActive ? (
                      <>
                        <i className="icon-map-o" /> {I18n.t('routeSelection.map')}
                      </>
                    ) : (
                      <>
                        <i className="icon-list-bullet" /> {I18n.t('routeSelection.list')}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }

  public setSelectedSlide = () => {
    const selectedRoute = this.props.selectedRouteSuggestion
    const initialSlide =
      selectedRoute && selectedRoute._id
        ? this.props.routeSuggestions.findIndex(x => x._id === selectedRoute._id)
        : undefined

    if (initialSlide !== undefined && this.slider) {
      this.slider.slickGoTo(initialSlide)
    }
  }
}

export default RouteOptionsList
