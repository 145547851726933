import MapboxGL from 'mapbox-gl'
import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { IExtendedShip } from '../../../../@types/types'

interface IProps {
  ships: IExtendedShip[]
  handleLayerClick: (event: MapboxGL.MapMouseEvent) => void
  onMouseEnter: (event: MapboxGL.MapMouseEvent) => void
  onMouseLeave: () => void
  minZoom?: number
  maxZoom?: number
}

const AISContourLayer: React.FC<IProps> = ({
  ships,
  handleLayerClick,
  onMouseEnter,
  onMouseLeave,
  minZoom,
  maxZoom
}) => {
  const labelSymbolLayout: MapboxGL.SymbolLayout = {
    'text-anchor': 'center',
    'text-justify': 'center',
    'text-offset': [1, 0],
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 6, 15, 13],
    'text-font': ['Metropolis Semi Bold']
  }
  const labelPaint: MapboxGL.SymbolPaint = {
    'icon-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8],
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
    'text-halo-width': 1.4
  }

  const filledLayerPaint: MapboxGL.FillPaint = {
    'fill-color': ['get', 'rawColor'],
    'fill-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8],
    'fill-antialias': true,
    'fill-outline-color': [
      'case',
      ['boolean', ['get', 'isSelected'], false],
      'rgb(255,0,0)',
      ['get', 'rawColor']
    ]
  }

  return (
    <>
      <GeoJSONLayer
        data={detailedShipToGeoJSONPolygons(ships)}
        fillPaint={filledLayerPaint}
        fillOnClick={handleLayerClick}
        fillOnMouseEnter={onMouseEnter}
        fillOnMouseLeave={onMouseLeave}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
      <GeoJSONLayer
        data={detailedShipToGeoJSONPolygons(ships)}
        symbolLayout={labelSymbolLayout}
        symbolPaint={labelPaint}
        symbolOnClick={handleLayerClick}
        symbolOnMouseEnter={onMouseEnter}
        symbolOnMouseLeave={onMouseLeave}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
    </>
  )

  function detailedShipToGeoJSONPolygons(elements: IExtendedShip[]): GeoJSON.FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: elements.map(ship => ({
        type: 'Feature',
        properties: ship.properties,
        geometry: {
          type: 'Polygon',
          coordinates: ship.properties.dimensions as GeoJSON.Position[][]
        }
      }))
    }
  }
}

export default AISContourLayer
