import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { IRouteSegment, ISegmentInfo } from '../../../services/TeqplayApiService/TeqplayApi'
import { decodeRoutePolyline } from '../../../utils/decodeRoutePolyline'

interface IProps {
  routeNetwork: IRouteSegment[]
}

/* Color for each Feature */
const recreationalClass = [
  'f',
  '#9A297D',
  'e',
  '#5ca8db',
  'dm',
  '#85BE41',
  'cm',
  '#038E47',
  'czm',
  '#023361',
  'bzm',
  '#91BEDC',
  'azm',
  '#D82332',
  '#cecece'
]

const RecreationalClassLayer = (props: IProps) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: props.routeNetwork.map(line => {
          const latlng = decodeRoutePolyline(line)
          return {
            type: 'Feature',
            properties: { recreationalClass: hasProperty(line.info) },
            geometry: {
              type: 'LineString',
              coordinates: latlng
            }
          }
        })
      }}
      linePaint={{
        'line-color': ['match', ['get', 'recreationalClass'], ...recreationalClass],
        'line-width': 3
      }}
    />
  )

  function hasProperty(line: ISegmentInfo) {
    if (line.hasOwnProperty('f')) {
      return 'f'
    }
    if (line.hasOwnProperty('e')) {
      return 'e'
    }
    if (line.hasOwnProperty('dm')) {
      return 'dm'
    }
    if (line.hasOwnProperty('cm')) {
      return 'cm'
    }
    if (line.hasOwnProperty('czm')) {
      return 'czm'
    }
    if (line.hasOwnProperty('bzm')) {
      return 'bzm'
    }
    if (line.hasOwnProperty('azm')) {
      return 'azm'
    }
    return ''
  }
}

export default RecreationalClassLayer
