import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import BridgeDetails from '../../itemDetails/BridgeDetails'
import LockDetails from '../../itemDetails/LockDetails'
import NotificationDetails from '../../itemDetails/NotificationDetails'
import WatermeterDetails from '../../itemDetails/WatermeterDetails'
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator'

import { IRootProps } from '../../../@types/types'
import { usePrevious } from '../../../customHooks/usePrevious'
import {
  IBridgeDetails,
  IBridgeOpeningInfo,
  ILockDetails,
  INotificationDetails,
  IRouteItem,
  IWatchDogStatus,
  IWaterMeterDetails
} from '../../../services/TeqplayApiService/TeqplayApi'
import { isCordovaApp } from '../../../utils/cordovaUtils'
import { formatTime } from '../../../utils/dates'
import { getIconNameByType } from '../../../utils/iconUtils'
import {
  IFullPosition,
  ILocationPermissions
} from '../../geoLocationWatcher/GeoLocationWatcherTypes'

import '../../itemDetails/ItemDetails.scss'

interface IProps {
  routeItem: IRouteItem
  bridgeOpeningInfo?: IBridgeOpeningInfo
  teqplayApiService: TeqplayApiService
  getTimeToReach: (eta: number) => string | null
  currentLocation?: IFullPosition | null
  watchdogStatus?: IWatchDogStatus
  locationPermissions: ILocationPermissions
  departureTime?: number | null
}

const RouteListItem = ({
  routeItem,
  bridgeOpeningInfo,
  teqplayApiService,
  getTimeToReach,
  currentLocation,
  watchdogStatus,
  locationPermissions,
  departureTime
}: IProps) => {
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string
  const previousRouteItem = usePrevious(routeItem)

  const [itemDetails, setItemDetails] = React.useState<
    IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails | null
  >(null)
  const [isExpanded, setExpanded] = React.useState(false)
  const [isLoadingDetails, setLoadingDetails] = React.useState(false)

  React.useEffect(() => {
    if (!isEqual(routeItem._id, previousRouteItem?._id)) {
      setItemDetails(null)
      setLoadingDetails(false)
    }
  }, [routeItem, previousRouteItem])

  return (
    <div
      id={routeItem._id}
      className={`route-list-item ${
        bridgeOpeningInfo && bridgeOpeningInfo.isActive ? 'can-open' : ''
      } ${isExpanded ? 'expanded' : 'closed'}`}
    >
      {/* ETA */}
      {routeItem.eta ? (
        <div className="item-eta">
          {routeItem.eta === '-' ? (
            <div>-</div>
          ) : (
            <div className="eta-time">{formatTime(new Date(routeItem.eta), locale)}</div>
          )}
          {routeItem.eta !== '-' && typeof routeItem.eta !== 'string' && (
            <div className="time-to-reach">{getTimeToReach(routeItem.eta)}</div>
          )}
        </div>
      ) : null}

      <div className={'item-info'} onClick={() => onExpandRouteItem(routeItem)}>
        {getDot(routeItem.type)}

        <div className="info-wrapper">
          <span className={`${getIconNameByType(routeItem.type, routeItem)} item-detail-icon`} />

          {routeItem && routeItem.vhfChannel ? (
            <span className="vhf-sign">
              <div className="vhf-sign-content">
                <div>VHF</div>
                <div>{routeItem.vhfChannel}</div>
              </div>
            </span>
          ) : null}

          <span className="item-title">
            {routeItem.name}
            {displayRouteItemMessage()}
          </span>
        </div>
        {bridgeOpeningInfo && bridgeOpeningInfo.isActive && (
          <div className="bridge-can-open">
            <i className="icon-info" />
            {I18n.t(
              isCordovaApp ? 'bridgeOpeningRequests.canOpen' : 'bridgeOpeningRequests.downloadApp'
            )}
          </div>
        )}
        {isLoadingDetails && <LoadingIndicator loading={true} />}
        <div className="expanded-item">{renderExpandedItem()}</div>
      </div>
    </div>
  )

  function renderExpandedItem() {
    switch (routeItem.type) {
      case 'BRIDGE':
        return (
          <BridgeDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as IBridgeDetails}
            locale={locale}
            teqplayApiService={teqplayApiService}
            bridgeOpeningInfo={bridgeOpeningInfo}
            currentLocation={currentLocation}
            watchdogStatus={watchdogStatus}
            isInsideRouteItem={true}
            locationPermissions={locationPermissions}
            departureTime={departureTime}
          />
        )
      case 'LOCK':
        return (
          <LockDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as ILockDetails}
            departureTime={departureTime}
          />
        )
      case 'WATERMETER':
        return (
          <WatermeterDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as IWaterMeterDetails}
          />
        )
      case 'NOTIFICATION':
        return (
          <NotificationDetails
            isExpanded={isExpanded}
            itemDetails={itemDetails as INotificationDetails}
          />
        )
      default:
        return null
    }
  }

  async function onExpandRouteItem(item: IRouteItem) {
    if (isExpanded) {
      setExpanded(false)
    } else {
      if (itemDetails) {
        setExpanded(true)
      } else {
        try {
          setLoadingDetails(true)
          const routeItemDetails = await getRouteDetails(item.type.toLowerCase(), item.refUuid)
          setItemDetails(routeItemDetails)
          setExpanded(true)
        } catch (error) {
          toast.warn(I18n.t('routeList.itemDetails.no_items_found'))
        } finally {
          setLoadingDetails(false)
        }
      }
    }
  }

  function displayRouteItemMessage() {
    if (routeItem.canBePassed !== undefined && routeItem.canBePassed === false) {
      return (
        <div className="passage-message unpassable">
          {I18n.t('routeList.itemDetails.cannot_pass_bridge')}
        </div>
      )
    }

    return null
  }

  function getDot(itemType: IRouteItem['type']): JSX.Element | null {
    if (
      itemType === 'BRIDGE' ||
      itemType === 'LOCK' ||
      itemType === 'START' ||
      itemType === 'END'
    ) {
      if (itemType === 'BRIDGE' && isInstanceOfBridge(routeItem)) {
        const passageClass =
          routeItem.canBePassed !== undefined
            ? routeItem.canBePassed === true
              ? 'passable'
              : 'unpassable'
            : 'unknown'
        return <div className={`list-item-icon ${passageClass}`} />
      } else {
        return <div className="list-item-icon" />
      }
    } else return null
  }

  function isInstanceOfBridge(object: any): object is IBridgeDetails {
    return object.type && object.type === 'BRIDGE'
  }

  async function getRouteDetails(
    type: string,
    itemId: string
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    try {
      const id = await teqplayApiService.fetchItemDetails(type, itemId)
      return id
    } catch (error) {
      throw error
    }
  }
}

export default RouteListItem
