import React from 'react'
import { I18n } from 'react-redux-i18n'

import './LocationPermissionDialog.scss'

interface IProps {
  show: boolean
  onApprove: () => void
  onCancel: () => void
}

const LocationPermissionDialog = ({ show, onApprove, onCancel }: IProps) => {
  if (!show) {
    return null
  }

  return (
    <div className="permissions-background">
      <div className="permissions-dialog">
        <div className="title-header">
          <div className="app-name">{I18n.t('appTitle')}</div>
        </div>
        <div className="content">
          <h3 className="subheader">
            {I18n.t('prominentDisclosure.title')}
            <i className="icon-user-location" />
          </h3>
          <div className="explanation">
            <p>{I18n.t('prominentDisclosure.explanation')}</p>
            <ul className="list-permissions">
              <li>{I18n.t('prominentDisclosure.feature1')}</li>
              <li>{I18n.t('prominentDisclosure.feature2')}</li>
              <li>{I18n.t('prominentDisclosure.feature3')}</li>
              <li>{I18n.t('prominentDisclosure.feature4')}</li>
              <li>{I18n.t('prominentDisclosure.feature5')}</li>
            </ul>
            <p>{I18n.t('prominentDisclosure.background')}</p>
            <p>{I18n.t('prominentDisclosure.notProperly')}</p>
          </div>
        </div>
        <div className="buttons">
          <button className="button primary" onClick={onApprove}>
            {I18n.t('prominentDisclosure.approve')}
          </button>
          <button className="button secondary" onClick={onCancel}>
            {I18n.t('prominentDisclosure.reject')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default LocationPermissionDialog
