import React, { useState } from 'react'
import { Layer, Feature, GeoJSONLayer, Popup } from 'react-mapbox-gl'
import { I18n } from 'react-redux-i18n'
import { headingDistanceTo, moveTo } from 'geolocation-utils'

import { detailedCrossings, IDetailedCrossing } from '../../../assets/vdjs/detailedCrossings'
import Advice_Route from '../../../assets/vdjs/VDJS_Knooppunten_Adviesroute01.json'
import Advice_Route1 from '../../../assets/vdjs/VDJS_Knooppunten_Adviesroute02.json'
import Advice_Route2 from '../../../assets/vdjs/VDJS_Knooppunten_Adviesroute03.json'
import VDJSMarkers from '../../../assets/vdjs/vdjsCrossings.json'
import VDJSIcon from '../icons/vdjs.svg'

import './layerPopup.scss'

interface IProps {
  cursor?: HTMLCanvasElement
  userZoom: number
  locale: string
}

interface IBridgeMarker {
  iconUrl: string
  cursor?: HTMLCanvasElement
  category: string
  setSingleItem: (item: JSONdata) => void
}

interface IVDJSLayerFill {
  opacity: number
  color: string
  vdjsItem: IDetailedCrossing[]
  category: 'deepSeaRouteAreas' | 'routeAreas' | 'routeLines'
}

interface IVDJSLayer {
  color: string
  vdjsItem: IDetailedCrossing[]
  category: 'bridges' | 'routeAreas' | 'routeLines'
}

interface JSONdata {
  type: string
  properties: any
  geometry: {
    type: string
    coordinates: number[]
  }
}

interface ITrafficLights {
  center: LatLng
  color: string
}

type LatLng = [number, number]

const trafficLights = detailedCrossings.filter(item => item.trafficLights)
const bridges = detailedCrossings.filter(item => item.bridges)
const routeLines = detailedCrossings.filter(item => item.routeLines)
const routeAreas = detailedCrossings.filter(item => item.routeAreas)
const deepSeaRouteAreas = detailedCrossings.filter(item => item.deepSeaRouteAreas)
const allRoutes = [...Advice_Route.features, ...Advice_Route1.features, ...Advice_Route2.features]

const ROUTE_ARROW_DISTANCE = 250
const ARROW_SIZE = 50
const ARROW_ANGLE = 20

const COLOR_DEEP_SEA_AREA = '#b28886'
const COLOR_ROUTE_AREA = '#75bfdd'
const COLOR_ROUTE_ARROW = '#80cbea'

const allTrafficLights: ITrafficLights[] = []
trafficLights.forEach(item => {
  item.trafficLights?.forEach(trafficLight => {
    allTrafficLights.push({
      center: [trafficLight.center[1], trafficLight.center[0]],
      color: trafficLight.color
    })
  })
})

const VDJSCrossingLayer = ({ cursor, userZoom, locale }: IProps) => {
  const [singleItem, setSingleItem] = useState<JSONdata | null>(null)
  return (
    <>
      <BridgeMarker
        iconUrl={VDJSIcon}
        cursor={cursor}
        category="BridgeMarker"
        setSingleItem={setSingleItem}
      />
      {userZoom > 10 && (
        <GeoJSONLayer
          data={{
            type: 'FeatureCollection',
            features: allRoutes
          }}
          fillPaint={{
            'fill-color': '#75bfdd',
            'fill-opacity': {
              stops: [
                [5, 0],
                [13, 1],
                [16, 1]
              ],
              base: 3
            }
          }}
          source="Advice_Route"
          id="Advice_Route"
        />
      )}

      <VDJSGeoJSONLayer vdjsItem={bridges} color="#fff" category="bridges" />
      <VDJSGeoJSONLayerFill
        vdjsItem={deepSeaRouteAreas}
        color={COLOR_DEEP_SEA_AREA}
        opacity={0.8}
        category={'deepSeaRouteAreas'}
      />
      <VDJSGeoJSONLayer vdjsItem={routeLines} color={COLOR_ROUTE_AREA} category="routeLines" />
      <VDJSGeoJSONLayerFill
        vdjsItem={routeAreas}
        color={COLOR_ROUTE_AREA}
        opacity={1}
        category="routeAreas"
      />
      <VDJSArrows vdjsItem={routeLines} color={COLOR_ROUTE_ARROW} category="routeLines" />
      <Layer
        type="circle"
        id={'trafficLight'}
        source={'trafficLight'}
        paint={{
          'circle-radius': {
            stops: [
              [10, 0],
              [13, 3],
              [16, 16]
            ]
          },
          'circle-color': ['get', 'color']
        }}
      >
        {allTrafficLights.map((item, index) => {
          return (
            <Feature coordinates={item.center} properties={{ color: item.color }} key={index} />
          )
        })}
      </Layer>

      {singleItem && (
        <Popup coordinates={singleItem.geometry.coordinates}>
          <div className="popup">
            <span className="close icon-cancel-circled" onClick={() => setSingleItem(null)} />
            <h3>{singleItem.properties.FID_}</h3>
            <p>{singleItem.properties[`Uitlegs-${locale.slice(0, 2).toUpperCase()}`]}</p>
            <button
              className="winter-button"
              onClick={() => window.open(singleItem.properties.Website, '_system')}
            >
              {I18n.t('routeList.itemDetails.moreInfo')}
            </button>
          </div>
        </Popup>
      )}
    </>
  )
}

const BridgeMarker = (props: IBridgeMarker) => {
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)
  const defaultLayout = {
    'icon-image': props.category,
    'icon-ignore-placement': true
  }
  const image = new Image(40, 40)
  image.onload = () => {
    setLoadedIcon(true)
  }
  image.src = props.iconUrl
  const specificIcon = [props.category, image]
  if (!loadedIcon) {
    return null
  }
  return (
    <Layer
      type="symbol"
      id={props.category}
      source={props.category}
      layout={defaultLayout}
      images={specificIcon}
      onMouseEnter={() => (props.cursor ? (props.cursor.style.cursor = 'pointer') : '')}
      onMouseLeave={() => (props.cursor ? (props.cursor.style.cursor = '') : '')}
      aboveLayerId="Advice_Route"
    >
      {VDJSMarkers.features.map(item => {
        return (
          <Feature
            coordinates={item.geometry.coordinates}
            key={item.properties.F1}
            onClick={() => props.setSingleItem(item)}
          />
        )
      })}
    </Layer>
  )
}

const VDJSGeoJSONLayer = (props: IVDJSLayer) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: MapboxTranslationPolylines(props.vdjsItem, props.category).map(item => {
          return {
            type: 'Feature',
            properties: item,
            geometry: {
              type: 'LineString',
              coordinates: item
            }
          }
        })
      }}
      linePaint={{
        'line-color': props.color,
        'line-width': ['interpolate', ['linear'], ['zoom'], 10, 2, 13, 7, 17, 25]
      }}
      source={props.category}
    />
  )
}

const VDJSGeoJSONLayerFill = (props: IVDJSLayerFill) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: MapboxTranslationPolylines(props.vdjsItem, props.category).map(item => {
          return {
            type: 'Feature',
            properties: item,
            geometry: {
              type: 'Polygon',
              coordinates: [item]
            }
          }
        })
      }}
      fillPaint={{
        'fill-color': props.color,
        'fill-opacity': props.opacity
      }}
      source={props.category}
    />
  )
}

const VDJSArrows = (props: IVDJSLayer) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: MapboxTranslationPolylines(props.vdjsItem, props.category).map(item => {
          return {
            type: 'Feature',
            properties: item,
            geometry: {
              type: 'Polygon',
              coordinates: calculateArrowPositions(item, 0).map(arrow => {
                return arrow
              })
            }
          }
        })
      }}
      linePaint={{
        'line-color': props.color,
        'line-width': 2
      }}
      aboveLayerId="routeLines"
      source={props.category}
    />
  )
}

function calculateArrowPositions(routeLine: LatLng[], initialOffset: number) {
  const arrows = []
  let arrowOffset = initialOffset // meter
  let index = 0
  while (index < routeLine.length - 1) {
    const startPoint = routeLine[index]
    const endPoint = routeLine[index + 1]
    const headingDistance = headingDistanceTo(startPoint, endPoint)
    const heading = headingDistance.heading
    const lineLength = headingDistance.distance

    while (arrowOffset < lineLength) {
      const arrowPoint = moveTo(startPoint, { heading, distance: 30 })
      const arrowPointLeft = moveTo(arrowPoint, {
        heading: heading + 180 + ARROW_ANGLE,
        distance: ARROW_SIZE
      })
      const arrowPointRight = moveTo(arrowPoint, {
        heading: heading + 180 - ARROW_ANGLE,
        distance: ARROW_SIZE
      })

      const arrow = [arrowPointLeft, arrowPointRight, arrowPoint, arrowPointLeft]
      arrows.push(arrow)

      arrowOffset += ROUTE_ARROW_DISTANCE
    }

    arrowOffset -= lineLength

    index = index + 1
  }
  return arrows
}

function MapboxTranslationPolylines(
  item: IDetailedCrossing[],
  category: 'bridges' | 'routeAreas' | 'routeLines' | 'deepSeaRouteAreas'
) {
  const translatedPolylines: LatLng[][] = []
  item.map(num => num[category]?.forEach(a => translatedPolylines.push(a.map(i => [i.lon, i.lat]))))
  return translatedPolylines
}

export default VDJSCrossingLayer
