import cloneDeep from 'lodash/cloneDeep'
import { IRoute, IRouteEtaUpdate, ISelectedRoute } from '../services/TeqplayApiService/TeqplayApi'

export function mergeTimeUpdatesInRoute(etaObject: IRouteEtaUpdate, route: IRoute): IRoute {
  let updatedRouteItems = cloneDeep(route.routeItems)

  updatedRouteItems = updatedRouteItems.map(item => {
    // Update eta
    item.eta = etaObject.eta[item._id]

    // Update distance
    item.distance = etaObject.distance[item._id]

    // Update waitingTime
    item.waitingTime = etaObject.waitingTime[item._id]

    return item
  })

  // merge current route with the changed route items
  return { ...route, routeItems: updatedRouteItems, distanceToRoute: etaObject.distanceToRoute }
}

export function mergeTimeUpdatesInSelectedRoute(
  etaObject: IRouteEtaUpdate,
  selectedRoute: ISelectedRoute
): ISelectedRoute {
  const updatedRoute = mergeTimeUpdatesInRoute(etaObject, selectedRoute.route)

  return {
    ...selectedRoute,
    route: updatedRoute,
    paused: etaObject.paused,
    distanceToRoute: etaObject.distanceToRoute
  }
}

export function getRouteDepth(route: IRoute) {
  const measuredDepth = route.maxDimensions.measuredDepth
  const minimumDepth = route.maxDimensions.minimumDepth

  if (measuredDepth && minimumDepth) {
    return Math.min(measuredDepth, minimumDepth)
  }

  if (measuredDepth) {
    return measuredDepth
  }

  if (minimumDepth) {
    return minimumDepth
  }

  return 0
}
