import get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'

import pjson from '../../../package.json'
import { IRootProps } from '../../@types/types'
import { confirmPopup } from '../../components/shared/confirmPopup/ConfirmPopup'
import {
  areShipDimensionsCorrectlyFilledIn,
  showDimensionsErrorMessage
} from '../../components/shared/dimensionUtils'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { IShipInfo, IUserAuth } from '../../services/TeqplayApiService/TeqplayApi'

import './MyProfile.scss'
import ValidationBox from './ValidationBox'

interface IDispatchProps {
  clearAuth: () => void
  setAuth: (userAuth: IUserAuth) => void
}

interface IState {
  name: string
  email: string
  mobile: string
  length: string
  width: string
  height: string
  draught: string
  currentPassword: string
  newPassword: string
  repeatNewPassword: string
  currentShip: IShipInfo | null
  passwordPeek: boolean
}

class MyProfile extends React.PureComponent<
  IRootProps & RouteComponentProps<null> & IDispatchProps & IAuthenticationWrapper,
  IState
> {
  public constructor(
    props: IRootProps & RouteComponentProps<null> & IDispatchProps & IAuthenticationWrapper
  ) {
    super(props)

    this.state = {
      name: get(props, 'currentShip.name', ''),
      email: get(props, 'currentUser.userName', ''),
      mobile: get(props, 'currentUser.mobile', ''),
      length: '',
      width: '',
      height: '',
      draught: '',
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      currentShip: null,
      passwordPeek: false
    }
  }

  public async componentDidMount() {
    this.getCurrentShip()
  }

  public render() {
    const { repeatNewPassword, newPassword } = this.state
    const validation = {
      match: newPassword !== '' && newPassword === repeatNewPassword,
      length: newPassword.length >= 8,

      containsLower: /[a-z]/.test(newPassword),
      containsUpper: /[A-Z]/.test(newPassword),
      containsNumeric: /[0-9]/.test(newPassword),
      // eslint-disable-next-line
      containsSpecial: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)
    }

    return (
      <div>
        <h2>{I18n.t('myProfile.title')}</h2>
        <form className="settings-form" onSubmit={this.handleFormSubmit}>
          <div className="ship-select-wrapper page-block">
            <div className="form-row">
              <h3>{I18n.t('myProfile.titleProfile')}</h3>
            </div>
            <div className="form-row">
              <label className="label" htmlFor={'email'}>
                {I18n.t('myProfile.email')}:
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={this.state.email}
                className="input disabled"
                onChange={this.fieldChangedHandler}
                disabled={true}
                autoCapitalize="off"
              />
            </div>
          </div>

          <div className="page-block">
            <div className="half-page">
              <div className="form-row">
                <h3>{I18n.t('myProfile.titlePasswordChange')}</h3>
              </div>
              <div className="form-row">
                <label className="label" htmlFor={'currentPassword'}>
                  {I18n.t('myProfile.currentPassword')}:
                </label>
                <div className="password-wrapper">
                  <input
                    id="currentPassword"
                    name="currentPassword"
                    type={this.state.passwordPeek ? 'text' : 'password'}
                    className="input"
                    value={this.state.currentPassword}
                    onChange={this.fieldChangedHandler}
                    autoCapitalize="off"
                  />
                  <div className="button-peek" onClick={this.togglePasswordPeek}>
                    <i className={this.state.passwordPeek ? 'icon-eye' : 'icon-eye-off'} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label className="label" htmlFor={'newPassword'}>
                  {I18n.t('myProfile.newPassword')}:
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type={this.state.passwordPeek ? 'text' : 'password'}
                  className="input"
                  value={this.state.newPassword}
                  onChange={this.fieldChangedHandler}
                  autoCapitalize="off"
                />
              </div>
              <div className="form-row">
                <label className="label" htmlFor={'repeatNewPassword'}>
                  {I18n.t('myProfile.repeatNewPassword')}:
                </label>
                <input
                  id="repeatNewPassword"
                  name="repeatNewPassword"
                  type={this.state.passwordPeek ? 'text' : 'password'}
                  className="input"
                  value={this.state.repeatNewPassword}
                  onChange={this.fieldChangedHandler}
                  autoCapitalize="off"
                />
              </div>
            </div>
            {(this.state.currentPassword.length > 0 ||
              this.state.newPassword.length > 0 ||
              this.state.repeatNewPassword.length > 0) && (
              <div className="half-page">
                <div className="password-validation">
                  <div className="requirements">
                    <div className="title">{I18n.t('myProfile.passwordTips.requirements')}</div>
                    <div className="info-box">
                      {I18n.t('myProfile.passwordTips.requirementsInfo')}
                    </div>
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.match')}
                      invalidName={I18n.t('myProfile.passwordTips.noMatch')}
                      value={validation.match}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsLength')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsLength')}
                      value={validation.length}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsCasing')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsCasing')}
                      value={validation.containsUpper && validation.containsLower}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsNumber')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsNumber')}
                      value={validation.containsNumeric}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsSpecialCharacters')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsSpecialCharacters')}
                      value={validation.containsSpecial}
                    />
                  </div>
                  <div className="recommendations">
                    <div className="title">{I18n.t('myProfile.passwordTips.tips')}</div>
                    <div className="info-box">{I18n.t('myProfile.passwordTips.tipsInfo')}</div>
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsStoreSafely')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsStoreSafely')}
                      subTitle={I18n.t('myProfile.passwordTips.tipsStoreSafelySubtitle')}
                      value={false}
                      optional={true}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsUnique')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsUnique')}
                      subTitle={I18n.t('myProfile.passwordTips.tipsUniqueSubtitle')}
                      value={false}
                      optional={true}
                    />
                    <ValidationBox
                      validName={I18n.t('myProfile.passwordTips.tipsNoPersonalInfo')}
                      invalidName={I18n.t('myProfile.passwordTips.tipsNoPersonalInfo')}
                      value={false}
                      optional={true}
                    />
                  </div>
                </div>
                {validation.length &&
                  validation.containsUpper &&
                  validation.containsLower &&
                  validation.containsNumeric &&
                  validation.containsSpecial && (
                    <div className="successful">{I18n.t('myProfile.passwordTips.allMet')}</div>
                  )}
              </div>
            )}
          </div>

          <div className="page-block">
            <div className="form-row">
              <h3>{I18n.t('myProfile.titleShipDimensions')}</h3>
            </div>
            {['length', 'width', 'height', 'draught'].map((label, index) => {
              return (
                <div className="form-row" key={index}>
                  <label className="label" htmlFor={label}>
                    {I18n.t('myProfile.ship.' + label)}
                  </label>

                  <input
                    id={label}
                    step="any"
                    type="text"
                    value={this.state[label]}
                    className="input"
                    autoCapitalize="off"
                    onChange={e => {
                      this.setState({ [label]: e.target.value.replace(',', '.') } as any)
                    }}
                  />

                  {showDimensionsErrorMessage(label, this.state[label], 'error-message')}
                </div>
              )
            })}
          </div>

          <div className="page-block">
            <input
              type="submit"
              className="button save-button primary large"
              value={I18n.t('myProfile.save')}
            />
            <button
              type="button"
              onClick={this.confirmDeleteAccount}
              className="button danger large delete-accout-button"
            >
              {I18n.t('myProfile.titleDeleteAccount')}
            </button>
          </div>

          <div className="version">
            {I18n.t('appTitle')} {I18n.t('version', { version: pjson.version })}
          </div>
        </form>
      </div>
    )
  }

  public fieldChangedHandler = (event: React.ChangeEvent<any>) => {
    const value = event.target.value
    const field = event.target.name

    this.setState({ [field]: value } as any)
  }

  public handleFormSubmit = async (e: React.ChangeEvent<any>) => {
    e.preventDefault()

    await this.handleRecreationalShipDimensionsUpdate()
    await this.handleUpdatePassword()
  }

  /**
   * Updates the password for Barge users
   */
  public handleUpdatePassword = async () => {
    const { currentUser } = this.props
    if (!currentUser) return

    // Update password if changes are made and new password is correct
    if (this.isPasswordValid()) {
      try {
        await this.props.teqplayApiService.updatePassword({
          username: this.props.currentUser.userName,
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword
        })

        toast.success(I18n.t('myProfile.updatedPasswordSuccess'))
        this.setState({
          currentPassword: '',
          newPassword: '',
          repeatNewPassword: ''
        })
      } catch (err) {
        console.error(err.statusCode)
        if (err.statusCode === 1506) {
          // New password too weak
          toast.error(I18n.t('myProfile.tooWeak'))
        } else if (err.statusCode === 1401) {
          // Current password incorrect
          toast.error(I18n.t('myProfile.wrongPassword'))
        } else {
          toast.error(I18n.t('generalError'))
        }
        // handleFetchError(err, this)
      }
    }
  }

  /**
   * Updates the ship dimensions for recreational skippers
   */
  public handleRecreationalShipDimensionsUpdate = async () => {
    const width = Number(this.state.width)
    const length = Number(this.state.length)
    const height = Number(this.state.height)
    const draught = Number(this.state.draught)

    const dimensions =
      this.state.currentShip && this.state.currentShip.dimensions
        ? this.state.currentShip.dimensions
        : null
    const shipDimensionsCorrect = areShipDimensionsCorrectlyFilledIn(width, height, length, draught)

    if (!shipDimensionsCorrect) {
      toast.error(I18n.t('myProfile.dimensionsSaveWarning'))
      return
    }

    // If dimensions are changed update send it to backend
    if (
      !dimensions ||
      width !== dimensions.width ||
      length !== dimensions.length ||
      height !== dimensions.height ||
      draught !== dimensions.draught
    ) {
      const newDimensions = { width, length, height, draught }
      try {
        await this.props.teqplayApiService.updateCurrentShipDimensions(newDimensions)
        toast.success(I18n.t('myProfile.updateProfileSuccess'))
      } catch (err) {
        toast.error(I18n.t('myProfile.updateProfileFailed'))
      }
    }
  }

  public handleProfileInformationUpdating = async (currentProfile: IUserAuth) => {
    if (!this.props.currentUser) return

    try {
      // const updatedProfile = await this.teqplayApiService.updateUserProfile(currentProfile)

      await this.props.teqplayApiService.updateUserProfile(currentProfile)
      toast.success(I18n.t('myProfile.updateProfileSuccess'))

      // this.props.dispatch(setCurrentProfile(updatedProfile))
      // this.props.dispatch(fetchCurrentShip(updatedProfile))
    } catch (err) {
      toast.error(I18n.t('myProfile.updateProfileFailed'))
    }
  }

  public isPasswordValid = () => {
    // Check if the password fields are not empty
    if (!this.state.currentPassword && !this.state.newPassword && !this.state.repeatNewPassword) {
      return false
    }

    if (this.state.newPassword !== this.state.repeatNewPassword) {
      toast.error(I18n.t('myProfile.newPasswordNoMatch'))
      return false
    }

    return true
  }

  public confirmDeleteAccount = () => {
    confirmPopup({
      className: 'delete-account-modal',
      message: I18n.t('myProfile.areYouSureDeleteAccount'),
      cancelText: I18n.t('myProfile.no'),
      confirmText: I18n.t('myProfile.yesDelete'),
      confirmClassName: 'button large danger',
      onConfirm: async () => {
        toast.success(I18n.t('myProfile.removeSuccess'))
        await this.props.teqplayApiService.deleteAccount()

        this.props.history.push({
          pathname: '/login'
        })
      }
    })
  }

  public getCurrentShip = async () => {
    try {
      const currentShip = await this.props.teqplayApiService.getCurrentShipInformation()

      if (currentShip && currentShip.dimensions) {
        const dimensions = currentShip.dimensions

        this.setState({
          width: dimensions.width ? dimensions.width.toString() : '',
          height: dimensions.height ? dimensions.height.toString() : '',
          length: dimensions.length ? dimensions.length.toString() : '',
          draught: dimensions.draught ? dimensions.draught.toString() : '',
          currentShip
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  public togglePasswordPeek = () => {
    this.setState({ passwordPeek: !this.state.passwordPeek })
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {}
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
