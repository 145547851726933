import { ZOOM_TYPES } from './zoomTypes'
import { getShipColor } from './getShipColor'
import { getPolygonPositions, getMarkerHeading } from './ContourFunctions'
import { IShipInfo } from '../../../../../services/TeqplayApiService/TeqplayApi'
import { IExtendedShip } from '../../../../../@types/types'
import { recreationalClasses } from '../../../../../utils/constants'

export function filterShipsSmartly(
  allShips: IShipInfo[],
  zoom: number | undefined,
  selectedShipID: IShipInfo | null
): {
  normalIcons: IExtendedShip[]
  circleIcons: IExtendedShip[]
  detailedShips: IExtendedShip[]
} {
  const normalIcons: IShipInfo[] = []
  const circleIcons: IShipInfo[] = []
  const detailedShips: IShipInfo[] = []

  allShips.forEach(s => {
    if (zoom && zoom > ZOOM_TYPES.fixed[1]) {
      if (
        zoom > ZOOM_TYPES.contour[0] &&
        s.positionOfTransponder &&
        s.location?.latitude &&
        s.location.longitude
      ) {
        return detailedShips.push(s)
      }
      if (s && s.speedOverGround && s.speedOverGround > 2) {
        return normalIcons.push(s)
      }
      return circleIcons.push(s)
    }
    if (s && s.speedOverGround && s.speedOverGround > 2) {
      return normalIcons.push(s)
    }
    return circleIcons.push(s)
  })

  const addProperties = (arr: IShipInfo[]): IExtendedShip[] =>
    arr.map(s => ({
      ...s,
      properties: {
        ...s,
        shipColor: `marker-ship-${getShipColor(s.shipType)}`,
        rawColor: isRecreationalVessel(s) ? '#009BDF' : '#E8B620',
        radius: 5,
        isSelected: s.mmsi === selectedShipID?.mmsi,
        dimensions: s.location
          ? getPolygonPositions(
              s,
              {
                lat: s.location?.latitude || s.location?.coordinates?.[1] || 0,
                lng: s.location?.longitude || s.location?.coordinates?.[0] || 0
              },
              getMarkerHeading(s, true)
            )
          : []
      }
    }))

  return {
    normalIcons: addProperties(normalIcons),
    circleIcons: addProperties(circleIcons),
    detailedShips: addProperties(detailedShips)
  }
}

function isRecreationalVessel(ship: IShipInfo) {
  if (recreationalClasses.includes(ship.shipType)) {
    return true
  } else {
    return false
  }
}
