import * as React from 'react'
import { Link } from 'react-router-dom'

import { createAnonymousPopup } from '../../../shared/confirmPopup/ConfirmPopup'

interface IProps {
  onClick: (e: React.ChangeEvent<any>) => void
  pageName: string
  iconClass: string
  url: string
  activePage?: string

  logoutAndRegister: () => void
  isAnonymous: boolean
  anonymousAllowed: boolean
}

const MenuItem: React.FunctionComponent<IProps> = props => {
  const { onClick, logoutAndRegister, isAnonymous, anonymousAllowed } = props
  const activePageClass = isPageActive(props.url, props.activePage) ? 'active' : ''

  return (
    <Link
      onClick={handleClick}
      className={`menu-item ${activePageClass}`}
      to={{ pathname: props.url }}
    >
      <i className={`${props.iconClass}`} />
      <span>{props.pageName}</span>
    </Link>
  )

  function handleClick(e: React.ChangeEvent<any>) {
    if (anonymousAllowed || (!anonymousAllowed && isAnonymous === false)) {
      onClick(e)
    } else {
      e.preventDefault()
      createAnonymousPopup(() => logoutAndRegister())
    }
  }
}

export default MenuItem

function isPageActive(pageName: string, activePage?: string) {
  if (!activePage) return false

  if ((activePage === '/' || activePage === '') && pageName !== '/') {
    // If the active page is empty (homepage) make sure not all pages are set to active
    return false
  }

  return pageName.includes(activePage)
}
