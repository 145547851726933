import React, { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ReactMapboxGl, { Marker, ZoomControl, RotationControl } from 'react-mapbox-gl'
import * as MapboxGl from 'mapbox-gl'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import { IUserLocation } from '../../@types/types'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { FRIESLAND_LOCATION, MAPBOX_TOKEN, MAP_BOX_DEFAULT } from '../../utils/constants'
import { isCordovaApp } from '../../utils/cordovaUtils'

import './FeedbackPage.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  userLocation: IUserLocation | null
}

interface IMapboxFeedback {
  feedbackLocation: { lat: number; lng: number } | null
  userLocation: { lat: number; lng: number } | null
  handleClick(map: MapboxGl.Map, evt: MapboxGl.MapMouseEvent | any): void
}

const MapBoxGL = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN
})

const FeedbackPage: React.FunctionComponent<IProps> = props => {
  const [feedbackLocation, setFeedbackLocation] = useState<{ lat: number; lng: number } | null>(
    null
  )
  const [feedbackDescription, setFeedbackDescription] = useState('')
  const [showMap, setShowMap] = useState(false)

  const userLocation =
    props.userLocation &&
    props.userLocation.currentLocation &&
    props.userLocation.currentLocation.location
      ? {
          lat: props.userLocation.currentLocation.location.latitude,
          lng: props.userLocation.currentLocation.location.longitude
        }
      : null

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      accept: 'image/jpeg, image/png',
      multiple: false
    })

  const acceptedFilesItems = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee'
  }

  const acceptStyle = {
    borderStyle: 'solid',
    borderColor: '#00e676'
  }

  const rejectStyle = {
    borderStyle: 'solid',
    borderColor: '#ff1744'
  }

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject]
  )

  if (showMap && isCordovaApp) {
    return (
      <div className="map-wrapper">
        <div className="page-block title">
          <h3>{I18n.t('feedbackPage.locationText')}</h3>
          <button className="button" onClick={() => setShowMap(!showMap)}>
            {I18n.t('feedbackPage.done')}
          </button>
        </div>
        <MapBoxFeedBack
          feedbackLocation={feedbackLocation}
          userLocation={userLocation}
          handleClick={handleClick}
        />
      </div>
    )
  }

  return (
    <div className="feedback-page-wrapper">
      <h2 className="page-block">{I18n.t('feedbackPage.title')}</h2>
      <div className="page-block danger">
        <i className="icon-attention" />
        <span>{I18n.t('feedbackPage.noEmergencies')}</span>
        <i className="icon-attention" />
      </div>
      <div className="page-block description-block">
        <h3>{I18n.t('feedbackPage.description')}</h3>
        <textarea
          name="description"
          className="feedback-description"
          id=""
          value={feedbackDescription}
          placeholder={I18n.t('feedbackPage.descriptionText')}
          onChange={(e: any) => {
            if (typeof e.target.value === 'string') {
              setFeedbackDescription(e.target.value)
            }
          }}
        />
      </div>

      <div className="page-block">
        <h3>{I18n.t('feedbackPage.location')}</h3>
        {isCordovaApp ? (
          <button className="button" onClick={() => setShowMap(!showMap)}>
            {I18n.t('feedbackPage.chooseLocation')}
          </button>
        ) : (
          <React.Fragment>
            <p>{I18n.t('feedbackPage.locationText')}</p>
            <div className="feedback-map-wrapper">
              <MapBoxFeedBack
                feedbackLocation={feedbackLocation}
                userLocation={userLocation}
                handleClick={handleClick}
              />
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="page-block">
        <h3> {I18n.t('feedbackPage.photo')}</h3>
        <p>{I18n.t('feedbackPage.photoText')}</p>
        <div>
          <section>
            <div className="file-upload-input" {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {acceptedFilesItems.length > 0 ? (
                acceptedFilesItems
              ) : (
                <p className="upload-message">{I18n.t('feedbackPage.uploadText')}</p>
              )}
            </div>
          </section>
        </div>
      </div>

      <div className="page-block">
        <button onClick={submitFeedback} className="button primary">
          {I18n.t('feedbackPage.sendFeedback')}
        </button>
      </div>
    </div>
  )

  function handleClick(map: MapboxGl.Map, evt: MapboxGl.MapMouseEvent | any) {
    setFeedbackLocation(evt.lngLat)
  }

  function submitFeedback() {
    const markerLoc = feedbackLocation ? feedbackLocation : userLocation ? userLocation : null

    if (feedbackDescription.length > 0) {
      const locationToSend = markerLoc
        ? `${I18n.t('latitude')}: ${markerLoc.lat} , ${I18n.t('longitude')}: ${markerLoc.lng}`
        : I18n.t('feedbackPage.noLocationKnown')
      const fileToUpload = acceptedFiles ? acceptedFiles[0] : null
      try {
        props.teqplayApiService.submitFeedback(feedbackDescription, locationToSend, fileToUpload)
        setFeedbackDescription('')
        setFeedbackLocation(null)
        toast.success(I18n.t('feedbackPage.successMessage'))
      } catch (error) {
        toast.error(I18n.t('feedbackPage.errorMessage'))
      }
    } else {
      toast.error(I18n.t('feedbackPage.warningMessage'))
    }
  }
}

const MapBoxFeedBack = ({ feedbackLocation, userLocation, handleClick }: IMapboxFeedback) => {
  console.log(feedbackLocation)

  return (
    <MapBoxGL
      style={MAP_BOX_DEFAULT}
      center={
        feedbackLocation
          ? [feedbackLocation.lng, feedbackLocation.lat]
          : userLocation
          ? [userLocation.lng, userLocation.lat]
          : [FRIESLAND_LOCATION.lng, FRIESLAND_LOCATION.lat]
      }
      onClick={(e, evt) => handleClick(e, evt)}
    >
      <>
        <Marker
          coordinates={
            userLocation
              ? [userLocation.lng, userLocation.lat]
              : [FRIESLAND_LOCATION.lng, FRIESLAND_LOCATION.lat]
          }
          anchor="bottom"
        >
          <div className="ship-user-icon">
            <span className="icon-user-location" />
          </div>
        </Marker>
        {feedbackLocation && (
          <Marker coordinates={[feedbackLocation.lng, feedbackLocation.lat]} anchor="bottom">
            <div className="feedback-flag" />
          </Marker>
        )}
        <ZoomControl />
        <RotationControl />
      </>
    </MapBoxGL>
  )
}

export default FeedbackPage
