import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import {
  IFullPosition,
  IGeoLocationWatcherState
} from '../../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import SetSpeed from '../../../components/mapShared/setSpeed/SetSpeed'
import NotifcationMonitor from '../../../components/notificationMonitor/NotificationMonitor'
import RouteList from '../../../components/routeList/RouteList'
import DefaultTransition from '../../../components/shared/defaultTransition/DefaultTransition'
import {
  IBridgeOpeningInfo,
  IRoute,
  IShipNotificationStatus,
  IWatchDogStatus
} from '../../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'

interface IProps {
  active: boolean
  teqplayApiService: TeqplayApiService
  navigationRoute: IRoute | null
  shipNotificationStatus: IShipNotificationStatus | null
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
  isRoutePaused: boolean
  bridgeOpenings: IBridgeOpeningInfo[]
  pauseRoute: () => void
  resumeRoute: () => void
  stopRoute: () => void
  toggleOptions: () => void
  currentLocation: IFullPosition | null
  watchdogStatus: IWatchDogStatus
  geoLocationWatcherState: IGeoLocationWatcherState
  selectedTime: number | undefined
}

const NavigationOptions: React.FunctionComponent<IProps> = props => {
  const [stoppingRoute, setStoppingRoute] = React.useState(false)
  return (
    <DefaultTransition active={props.active} slideFrom="bottom" offset={500}>
      {styleProps => (
        <div style={styleProps} className="navigation-options-wrapper">
          <Tabs className="tabs-wrapper">
            <TabList className="tabs-header">
              <Tab className="tab" selectedClassName="active-tab">
                <span className="icon-list" /> {I18n.t('navigationPage.list_view')}
              </Tab>
              <Tab className="tab" selectedClassName="active-tab">
                <span className=" icon-notification-bell" />
                {I18n.t('navigationPage.notifications')}
              </Tab>
              <Tab className="tab" selectedClassName="active-tab">
                <span className="icon-gauge-1" />
                {I18n.t('navigationPage.ship_speed')}
              </Tab>
            </TabList>

            <div className="tabs-content">
              <TabPanel>
                <RouteList
                  teqplayApiService={props.teqplayApiService}
                  selectedRoute={props.navigationRoute}
                  bridgeOpenings={props.bridgeOpenings}
                  currentLocation={props.currentLocation}
                  watchdogStatus={props.watchdogStatus}
                  locationPermissions={props.geoLocationWatcherState.locationPermissions}
                  selectionTime={props.selectedTime}
                />
              </TabPanel>
              <TabPanel>
                <NotifcationMonitor
                  notifications={
                    props.shipNotificationStatus ? props.shipNotificationStatus.notifications : null
                  }
                  VDJSNotificationMessage={props.VDJSNotificationMessage}
                  channelApproachNotificationMessage={props.channelApproachNotificationMessage}
                />
              </TabPanel>
              <TabPanel>
                <SetSpeed teqplayApiService={props.teqplayApiService} />
              </TabPanel>
            </div>
          </Tabs>

          {stoppingRoute && (
            <div className="confirm-popup-wrapper" onClick={() => setStoppingRoute(false)}>
              <div
                className="confirm-popup"
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <h3>{I18n.t('navigationPage.stop_route_confirmation')}</h3>
                <div className="button-wrapper">
                  <button
                    className="button primary"
                    onClick={() => {
                      props.stopRoute()
                    }}
                  >
                    {I18n.t('myProfile.yes')}
                  </button>
                  <button className="button close" onClick={() => setStoppingRoute(false)}>
                    {I18n.t('myProfile.no')}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="navigation-options-controls">
            <button className="control-small" onClick={props.toggleOptions}>
              <span className="icon-down-open" />
            </button>
            {props.isRoutePaused ? (
              <button className="control-large resume" onClick={props.resumeRoute}>
                <span className="icon-pause-circle-o" />
                {I18n.t('routeSelection.resumeRoute')}
              </button>
            ) : (
              <button className="control-large pause" onClick={props.pauseRoute}>
                <span className="icon-pause-circle-o" />
                {I18n.t('routeSelection.pauseRoute')}
              </button>
            )}

            <button
              className="control-large stop"
              onClick={() => {
                setStoppingRoute(true)
              }}
            >
              <span className="icon-stop-circle-o" />
              {I18n.t('navigationPage.stop_route')}
            </button>
          </div>
        </div>
      )}
    </DefaultTransition>
  )
}

export default NavigationOptions
