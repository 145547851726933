import { BACKEND_URL } from '../utils/constants'
import { IRestError } from './TeqplayApiService/TeqplayApi'

import fyLocale from '@teqplay/date-fns/src/locale/fy'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import nlLocale from 'date-fns/locale/nl'

export class AnonymousUserError implements Error {
  public name: string
  public message: string

  constructor() {
    this.name = 'AnonymousUserError'
    this.message = 'anonymous.notAllowed'

    console.trace()
  }
}

export function returnLanguageCodeFromLocale(locale: string) {
  switch (locale) {
    case 'nl_NL':
      return 'nl'
    case 'en_GB':
      return 'en'
    case 'de_DE':
      return 'de'
    case 'frl_NL':
      return 'fy'

    default:
      console.error(
        `Could not parse language code ${locale} into a known code, please add it to the list.`
      )
      return 'nl'
  }
}

export function returnDateFNSLocale(locale: string) {
  switch (locale) {
    case 'nl_NL':
      return nlLocale
    case 'en_GB':
      return enLocale
    case 'de_DE':
      return deLocale
    case 'frl_NL':
      return fyLocale

    default:
      console.error(
        `Could not parse language code ${locale} into a known code, please add it to the list.`
      )
      return 'nl'
  }
}

export function requestPasswordResetMail(username: string, language: string) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'TEQPLAY-APP': 'VARENFRIESLAND'
    },
    body: JSON.stringify({
      username
    })
  }

  return fetch(`${BACKEND_URL}/auth/resetPassword?language=${language}`, options).then(
    handleRestResponse
  )
}

function handleRestResponse(response: any): Promise<any> {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response.json())
  } else {
    return Promise.resolve(response.json())
      .catch(err => ({ status: response.status, message: err.message }))
      .then((restError: IRestError) => {
        throw restError
      })
  }
}
