import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { kmhToKnots, knotsToKmh } from '../../../../utils/general'

import './SpeedOptions.scss'

interface IProps {
  showSpeedOptions: boolean
  cruiseSpeed: number // knots
  setCruiseSpeed: (speed: number) => void
}

const SpeedOptions = ({ cruiseSpeed, setCruiseSpeed, showSpeedOptions }: IProps) => {
  const unit = 'kmh'

  if (!showSpeedOptions) {
    return null
  }

  return (
    <div className="time-options-wrapper speed-options">
      <div className="option-label">
        <Translate value="navigationPage.ship_speed" className="form-label" />
      </div>
      <div className="input-wrapper">
        <input
          className="input right"
          value={knotsToKmh(cruiseSpeed)}
          onChange={handleSpeedChange}
          name="speed"
          id="speed-unit"
          type="number"
          step={1}
        />
        <div className="suffix">{I18n.t(unit === 'kmh' ? 'units.kmh' : 'units.knots')}</div>
      </div>
      <div className="subtitle">{I18n.t('map.speed.title')}</div>
    </div>
  )

  function handleSpeedChange(e: React.ChangeEvent<any>) {
    const value = kmhToKnots(Number(e.target.value))

    setCruiseSpeed(value)
  }
}

export default SpeedOptions
