import React from 'react'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'
import LocalePicker from '../../localePicker/LocalePicker'
import DynamicSave from './DynamicSave'

interface IConfirmPopupOptions {
  message: string | any
  title?: string
  confirmText?: React.ReactChild
  cancelText?: string
  confirmClassName?: string
  cancelClassName?: string
  onConfirm?: () => void
  hideConfirm?: boolean
  onCancel?: () => void
  className?: string
  closeOnOutsideClick?: boolean
  hideCancel?: boolean
}

/**
 * Open a confirm popup using react-popup
 * showing a message and a Cancel and Ok button.
 */
export function confirmPopup({
  message,
  title,
  className,
  confirmText,
  cancelText,
  confirmClassName,
  hideCancel,
  hideConfirm,
  cancelClassName,
  onConfirm,
  onCancel,
  closeOnOutsideClick
}: IConfirmPopupOptions) {
  const cancelButton = (onc?: () => void) => ({
    text: cancelText || I18n.t('cancel'),
    className: cancelClassName || 'cancel button large secondary',
    key: 'esc',
    action: () => {
      Popup.close()

      if (onc) {
        onc()
      }
    }
  })

  const confirmButton = (onc?: () => void) => ({
    text: confirmText || I18n.t('affirm'),
    className: confirmClassName || 'button large primary',
    key: 'enter',
    action: () => {
      Popup.close()

      if (onc) {
        onc()
      } else {
        throw new Error('No confirm action given to Popup')
      }
    }
  })

  Popup.create({
    title,
    content: message,
    className,
    closeOnOutsideClick,
    buttons: {
      left: [],
      right: [
        hideCancel !== true && cancelButton(onCancel),
        hideConfirm !== true && confirmButton(onConfirm)
      ].filter(e => e)
    }
  })
}

export function createAnonymousPopup(onConfirm: () => void) {
  confirmPopup({
    message: I18n.t('anonymous.notAllowed'),
    onConfirm,
    confirmText: I18n.t('anonymous.createAccount'),
    cancelText: I18n.t('anonymous.cancel'),
    closeOnOutsideClick: true
  })
}

export function createLocalePopup() {
  confirmPopup({
    message: (
      <div className="locale-popup-picker">
        <LocalePicker alwaysSelecting />
      </div>
    ),
    className: 'locale-modal',
    onConfirm: () => Popup.close(),
    hideConfirm: false,
    hideCancel: true,
    confirmText: <DynamicSave />
  })
}
