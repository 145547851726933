import cloneDeep from 'lodash/cloneDeep'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import { IRouteSearchProps, RouteSelectionFields } from '../../../../@types/types'
import { IRouteLocation } from '../../../../services/TeqplayApiService/TeqplayApi'
import RouteSelectField from '../routeSelectField/RouteSelectField'

import './ViaRouteSelection.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  setRouteSelectionInactive: () => void
  setrouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
}

class ViaRouteSelection extends React.PureComponent<IProps> {
  public render() {
    const viaRoutes = this.props.routeSelection.viaRoutes
    return (
      <div className="via-routes-wrapper">
        {viaRoutes.map((viaRoute, index) => {
          const locationValue =
            viaRoute.displayName && viaRoute.displayName !== null ? viaRoute.displayName : ''

          return (
            <RouteSelectField
              key={'via-route' + index + locationValue}
              fieldName={'VIA_ROUTES'}
              value={locationValue}
              setrouteSelectionFormFieldActive={this.props.setrouteSelectionFormFieldActive}
              placeholder={I18n.t('routeSelection.selectViaPoint')}
              viaRouteIndex={index}
              className="via-route-field"
              removeViaRoute={this.removeViaRoute}
            />
          )
        })}

        <button type="button" className="button via-button" onClick={this.addEmptyViaRoute}>
          {I18n.t('routeSelection.viaPoint')} <i className="icon-plus-circled via-icon" />
        </button>
      </div>
    )
  }

  public addEmptyViaRoute = () => {
    const viaRoutes = cloneDeep(this.props.routeSelection.viaRoutes)
    viaRoutes.push({
      displayName: '',
      coordinates: null
    })

    this.props.setViaRoutes(viaRoutes)
  }

  public removeViaRoute = (viaRouteIndex: number) => {
    const viaRoutes = cloneDeep(this.props.routeSelection.viaRoutes)
    viaRoutes.splice(viaRouteIndex, 1)
    this.props.setViaRoutes(viaRoutes)
  }
}

export default ViaRouteSelection
