import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import RouteSelectField from './routeSelectField/RouteSelectField'
import SavedRoutesList from './savedRoutesList/SavedRoutesList'
import SpeedOptions from './speedOptions/SpeedOptions'
import TimeOptions from './timeOptions/TimeOptions'
import ViaRouteSelection from './viaRouteSelection/ViaRouteSelection'

import { IRouteSearchProps, RouteSelectionFields } from '../../../@types/types'
import { IRoute, IRouteLocation } from '../../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import { loadFromSessionStorage, saveToSessionStorage } from '../../../utils/localStorage'
import { IFullPosition } from '../../geoLocationWatcher/GeoLocationWatcherTypes'
import './RouteSelectForm.scss'

interface IProps {
  routeSelection: IRouteSearchProps
  teqplayApiService: TeqplayApiService
  currentLocation: IFullPosition | null
  searchAvailableRoutes: () => void
  setRouteSelectionInactive: () => void
  setrouteSelectionFormFieldActive: (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (fromLocation: IRouteLocation | null) => void
  departureTime: number
  setDepartureTime: (arrivalDate: Date) => void
  startRoute: (route: IRoute) => void
  cruiseSpeed: number
  setCruiseSpeed: (speed: number) => void
}

interface IState {
  showExtraOptions: boolean
}

class RouteSelectForm extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    showExtraOptions: loadFromSessionStorage('WATERSPORT-showExtraOptions', false)
  }

  public render() {
    const fromLocationValue =
      this.props.routeSelection.fromLocation &&
      this.props.routeSelection.fromLocation.displayName !== null
        ? this.props.routeSelection.fromLocation.displayName
        : ''

    const toLocationValue =
      this.props.routeSelection.toLocation &&
      this.props.routeSelection.toLocation.displayName !== null
        ? this.props.routeSelection.toLocation.displayName
        : ''

    return (
      <div className="route-select-wrapper">
        <div className="select-multiline-inner-wrapper">
          <div className="route-select-menu-buttons">
            <i
              className="icon-left-big close-button"
              onClick={this.props.setRouteSelectionInactive}
            />
          </div>

          <form
            action=""
            className={`route-selection-form${this.state.showExtraOptions ? ' show-options' : ''}`}
          >
            <RouteSelectField
              key="source-select-field"
              displayName={I18n.t('routeSelection.startingPoint')}
              fieldName={'FROM_LOCATION'}
              value={fromLocationValue}
              setrouteSelectionFormFieldActive={this.props.setrouteSelectionFormFieldActive}
              clearLocation={() => this.props.setFromLocation(null)}
              placeholder={
                this.props.currentLocation
                  ? I18n.t('routeSelection.currentLocation')
                  : I18n.t('routeSelection.selectStartingPoint')
              }
            />

            <ViaRouteSelection
              routeSelection={this.props.routeSelection}
              setrouteSelectionFormFieldActive={this.props.setrouteSelectionFormFieldActive}
              setRouteSelectionInactive={this.props.setRouteSelectionInactive}
              setViaRoutes={this.props.setViaRoutes}
            />

            <RouteSelectField
              key="destination-select-field"
              displayName={I18n.t('routeSelection.destinationPoint')}
              fieldName={'TO_LOCATION'}
              value={toLocationValue}
              setrouteSelectionFormFieldActive={this.props.setrouteSelectionFormFieldActive}
              clearLocation={() => this.props.setToLocation(null)}
              placeholder={I18n.t('routeSelection.selectDestination')}
            />
          </form>

          <TimeOptions
            showTimeOptions={this.state.showExtraOptions}
            toggleTimeOptions={this.toggleExtraOptions}
            setDepartureTime={this.props.setDepartureTime}
            departureTime={this.props.departureTime}
          />

          <SpeedOptions
            showSpeedOptions={this.state.showExtraOptions}
            cruiseSpeed={this.props.cruiseSpeed}
            setCruiseSpeed={this.props.setCruiseSpeed}
          />

          <div className="button-wrapper">
            {this.props.routeSelection.toLocation &&
            (this.props.routeSelection.fromLocation ||
              (this.props.currentLocation && this.props.currentLocation.location)) ? (
              <button
                type="button"
                className="button primary route-select-button large"
                onClick={this.searchRoutes}
              >
                {I18n.t('routeSelection.calculateRoute')}
              </button>
            ) : (
              <button
                type="button"
                className="button disabled-button route-select-button large"
                onClick={this.showRouteSelectWarning}
              >
                {I18n.t('routeSelection.calculateRoute')}
              </button>
            )}

            <button
              type="button"
              className="button large options-toggle"
              onClick={this.toggleExtraOptions}
            >
              {I18n.t('routeSelection.options')}
            </button>
          </div>
        </div>

        <SavedRoutesList
          teqplayApiService={this.props.teqplayApiService}
          startRoute={this.props.startRoute}
        />
      </div>
    )
  }

  public searchRoutes = () => {
    // this sets the current location as the 'from location' of the route
    // ( only does this when the user has not selected a from location himself )
    if (
      !this.props.routeSelection.fromLocation &&
      this.props.currentLocation &&
      this.props.currentLocation.location
    ) {
      this.props.setFromLocation({
        displayName: I18n.t('routeSelection.currentLocation'),
        coordinates: {
          lat: this.props.currentLocation.location.latitude,
          lng: this.props.currentLocation.location.longitude
        }
      })
    }

    this.props.searchAvailableRoutes()
  }

  public showRouteSelectWarning = () => {
    toast.warn(I18n.t('routeSelection.selectionWarning'))
  }

  public toggleExtraOptions = () => {
    this.setState({ showExtraOptions: !this.state.showExtraOptions })
    saveToSessionStorage('WATERSPORT-showExtraOptions', !this.state.showExtraOptions)
  }
}

export default RouteSelectForm
