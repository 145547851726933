import React from 'react'
import { I18n } from 'react-redux-i18n'

export function showDimensionsErrorMessage(label: string, value: any, className?: string) {
  const MAX_DIMENSION = 100
  const MIN_DIMENSION = 0

  if (isNaN(value)) {
    return <label className={className}>{I18n.t('myProfile.dimensionsErrorMessage')}</label>
  }

  if (!value) {
    return <label className={className}>{I18n.t('myProfile.dimensionsWarningMessage')}</label>
  }

  if (value && (value > MAX_DIMENSION || value < MIN_DIMENSION)) {
    return <label className={className}>{I18n.t('myProfile.dimensionsErrorMessage')}</label>
  }

  return null
}

export function areShipDimensionsCorrectlyFilledIn(
  width: any,
  height: any,
  length: any,
  draught: any
) {
  if (
    !width ||
    !length ||
    !height ||
    !draught ||
    isNaN(width) ||
    isNaN(length) ||
    isNaN(height) ||
    isNaN(draught)
  ) {
    return false
  } else {
    if (width > 100 || width < 0) {
      return false
    }
    if (length > 100 || length < 0) {
      return false
    }
    if (height > 100 || height < 0) {
      return false
    }
    if (draught > 100 || draught < 0) {
      return false
    }
  }

  return true
}
