import cloneDeep from 'lodash/cloneDeep'
import * as MapboxGl from 'mapbox-gl'
import { BridgeRequestResponse, IHectometer } from '../../@types/types'
import { IShipLocationUpdate } from '../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import { ANONYMOUS_TOKEN, EMPTY_USER_AUTH, GENERAL_HEADERS_FORM_DATA } from '../../utils/constants'
import { sendMessageToSentry } from '../../utils/sentry'
import { AnonymousUserError, returnLanguageCodeFromLocale } from '../rest'
import {
  IAnonymousUser,
  IBackendError,
  IBerth,
  IBridgeDetails,
  IBridgeOpeningInfo,
  IChargingStation,
  IElectricalRoute,
  IGeocodedLocation,
  IGeoServiceLocation,
  IInlandHarbour,
  IlatLng,
  ILockDetails,
  ILoggedInUser,
  INavigationLocation,
  INewUser,
  INotificationDetails,
  IRestError,
  IRoute,
  IRouteEtaUpdate,
  IRouteLocation,
  IRouteScoutNetwork,
  ISelectedRoute,
  IShipDimensions,
  IShipInfo,
  IShipNotificationStatus,
  IStoredRoute,
  ITrailerSlipway,
  IUserAuth,
  IUserProfile,
  IWasteWaterStation,
  IWatchDogStatus,
  IWaterMeterDetails,
  IWinterRestArea,
  SpeedTypes,
  IFastSailingRoute,
  INodes
} from './TeqplayApi'

interface IFetchOptions {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  headers: any
  body?: string | FormData
}

class TeqplayApiService {
  // static reference to the class itself. Uses singleton pattern to enforce only 1 entity.
  private static _instance: TeqplayApiService

  protected languageCode: string
  protected userAuth: INewUser & ILoggedInUser // Internal use only
  private clearAuthInRedux: () => void
  private setAuthInRedux: (user: ILoggedInUser | IAnonymousUser, userInfo?: INewUser) => void
  private GENERAL_HEADERS: any
  private fcmId: string | null = null
  public BACKEND_URL: string

  // Function to determine if user is currenly anonymous or not
  public isAnonymousUser: () => boolean

  private constructor(
    setAuth: (user: ILoggedInUser | IAnonymousUser, userInfo?: INewUser | undefined) => void,
    clearAuth: () => void,
    userAuth: INewUser & ILoggedInUser,
    BACKEND_URL: string,
    GENERAL_HEADERS: any,
    locale: string
  ) {
    this.setAuthInRedux = setAuth
    this.clearAuthInRedux = clearAuth
    this.userAuth = userAuth
    this.BACKEND_URL = BACKEND_URL
    this.GENERAL_HEADERS = GENERAL_HEADERS
    this.languageCode = returnLanguageCodeFromLocale(locale)

    // Set check to ID if user is anonymous and set in state
    this.isAnonymousUser = () =>
      this.userAuth?.anonymous !== undefined
        ? this.userAuth.anonymous
        : this.userAuth?.token
        ? this.userAuth.token === ANONYMOUS_TOKEN
        : true
  }

  // method to return the service if it has been initialised already, otherwise it creates a instance and returns it.
  public static Instance(
    setAuth: (user: ILoggedInUser | IAnonymousUser, userInfo?: INewUser | undefined) => void,
    clearAuth: () => void,
    userAuth: INewUser & ILoggedInUser,
    BACKEND_URL: string,
    GENERAL_HEADERS: any,
    locale: string
  ) {
    if (returnLanguageCodeFromLocale(locale) !== this._instance?.languageCode) {
      return (this._instance = new this(
        setAuth,
        clearAuth,
        userAuth,
        BACKEND_URL,
        GENERAL_HEADERS,
        locale
      ))
    }
    return (
      this._instance ||
      (this._instance = new this(
        setAuth,
        clearAuth,
        userAuth,
        BACKEND_URL,
        GENERAL_HEADERS,
        locale
      ))
    )
  }

  private clearUserAuth = () => {
    this.userAuth = {
      userName: null,
      emailAddress: null,
      shipName: null,
      shipType: null,
      length: null,
      height: null,
      width: null,
      draught: null,

      token: '',
      refreshToken: null,
      expiresInSeconds: null,
      timestamp: null,
      teqplayId: null,
      createdAt: null
    }

    this.clearAuthInRedux()
  }

  public logoutUser() {
    if (this.fcmId) {
      this.deleteUserFCM()
    }

    if (this.userAuth.token) {
      this.logoutUserBackend()
    }

    this.clearUserAuth()
  }

  private handleRestResponse(response: any, noErrorNotification?: boolean): Promise<any> {
    if (response?.status >= 200 && response?.status < 300 && response.json) {
      return Promise.resolve(response.json())
    } else {
      return Promise.resolve((response.json && response.json()) || response)
        .catch(err => ({
          status: response.status || -1,
          message: err.message || 'An unknown error has occurred'
        }))
        .then((restError: IRestError) => {
          // This ensures that the status of the request is ALWAYS passed on
          // The app will break if removed
          const tempError = { ...restError, status: response?.status }

          // Makes for easier debugging to turn on toasts when things go wrong
          // if (!noErrorNotification) {
          //   toast.error(`${tempError.statusCode} ${tempError.message}`)
          // }

          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw tempError
        })
    }
  }

  private async fetchApiCall(url: string, options: IFetchOptions, fetchWithoutReturn?: boolean) {
    try {
      // returns null in case of requests without a body being returned by the backend
      if (fetchWithoutReturn === true) {
        return await fetch(url, options).then(() => null)
      }

      return await fetch(url, options).then(this.handleRestResponse)
    } catch (error) {
      if (this.isAnonymousUser()) {
        console.error('USER IS ANONYMOUS AND CALL IS FORBIDDEN')
      } else if (error.status === 401) {
        try {
          if (this.userAuth?.refreshToken) {
            const newAuth = await this.fetchLoginWithRefreshToken(
              this.userAuth.userName,
              this.userAuth.refreshToken
            )

            this.userAuth = { ...this.userAuth, ...newAuth }
            this.setAuthInRedux(newAuth)

            const newOptions = cloneDeep(options)
            newOptions.headers.Authorization = newAuth.token
            return await fetch(url, newOptions).then(this.handleRestResponse)
          } else {
            // No refresh token present, should logout
            this.logoutUser()
            throw new Error('No refreshToken present')
          }
        } catch (refreshError) {
          if (refreshError && refreshError.status === 401 && this.userAuth.refreshToken) {
            this.logoutUser()
          }
          throw error
        }
      } else {
        throw error
      }
    }
  }

  private fetchLoginWithRefreshToken(
    username: ILoggedInUser['userName'],
    refreshToken: ILoggedInUser['refreshToken']
  ): Promise<ILoggedInUser> {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, refreshToken })
    }

    return fetch(`${this.BACKEND_URL}/auth/loginWithRefreshToken`, options).then(
      this.handleRestResponse
    )
  }

  public async registerUser(newUser: INewUser): Promise<ILoggedInUser> {
    function isError(response: ILoggedInUser | IBackendError): response is IBackendError {
      return (response as IBackendError).errors !== undefined
    }

    const options: IFetchOptions = {
      method: 'PUT',
      headers: { ...this.GENERAL_HEADERS },
      body: JSON.stringify({
        emailAddress: newUser.emailAddress,
        password: newUser.password,
        shipName: newUser.shipName,
        shipType: newUser.shipType,
        dimensions: {
          length: newUser.length,
          width: newUser.width,
          height: newUser.height,
          draught: newUser.draught
        }
      })
    }

    try {
      const registeredReturn: ILoggedInUser | IBackendError = await fetch(
        `${this.BACKEND_URL}/register/varenfriesland`,
        options
      ).then(this.handleRestResponse)

      if (isError(registeredReturn)) {
        throw new Error(registeredReturn.errors[0])
      } else {
        const loggedInUser: ILoggedInUser = {
          createdAt: new Date().valueOf(),
          token: registeredReturn.token,
          userName: newUser.emailAddress,
          refreshToken: registeredReturn.refreshToken,
          expiresInSeconds: registeredReturn.expiresInSeconds,
          timestamp: new Date().valueOf() || null,
          teqplayId: null
        }

        // set the userAuth because we need the tokens to be able to fetch the profile
        // clear the password
        this.userAuth = { ...newUser, ...loggedInUser, password: undefined }
        this.setAuthInRedux(loggedInUser)

        // Then fetch the profile to add the TeqplayId to the loggedInUser
        const userProfile = await this.getUserProfile()
        // update the user with a teqplayId
        const currentLoggedInUser: ILoggedInUser = {
          ...cloneDeep(loggedInUser),
          teqplayId: userProfile.teqplayId
        }

        this.userAuth = { ...newUser, ...currentLoggedInUser, password: undefined }
        this.setAuthInRedux(currentLoggedInUser)

        return currentLoggedInUser
      }
    } catch (error) {
      throw error
    }
  }

  public async loginUser(username: string, password: string): Promise<ILoggedInUser> {
    const options = {
      method: 'POST',
      headers: { ...this.GENERAL_HEADERS },
      body: JSON.stringify({
        username,
        password
      })
    }

    try {
      const loggedInUser: ILoggedInUser = await fetch(
        `${this.BACKEND_URL}/auth/login`,
        options
      ).then(this.handleRestResponse)

      this.userAuth = { ...EMPTY_USER_AUTH, ...loggedInUser }
      this.setAuthInRedux(loggedInUser)

      const userProfile = await this.getUserProfile()

      // update the user with a teqplayId
      const currentLoggedInUser: ILoggedInUser = {
        ...cloneDeep(loggedInUser),
        teqplayId: userProfile.teqplayId
      }

      this.userAuth = { ...EMPTY_USER_AUTH, ...currentLoggedInUser }
      this.setAuthInRedux(currentLoggedInUser)

      const FRIESLAND_AUTH_GROUP = 'READ_FRIESLAND_DATA'
      if (
        !userProfile.authorizationGroups.includes(FRIESLAND_AUTH_GROUP) ||
        !userProfile.teqplayId
      ) {
        throw new Error('ACCOUNT_TYPE_ERROR')
      }

      return currentLoggedInUser
    } catch (error) {
      this.logoutUser()
      throw error
    }
  }

  public async loginAnonymously(): Promise<ILoggedInUser> {
    this.userAuth = {
      ...EMPTY_USER_AUTH,
      token: ANONYMOUS_TOKEN,
      anonymous: true
    }

    const anonymousUser: IAnonymousUser = {
      token: ANONYMOUS_TOKEN,
      teqplayId: null,
      anonymous: true
    }
    this.setAuthInRedux(anonymousUser)

    return { ...EMPTY_USER_AUTH, ...anonymousUser }
  }

  public deleteAccount = () => {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    if (this.fcmId) {
      this.deleteUserFCM()
    }

    return fetch(`${this.BACKEND_URL}/userProfile/current`, options).then(() =>
      this.clearUserAuth()
    )
  }

  public updatePassword = (passwordFields: {
    username: string
    currentPassword: string
    newPassword: string
  }) => {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(passwordFields)
    }

    return fetch(`${this.BACKEND_URL}/auth/changePassword`, options).then(this.handleRestResponse)
  }

  public logoutUserBackend() {
    if (this.isAnonymousUser()) {
      return new Promise(r => r(null))
    }

    const options = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return fetch(`${this.BACKEND_URL}/auth/logout`, options).then(() => null)
  }

  public fetchAllInlandHarbours(bounds?: MapboxGl.LngLatBounds): Promise<INavigationLocation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/inlandHarbour?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchChargingStations(bounds?: MapboxGl.LngLatBounds): Promise<IChargingStation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/chargingStation?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchBridges(bounds?: MapboxGl.LngLatBounds): Promise<IBridgeDetails[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(`${this.BACKEND_URL}/static/bridge?${urlBounds}`, options)
  }

  public fetchWasteWaterStations(bounds?: MapboxGl.LngLatBounds): Promise<IWasteWaterStation[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/wasteWaterStation?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchTrailerSlipways(bounds?: MapboxGl.LngLatBounds): Promise<ITrailerSlipway[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/trailerslipway?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchHectometer(bounds?: MapboxGl.LngLatBounds): Promise<IHectometer[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(`${this.BACKEND_URL}/static/hectometer?${urlBounds}`, options)
  }

  public fetchWinterRestAreas(bounds?: MapboxGl.LngLatBounds): Promise<IWinterRestArea[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/winterRestArea?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchBerths(bounds?: MapboxGl.LngLatBounds): Promise<IBerth[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/berth?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public async fetchInlandHarbours(bounds?: MapboxGl.LngLatBounds): Promise<IInlandHarbour[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)

    const inlandHarbours: IInlandHarbour[] = await this.fetchApiCall(
      `${this.BACKEND_URL}/static/inlandHarbour?${urlBounds}&language=${this.languageCode}`,
      options
    )
    const filteredInlandHarbours = inlandHarbours.filter(
      x => !x.shortStayPlaces || x.shortStayPlaces === 0
    ) // filter out any inlandharbours which don't have shortstayplaces

    return filteredInlandHarbours
  }

  public fetchElectricRoutes(bounds?: MapboxGl.LngLatBounds): Promise<IElectricalRoute[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/electricalRoute?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public fetchRouteNetwork(): Promise<IRouteScoutNetwork> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/routescout/network/FRYSLAN`, options)
  }

  public fetchShipsInBounds(bounds?: MapboxGl.LngLatBounds): Promise<IShipInfo[]> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(`${this.BACKEND_URL}/ship/details?${urlBounds}`, options)
  }

  public fetchAllNodes(): Promise<INodes[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }
    return this.fetchApiCall(`${this.BACKEND_URL}/static/fryslanNode`, options)
  }

  /**
   * Set the user FCM
   * @param {string} authToken
   * @param {string} fcmid
   * @returns {Promise.<T>}
   */
  public setUserFCM(fcmid: string): Promise<string[] | null> {
    if (this.isAnonymousUser()) {
      return new Promise(() => ({}))
    }

    // Prevention of any double setting which might be done by the plugin
    if (fcmid === this.fcmId) {
      console.warn(`[setUserFCM] FCM id has already been set to ${fcmid}`)
      return Promise.resolve(null)
    }

    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({ fcmid })
    }

    this.fcmId = fcmid

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/fcm`, options).then(() => null)
  }

  /**
   * Delete the user FCM
   * @param {string} fcmid
   * @returns {Promise.<T>}
   */
  public deleteUserFCM(): Promise<void> {
    if (!window.cordova || !window.FirebasePlugin || !this.fcmId || this.isAnonymousUser()) {
      return Promise.reject(new Error('No FCM id present'))
    }

    try {
      const options: IFetchOptions = {
        method: 'DELETE',
        headers: {
          ...this.GENERAL_HEADERS,
          Authorization: this.userAuth.token
        },
        body: JSON.stringify({ fcmid: this.fcmId })
      }
      return fetch(`${this.BACKEND_URL}/userProfile/fcm`, options).then(() => {
        this.fcmId = null
      })
    } catch (err) {
      console.error(err)
      return Promise.reject(new Error('Unexpected exception while deleting FCM id inside backend'))
    }
  }

  public setCurrentPosition(data: IShipLocationUpdate) {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(data)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/current`, options)
  }

  public getCurrentShipInformation(): Promise<IShipInfo> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/current`, options)
  }

  public updateCurrentShipDimensions(shipDimensions: IShipDimensions) {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'PATCH',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({ dimensions: shipDimensions })
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/ship/current`, options)
  }

  public updateUserProfile(updatedProfile: IUserAuth) {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(updatedProfile)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/current`, options)
  }

  public getUserProfile(): Promise<IUserProfile> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/userProfile/current`, options)
  }

  public fetchRouteSuggestions(
    fromLocation: IlatLng | null,
    toLocation: IlatLng,
    viaRoutes: IRouteLocation[],
    departureTime: number,
    cruiseSpeed: number
  ): Promise<IRoute[]> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const viaRouteCoordinates: string[] = []

    viaRoutes.forEach(viaRoute => {
      if (viaRoute.coordinates) {
        const stringCoord =
          viaRoute.coordinates.lat.toString() + ',' + viaRoute.coordinates.lng.toString()
        viaRouteCoordinates.push(stringCoord)
      }
    })

    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(viaRouteCoordinates)
    }

    const params = []
    params.push(`cemtFilter=${false}`)
    params.push(`etaToObjects=${true}`)
    params.push(`heightBridges=${true}`)
    params.push(`maximumResults=${5}`)
    params.push(`startTime=${departureTime}`)
    params.push(`language=${this.languageCode}`)
    params.push(`cruiseSpeed=${cruiseSpeed || 4.32}`)

    if (fromLocation) {
      params.push('from=' + fromLocation.lat + ',' + fromLocation.lng)
    }

    params.push('to=' + toLocation.lat + ',' + toLocation.lng)

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/routescout?${params.join('&')}&additionalItems=false`,
      options
    )
  }

  public setUsersActiveRoute(route: IRoute, cruiseSpeed?: number): Promise<ISelectedRoute> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(route)
    }

    const params = []
    params.push(`language=${this.languageCode}`)
    params.push(`cruiseSpeed=${cruiseSpeed || 4.32}`)
    params.push(`recreational=true`)

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute?${params.join('&')}`, options)
  }

  public getBridgeOpeningsList(bridgeIdList?: string[]): Promise<IBridgeOpeningInfo[]> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(bridgeIdList)
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/bridgeCoordinator/frieslandBridgeOpenings/bridgelist?language=${this.languageCode}`,
      options
    )
  }

  public getAllFrisianBridgeOpeningList(): Promise<IBridgeOpeningInfo[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/bridgeCoordinator/frieslandBridgeOpenings?language=${this.languageCode}`,
      options
    )
  }

  public requestBridgeOpening(
    isrsCode: IBridgeOpeningInfo['isrsId']
  ): Promise<BridgeRequestResponse> {
    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token,
        body: JSON.stringify(isrsCode)
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/bridgeCoordinator/frieslandBridgeOpenings/request/${isrsCode}`,
      options
    )
  }

  public getSelectedRoute(): Promise<ISelectedRoute> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/selectedRoute?language=${this.languageCode}`,
      options
    )
  }

  public getSelectedRouteEtaUpdate(): Promise<IRouteEtaUpdate> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/eta`, options)
  }

  public updateSelectedRoute(speedType: SpeedTypes, cruiseSpeed: number): Promise<ISelectedRoute> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'PATCH',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify({
        cruiseSpeed: speedType === 'CUSTOM' ? cruiseSpeed : null
      })
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/selectedRoute?language=${this.languageCode}`,
      options
    )
  }

  public fetchItemDetails(
    type: string,
    itemId: string
  ): Promise<IBridgeDetails | IWaterMeterDetails | ILockDetails | INotificationDetails> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/${type}/${itemId}?language=${this.languageCode}`,
      options
    )
  }

  public getShipNotificationStatus(): Promise<IShipNotificationStatus> {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/notifications?language=${this.languageCode}`,
      options
    )
  }

  private mapboxBoundsToTeqplayBounds(bounds?: MapboxGl.LngLatBounds) {
    if (!bounds) return ''

    const topLeftLat = bounds.getNorthEast().lat
    const topLeftLon = bounds.getSouthWest().lng
    const bottomRightLat = bounds.getSouthWest().lat
    const bottomRightLon = bounds.getNorthEast().lng

    if (!topLeftLat || !topLeftLon || !bottomRightLat || !bottomRightLon) {
      sendMessageToSentry('Invalid bounding box detected', {
        bounds,
        editedBounds: { topLeftLat, topLeftLon, bottomRightLat, bottomRightLon }
      })
      return ''
    }

    return `topLeftLat=${topLeftLat}&topLeftLon=${topLeftLon}&bottomRightLat=${bottomRightLat}&bottomRightLon=${bottomRightLon}`
  }

  public pauseRoute() {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/pause`, options)
  }

  public resumeRoute() {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/resume`, options)
  }

  public stopRoute() {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/selectedRoute/stop`, options)
  }

  public addToStoredRoutes(route: IRoute, customName: string) {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    // const routeName = encodeURIComponent(fromName)
    const options: IFetchOptions = {
      method: 'PUT',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      },
      body: JSON.stringify(route)
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes?key=${customName}`, options)
  }

  public deleteStoredRoute(key: string) {
    if (this.isAnonymousUser()) {
      throw new AnonymousUserError()
    }

    // const routeName = encodeURIComponent(fromName)
    const options: IFetchOptions = {
      method: 'DELETE',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes?key=${key}`, options)
  }

  public getStoredRoutes(): Promise<IStoredRoute[]> {
    if (this.isAnonymousUser()) {
      return new Promise(() => [])
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/route/storedRoutes`, options)
  }

  public submitFeedback(feedback: string, coordinates: string, attachment: Blob | null) {
    const formData = new FormData()
    formData.append('feedback', feedback)
    formData.append('coordinates', coordinates)

    if (this.userAuth.userName && this.isEmail(this.userAuth.userName)) {
      formData.append('email', this.userAuth.userName)
    }

    if (attachment) {
      formData.append('type', attachment.type)
      formData.append('attachment', attachment)
    }

    const options: IFetchOptions = {
      method: 'POST',
      headers: {
        ...GENERAL_HEADERS_FORM_DATA,
        Authorization: this.userAuth.token
      },
      body: formData
    }

    return this.fetchApiCall(`${this.BACKEND_URL}/communication/feedbackFryslan`, options)
  }

  public fetchPhoneLocations(): Promise<IGeoServiceLocation[]> {
    const options = {
      method: 'GET'
    }

    return fetch(
      'https://api.mlab.com/api/1/databases/locationupdates/collections/phonelocations?apiKey=6j20x7E8rCWKAPz7nci4mgFbdcDoaERU&l=2000',
      options
    ).then(this.handleRestResponse)
  }

  private isEmail(email: string): boolean {
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  public fetchFastSailingRoutes(bounds?: MapboxGl.LngLatBounds): Promise<IFastSailingRoute[]> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    const urlBounds = this.mapboxBoundsToTeqplayBounds(bounds)
    return this.fetchApiCall(
      `${this.BACKEND_URL}/static/fastSailingArea?${urlBounds}&language=${this.languageCode}`,
      options
    )
  }

  public getGeocodedLocation(placeName: string, locale?: string): Promise<IGeocodedLocation[]> {
    if (this.isAnonymousUser()) {
      return new Promise(() => null)
    }

    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/route/location/geocode?placeName=${encodeURIComponent(
        placeName
      )}&locale=${locale ? returnLanguageCodeFromLocale(locale) : 'nl'}`,
      options
    )
  }

  public getBridgeWatchdogStatus(): Promise<IWatchDogStatus> {
    const options: IFetchOptions = {
      method: 'GET',
      headers: {
        ...this.GENERAL_HEADERS,
        Authorization: this.userAuth.token
      }
    }

    return this.fetchApiCall(
      `${this.BACKEND_URL}/bridgeCoordinator/frieslandBridgeOpenings/watchdog`,
      options
    )
  }
}

export default TeqplayApiService
