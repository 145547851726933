import MapboxGL from 'mapbox-gl'
import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { detailedShipToGeoJSONPoints } from './utils/detailedShipToGeoJSONPoints'
import { IExtendedShip } from '../../../../@types/types'

interface IProps {
  ships: IExtendedShip[]
  handleLayerClick: (event: MapboxGL.MapMouseEvent) => void
  onMouseEnter: (event: MapboxGL.MapMouseEvent) => void
  onMouseLeave: () => void
  minZoom: number
  maxZoom: number
}

const AISCircleLayer: React.FC<IProps> = ({
  ships,
  handleLayerClick,
  onMouseEnter,
  onMouseLeave,
  minZoom,
  maxZoom
}) => {
  const labelSymbolLayout: MapboxGL.SymbolLayout = {
    'text-anchor': 'top-left',
    'text-justify': 'left',
    'text-offset': [1, 0],
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 0, 12, 13],
    'text-font': ['Metropolis Semi Bold']
  }
  const labelPaint: MapboxGL.SymbolPaint = {
    'icon-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8],
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
    'text-halo-width': 1.4
  }

  const circlePaint: MapboxGL.CirclePaint = {
    'circle-color': ['get', 'rawColor'],
    'circle-pitch-alignment': 'map',
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 3, 13, 4],
    'circle-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8]
  }

  return (
    <>
      <GeoJSONLayer
        data={detailedShipToGeoJSONPoints(ships)}
        circlePaint={circlePaint}
        circleOnClick={handleLayerClick}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
      <GeoJSONLayer
        data={detailedShipToGeoJSONPoints(ships)}
        symbolLayout={labelSymbolLayout}
        symbolPaint={labelPaint}
        symbolOnClick={handleLayerClick}
        symbolOnMouseEnter={onMouseEnter}
        symbolOnMouseLeave={onMouseLeave}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
    </>
  )
}

export default AISCircleLayer
