import * as React from 'react'
import DatePicker from 'react-mobile-datepicker'
import { I18n } from 'react-redux-i18n'

interface IProps {
  toggleTimePicker: () => void
  selectTime: (date: any) => void
}

const TimePicker: React.FunctionComponent<IProps> = props => {
  const dateConfigTime = {
    hour: {
      format: 'hh',
      caption: 'Hour',
      step: 1
    },
    minute: {
      format: 'mm',
      caption: 'Min',
      step: 1
    }
  }

  return (
    <DatePicker
      isOpen={true}
      onSelect={(date: Date) => {
        props.selectTime(date)
      }}
      onCancel={props.toggleTimePicker}
      confirmText={I18n.t('select')}
      cancelText={I18n.t('routeSelection.cancel')}
      dateConfig={dateConfigTime}
      headerFormat={'hh:mm'}
    />
  )
}

export default TimePicker
