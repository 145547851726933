import { LatLng, moveTo } from 'geolocation-utils'
import { getShipWidth } from '../../../../../utils/ship'
import { IShipInfo } from '../../../../../services/TeqplayApiService/TeqplayApi'

export function getMarkerHeading(shipToDetermine: IShipInfo, useCalculatedHeading: boolean) {
  if (useCalculatedHeading && shipToDetermine.calculatedHeading) {
    return shipToDetermine.calculatedHeading
  }
  if (shipToDetermine.trueHeading && shipToDetermine.trueHeading !== 511) {
    return shipToDetermine.trueHeading
  }
  if (shipToDetermine.courseOverGround) {
    return shipToDetermine.courseOverGround
  }
  return 0
}

export function getPolygonPositions(
  shipToDetermine: IShipInfo,
  centerOfShip: { lat: number; lng: number },
  shipOrientation: number
) {
  // Set the ship dimensions, and if there are none fall back to a preset
  const distanceToStern =
    shipToDetermine.positionOfTransponder &&
    shipToDetermine.positionOfTransponder.distanceToStern &&
    shipToDetermine.positionOfTransponder.distanceToStern > 0 &&
    shipToDetermine.positionOfTransponder.distanceToBow &&
    shipToDetermine.positionOfTransponder.distanceToBow > 0
      ? shipToDetermine.positionOfTransponder.distanceToStern
      : shipToDetermine.length && shipToDetermine.length > 0
      ? shipToDetermine.length / 2
      : 10
  const distanceToBow =
    shipToDetermine.positionOfTransponder &&
    shipToDetermine.positionOfTransponder.distanceToStern &&
    shipToDetermine.positionOfTransponder.distanceToStern > 0 &&
    shipToDetermine.positionOfTransponder.distanceToBow &&
    shipToDetermine.positionOfTransponder.distanceToBow > 0
      ? shipToDetermine.positionOfTransponder.distanceToBow
      : shipToDetermine.length && shipToDetermine.length > 0
      ? shipToDetermine.length / 2
      : 10

  const width = shipToDetermine.width || getShipWidth(shipToDetermine)
  const bowLength = ((width < 8 ? 8 : width) / 2) * Math.SQRT2

  const stern = moveTo(centerOfShip, { distance: distanceToStern, heading: shipOrientation - 180 })
  const bow = moveTo(centerOfShip, { distance: distanceToBow, heading: shipOrientation })
  const bowLeft = moveTo(bow, { distance: bowLength, heading: shipOrientation - 90 - 45 })
  const bowRight = moveTo(bow, { distance: bowLength, heading: shipOrientation + 90 + 45 })
  const sternLeft = moveTo(stern, {
    distance: (width < 8 ? 8 : width) / 2,
    heading: shipOrientation - 90
  })
  const sternRight = moveTo(stern, {
    distance: (width < 8 ? 8 : width) / 2,
    heading: shipOrientation + 90
  })

  // Remove weird ghostShips or abnormal dimensions
  if (
    +convertToScheme(bowRight as any)[0].toPrecision(2) !==
      +convertToScheme(bowLeft as any)[0].toPrecision(2) ||
    (shipToDetermine.length && shipToDetermine.length > 500) ||
    (shipToDetermine.dimensions?.width && shipToDetermine.dimensions?.width > 100)
  ) {
    return [[]]
  }

  return [
    [
      convertToScheme(bow as any as LatLng),
      convertToScheme(bowRight as any as LatLng),
      convertToScheme(sternRight as any as LatLng),
      convertToScheme(sternLeft as any as LatLng),
      convertToScheme(bowLeft as any as LatLng)
    ]
  ]
}

function convertToScheme(coordinates: LatLng) {
  return [coordinates.lng, coordinates.lat]
}
