import * as React from 'react'
import { Translate } from 'react-redux-i18n'

import SideMenu from '../../sideMenu/SideMenu'
import './InactiveRouteSelect.scss'

interface IProps {
  setrouteSelectionFormActive: () => void
  userName: string
  activePage: string
  logout: () => void
  isAnonymous: boolean
}

const InactiveRouteSelect: React.FunctionComponent<IProps> = props => {
  return (
    <div className="route-select-wrapper">
      <div className="select-inline-inner-wrapper">
        <SideMenu
          activePage={props.activePage}
          userName={props.userName}
          logout={props.logout}
          isAnonymous={props.isAnonymous}
        />

        <div className="activate-route-select" onClick={props.setrouteSelectionFormActive}>
          <Translate value="routeSelection.selectRoutePlaceholder" />
        </div>
      </div>
    </div>
  )
}

export default InactiveRouteSelect
