import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { ISelectedRoute } from '../../services/TeqplayApiService/TeqplayApi'

interface ISelectedDottedLine {
  navigationRoute: ISelectedRoute
}

export const SelectedDottedLine = ({ navigationRoute }: ISelectedDottedLine) => {
  const fromCoordinates = reverseCordinates(navigationRoute.route.from)
  const toCoordinates = reverseCordinates(navigationRoute.route.to)
  const coordinates = [
    [fromCoordinates, navigationRoute.route.routeItems[0].singleLocation.coordinates],
    [toCoordinates, navigationRoute.route.routeItems.slice(-1)[0].singleLocation.coordinates]
  ]
  return (
    <>
      <GeoJSONLayer
        data={{
          type: 'FeatureCollection',
          features: coordinates.map(coordinate => {
            return {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coordinate
              }
            }
          })
        }}
        lineLayout={{
          'line-join': 'round',
          'line-cap': 'round'
        }}
        linePaint={{
          'line-color': '#a5a5a5',
          'line-width': 3,
          'line-dasharray': [0, 2]
        }}
      />
    </>
  )
  function reverseCordinates(item: string) {
    return item.split(',').map(Number).reverse()
  }
}
