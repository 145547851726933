import { createBrowserHistory } from 'history'
import { syncTranslationWithStore } from 'react-redux-i18n'
import { applyMiddleware, createStore } from 'redux'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { II18nState } from '../@types/types'
import nl from '../translations/nl_NL'
import rootReducer from './reducers'

const persistConfig: PersistConfig = {
  key: 'friesland-app',
  storage,
  whitelist: ['currentUser', 'map']
}

const initialStore = {
  currentUser: undefined,
  i18n: {
    translations: { nl },
    locale: 'nl'
  } as II18nState
}

const history = createBrowserHistory()
const persistedReducer = persistReducer(persistConfig, rootReducer(history))
const store = createStore(persistedReducer, initialStore, applyMiddleware(thunk))

// Translations
syncTranslationWithStore(store)

const exportedStore = () => {
  const persistor = persistStore(store)
  return { store, persistor }
}

export default exportedStore
