import { IUserAuth } from '../../services/TeqplayApiService/TeqplayApi'

export function setAuth(userAuth?: IUserAuth) {
  return {
    type: 'SET_AUTH',
    userAuth
  }
}

export function clearAuth() {
  return {
    type: 'CLEAR_AUTH'
  }
}
