import * as MapboxGl from 'mapbox-gl'
import React, { useState, useEffect } from 'react'
import { Layer, Feature } from 'react-mapbox-gl'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import KilometreWhiteIcon from '../icons/kilometreringsTextIcon.svg'

import { IHectometer } from '../../../@types/types'

import './layerPopup.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  bounds?: MapboxGl.LngLatBounds
}

const KilometreIconLayoutSmall = {
  'icon-image': 'KilometreIcon',
  'text-field': '{speed}',
  'text-size': 14,
  'icon-text-fit': 'width',
  'symbol-placement': 'point',
  'icon-text-fit-padding': [5, 5, 5, 5]
}

const KilometreIconLayoutBig = {
  'icon-image': 'KilometreIcon',
  'text-field': '{speed}',
  'text-size': 10,
  'icon-text-fit': 'both',
  'symbol-placement': 'point',
  'icon-text-fit-padding': [3, 3, 3, 3],
  'icon-allow-overlap': true
}

/* 
Some titles can be really big, therefore 
Having two layouts, this can also be done with Mapbox Case Expression,
however better performance to filter with 2 layers
*/

const KilometreringsLayer = (props: IProps) => {
  const [kilometrering, setKilometrering] = useState<IHectometer[]>([])
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)

  useEffect(() => {
    fetchKilometrering(props.bounds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bounds])

  const image = new Image(40, 25)
  image.onload = () => {
    setLoadedIcon(true)
  }
  image.src = KilometreWhiteIcon
  const Icon = ['KilometreIcon', image]

  if (!loadedIcon) {
    return null
  }

  return (
    <>
      <Layer type="symbol" layout={KilometreIconLayoutSmall} images={Icon}>
        {kilometrering &&
          kilometrering
            .filter(item => item.name.length < 20)
            .map((item: IHectometer) => (
              <Feature
                coordinates={item.loc.coordinates}
                properties={{ speed: item.name }}
                key={item._id}
              />
            ))}
      </Layer>
      <Layer type="symbol" layout={KilometreIconLayoutBig} images={Icon}>
        {kilometrering &&
          kilometrering
            .filter(item => item.name.length > 20)
            .map((item: IHectometer) => (
              <Feature
                coordinates={item.loc.coordinates}
                properties={{ speed: item.name }}
                key={item._id}
              />
            ))}
      </Layer>
    </>
  )

  async function fetchKilometrering(bounds?: MapboxGl.LngLatBounds) {
    try {
      const fetchItem = await props.teqplayApiService.fetchHectometer(bounds)
      setKilometrering(fetchItem)
    } catch (error) {
      console.log(error)
    }
  }
}

export default KilometreringsLayer
