import { insideCircle } from 'geolocation-utils'
import { get } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { IBridgeOpeningInfo } from '../services/TeqplayApiService/TeqplayApi'

export function activeClass(value: boolean, customName: string = 'active'): string {
  return value ? customName : ''
}

export function knotsToKmh(knots: number): number | string {
  if (knots === 0 || knots === null) return ''
  return Math.round((cloneDeep(knots) / 0.539956803) * 10) / 10
}

export function kmhToKnots(kmh: number): number {
  return cloneDeep(kmh) * 0.539956803
}

export function shortenStringToMax(value: string, maxSize: number = 25) {
  if (value.length > maxSize) {
    return value.substring(0, maxSize) + '...'
  } else {
    return value
  }
}

export function isUserInBridgeRadius(
  bridgeOpening: IBridgeOpeningInfo,
  currentUserLocation: { latitude: number; longitude: number } | null,
  bridgeLocation: [number, number]
): boolean {
  return currentUserLocation
    ? insideCircle(
        currentUserLocation,
        { latitude: bridgeLocation[0], longitude: bridgeLocation[1] },
        bridgeOpening.radiusForOperation || 0
      )
    : false
}

export function filterDetailTypes(type: string) {
  if (type !== 'bridge' && type !== 'lock' && type !== 'watermeter' && type !== 'notification')
    return true

  return false
}

export function keysrt(key: string) {
  return (a: any, b: any) => {
    if (a[key] > b[key]) return 1
    if (a[key] < b[key]) return -1
    return 0
  }
}

export function parseStringForID(s: string | any) {
  if (s && s?.toLowerCase) {
    return s.replace(/ /g, '-').toLowerCase()
  } else {
    // TO-DO: Maybe improve by sending error to Sentry upon failing to parse?
    console.error(`${JSON.stringify(s)} not parsable as string to use for ID`)
    return 'error-string-not-parsable'
  }
}

/**
 * Sort an array alphabetically
 */
export function sortByKey<A>(array: A[], key: string, order: 'asc' | 'desc' = 'asc'): A[] {
  return array.sort((a, b) => {
    const smaller = order === 'asc' ? -1 : 1
    const bigger = order === 'asc' ? 1 : -1
    const x = get(a, key)
    const y = get(b, key)

    if (x && y) {
      return x < y ? smaller : x > y ? bigger : 0
    } else if (!x) {
      return 1
    } else {
      return -1
    }
  })
}

/**
 * Get a formatted layer name for use in the state.
 * Adds a prefix and removes spaces
 */
export function formatLayerStateName(layerName: string): string {
  const stateName = layerName

  // remove spaces from the string
  return stateName.replace(/-|\s/g, '')
}
