import React from 'react'
import * as MapboxGl from 'mapbox-gl'
import { I18n } from 'react-redux-i18n'

import DefaultMarkerLayer from './DefaultMarkerLayer'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import InlandHarbourIcon from '../icons/inlandHarbours.svg'

import { INavigationLocation } from '../../../services/TeqplayApiService/TeqplayApi'
import { labelAndValue } from '../../../utils/labelAndValue'

interface IProps {
  bounds?: MapboxGl.LngLatBounds
  teqplayApiService: TeqplayApiService
  cursor: HTMLCanvasElement | undefined
}

const InlandHarboursLayer: React.FunctionComponent<IProps> = props => {
  return (
    <DefaultMarkerLayer<INavigationLocation>
      fetchCall={() => props.teqplayApiService.fetchAllInlandHarbours(props.bounds)}
      bounds={props.bounds}
      category="InlandHarbours"
      cursor={props.cursor}
      iconUrl={InlandHarbourIcon}
      getPopup={inlandHarbour => {
        return (
          <>
            <h3>{inlandHarbour.name}</h3>
            {labelAndValue(I18n.t('mapLayers.inlandHarbours.city'), inlandHarbour.city)}
            {labelAndValue(
              I18n.t('mapLayers.inlandHarbours.shortStayPlaces'),
              inlandHarbour.shortStayPlaces
            )}
            {labelAndValue(
              I18n.t('mapLayers.inlandHarbours.longStayPlaces'),
              inlandHarbour.longStayplaces
            )}
            {labelAndValue(
              I18n.t('mapLayers.inlandHarbours.suppliesFuel'),
              inlandHarbour.suppliesFuel
            )}
          </>
        )
      }}
    />
  )
}
export default InlandHarboursLayer
