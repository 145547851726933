import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import polyline from '@mapbox/polyline'

import { offsetCrossingMeridian } from '../../utils/offsetCrossingMeridan'
import { IRoute } from '../../services/TeqplayApiService/TeqplayApi'

interface IRouteSuggetions {
  routeSuggestions: IRoute
  setSelectedRouteSuggestion: (routeSuggestion: IRoute) => void
  map: HTMLCanvasElement | undefined
}

export const RouteSuggestions = ({
  routeSuggestions,
  setSelectedRouteSuggestion,
  map
}: IRouteSuggetions) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: getSelectedRoute(routeSuggestions)
            }
          }
        ]
      }}
      lineLayout={{
        'line-join': 'round',
        'line-cap': 'round'
      }}
      linePaint={{
        'line-color': '#a5a5a5',
        'line-width': 6
      }}
      lineOnMouseDown={() => setSelectedRouteSuggestion(routeSuggestions)}
      lineOnMouseEnter={() => (map ? (map.style.cursor = 'pointer') : '')}
      lineOnMouseLeave={() => (map ? (map.style.cursor = '') : '')}
    />
  )
}

function getSelectedRoute(item: IRoute) {
  return offsetCrossingMeridian(
    polyline.decode(item.polyLine).map(coordinates => [coordinates[1], coordinates[0]])
  )
}
