import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { IRootProps } from '../../@types/types'
import LoginForm from '../../components/loginForm/LoginForm'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { requestPasswordResetMail, returnLanguageCodeFromLocale } from '../../services/rest'
import { getFirebaseTokenOnce } from '../../utils/fcmUtils'

const LoginContainer = (props: IAuthenticationWrapper) => {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  return (
    <div className="login-container">
      <LoginForm
        locale={locale}
        handleLogin={handleLogin}
        handleAnonymousLogin={handleAnonymousLogin}
        errorMessage={errorMessage}
        handlePasswordForget={submitForgotPasswordRequest}
      />
    </div>
  )

  async function submitForgotPasswordRequest(username: string): Promise<boolean> {
    try {
      if (username && username.includes('@') && username.includes('.')) {
        await requestPasswordResetMail(username, returnLanguageCodeFromLocale(locale))
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)

      return false
    }
  }

  function handleLogin(userName: string, password: string) {
    const strippedUsername = userName.replace(/\s+/g, '')
    props.teqplayApiService
      .loginUser(strippedUsername, password)
      .then(async response => {
        // set error log user information
        Sentry.configureScope(scope => scope.setUser({ userName, createdAt: response.createdAt }))

        setErrorMessage(null)
        history.push('/')

        // setup fcm
        getFirebaseTokenOnce(props.teqplayApiService)
      })
      .catch(err => {
        console.error(err)

        if (err.toString().includes('ACCOUNT_TYPE_ERROR')) {
          setErrorMessage(I18n.t('login.wrongAccountType'))
        }
        // Set error message
        let message = err.message
        if (err.statusCode === 1401 || err.status === 401 || err.status === 400) {
          // login failed
          message = I18n.t('login.wrongCredentials')
          setErrorMessage(message)
        }
      })
  }

  function handleAnonymousLogin() {
    props.teqplayApiService
      .loginAnonymously()
      .then(async response => {
        // set error log user information
        Sentry.configureScope(scope =>
          scope.setUser({ userName: 'ANONYMOUS', createdAt: response.createdAt })
        )

        setErrorMessage(null)
        history.push('/')
      })
      .catch(err => {
        console.error(err)

        // Set error message
        let message = err.message
        if (err.statusCode === 1401 || err.status === 401 || err.status === 400) {
          // login failed
          message = I18n.t('login.wrongCredentials')
          setErrorMessage(message)
        }
      })
  }
}

export default LoginContainer
