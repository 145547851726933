import { IUserLocation } from '../@types/types'
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage'
import { ILocationAction } from './actions'

// Set inital login state
export const initialUserLocationState: IUserLocation = {
  currentLocation: null,
  history: loadFromLocalStorage('user-location-history', [])
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function locationReducer(state = initialUserLocationState, action: ILocationAction) {
  switch (action.type) {
    case 'SET_CURRENT_LOCATION': {
      return Object.assign({}, state, { currentLocation: action.currentLocation })
    }

    case 'SET_LOCATION_HISTORY': {
      saveToLocalStorage('user-location-history', action.history)
      return Object.assign({}, state, { history: action.history })
    }

    default:
      return state
  }
}
