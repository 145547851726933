import * as React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import LocalePicker from '../localePicker/LocalePicker'

import DefaultTransition from '../shared/defaultTransition/DefaultTransition'
import './LoginForm.scss'

interface IProps {
  locale: string
  handleLogin: (userName: string, password: string) => void
  handleAnonymousLogin: () => void
  handlePasswordForget: (username: string) => Promise<boolean>
  errorMessage: string | null
}

const LoginForm = (props: IProps) => {
  const { handleLogin, handlePasswordForget, errorMessage } = props
  const [userName, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [disclaimerActive, setDisclaimerActive] = React.useState<boolean>(true)

  const [passFormActive, setPassFormActive] = React.useState<boolean>(false)
  const [forgotPasswordUsername, setForgotPasswordUsername] = React.useState<string>('')
  const [passwordResult, setPasswordResult] = React.useState<undefined | boolean>()

  const showTempNewApp = new Date().valueOf() <= 1619827200000

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    handleLogin(userName, password)
  }

  async function handleSubmitForgetPassword() {
    const resetForgetPassword = await handlePasswordForget(forgotPasswordUsername)
    setPasswordResult(resetForgetPassword)
    if (resetForgetPassword) {
      setForgotPasswordUsername('')
    }
  }

  return (
    <DefaultTransition active={true} slideFrom="left" offset={100}>
      {styleProps => (
        <div className="login-form-wrapper" style={styleProps}>
          <div className="login-form-inner-container">
            {passFormActive ? (
              <div className="disclaimer-wrapper">
                <h2>{I18n.t('login.forgotpassemail')}</h2>
                {passwordResult === false ? (
                  <div className="error">{I18n.t('generalError')}</div>
                ) : passwordResult === true ? (
                  <div className="success">{I18n.t('login.forgotpassconfim')}</div>
                ) : null}
                {!passwordResult ? (
                  <div className="input-wrapper">
                    <i className="icon-mail-alt" />
                    <input
                      className="textfield"
                      placeholder={I18n.t('login.email')}
                      autoCapitalize="off"
                      type="text"
                      value={forgotPasswordUsername}
                      onChange={e => {
                        setPasswordResult(undefined)
                        setForgotPasswordUsername(e.target.value)
                      }}
                    />
                    <button
                      className="button login-button large"
                      onClick={() => handleSubmitForgetPassword()}
                    >
                      {I18n.t('login.send')}
                    </button>
                  </div>
                ) : null}
                <div className="create-account-link" onClick={() => setPassFormActive(false)}>
                  <div className="forgot-pass">{I18n.t('register.backToLogin')}</div>
                </div>
              </div>
            ) : disclaimerActive ? (
              <div className="disclaimer-wrapper">
                <h2>{I18n.t('disclaimer.title')}</h2>
                <p>{I18n.t('disclaimer.body')}</p>
                <button
                  className="button login-button large"
                  onClick={() => setDisclaimerActive(false)}
                >
                  {I18n.t('affirm')}
                </button>
              </div>
            ) : (
              <>
                <div className="login-header">
                  <h2>{I18n.t('appTitle')}</h2>
                  <p className="welcome-message">{I18n.t('login.welcome')}</p>
                </div>

                <form onSubmit={submitForm}>
                  {errorMessage && <span className="error-message">{errorMessage}</span>}

                  {showTempNewApp && (
                    <div className="new-app-message">
                      <i className="icon-info" />
                      <span>{I18n.t('login.newAppMessage')}</span>
                    </div>
                  )}

                  <LocalePicker locale={props.locale} />

                  <div className="input-wrapper">
                    <i className="icon-mail-alt" />
                    <input
                      className="textfield"
                      placeholder={I18n.t('login.email')}
                      autoCapitalize="off"
                      type="email"
                      value={userName}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>

                  <div className="input-wrapper">
                    <i className="icon-lock-1" />
                    <input
                      className="textfield"
                      type="password"
                      autoCapitalize="off"
                      placeholder={I18n.t('login.password')}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>

                  <input
                    className="button login-button large"
                    type="submit"
                    value={I18n.t('login.submit')}
                  />

                  <button
                    className="button large alt"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()

                      props.handleAnonymousLogin()
                    }}
                  >
                    {I18n.t('login.anonymously')}
                  </button>
                </form>

                <div className="create-account-link">
                  <Translate value="login.noUser" />
                  <Link to="/register">{I18n.t('login.registerLink')}</Link>
                </div>

                <div className="create-account-link">
                  <div className="forgot-pass" onClick={() => setPassFormActive(true)}>
                    {I18n.t('login.forgotPassword')}
                  </div>
                  <Link to="/policy">{I18n.t('login.policy')}</Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </DefaultTransition>
  )
}

export default LoginForm
