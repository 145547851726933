import * as MapboxGl from 'mapbox-gl'
import React, { useState, useEffect } from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { IPlatformLocation } from '../../../services/TeqplayApiService/TeqplayApi'

interface ISelectedRoute<T> {
  bounds: MapboxGl.LngLatBounds | undefined
  fetchCall: (bounds?: any) => Promise<T[]>
  category: string
  color: string
}

interface ICustomPolyline {
  locations: IPlatformLocation[]
  _id: string
}

const DefaultPolylineLayer = <T extends ICustomPolyline>({
  category,
  color,
  fetchCall,
  bounds
}: ISelectedRoute<T>) => {
  const [defaultItems, setDefaultItems] = useState<T[]>([])

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: defaultItems.map(item => {
          const allLines: any = []
          item.locations.forEach(line => allLines.push(line.coordinates))
          return {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: allLines
            }
          }
        })
      }}
      linePaint={{
        'line-color': color,
        'line-width': 5
      }}
      source={category}
    />
  )
  async function fetchItems() {
    try {
      const fetchItem = await fetchCall(bounds)
      setDefaultItems(fetchItem)
    } catch (error) {
      console.error(error)
    }
  }
}

export default DefaultPolylineLayer
