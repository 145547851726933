import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import RegisterForm from '../../components/registerForm/RegisterForm'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import { ILoggedInUser, INewUser } from '../../services/TeqplayApiService/TeqplayApi'
import { getFirebaseTokenOnce } from '../../utils/fcmUtils'
import { sendExceptionToSentry } from '../../utils/sentry'
import { setAuth } from '../loginPage/actions'

const RegisterPage = (props: IAuthenticationWrapper) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [success, setSuccess] = React.useState<string | null>(null)

  if (success) {
    return (
      <div className="register-form login-container">
        <div className="login-form-inner">
          <div className="login-form-wrapper">
            <div className="login-form-inner-container">
              <div className="login-header">
                <h2>{I18n.t('register.done')}</h2>
              </div>
              <div className="login-form-inner alt">
                <span>{success}</span>

                <button className="button login" onClick={() => history.push('/login')}>
                  {I18n.t('register.backToLogin')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <RegisterForm
      handleRegister={handleRegister}
      errorMessage={errorMessage}
      resetErrorMessage={() => setErrorMessage(null)}
    />
  )

  async function handleRegister(newUser: INewUser) {
    try {
      const registeredUser = await props.teqplayApiService.registerUser(newUser)
      // set error log user information
      Sentry.configureScope(scope => {
        scope.setUser({
          userName: registeredUser.userName,
          createdAt: new Date().valueOf()
        })
      })

      dispatchLogin(registeredUser)
      setErrorMessage(null)

      // setup fcm
      getFirebaseTokenOnce(props.teqplayApiService)
    } catch (err) {
      let errorMessageType = err?.message

      if (errorMessageType.includes('User has now access to Varen Friesland')) {
        setSuccess(I18n.t('register.gainedAccessToVarenFriesland'))
        return
      }

      if (errorMessageType.includes('The provided email address is already')) {
        errorMessageType = I18n.t('register.alreadyExists')
      }

      if (errorMessageType.includes('The provided email address is invalid')) {
        errorMessageType = I18n.t('register.emailInvalid')
      }

      if (
        errorMessageType.includes('Failed to fetch') ||
        errorMessageType.includes('The request timed out') ||
        errorMessageType.includes('The network connection was lost') ||
        errorMessageType.includes('A server with the specified hostname could not be found.')
      ) {
        errorMessageType = I18n.t('register.failedToFetch')
      }

      if (errorMessageType === err.message) {
        // Uncaught exception, display generic error that something went wrong
        errorMessageType = I18n.t('register.serverError')
        sendExceptionToSentry(err)
      }

      toast.error(errorMessageType)
      setErrorMessage(errorMessageType)
    }
  }

  async function dispatchLogin(registeredUser: ILoggedInUser) {
    if (registeredUser.token === undefined) {
      return
    }

    await dispatch(
      setAuth({
        userName: registeredUser.userName,
        token: registeredUser.token,
        refreshToken: registeredUser.refreshToken,
        timestamp: Date.now(),
        teqplayId: registeredUser.teqplayId
      })
    )

    history.push('/')
  }
}

export default RegisterPage
