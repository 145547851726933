/**
 * Helper function to load and deserialize a key/value in the browsers local storage.
 * The function will return undefined when deserializing a stored key failed
 * @param {string} key
 * @param {*} [defaultValue=undefined] Optional default value to be returned
 *                                     When key was not found in local storage
 * @return {*} value
 */
export function loadFromLocalStorage<T = any>(key: string, defaultValue?: T) {
  try {
    const value = localStorage[key]

    return value !== undefined ? JSON.parse(value) : defaultValue
  } catch (err) {
    console.error('error in function loadProperty  ' + err.message)
    return defaultValue
  }
}

/**
 * Helper function to serialize and store a key/value in the browsers local storage
 * @param {string} key
 * @param {*} value
 */
export function saveToLocalStorage<T = any>(key: string, value: T) {
  try {
    localStorage[key] = JSON.stringify(value)
  } catch (err) {
    console.error('error in function saveProperty  ' + err.message)
  }
}

/**
 * Remove a key from local storage (if it exists)
 * @param {string} key
 */
export function removeFromLocalStorage(key: string) {
  try {
    delete localStorage[key]
  } catch (err) {
    console.error('error in function removeProperty  ' + err.message)
  }
}

/**
 * Helper function to load and deserialize a key/value in the browsers local storage.
 * The function will return undefined when deserializing a stored key failed
 * @param {string} key
 * @param {*} [defaultValue=undefined] Optional default value to be returned
 *                                     When key was not found in local storage
 * @return {*} value
 */
export function loadFromSessionStorage<T = any>(key: string, defaultValue?: T) {
  try {
    const value = sessionStorage[key]

    return value !== undefined ? JSON.parse(value) : defaultValue
  } catch (err) {
    console.error('error in function loadFromSessionStorage  ' + err.message)
    return defaultValue
  }
}

/**
 * Helper function to serialize and store a key/value in the browsers local storage
 * @param {string} key
 * @param {*} value
 */
export function saveToSessionStorage<T = any>(key: string, value: T) {
  try {
    sessionStorage[key] = JSON.stringify(value)
  } catch (err) {
    console.error('error in function saveToSessionStorage  ' + err.message)
  }
}

/**
 * Remove a key from local storage (if it exists)
 * @param {string} key
 */
export function removeFromSessionStorage(key: string) {
  try {
    delete sessionStorage[key]
  } catch (err) {
    console.error('error in function removeFromSessionStorage  ' + err.message)
  }
}
