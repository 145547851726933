import * as React from 'react'
import { Spring, SpringRendererFunc, Transition } from 'react-spring'

interface IProps {
  children: SpringRendererFunc
  active: boolean
  slideFrom?: 'bottom' | 'top' | 'right' | 'left'
  fadeIn?: boolean
  offset?: number
  forceRenderOnHide?: boolean
}

const DefaultTransition = ({
  children,
  active,
  slideFrom,
  fadeIn = true,
  offset = 100,
  forceRenderOnHide = false
}: IProps) => {
  let xOffset = slideFrom === 'left' || slideFrom === 'right' ? offset : 0
  if (slideFrom === 'left') xOffset *= -1

  let yOffset = slideFrom === 'top' || slideFrom === 'bottom' ? offset : 0
  if (slideFrom === 'top') yOffset *= -1

  const transformOrigin = `translate3d(${xOffset}px,${yOffset}px,0)`

  if (forceRenderOnHide) {
    return (
      <Spring
        to={{
          transform: active ? 'translate3d(0,0,0)' : transformOrigin,
          opacity: active ? 1 : fadeIn ? 0 : 1,
          pointerEvents: active ? 'initial' : 'none'
        }}
      >
        {children}
      </Spring>
    )
  }

  return (
    <Transition
      items={active}
      from={{
        transform: transformOrigin,
        opacity: fadeIn ? 0 : 1
      }}
      enter={{
        transform: 'translate3d(0,0,0)',
        opacity: 1
      }}
      leave={{
        transform: transformOrigin,
        opacity: fadeIn ? 0 : 1
      }}
    >
      {show => show && children}
    </Transition>
  )
}

export default DefaultTransition
