import React, { useState, useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import * as MapboxGl from 'mapbox-gl'
import { GeoJSONLayer, Popup, Layer, Feature } from 'react-mapbox-gl'

import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import RouteItemIcon from '../icons/single_berth.svg'

import { IBerth } from '../../../services/TeqplayApiService/TeqplayApi'
import { findCenterPolygon } from '../../../utils/findCenterPolygon'
import { labelAndValue } from '../../../utils/labelAndValue'

import './layerPopup.scss'

interface ISelectedRoute {
  bounds?: MapboxGl.LngLatBounds
  teqplayApiService: TeqplayApiService
  layerProperties: any
  cursor: HTMLCanvasElement | undefined
}

interface IBerthPopup {
  berth: IBerth
  coordinates: [number, number] | number[]
  setSingleBerth(berth: IBerth | null): void
  setShowPopup(popup: boolean): void
}

const defaultLayout = {
  'icon-image': 'singleBerth',
  'icon-allow-overlap': true,
  'icon-ignore-placement': true
}

export const BerthLayer = ({
  teqplayApiService,
  bounds,
  cursor,
  layerProperties
}: ISelectedRoute) => {
  const [berths, setBerths] = useState<IBerth[]>([])
  const [singleBerth, setSingleBerth] = useState<IBerth | null>(null)
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const image = new Image(30, 30)
  image.onload = () => {
    setLoadedIcon(true)
  }
  image.src = RouteItemIcon
  const specificIcon = ['singleBerth', image]

  useEffect(() => {
    if (bounds) {
      fetchBerths(bounds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  useEffect(() => {
    if (layerProperties || singleBerth) {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerProperties])

  if (!berths) {
    return null
  }

  return (
    <>
      <GeoJSONLayer
        data={{
          type: 'FeatureCollection',
          features: berths
            .filter(item => item.area?.coordinates)
            .map((berth: IBerth) => {
              return {
                type: 'Feature',
                properties: berth,
                geometry: {
                  type: 'Polygon',
                  coordinates: [berth.area.coordinates[0]]
                }
              }
            })
        }}
        fillPaint={{
          'fill-color': '#197ed4',
          'fill-opacity': 0.5
        }}
        source={'public_berths'}
        id={'public_berths'}
        fillOnMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
        fillOnMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
      />
      <GeoJSONLayer
        data={{
          type: 'FeatureCollection',
          features: berths
            .filter(item => item.area)
            .map((berth: IBerth) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: berth.area.coordinates[0]
                }
              }
            })
        }}
        linePaint={{
          'line-color': '#197ed4',
          'line-width': 3
        }}
      />

      {loadedIcon && (
        <Layer
          type="symbol"
          id={'public_berth1'}
          source={'public_berth1'}
          layout={defaultLayout}
          images={specificIcon}
          onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
          onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
        >
          {berths
            .filter(item => !item.area)
            .map(berth => (
              <Feature
                coordinates={berth.singleLocation.coordinates}
                key={berth._id}
                onClick={() => setSingleBerth(berth)}
              />
            ))}
        </Layer>
      )}

      {showPopup && (
        <>
          {singleBerth && (
            <BerthPopup
              berth={singleBerth}
              coordinates={singleBerth.singleLocation.coordinates}
              setSingleBerth={setSingleBerth}
              setShowPopup={setShowPopup}
            />
          )}
          {layerProperties && layerProperties?.area?.coordinates && (
            <BerthPopup
              berth={layerProperties}
              coordinates={findCenterPolygon(layerProperties.area.coordinates[0])}
              setSingleBerth={setSingleBerth}
              setShowPopup={setShowPopup}
            />
          )}
        </>
      )}
    </>
  )

  async function fetchBerths(mapBounds: MapboxGl.LngLatBounds) {
    try {
      const fetchedBerths = await teqplayApiService.fetchBerths(mapBounds)
      setBerths(fetchedBerths)
    } catch (error) {
      console.log(error)
    }
  }
}

function BerthPopup({ coordinates, berth, setSingleBerth, setShowPopup }: IBerthPopup) {
  return (
    <Popup coordinates={coordinates} onClick={() => setSingleBerth(null)}>
      <div className="popup">
        <span className="close icon-cancel-circled" onClick={() => setShowPopup(false)} />
        <h3>{berth.berthName}</h3>
        {(berth.length > 0 || berth.width > 0 || berth.draught > 0) && (
          <>
            {labelAndValue(I18n.t('mapLayers.berthLayer.length'), berth.length)}
            {labelAndValue(I18n.t('mapLayers.berthLayer.width'), berth.width)}
            {labelAndValue(I18n.t('mapLayers.berthLayer.draught'), berth.draught)}
          </>
        )}
        {labelAndValue(I18n.t('mapLayers.berthLayer.berthName'), berth.berthName)}
        {labelAndValue(I18n.t('mapLayers.berthLayer.owner'), berth.owner)}
        {labelAndValue(
          I18n.t('mapLayers.berthLayer.dangerousGoodsLevel'),
          berth.dangerousGoodsLevel
        )}
        {labelAndValue(I18n.t('mapLayers.berthLayer.mooringOptions'), berth.mooringOptions)}
        {labelAndValue(I18n.t('mapLayers.berthLayer.mooringServices'), berth.mooringServices)}
        {labelAndValue(I18n.t('mapLayers.berthLayer.mooringFunctions'), berth.mooringFunctions)}
        {labelAndValue(
          I18n.t('mapLayers.berthLayer.mooringHourTimeLimit'),
          berth.mooringHourTimeLimit
        )}
        {labelAndValue(I18n.t('mapLayers.berthLayer.camping'), berth.camping)}
        {labelAndValue(I18n.t('mapLayers.berthLayer.shoreConnection'), berth.shoreConnection)}
        {labelAndValue(I18n.t('mapLayers.berthLayer.sort'), berth.sort)}
      </div>
    </Popup>
  )
}

export default BerthLayer
