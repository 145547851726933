import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import BridgeDrawing from './bridgeDrawing/BridgeDrawing'

import {
  IBridgeDetails,
  IBridgeOpeningInfo,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { formatDateTimeShort } from '../../utils/dates'
import { keysrt, parseStringForID } from '../../utils/general'
import { determineOperatingRulesForDaySet } from '../../utils/openingHours'
import { IFullPosition, ILocationPermissions } from '../geoLocationWatcher/GeoLocationWatcherTypes'

import BridgeOpeningRequests from '../bridgeOpeningRequests/BridgeOpeningRequests'
import './BridgeDetails.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  itemDetails: IBridgeDetails | null
  isExpanded: boolean
  locale: string
  bridgeOpeningInfo?: IBridgeOpeningInfo
  currentLocation?: IFullPosition | null
  watchdogStatus?: IWatchDogStatus
  isInsidePopup?: boolean
  isInsideRouteItem?: boolean
  locationPermissions: ILocationPermissions
  departureTime?: number | null
}

const BridgeDetails = ({
  itemDetails,
  isExpanded,
  locale,
  teqplayApiService,
  bridgeOpeningInfo,
  currentLocation,
  watchdogStatus,
  isInsidePopup,
  isInsideRouteItem,
  locationPermissions,
  departureTime
}: IProps) => {
  if (!itemDetails || !itemDetails.name || isExpanded === false) return null

  const { dateBasedRules, activeOperatingPeriods } = determineOperatingRulesForDaySet(
    itemDetails.operatingTimes,
    locale,
    departureTime ? new Date(departureTime) : undefined
  )
  const notes: string[] = [itemDetails.note, itemDetails?.operatingTimes?.note]
    .concat(activeOperatingPeriods.map(x => x.note))
    .filter(x => x)
    .filter((v, i, s) => s.indexOf(v) === i) as string[]

  return (
    <React.Fragment>
      {watchdogStatus && (
        <BridgeOpeningRequests
          bridgeRouteItem={itemDetails}
          bridgeOpeningInfo={bridgeOpeningInfo}
          teqplayApiService={teqplayApiService}
          currentLocation={currentLocation || null}
          watchdogStatus={watchdogStatus}
          isInsidePopup={isInsidePopup}
          isInsideRouteItem={isInsideRouteItem}
          locationPermissions={locationPermissions}
        />
      )}
      <div className="item-info-details">
        <section className="details-section">
          {itemDetails.name && (
            <b>
              <span>
                {itemDetails.name}
                {itemDetails.city &&
                  itemDetails.name !== itemDetails.city &&
                  `, ${itemDetails.city}`}
              </span>
            </b>
          )}
          {itemDetails.fairwayName && (
            <div>
              <b>
                <span> {itemDetails.fairwayName}</span>
              </b>
            </div>
          )}
        </section>

        {(itemDetails.vhfChannel || itemDetails.phoneNumber) && (
          <section className="details-section">
            {itemDetails.vhfChannel ? (
              <div className="route-item-details-item">
                <b>{I18n.t('routeList.itemDetails.vhf')}:</b> {itemDetails.vhfChannel}
              </div>
            ) : null}
            {itemDetails.phoneNumber ? (
              <div className="route-item-details-item">
                <b>{I18n.t('routeList.itemDetails.phoneNumber')}: </b>
                {itemDetails.phoneNumber}
                <a href={'tel:' + itemDetails.phoneNumber}>
                  <i className="icon-phone" />
                </a>
              </div>
            ) : null}
          </section>
        )}

        {(itemDetails.canOpen || itemDetails.waterLevel || itemDetails.bridgeOpening) && (
          <section className="details-section">
            {itemDetails.canOpen !== undefined ? (
              <div>
                <b>{I18n.t('routeList.itemDetails.operable')}</b>:{' '}
                {itemDetails.canOpen
                  ? I18n.t('routeList.itemDetails.yes')
                  : I18n.t('routeList.itemDetails.no')}
              </div>
            ) : null}

            {dateBasedRules.length > 0 && (
              <div className="opening-item" id="bridge-details-opening-hours">
                <div className="route-item-caption">
                  <b>{I18n.t('routeList.itemDetails.control_times')}:</b>
                </div>
                {dateBasedRules.map((time, key) => (
                  <div
                    key={key}
                    className="opening-time-wrapper"
                    id={`bridge-details-opening-hour-${parseStringForID(
                      time.validDates[0].date.toDateString()
                    )}`}
                  >
                    <div className="opening-time">
                      <div className="day-row">
                        <div className="dates">
                          {time.validDates.map((d, i) => (
                            <div className="date" key={i}>
                              {i !== 0 ? '& ' : ''}
                              {d.textFormatted} {d.isHoliday && `(${d.isHoliday.name})`}
                            </div>
                          ))}
                        </div>
                        <div className="rules">
                          {time.validRulesForToday.length === 0 && (
                            <div
                              className="rule"
                              id={`bridge-details-opening-hour-${parseStringForID(
                                time.validDates[0].date.toDateString()
                              )}-no-service`}
                            >
                              {I18n.t('routeList.itemDetails.bridge_operating_rules.noService')}
                            </div>
                          )}
                          {time.validRulesForToday.map((rule, ruleKey) => (
                            <div
                              className="rule"
                              key={ruleKey}
                              id={`bridge-details-opening-hour-${parseStringForID(
                                time.validDates[0].date.toDateString()
                              )}-${ruleKey}`}
                            >
                              <div className="time">
                                {rule.timespan === '00:00 - 23:59'
                                  ? I18n.t('routeList.itemDetails.wholeDay')
                                  : rule.timespan}
                              </div>
                              <div className="note">
                                {rule.notes?.map((subNote, i) => (
                                  <div className="subNote" key={i}>
                                    {subNote}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {itemDetails.waterLevel && (
              <div>
                <b>{I18n.t('routeList.itemDetails.waterLevel')}</b>:{' '}
                {Math.round(itemDetails.waterLevel.heightInCm) / 100} m [
                {I18n.t('routeList.itemDetails.NAP')}] (
                {formatDateTimeShort(itemDetails.waterLevel.timestamp, locale)})
              </div>
            )}

            {notes.map((note, noteIndex) => (
              <div key={noteIndex} id={`bridge-details-note-${noteIndex}`}>
                <b>
                  {I18n.t('routeList.itemDetails.note')}
                  {notes.length > 1 ? ' ' + (noteIndex + 1) : ''}
                </b>
                : {note}
              </div>
            ))}

            {itemDetails.bridgeOpening.sort(keysrt('number')).map((opening, key) => (
              <div key={key}>
                <div className="route-item-caption">
                  <b>
                    {I18n.t('routeList.itemDetails.opening')} {opening.number}: {opening.name}
                  </b>
                </div>
                <div className="route-item-details-item">
                  <b>{I18n.t('routeList.itemDetails.type')}:</b>{' '}
                  {I18n.t('routeList.itemDetails.bridge_type.' + opening.type)}
                </div>
                {opening.width && (
                  <div className="route-item-details-item">
                    <b>{I18n.t('routeList.itemDetails.opening_width')}:</b> {opening.width} m
                  </div>
                )}
                {opening.heightClosed && (
                  <div className="route-item-details-item">
                    <b>{I18n.t('routeList.itemDetails.closed_height')}:</b> {opening.heightClosed}{' '}
                    m, [{itemDetails.referencelevel}]
                  </div>
                )}
                {opening.heightOpened && (
                  <div className="route-item-details-item">
                    <b>{I18n.t('routeList.itemDetails.opened_height')}:</b> {opening.heightOpened} m
                  </div>
                )}
                {opening.note && (
                  <div className="route-item-details-item">
                    <b>{I18n.t('routeList.itemDetails.bridgeOpeningNote')}:</b> {opening.note}
                  </div>
                )}
              </div>
            ))}
          </section>
        )}

        <BridgeDrawing itemDetails={itemDetails} />
      </div>
    </React.Fragment>
  )
}

export default BridgeDetails
