import * as React from 'react'
import Modal from 'react-modal'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { customModalStyles } from '../../../utils/constants'

interface IProps {
  isOpen: boolean
  savedRouteName: string
  closeModal: () => void
  onNameChange: (name: string) => void
  saveRoute: () => void
}

const SaveRouteModal: React.FunctionComponent<IProps> = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={customModalStyles}
      className="modal save-route-modal"
    >
      <form onSubmit={onSubmit}>
        <h3>{I18n.t('routeSelection.saveRouteTitle')}</h3>

        <div className="input-wrapper">
          <label className="label" htmlFor={'routeNameInput'}>
            {I18n.t('routeSelection.routeNameInput')}:
          </label>
          <input
            id="routeNameInput"
            name="routeNameInput"
            className="input"
            value={props.savedRouteName}
            onChange={onChangeName}
          />
        </div>

        <button className="button primary" type="submit">
          {I18n.t('routeSelection.saveRoute')}
        </button>
      </form>
    </Modal>
  )

  function onChangeName(e: React.ChangeEvent<any>) {
    props.onNameChange(e.target.value)
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (props.savedRouteName) {
      props.saveRoute()
    } else {
      toast.error(I18n.t('routeSelection.noNameFilled'))
    }
  }
}

export default SaveRouteModal
