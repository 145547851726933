const frlLocale = {
  appTitle: 'Watersport',
  version: 'ferzje %{version}',
  save: 'Opslaan',
  cancel: 'Ofbrekke',
  affirm: 'Begrepen',
  select: 'selektearje',
  generalError:
    'Spitigernôch gie der wat mis, besykje it letter nochris of nim kontakt op mei support@teqplay.nl',
  now: 'No',
  yes: 'ja',
  no: 'nee',
  ok: 'OK',
  latitude: 'Breedtegraad',
  longitude: 'Lingtegraad',

  units: {
    kmh: 'km/oere',
    knots: 'knopen',
    kilowatt: 'kW',
    amps: 'A'
  },

  locale: {
    selectLocale: 'Taal',
    nl_NL: 'Nederlânsk',
    en_GB: 'Ingelsk',
    de_DE: 'Dútsk',
    frl_NL: 'Frysk',

    nl_NL_native: 'Nederlands',
    en_GB_native: 'English',
    de_DE_native: 'Deutsch',
    frl_NL_native: 'Frysk'
  },

  login: {
    anonymously: 'Trochgean sûnder in akkount',
    welcome: 'Wolkom, log yn om fierder te gean.',
    email: 'E-mail',
    password: 'Wachtwurd',
    submit: 'Oanmelde',
    noUser: 'Noch gjin brûker:',
    policy: 'Privacy policy',
    registerLink: 'Registrearje',
    wrongCredentials: 'De brûkersnamme of it wachtwurd is ferkeard',
    wrongAccountType: 'Jo akkounttype is net geskikt foar de Watersport-app',
    forgotPassword: 'Wachtwurd ferjitten?',
    forgotpassemail: 'Fier jo e-mail yn om jo wachtwurd opnij yn te stellen',
    forgotpassconfim:
      'Jo fersyk is ûntfongen, wy sille it sa gau mooglik ferwurkje en kontakt mei jo opnimme fia e-mail.',
    send: 'Ferstjoere',
    newAppMessage:
      'Watersport is fernijd! Akkounts makke foar oktober 2020 kinne mooglik net brûkt wurde om oan te melden. Meitsje in nij akkount.'
  },
  // Possibilty to add translations
  policy: {
    permission:
      'When you create an account for this Watersports app, you are granting permission for the processing of your data. We want you to understand what data we collect and how we use it.',
    storage:
      'When you sign up for a Watersports account, we store the account information you provide. This includes your vessel name, name, email address, and password. We also retain information about your location and your device data.',
    dataCollection:
      'When you use the Watersports app, we collect data to provide and improve our services. Your data is accessible to the Province of Friesland, other waterway authorities, and the app administrator, Teqplay. The purpose of using your data is to allow you, as a waterway user, to make the best possible use of the Frisian waterways. Your data is used to deliver our service and manage your user account.',
    privacyManagement:
      'You can manage your privacy in the Watersports app. You can access your data and delete your account at any time. After the termination of your account, personal data is immediately deleted. However, we retain location data for our statistics. Personal data is retained as long as you have an account.',
    dataProtection:
      "We protect your data and our applications against unauthorized use. That's why we encrypt many of our services with SSL and evaluate our approach to data collection, storage, and processing. Access to personal data is limited to employees and waterway authorities who need this data to provide our service.",
    lastUpdated:
      "Last updated: 26th October 2023 Teqplay ('us', 'we', or 'our') operates Watersport (the 'platform'). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the platform. We use your Personal Information only for providing and improving the platform. By using the platform, you agree to the collection and use of information in accordance with this policy.",
    personal:
      'Personal information: We collect some personal information that makes us able to separate one user from the other. This information is used to create your account in our systems and may include, but is not limited to your name, E-Mail address and mobile phone number',
    device:
      'Device information: We collect a specific set of device information. This information includes information about the hardware model, the version of the running operating system, unique device identifiers and the status of the wireless network.',
    account:
      'Account information: Communication is a core functionality of the platform. By sending you notifications we can let you know about important events that take place. In order to do this we need to know the Google account identifier that belongs to the Google account you use on your phone.',
    thirdParties: 'Third parties:',
    ageRestriction:
      'Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not knowingly collect personal information from anyone under 13.',
    cookies:
      "Like many websites and platforms, we use 'cookies' to collect information. We use these cookies to prevent you from having to log in each time you use the platform, for example.",
    security:
      'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
    changesToPrivacyPolicy:
      'we reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.',
    contactUs:
      'If you have any questions about this Privacy Policy, please contact us. For our contact details:',
    title: {
      children: 'Children',
      cookies: 'Cookies',
      security: 'Security',
      contactUs: 'Contact us',
      changes: 'Changes to this Privacy Policy',
      watersport: 'Watersport Privacy Policy'
    },
    back: 'Back'
  },

  anonymous: {
    notAllowed:
      'Jo moatte oanmeld wêze om dizze funksje te brûken. Klik op "Meitsje in akkount" om  fergees in akkount te meitsjen',
    createAccount: 'Meitsje in akkount oan',
    cancel: 'Letter'
  },

  shipTypes: {
    select: 'Selektearje skipstype',
    MOTOR_YACHT: 'Motorjacht',
    SPEEDBOAT: 'Speedboot',
    SAILING: 'Sylskip',
    SAILING_WITH_MOTOR: 'Sylskip mei motor',
    SPORT_FISHERMEN_VESSEL: 'Boat foar fiskleafhawwers',
    SAILING_MOTOR_BIG: 'Syl- of motorskip grutter as 20m',
    OTHER_RECREATIONAL_VESSEL: 'Oar rekreaasjeskip'
  },

  register: {
    createAccount: 'In akkount registrearje',
    submit: 'Registrearje',
    done: 'Slagge!',
    backToLogin: 'Werom nei oanmelden',
    emailAddress: 'E-mail',
    password: 'Wachtwurd',
    repeatPassword: 'Werhelje wachtwurd',
    phoneNumber: 'Telefoannûmer',
    shipName: 'Skipnamme',
    shipType: 'Type skip',
    length: 'Lingte skip (m)',
    width: 'Breedte skip (m)',
    height: 'Hichte skip (m)',
    draught: 'Kiel djipte fan it skip (m)',
    dimensionsHead: 'Ofmjittings skip',
    pleaseFillRealistic: 'De ôfmjittings fan it skip moatte tusken 0 en 100 m wêze',
    passwordsNotMatch: 'De wachtwurden binne net itselde',
    fieldsRequired: 'Folje alle ferplichte fjilden yn (*)',
    alreadyExists: 'Der is al in akkount foar dit e-mailadres',
    emailInvalid: 'Jo ynfierde e-mailadres is ûnjildich',
    failedToFetch: 'Kin gjin ferbining meitsje mei de server. Kontrolearje jo ynternetferbining.',
    serverError:
      'Der hat him in ûnbekende flater foardien, besykje it opnij of nim kontakt op mei support@teqplay.nl',
    gainedAccessToVarenFriesland:
      'Jo besteande Teqplay-akkount hat no tagong ta Watersport. Oanmelde mei jo gegevens of freegje in nij wachtwurd oan fia de fergetten-wachtwurd-knop mei itselde e-mailadres.',

    gdpr: {
      title: 'Privacyferklearring',
      privacyBody:
        "As jo ​​in akkount meitsje foar dizze Watersport-app, stimme jo yn mei de ferwurking fan jo gegevens. Wy wolle dat jo begripe hokker ynformaasje wy sammelje en hoe't wy dy brûke." +
        "<br /><br /> As jo ​​jo oanmelde foar in Watersport-akkount, bewarje wy de akkountynformaasje dy't jo leverje. Dat binne de namme fan jo skip, jo namme, e-mail en wachtwurd. Wy bewarje ek ynformaasje oer jo lokaasje en jo apparaatgegevens." +
        '<br /><br /> As jo ​​de Watersports-app brûke, sammelje wy gegevens om ús tsjinsten leverje en  ferbetterje te kinnen.' +
        '<br /><br /> Jo gegevens binne beskikber foar de provinsje Fryslân, foar oare wetterbehearders en de appbehearder Teqplay. It doel fan it brûken fan jo gegevens is om jo as farwei-brûker sa optimaal mooglik gebrûk meitsje te litten fan de Fryske farwegen. Jo gegevens wurde brûkt foar it leverjen fan ús service en it behearen fan jo brûkersakkount.' +
        "<br /><br /> Jo kinne jo privacy sels yn 'e Watersport-app beheare. Jo kinne jo gegevens sels besjen en jo akkount op elk momint wiskje. Nei it beëinigjen fan jo akkount wurde de persoanlike gegevens fuortendaliks wiske. Wy bewarje lokaasjegegevens foar ús statistiken. Persoanlike gegevens wurde bewarre sa lang as jo in akkount hawwe." +
        "<br /><br /> Wy beskermje jo en ús gegevens en applikaasjes tsjin ûnautorisearre gebrûk. Dêrom kodearje wy in protte fan ús tsjinsten mei SSL en evaluearje wy ús wurkwize oangeande sammeljen, opslach en ferwurkjen fan gegevens. Wy beheine de tagong ta persoanlike ynformaasje ta meiwurkers en behearders fan wetterwegen dy't dizze ynformaasje nedich binne om ús tsjinst leverje te kinnen." +
        '<br /><br /> Foar fragen oer dizze disclaimer kinne jo kontakt opnimme mei: provincie@fryslan.frl',
      accept: 'Akseptearje',
      deny: 'Wegerje',
      error: 'Jo moatte de privacybetingsten akseptearje om de Watersport-app brûke te kinnen.',
      view: 'Lês de betingsten',
      close: 'Slute'
    }
  },

  routeSelection: {
    routeNumbered: 'Rûte %{routeNumber}',
    selectRoutePlaceholder: 'Selektearje jo reis',
    startingPoint: 'Begjinpunt',
    destinationPoint: 'Bestimming',
    selectDestination: 'Selektearje jo bestimming',
    selectStartingPoint: 'Selektearje jo begjinpunt',
    selectViaPoint: 'Selektearje in fia punt',
    viaPoint: 'Fia punt',
    options: 'Opsjes',
    calculateRoute: 'Rûte berekkenje',
    arrival: 'Oankomst',
    departure: 'Ofreis',
    searchOnLocation: 'Sykje op lokaasje',
    noOptions: 'Gjin lokaasjes fûn',
    searchAdvice: 'Sykje op lokaasje',
    currentLocation: 'Aktuele lokaasje',
    selectOnMap: 'Selektearje lokaasje op kaart',
    distance: 'Ôfstân',
    arrivalTime: 'Oankomsttiid',
    viewList: 'List toane',
    startRoute: 'Rûte begjinne',
    deleteRoute: 'Rûte wiskje',
    saveRoute: 'Opslaan',
    cancel: 'Ofbrekke',
    list: 'Objekten',
    details: 'Ynfo',
    map: 'Kaart',
    notAllowedDimensions: 'Dizze rûte is net befarber mei de ynfierde ôfmjittings fan dit skip',
    notAllowedBridge: 'Net alle brêgen kinne passearre wurde mei de ynfierde skipshichte',
    noRoutesFoundWarning: 'Der binne gjin rûtes fûn foar dit trajekt',
    noDetailsFoundWarning: 'Gjin details beskikber foar dit objekt',
    routeDetails: 'Rûteynformaasje',
    routeStoredSucces:
      "De rûte is opslein. As jo ​​de lokaasjes selektearje, sjogge jo de rûtes dy't opslein binne.",
    showSavedRoutes: 'Opsleine rûtes sjen litte',
    hideSavedRoutes: 'Ferbergje opsleine rûtes',
    fromTo: '%{from} nei %{to}',
    dateSaved: 'Opslein op',
    saveRouteTitle: 'Rûte opslaan',
    noNameFilled: 'Fier in namme yn om de rûte op te slaan',
    routeNameInput: 'Rûte namme',
    selectionWarning: 'Selektearje in begjinpunt en bestimming',
    pauseRoute: 'Underbrek de rûte',
    resumeRoute: 'Trochgean mei de rûte',
    noLocationWarning: 'Skeakelje jo GPS yn om de rûte te begjinnen.',
    shipDepth: 'Ûndjipste punt',
    shipHeight: 'Leechste trochfearthichte',
    maxWidth: 'Smelste punt',
    waitingTime: 'Ferwachte wachttiid',
    duration: 'Totale doer',
    createGeolocation: '"%{value}"',
    node: '%{value} (Vaarknooppunt)'
  },

  routeList: {
    itemDetails: {
      opening_required:
        'In brêge-iepening is nedich foar jo skip. Freegje de brêge-iepening sels oan.',
      opening_required_bms_bridge:
        'In brêge-iepening is nedich foar jo skip. Dat wurdt automatysk frege.',
      cannot_pass_bridge: 'Tink derom! Jo skip sil net ûnder dizze brêge troch kinne.',
      opening_requested: 'In brêge-iepening is oanfrege',
      opening_planned: 'De brêge giet ynkoarten iepen',
      communication: 'Kommunikaasje',
      vhf: 'VHF',
      control_times: 'Betsjinningstiden',
      yes: 'Ja',
      no: 'Nee',
      NAP: 'NAP',
      opening: 'Iepening',
      type: 'Type',
      opening_width: 'Breedte',
      width: 'Breedte',
      length: 'Lingte',
      closed_height: 'Sletten hichte',
      opened_height: 'Iepene hichte',
      telephone: 'Tel',
      lock_chamber: 'Slûskom',
      max_width: 'Maks. breedte (m)',
      threshold_outside: 'Drompel ûnder/bûten',
      threshold_inside: 'Drompel boppe/binnen',
      address: 'Wâladres',
      from: 'Fan',
      to: 'oant',
      wholeDay: 'Hiele dei',
      bridge_type: {
        VST: 'Fêst',
        DR: 'Draai',
        BC: 'Baskule',
        OPH: 'Ophel',
        HEF: 'Hef',
        DBC: 'DBC'
      },
      bridge_status: {
        open: 'Dizze brêge is op it stuit iepen',
        closed: 'Dizze brêge is op it stuit ticht.',
        unknown: 'De aktuele status fan dizze brêge is ûnbekend.'
      },
      bridge_operating_rules: {
        today: 'Hjoed',
        tomorrow: 'Moarn',
        holiday: 'Feestdagen',
        noService: 'Gjin betsjinning'
      },
      bridgeRecommendation: {
        AFSC: 'Delskutte',
        AFVT: 'Ôffeart',
        BERP: 'Allinne foar beropsfeart',
        BSCH: 'Begelate beropsfeart',
        BVAF: 'Allinne beropsfeart op ôfrop',
        ETAA: 'Eintiid ôfhinklik oanbod',
        FACO: 'Fakultative iepening',
        GEEN: 'Gjin betsjinning',
        HAR: 'Allinne rjochting Haringvliet',
        KAN: 'Allinne rjochting kanaal',
        KONV: 'Konfoaifeart',
        OPEN: 'Stiet (meastal) iepen',
        OPSC: 'Opskutte',
        OPVT: 'Opfeart',
        RECR: 'Rekreaasjefeart',
        STDU: 'Allinne de stêd út',
        VERZ: 'Allinne op ôfrop',
        ZELF: 'Selsbetsjinning',
        ZUID: 'Allinne nei it suden geand'
      },
      no_items_found: 'Gjin details beskikber foar dit objekt',
      waitingTime: '%{waitingTime} ferwachte wachttiid foar folgjende %{objectType}',
      note: 'Opmerking',
      bridgeOpeningNote: 'Opmerking oer brêge-iepening',
      chamberNote: 'Opmerking oer slûskom',
      waterLevel: 'Wetternivo',
      operable: 'Kin betsjinne wurde',
      phoneNumber: 'Telefoannûmer',
      moreInfo: 'Mear ynformaasje'
    },
    objectTypes: {
      BRIDGE: 'brêge',
      LOCK: 'slûs',
      START: 'start',
      END: 'ein',
      NOTIFICATION: 'notifikaasje',
      WATERMETER: 'wettermjitter',
      TRAFFIC_SIGN: 'ferkearsboerd',
      BOTTLENECK: 'fersmelling',
      VHFSECTOR: 'VHF sektor',
      UNKNOWN: 'ûnbekend objekt'
    }
  },

  navigationPage: {
    destination: 'Bestimming',
    arrival: 'Oankomst',
    next_object: 'Folgjende objekt',
    no_user_location_warning: 'Gjin GPS-lokaasje fûn',
    no_notifications: 'Der binne gjin rapporten foar de hjoeddeistige farlokaasje',
    list_view: 'List',
    notifications: 'Notifikaasjes',
    ship_speed: 'Faasje',
    route_paused_warning: 'Rûte ûnderbrutsen',
    stop_route_confirmation: 'Binne jo der wis fan dat jo de rûte stopje wolle?',
    pause_route: 'Underbrek de rûte',
    stop_route: 'Stopje rûte',
    resume_route: 'Begjin wer mei de rûte'
  },

  modalSelectPreviousRoute: {
    question: 'Wolle jo trochgean mei jo foarige rûte?',
    yes: 'ja',
    no: 'nee'
  },

  pages: {
    welcome: 'Wolkom',
    feedback: 'Melding meitsje',
    navigation: 'Navigaasje',
    myProfile: 'Myn profyl',
    logout: 'Utlogge',
    loginLink: 'Ynlogge',
    register: 'Akkount registrearje',
    policy: 'Privacy Policy'
  },

  map: {
    followButton: 'Sintrum dyn skip',
    speedButton: 'Stel faasje yn',
    activelayers: 'Selektearje kaartlagen',

    category: {
      categoryBerths: 'Oanlisplakken',
      categoryFairway: 'Wetterwegen',
      categoryElements: 'Eleminten',
      maptype: 'Kaart'
    },

    crossings: 'VDJS krúspunten',
    public_berths: 'Iepenbiere lisplakken',
    berthsOutside: 'Ligplaatsen bûtengebiet',
    fairways: 'Wetterwegen',
    CEMTClass: 'CEMT Klasse',
    fairwayDepth: 'Djipte',
    recreationalClass: 'Rekreaasjeklasse',
    electricRoutes: 'Elektryske rûtes',
    contractDepth: 'Kontraktdjipte',
    levelDepth: 'Peildjipte',
    inlandHarbours: 'Jachthavens',
    chargingStations: 'Oplaadstasjons',
    bridges: 'Brêgen',
    kilometre: 'Ofstân yn kilometers',
    trailerSlope: 'Trailer hellings',
    wasteWaterStations: 'Smoarch wetter oan wâl',
    nodes: 'Vaarknooppunten',
    winterRestAreas: 'Fûgelrêstgebieten',
    AISShips: 'Skipfeart',
    fastRoutes: 'Hurdfargebieten',
    fastRoutesNoInfo: 'Gjin ynformaasje beskikber',

    speed: {
      title: 'Stel de faasje yn foar it berekkenjen fan oankomsttiden',
      useShip: 'Brûk skipsfaasje',
      useCustom: 'Stel sels yn',
      setRealisticSpeed: 'Stel in faasje yn tusken 1 en 50 km/o',
      saved: 'De wiziging is bewarre'
    },

    details: 'details',

    notification: {
      routeNotifications: 'Farwei-notifikaasjes',
      tooMuchBerths: 'Te folle lisplakken fûn, zoom fierder yn om de lisplakken sjen te litten.',
      approachVdjsHeader: 'VDJS knooppunt',
      approachingVdjsCrossing:
        'Jo komme by it knooppunt% {crossingName}. Foar dit knooppunt binne oanrekommandearre rûtes te krijen by Varen Doe je Samen.',
      approachingVdjsCrossingShort: 'Jo komme oan by knooppunt% {crossingName}',
      show: 'Sjen litte op kaart',
      channelNotificationHeader: 'Drokke farwei',
      nearingChannelNotification:
        "Jo komme yn 'e buert fan it% {channelName}. Far foarsichtich, hjir is in soad beropsfeart aktyf.",
      zoomInNotification: "Zoom yn om de folgjende kaartlagen op 'e kaart te sjen:"
    }
  },

  myProfile: {
    title: 'Myn profyl',
    titleProfile: 'Profyldata',
    titlePasswordChange: 'Feroarje Wachtwurd',
    titleShipDimensions: 'Ofmjittings skip',
    myShip: 'Myn skip',
    password: 'Wachtwurd',
    email: 'E-mail',
    mobile: 'Mobyl',
    mmsi: 'MMSI',
    currentPassword: 'Hjoeddeistich wachtwurd',
    newPassword: 'Nij Wachtwurd',
    repeatNewPassword: 'Werhelje nij wachtwurd',
    save: 'Opslaan',
    newPasswordNoMatch: 'De nije wachtwurden komme net oerien',
    updatedPasswordSuccess: 'Jo wachtwurd is opslein',
    wrongPassword: 'Ferkeard aktueel wachtwurd',
    tooWeak: 'Jo nije wachtwurd is te swak',
    titleDeleteAccount: 'Profyl wiskje',
    selectAShip: 'Selektearje in skip',
    updateProfileSuccess: 'It profyl is bywurke',
    updateProfileFailed:
      'Der gie wat mis by it opslaan fan jo profyl, besykje it letter nochris of nim kontakt op mei support@teqplay.nl',
    areYouSureDeleteAccount:
      'Binne jo der wis fan dat jo jo profyl wiskje wolle? Dit kin net ûngedien makke wurde.',
    removeSuccess: 'It profyl is wiske',
    no: 'Nee',
    yes: 'Ja',
    yesDelete: 'Ja, wiskje',
    dimensionsErrorMessage: '* Ofmjittings moatte tusken 0 en 100 meter wêze.',
    dimensionsWarningMessage: '* Fier in wearde yn.',
    dimensionsSaveWarning: 'De ôfmjittings foar jo skip binne net goed ynfierd.',
    ship: {
      length: 'Lingte (m)',
      width: 'Breedte (m)',
      height: 'Hichte (m)',
      draught: 'Kyldjipte (m)'
    },

    passwordTips: {
      title: 'Wachtwurdeasken',
      match: 'Wachtwurden binne itselde',
      noMatch: 'Wachtwurden binne net itselde',

      requirements: 'Wachtwurdeasken',
      requirementsInfo:
        'Wy wolle dat jo wachtwurd sa feilich mooglik is om jo en jo gegevens te beskermjen. Dêrom hawwe wy de folgjende easken foar jo wachtwurd.',

      tips: 'Tips foar in feilich wachtwurd',
      tipsCasing: 'Brûk lytse letters en haadletters',
      tipsNumber: 'Brûk in sifer',
      tipsSpecialCharacters: 'Brûk in spesjaal karakter lykas! @ # $%',
      tipsLength: 'Brûk 8 karakters of mear',

      tipsInfo: 'Wy hawwe wat tips foar jo om it noch feiliger te meitsjen.',
      tipsUnique: 'Brûk in unyk wachtwurd dat jo net foar alles brûke',
      tipsUniqueSubtitle:
        "Dit is fansels net ferplicht en wy kinne dit net iens wite of kontrolearje, om't jo wachtwurd fersifere is. Mar foar de heechste befeiligingsnormen freegje wy jo in unyk wachtwurd oan te meitsjen.",
      tipsNoPersonalInfo: 'Brûk gjin maklik te rieden persoanlike ynformaasje yn jo wachtwurd',
      tipsStoreSafely: 'Bewarje jo wachtwurden feilich',
      tipsStoreSafelySubtitle:
        'Wy advisearje it gebrûk fan in wachtwurdbehearder. Jo kinne it wachtwurd ek opskriuwe en op in feilich plak bewarje.',

      allMet: 'Dat is wat ik in feilich wachtwurd neam, goed dien! 👍',
      abideToAll: "Jo nije wachtwurd foldocht net oan' e easken"
    }
  },

  feedbackPage: {
    title: 'Melding meitsje',
    description: 'Beskriuwing',
    descriptionText: 'Foegje in beskriuwing fan jo rapport ta',
    location: 'Lokaasje',
    locationText: "Selektearje in plak op 'e kaart dêr't jo rapport of opmerking oer giet.",
    photo: 'Foto',
    photoText: 'Foegje in foto ta foar dy lokaasje',
    uploadText: 'Sleep of klik om in foto ta te foegjen',
    sendFeedback: 'Ferstjoer melding',
    warningMessage: 'Fier in beskriuwing fan jo melding yn',
    successMessage: 'Jo berjocht is ferstjoerd, tank foar jo bydrage!',
    errorMessage: 'Der gie wat mis mei it ferstjoeren fan jo melding, ekskús foar it ûngemak.',
    done: 'Klear',
    chooseLocation: "Klik hjir om in lokaasje op 'e kaart te kiezen",
    noLocationKnown: 'Gjin lokaasje bekend',
    noEmergencies:
      'Dizze funksje is net foar needsituaasjes, belje yn driuwende gefallen altyd 112.'
  },

  mobile: {
    navigation_active: 'Navigaasje aktyf',
    navigation_active_destination: 'Navigaasje nei %{bestimming} aktyf',
    offline_title: 'Gjin ferbining',
    offline_text: 'Der is gjin ferbining, jo rûte wurdt ûnderbrutsen.',
    high_speed_title: 'Jo geane te hurd',
    high_speed_text:
      "Farre jo noch? Jo geane hurder as 80 km /oere, stopje de rûte as jo net mear yn 'e app farre."
  },

  mapLayers: {
    last_updated: 'Lêste fernijing',
    past: 'lyn',
    berthLayer: {
      width: 'Breedte',
      length: 'Lingte',
      draught: 'Djipgong',
      berthName: 'Namme fan lisplak',
      owner: 'Behearder',
      dangerousGoodsLevel: 'Kegels',
      mooringOptions: 'Oanlisopsjes',
      mooringServices: 'Oanlistsjinsten',
      mooringFunctions: 'Oanlisfunksjes',
      mooringHourTimeLimit: 'Oanlistiidlimyt',
      shoreConnection: 'Wâlferbining',
      sort: 'Type',
      viewFotoURL: 'Besjoch foto fan lisplak'
    },

    chargingStations: {
      city: 'Stêd',
      chargingPointAvailable: 'Laadpunt beskikber',
      publicAvailable: 'Laadpunt iepenbier',
      greenPower: 'Griene stroom',
      paymentMethod: 'Wize fan beteljen',
      paymentMethods: {
        CASH: 'Kontant jild',
        CARD: 'Pin',
        CASH_AND_CARD: 'Kontant en pinne',
        FREE: 'Fergees',
        UNKNOWN: 'Ûnbekend'
      },
      comments: 'Opmerkingen',
      chargingPossibilities: 'Opsjes om op te laden',
      unavailable: 'Net beskikber',
      connections: 'oanslutings'
    },

    inlandHarbours: {
      city: 'Stêd',
      shortStayPlaces: 'Koart ferbliuwplakken',
      longStayPlaces: 'Lang ferbliuwplakken',
      suppliesFuel: 'Brânstof beskikber'
    },

    wasteWaterStations: {
      city: 'Stêd',
      address: 'Adres',
      containsWasteWater: 'Befettet smoarch wetter',
      bilge: 'Bilge',
      phone: 'Telefoan'
    }
  },

  pushNotification: {
    buttonClose: 'Okee'
  },

  geolocationStrings: {
    warning: 'Warskôging',
    info: 'Ynformaasje',
    deniedPermission: 'De app hat gjin tastimming om jo lokaasje te brûken.',
    clickToRetry: 'Klik om opnij te besykjen',
    stillDenied:
      'App hat noch hieltyd gjin tastimming, gean nei Ynstellings > Apps > Wettersport > Machtigingen / tagongsrjochten en skeakelje Lokaasjehelp yn',
    clickToMinimize: 'Klik om te minimalisearjen',

    androidWhenActive: 'Lokaasjebepaling aktyf',
    androidChannelName: 'GPS lokaasjebepaling aktyf',
    androidPermissionDeniedInfo: 'Sûnder lokaasjebepaling hat de app beheinde funksjonaliteit',

    whenInUse:
      'Om lokaasjebepaling op \'e eftergrûn te brûken en aktuele notifikaasjes te krijen, moatte jo yn dizze app foar "Lokaasje" tastimming jaan om "Altyd" te brûken.',
    instructions:
      'Om lokaasjebepaling op \'e eftergrûn te brûken en aktuele notifikaasjes te krijen, moatte jo yn dizze app foar "Lokaasje" tastimming jaan om "Altyd" te brûken.',
    cancelButton: 'Ofbrekke',
    settingsButton: 'Ynstellings',
    iosTitleWhenNotEnabled: "Lokaasjebepaling is op 'e eftergrûn út",
    iosTitleWhenOff: 'Lokaasjebepaling is út',

    // backgroundPermissionRationale settings
    androidAllowAlwaysQuestion: "Tastean om de lokaasje altyd op 'e eftergrûn te krijen?",
    androidChangeAllTheTime: 'Feroarje nei altyd',

    errors: {
      0: 'Jo lokaasje koe net bepaald wurde',
      1: 'Watersport hat gjin tagongsrjochten om jo lokaasje op te heljen',
      2: 'Watersport kin jo lokaasje net bepale fanwegen in netwurkprobleem',
      408: "Watersport kin jo lokaasje net bepale, om't it te lang duorret",
      499: 'Krij jo lokaasje is annulearre'
    }
  },

  disclaimer: {
    title: 'De Watersport App is yn ûntwikkeling, wês foarsichtich.',
    body:
      "Hâld jo eagen op 'e farwei en folgje de wet. " +
      'Der is in mooglikheid dat de rûtes ûnfolslein of ferkeard binne. ' +
      "It is mooglik dat gegevens net altyd real-time binne en de krektens fan 'e lokaasje net altyd foldwaande is. " +
      'Teqplay, har opdrachtjouwers en har leveransiers fan gegevens binne net ferantwurdlik foar skea as gefolch fan it gebrûk fan dizze applikaasje.',
    readMore: 'Lês mear',
    dontShowAgain: 'Net wer sjen litte',
    bridgesTitle2022: 'Brêgen en slûzen 2022',
    bridges2022:
      'Brêgen en slûzen Koarnwertersân: grutte drokte ferwachte yn rekreaasjeseizoen 2022'
  },

  prominentDisclosure: {
    title: 'Lokaasjegebrûk',
    approve: 'Ik gean akkoart',
    reject: 'Ik wegerje',
    explanation:
      'Dizze app brûkt lokaasjegegevens om de neikommende funksjes by steat te stellen om:',
    feature1: 'Te navigearjen lâns in rûte',
    feature2: 'Rûten fanôf/nei jo lokaasje te berekkenjen',
    feature3: 'Oankomsttiden ûnder it farren te berekkenjen',
    feature4: 'Brêge-iepenings op jo lokaasjes oan te freegjen',
    feature5: 'Jo lokaasje op de kaart te toanen',
    background:
      'De app kin dy funksjonaliteit mooglik ek brûke as de app op de eftergrûn draait of sletten is.',
    notProperly:
      'By it net tastean fan dizze permisjes kin de app mooglik net wurkje sa’t it bedoeld is.'
  },

  bridgeOpeningRequests: {
    willOpen: 'Brêge giet iepen',
    requested: 'Iepeningsfersyk yntsjinje',
    noRequestsActive: 'Gjin oanmeldoanfragen aktyf',
    tooFar:
      'Jo binne te fier fan de brêge ôf om in iepeningsfersyk oanfreegje te kinnen. Sadree’t jo yn it oanfraachgebiet binne, kinne jo in brêge-iepening oanfreegje.',
    status: 'Status',
    applyForRequest: 'Oanmeldfersyk yntsjinje',
    errorText: 'Fout',
    error: 'Der gie wat mis by it yntsjinjen fan it iepeningsfersyk',
    canOpen: 'Foar dizze brêge kin in brêge-iepening oanfrege wurde',
    onlyOnce: 'Dit berjocht wurdt mar 1 kear toand.',
    previousRequest:
      'Jo hawwe foar dizze brêge al %{amount}x earder in brêge-iepening oanfrege yn dizze sesje. Lêste kear wie om %{time}.',
    downloadApp:
      'Download de Wettersport app op jo mobyl of tablet om in brêgefersyk foar dizze brêge yn te tsjinjen.',
    notHealthy:
      'De ferbining mei it brêge-iepeningoanfraachsysteem is mislearre. Op it stuit kinne jo fia de app gjin brêge-iepening oanfreegje.',
    needApproval:
      'Jo hawwe noch gjin tastimming jûn foar lokaasjegebrûk, dit is ferplicht om brêge iepeningen oan te freegjen.',
    retry: 'Tastean',

    response: {
      BRIDGE_MAINTENANCE:
        'Se binne op stuit mei ûnderhâldswurk oan dizze brêge dwaande. ' +
        'De brêge wurdt mominteel net betsjinne. ' +
        'Untskuldigings foar it mooglike ûngemak. ' +
        '\nFolgje de seinen op de brêge foar neiere ynstruksjes en/of sjoch foar skipfeartberjochten op Twitter @ScheepvaartFRL.',
      BRIDGE_ERROR:
        'Dizze brêge is op it stuit yn steuring en wurdt no net betsjinne. ' +
        'Untskuldigings foar it ûngemak, wy wurkje oan in oplossing. ' +
        '\nFolgje de seinen op de brêge foar neiere ynstruksjes en/of sjoch foar skipfeartberjochten op Twitter @ScheepvaartFRL',
      NOT_OPERATED:
        'Jo oanfraach falt bûten betsjinningstiden. Sjoch foar aktuele betsjinningstiden op www.fryslan.frl/brugbediening',
      ACCEPT: 'Betanke foar jo oanfraach. Wy betsjinje de brêge sa gau mooglik.',
      TECH_ERROR: 'Der is technysk wat misgien, besykje it letter nochris.'
    },

    responseTitle: {
      BRIDGE_MAINTENANCE: 'Underhâldswurk oan ‘e brêge',
      BRIDGE_ERROR: 'De brêge is yn steuring',
      NOT_OPERATED: 'Oanfraach bûten betsjinningstiid',
      ACCEPT: 'Brêge-iepeningoanfraach suksesfol',
      TECH_ERROR: 'Fout'
    },

    success: 'Brêge-iepeningoanfraach suksesfol',
    successDescription: 'Betanke foar jo oanfraach. Wy betsjinje de brêge sa gau mooglik.',
    viewTwitter: 'Sjoch op @ScheepvaartFRL'
  },

  bridges: {
    solid: 'Net operearber',
    openable: 'Operearber',
    openingRequestable: 'Brêge mei oanmeldfunksje yn app'
  }
}

export default frlLocale
