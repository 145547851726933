import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'

import LabelWithValue from '../../../shared/labelWithValue/LabelWithValue'

import { IRootProps } from '../../../../@types/types'
import { IRoute } from '../../../../services/TeqplayApiService/TeqplayApi'
import { formatDateTimeShort } from '../../../../utils/dates'
import { getRouteDepth } from '../../../../utils/routeUtils'

interface IProps {
  route?: IRoute
  closeModal: () => void
}

const RouteDetailedInfo: React.FunctionComponent<IProps> = props => {
  const route = props.route
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string

  if (!route) {
    return null
  }

  const routeNotAccessible = !route.canPassBridges || !route.shipAllowed
  const depth = getRouteDepth(route)
  const height = route.minimalBridgeHeight ? route.minimalBridgeHeight.toFixed(2) : null
  const width =
    route.minimalWidth && route.minimalWidth < 10000 ? route.minimalWidth.toFixed(2) : null

  return (
    <div className="route-info-inner">
      {routeNotAccessible && (
        <div className="route-warning-not-sailing">
          <span className="icon-attention" data-tip data-for="shipNotAllowed" />
          {route.shipAllowed ? (
            <Translate value="routeSelection.notAllowedBridge" />
          ) : (
            <Translate value="routeSelection.notAllowedDimensions" />
          )}
        </div>
      )}

      <h4 className="option-title">{I18n.t('routeSelection.routeDetails')}</h4>

      <div className="option-details">
        <div className="labels-for-details">
          <LabelWithValue
            label={I18n.t('routeSelection.distance')}
            value={route.distance.toFixed(0) + ' km'}
          />
          <LabelWithValue
            label={I18n.t('routeSelection.arrivalTime')}
            value={formatDateTimeShort(route.eta, locale)}
          />
          <LabelWithValue
            label={I18n.t('routeSelection.waitingTime')}
            value={route.waitingTime + ' min'}
          />
        </div>

        <div className="option-dimensions">
          <div className="dimension">
            <span className="dimension-label">{I18n.t('routeSelection.shipDepth')}</span>
            <div className="dimension-value">
              <span className="icon-depth">{depth > 0 ? depth.toFixed(2) + 'm' : '-'}</span>
            </div>
          </div>

          <div className="dimension">
            <span className="dimension-label">{I18n.t('routeSelection.shipHeight')}</span>
            <div className="dimension-value">
              <span className="icon-height">{height ? height + 'm' : '-'}</span>
            </div>
          </div>

          <div className="dimension">
            <span className="dimension-label">{I18n.t('routeSelection.maxWidth')}</span>
            <div className="dimension-value">
              <span className="icon-fairway-width">{width ? width + 'm' : '-'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(RouteDetailedInfo)
