import { AnyAction } from 'redux'
import { IBridgeOpeningInfo, ISelectedRoute } from '../../services/TeqplayApiService/TeqplayApi'

export interface IRouteSelectionState {
  navigationRoute: ISelectedRoute | null
  bridgeOpenings: IBridgeOpeningInfo[]
}

// Set inital login state
const initialState: IRouteSelectionState = {
  navigationRoute: null,
  bridgeOpenings: []
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function routeSelectionReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case 'SET_NAVIGATION_ROUTE': {
      return Object.assign({}, state, { navigationRoute: action.route })
    }

    case 'SET_BRIDGE_OPENINGS': {
      return Object.assign({}, state, { bridgeOpenings: action.bridgeOpenings })
    }

    default:
      return state
  }
}
