import React from 'react'
import * as MapboxGl from 'mapbox-gl'

import DefaultMarkerLayer from './DefaultMarkerLayer'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import wasteWaterIcon from '../icons/trailerSlopes.svg'

import { ITrailerSlipway } from '../../../services/TeqplayApiService/TeqplayApi'

interface IProps {
  bounds?: MapboxGl.LngLatBounds
  teqplayApiService: TeqplayApiService
  cursor: HTMLCanvasElement | undefined
}

const TrailerSlipwaysLayer: React.FunctionComponent<IProps> = props => {
  return (
    <DefaultMarkerLayer<ITrailerSlipway>
      fetchCall={() => props.teqplayApiService.fetchTrailerSlipways(props.bounds)}
      bounds={props.bounds}
      category="trailerSlope"
      cursor={props.cursor}
      iconUrl={wasteWaterIcon}
      getPopup={(slipway: ITrailerSlipway) => {
        return <h3>{slipway.name}</h3>
      }}
    />
  )
}

export default TrailerSlipwaysLayer
