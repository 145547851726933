import { debounce } from 'lodash'
import * as MapboxGl from 'mapbox-gl'
import React, { useEffect, useRef, useState } from 'react'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'

import {
  IExtendedPropertyLocation,
  IRouteSearchProps,
  MapTypes,
  RouteSelectionFields
} from '../../@types/types'
import { useLocalStorage } from '../../customHooks/useLocalStorage'
import {
  IBridgeOpeningInfo,
  IRouteLocation,
  IWatchDogStatus,
  IRoute
} from '../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { FRIESLAND_LOCATION, MAPBOX_TOKEN } from '../../utils/constants'
import { mapClickedLayer } from '../../utils/mapClickedLayer'
import { mapTypeToTileURL } from '../../utils/mapTypeToTileURL'
import {
  IFullPosition,
  IGeoLocationWatcherState
} from '../geoLocationWatcher/GeoLocationWatcherTypes'
import LayerZoomWarning from '../mapShared/layers/legends/LayerZoomWarning'
import BridgeLayerLegend from '../mapShared/layers/legends/BridgeLayerLegend'
import RouteNetworkLegend from '../mapShared/RouteNetworkLegend'
import ShipMarker from '../mapShared/shipMarker/ShipMarker'
import MapBoxController from '../newMapController/MapBoxController'
import StartEndLayer from '../mapShared/layers/StartEndLayer'
import DottedLine from '../mapShared/DottedLine'
import { layersWithZoomLevelRestrictions } from '../mapShared/layersWithZoomLevelRestrictions'
import MapLayers from '../mapShared/MapLayers'
import { RouteSuggestions } from '../mapShared/RouteSuggestions'
import { SelectedRoute } from '../mapShared/SelectedRoute'

interface IProps {
  activeMap?: MapTypes
  hideControlButtons?: boolean
  currentLocation: IFullPosition | null
  routeSuggestions: IRoute[] | null
  routeSelection: IRouteSearchProps
  selectedRouteSuggestion: IRoute | null
  selectedField: RouteSelectionFields | null
  routeSelectionFormActive: boolean
  routeSelectionFormFieldActive: boolean
  setFromLocation: (fromLocation: IRouteLocation | null) => void
  setToLocation: (toLocation: IRouteLocation | null) => void
  selectedViaRouteIndex: number | null
  onChangeActiveMap: (activeMap: MapTypes) => void
  setViaRoutes: (viaRoutes: IRouteLocation[]) => void
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  bridgeOpenings: IBridgeOpeningInfo[]
  watchdogStatus: IWatchDogStatus
  userHistory: IExtendedPropertyLocation[]
  geoLocationWatcherState: IGeoLocationWatcherState
  teqplayApiService: TeqplayApiService
}

const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN
})

const MapRouteSelection = (props: IProps) => {
  const [userLocation, setUserLocation] = useState<IFullPosition | null>(props.currentLocation)
  const [retrievedUserLocation, setRetrievedUserLocation] = useState<boolean>(false)
  const [activeMap, setActiveMap] = useLocalStorage<MapTypes>('WATERSPORT-mapType', 'DEFAULT')
  const [followMode, setFollowMode] = useLocalStorage<boolean>('WATERSPORT-followMode', false)
  const [userZoom, setUserZoom] = useLocalStorage<number>('WATERSPORT-mapZoomLevel', 8)
  const [activeLayers, setActiveLayers] = useLocalStorage<string[]>('WATERSPORT-layers', [])
  const [heading, setHeading] = useState<boolean>(false)
  const [center, setCenter] = useLocalStorage<[number, number] | undefined>('WATERSPORT-center', [
    FRIESLAND_LOCATION.lng,
    FRIESLAND_LOCATION.lat
  ])
  const [mapBounds, setMapBounds] = useState<MapboxGl.LngLatBounds>()
  const [layerProperties, setLayerProperties] = useState<any>([])

  const mapRef = useRef<MapboxGl.Map>()
  useEffect(() => {
    if (props.currentLocation) {
      setUserLocation(props.currentLocation)
      setRetrievedUserLocation(true)
      if ((followMode || heading) && props.currentLocation.location) {
        if (heading) {
          const flyToSettings = {
            center: [
              props.currentLocation.location.longitude,
              props.currentLocation.location.latitude
            ] as [number, number],
            zoom: userZoom < 15 ? 15 : userZoom,
            pitch: props.currentLocation.courseOverGround ? 100 : 0
          }
          mapRef.current?.flyTo(
            props.currentLocation.courseOverGround
              ? { ...flyToSettings, ...{ bearing: props.currentLocation.courseOverGround } }
              : flyToSettings
          )
        } else {
          setCenter([
            props.currentLocation.location.longitude,
            props.currentLocation.location.latitude
          ])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentLocation])

  useEffect(() => {
    if (retrievedUserLocation && props.currentLocation?.location) {
      setCenter([props.currentLocation.location.longitude, props.currentLocation.location.latitude])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedUserLocation])

  useEffect(() => {
    if (mapRef.current && props.selectedRouteSuggestion) {
      // Take location from start & endpoint
      mapRef.current?.fitBounds(
        [
          props.selectedRouteSuggestion.routeItems.slice(-1)[0].location[0].coordinates,
          props.selectedRouteSuggestion.routeItems[0].location[0].coordinates
        ],
        { padding: 100 }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.routeSuggestions])

  useEffect(() => {
    /* Instead of always setting things above, this will move the shipMarker always to prio 1 */
    if (mapRef.current && mapRef.current?.getStyle()) {
      mapRef.current.moveLayer('point-blip')
      mapRef.current.moveLayer('point')
      if (mapRef.current.getSource('ship')) {
        mapRef.current.moveLayer('ship')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.routeSelection.fromLocation?.coordinates])

  useEffect(() => {
    setLayerProperties([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayers])

  return (
    <Map
      style={mapTypeToTileURL(activeMap)}
      zoom={[userZoom]}
      onZoomEnd={map => setUserZoom(map.getZoom())}
      center={center}
      onStyleLoad={handleStyleLoad}
      onClick={handleClick}
      onMoveEnd={debounce(onMoveEnd, 250)}
      onDragEnd={() => {
        setFollowMode(false)
        setHeading(false)
      }}
      onRotateEnd={() => (!props.currentLocation?.courseOverGround ? setHeading(false) : undefined)}
    >
      <LayerZoomWarning
        mapZoomLevel={userZoom}
        layersWithZoomLevelRestrictions={layersWithZoomLevelRestrictions}
        activeLayers={activeLayers}
      />
      <div>
        <RouteNetworkLegend activeLayers={activeLayers} />
        <BridgeLayerLegend activeLayers={activeLayers} />
        {props.routeSelection && (
          <StartEndLayer
            fromCoordinates={
              props.routeSelection.fromLocation?.coordinates?.lng
                ? [
                    props.routeSelection.fromLocation.coordinates.lng,
                    props.routeSelection.fromLocation.coordinates.lat
                  ]
                : undefined
            }
            toCoordinates={
              props.routeSelection?.toLocation?.coordinates
                ? [
                    props.routeSelection.toLocation.coordinates.lng,
                    props.routeSelection.toLocation.coordinates.lat
                  ]
                : undefined
            }
          />
        )}
        <MapLayers
          teqplayApiService={props.teqplayApiService}
          activeLayers={activeLayers}
          mapBounds={mapBounds}
          cursor={mapRef.current?.getCanvas()}
          layerProperties={layerProperties}
          userZoom={userZoom}
          bridgeOpenings={props.bridgeOpenings}
          isAnonymous={props.teqplayApiService.isAnonymousUser()}
          watchdogStatus={props.watchdogStatus}
          locationPermissions={props.geoLocationWatcherState.locationPermissions}
          currentLocation={props.currentLocation}
        />

        {props.routeSuggestions && props.routeSelection && (
          <DottedLine
            routeSuggestion={props.routeSuggestions[0]}
            routeSelection={props.routeSelection}
            currentLocation={props.currentLocation}
          />
        )}
        {props.routeSelection.viaRoutes &&
          props.routeSelection.viaRoutes.length > 0 &&
          props.routeSelection.viaRoutes.map(item => {
            /* For the user to select custom routes along the way */
            if (!item.coordinates) {
              return null
            }
            return (
              <Marker
                coordinates={[item.coordinates.lng, item.coordinates.lat]}
                anchor="bottom"
                key={item.displayName || undefined}
              >
                <div className="small-dot-icon red" />
              </Marker>
            )
          })}

        {props.routeSuggestions &&
          props.routeSuggestions.length > 0 &&
          props.routeSuggestions.map(suggestion => {
            /* Display the Route suggestions for the user */
            return (
              <RouteSuggestions
                routeSuggestions={suggestion}
                setSelectedRouteSuggestion={props.setSelectedRouteSuggestion}
                map={mapRef.current?.getCanvas()}
                key={suggestion._id}
              />
            )
          })}

        {props.selectedRouteSuggestion && props.routeSuggestions && (
          <SelectedRoute selectedRouteSuggestion={props.selectedRouteSuggestion} />
        )}
      </div>
      <MapBoxController
        userZoom={userZoom}
        followMode={followMode}
        setFollowMode={setFollowMode}
        heading={heading}
        setHeading={setHeading}
        setCenter={setCenter}
        currentLocation={props.currentLocation}
        setUserZoom={setUserZoom}
        activeMap={activeMap}
        setActiveMap={setActiveMap}
        activeLayers={activeLayers}
        setActiveLayers={setActiveLayers}
        isAnonymous={props.teqplayApiService.isAnonymousUser()}
        routeNavigation={props.teqplayApiService.isAnonymousUser()}
        mapRef={mapRef?.current}
      />
      <ShipMarker userLocation={userLocation} />
    </Map>
  )

  function handleStyleLoad(map: MapboxGl.Map) {
    mapRef.current = map
  }

  function onMoveEnd(map?: MapboxGl.Map, evt?: any) {
    const b = map?.getBounds()
    if (map?.getZoom()) {
      setUserZoom(map.getZoom())
      if (map.getZoom() < 13 && mapRef.current) {
        mapRef.current.getCanvas().style.cursor = ''
      }
    }
    setMapBounds(b)

    const distanceWhenZoom = map?.getCenter().distanceTo({
      lng: props.currentLocation?.location?.longitude,
      lat: props.currentLocation?.location?.latitude
    } as MapboxGl.LngLat)
    if (
      mapRef !== undefined &&
      distanceWhenZoom &&
      distanceWhenZoom > 1000 &&
      (followMode || heading)
    ) {
      setFollowMode(false)
      setHeading(false)
    }
  }

  function handleClick(map: MapboxGl.Map, evt: MapboxGl.MapMouseEvent | any) {
    setLayerProperties(mapClickedLayer(map, evt))
    if (props.routeSelectionFormFieldActive) {
      const { lat, lng } = evt.lngLat
      const customlocation: IRouteLocation = {
        displayName: `${lat},${lng}`,
        coordinates: { lat, lng }
      }
      if (props.selectedField === 'FROM_LOCATION') {
        props.setFromLocation(customlocation)
      }
      if (props.selectedField === 'TO_LOCATION') {
        props.setToLocation(customlocation)
      }
      if (props.selectedField === 'VIA_ROUTES' && customlocation.coordinates) {
        if (props.routeSelection.viaRoutes) {
          const correctSelections = props.routeSelection.viaRoutes.filter(item => item.coordinates)
          props.setViaRoutes([...correctSelections, customlocation])
        } else {
          props.setViaRoutes([customlocation])
        }
      }
    }
  }
}

export default MapRouteSelection
