import React from 'react'
import { I18n } from 'react-redux-i18n'
import * as MapboxGl from 'mapbox-gl'

import DefaultMarkerLayer from './DefaultMarkerLayer'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import wasteWaterIcon from '../icons/wasteWaterIcon.svg'

import { IWasteWaterStation } from '../../../services/TeqplayApiService/TeqplayApi'
import { labelAndValue } from '../../../utils/labelAndValue'

interface IProps {
  bounds?: MapboxGl.LngLatBounds
  teqplayApiService: TeqplayApiService
  cursor: HTMLCanvasElement | undefined
}

const WasteWaterStations: React.FunctionComponent<IProps> = props => {
  return (
    <DefaultMarkerLayer<IWasteWaterStation>
      fetchCall={() => props.teqplayApiService.fetchWasteWaterStations(props.bounds)}
      bounds={props.bounds}
      category="electricRoutes"
      cursor={props.cursor}
      iconUrl={wasteWaterIcon}
      getPopup={(wasteWaterStation: IWasteWaterStation) => {
        return (
          <>
            <h3>{wasteWaterStation.name}</h3>
            {labelAndValue(I18n.t('mapLayers.wasteWaterStations.city'), wasteWaterStation.city)}
            {labelAndValue(
              I18n.t('mapLayers.wasteWaterStations.address'),
              wasteWaterStation.address
            )}
            {labelAndValue(
              I18n.t('mapLayers.wasteWaterStations.containsWasteWater'),
              wasteWaterStation.containsWasteWater
            )}
            {labelAndValue(I18n.t('mapLayers.wasteWaterStations.bilge'), wasteWaterStation.bilge)}
            {labelAndValue(I18n.t('mapLayers.wasteWaterStations.phone'), wasteWaterStation.phone)}
          </>
        )
      }}
    />
  )
}

export default WasteWaterStations
