import React from 'react'
import { connect, MapDispatchToPropsFunction } from 'react-redux'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import MapNavigation from '../../components/mapNavigation/MapNavigation'
import NavigationHeader from '../../components/navigationHeader/NavigationHeader'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import NavigationOptions from './navigatingOptions/NavigationOptions'

import { IRootProps, MapTypes } from '../../@types/types'
import {
  IGeoLocationWatcherState,
  IShipLocationUpdate
} from '../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import { setActiveMap } from '../../components/mapShared/actions'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import {
  ISelectedRoute,
  IShipNotificationStatus,
  IUserAuth,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { clearAuth, setAuth } from '../loginPage/actions'
import {
  checkAndSetActiveRoute,
  clearNavigationRoute,
  pauseCurrentRoute,
  resumeCurrentRoute,
  setNavigationRoute,
  stopCurrentRoute,
  updateBridgeOpenings,
  updateRouteEta
} from '../routeSelectionPage/actions'

import 'react-tabs/style/react-tabs.css'
import './NavigationPage.scss'

interface IDispatchProps {
  setAuth: (userAuth: IUserAuth) => void
  clearAuth: () => void
  setNavigationRoute: (selectedRoute: ISelectedRoute) => void
  pauseCurrentRoute: (teqplayApiService: TeqplayApiService, selectedRoute: ISelectedRoute) => void
  resumeCurrentRoute: (teqplayApiService: TeqplayApiService, selectedRoute: ISelectedRoute) => void
  stopCurrentRoute: (teqplayApiService: TeqplayApiService) => void
  clearNavigationRoute: () => void
  checkAndSetActiveRoute: (teqplayApiService: TeqplayApiService, redirectToHome: () => void) => void
  updateRouteEta: (
    teqplayApiService: TeqplayApiService,
    selectedRoute: ISelectedRoute,
    currentLocation: IShipLocationUpdate | null,
    redirectToHome: () => void
  ) => void
  updateBridgeOpenings: (teqplayApiService: TeqplayApiService) => void
  setActiveMap: (activeMap: MapTypes) => void
}

interface IProps {
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
  watchdogStatus: IWatchDogStatus
  geoLocationWatcherState: IGeoLocationWatcherState
}

interface IState {
  loading: boolean
  listViewActive: boolean
  shipNotificationStatus: IShipNotificationStatus | null
  optionsActive: boolean
}

class NavigationPage extends React.PureComponent<
  IProps & IRootProps & IDispatchProps & RouteComponentProps<null> & IAuthenticationWrapper,
  IState
> {
  private intervalFetchETA: number | null = null
  private intervalFetchNotifications: number | null = null
  private intervalFetchBridgeOpenings: number | null = null

  public readonly state: Readonly<IState> = {
    loading: true,
    listViewActive: false,
    shipNotificationStatus: null,
    optionsActive: false
  }

  public componentDidMount() {
    this.fetchActiveRoute()
    this.fetchShipNotificationStatus(true)

    this.setFetchIntervals()
  }

  public componentDidUpdate(prevProps: IProps & IRootProps) {
    if (this.props.i18n.locale !== prevProps.i18n.locale) {
      this.fetchActiveRoute()
    }
  }

  public componentWillUnmount() {
    this.clearFetchIntervals()
  }

  public render() {
    if (!this.props.currentUser || !this.props.currentUser.token) {
      return <Redirect to="/login" push />
    }

    const userLocation = this.props.userLocation.currentLocation

    const isRoutePaused =
      this.props.routeSelection.navigationRoute &&
      this.props.routeSelection.navigationRoute.paused === true
        ? true
        : false

    const totalNotificationsCount = this.getNotificationCount()

    return (
      <div className="page-wrapper navigation-page">
        {this.props.routeSelection && this.props.routeSelection.navigationRoute ? (
          <>
            <NavigationHeader
              userName={this.props.currentUser.userName}
              activePage={this.props.location.pathname}
              logout={this.handleLogout}
              navigationRoute={this.props.routeSelection.navigationRoute}
              toggleOptions={this.toggleOptions}
              speed={this.props.speed}
              userLocation={userLocation}
              shipNotificationStatus={this.state.shipNotificationStatus}
              isRoutePaused={isRoutePaused}
              isAnonymous={false}
            />
            <MapNavigation
              token={this.props.currentUser.token}
              navigationRoute={this.props.routeSelection.navigationRoute}
              bridgeOpenings={this.props.routeSelection.bridgeOpenings}
              currentLocation={this.props.userLocation.currentLocation}
              activeMap={this.props.map.activeMap}
              onChangeActiveMap={this.handleChangeActiveMap}
              teqplayApiService={this.props.teqplayApiService}
              watchdogStatus={this.props.watchdogStatus}
              userHistory={this.props.userLocation.history}
              geoLocationWatcherState={this.props.geoLocationWatcherState}
              toggleOptions={this.toggleOptions}
              totalNotificationsCount={totalNotificationsCount}
            />

            <NavigationOptions
              active={this.state.optionsActive}
              navigationRoute={
                this.props.routeSelection.navigationRoute
                  ? this.props.routeSelection.navigationRoute.route
                  : null
              }
              toggleOptions={this.toggleOptions}
              shipNotificationStatus={this.state.shipNotificationStatus}
              VDJSNotificationMessage={this.props.VDJSNotificationMessage}
              channelApproachNotificationMessage={this.props.channelApproachNotificationMessage}
              teqplayApiService={this.props.teqplayApiService}
              pauseRoute={this.pauseRoute}
              resumeRoute={this.resumeRoute}
              stopRoute={this.stopRoute}
              isRoutePaused={isRoutePaused}
              bridgeOpenings={this.props.routeSelection.bridgeOpenings}
              currentLocation={this.props.userLocation.currentLocation}
              watchdogStatus={this.props.watchdogStatus}
              geoLocationWatcherState={this.props.geoLocationWatcherState}
              selectedTime={this.props.routeSelection.navigationRoute.selectionTime}
            />
          </>
        ) : (
          <LoadingIndicator loading={true} />
        )}
      </div>
    )
  }

  public handleChangeActiveMap = (activeMap: MapTypes) => {
    this.props.setActiveMap(activeMap)
  }

  public getNotificationCount = (): number => {
    const shipNotificationCount = this.state.shipNotificationStatus
      ? this.state.shipNotificationStatus.notifications.length
      : 0

    const VDJSNotificationCount = this.props.VDJSNotificationMessage ? 1 : 0
    const channelApproachCount = this.props.channelApproachNotificationMessage ? 1 : 0

    return shipNotificationCount + VDJSNotificationCount + channelApproachCount
  }

  public pauseRoute = () => {
    const activeRoute = this.props.routeSelection.navigationRoute
    if (activeRoute) {
      this.props.pauseCurrentRoute(this.props.teqplayApiService, activeRoute)
    }
  }

  public stopRoute = async () => {
    await this.props.stopCurrentRoute(this.props.teqplayApiService)
    this.props.history.push('/')
  }

  public resumeRoute = () => {
    const activeRoute = this.props.routeSelection.navigationRoute
    if (activeRoute) {
      this.props.resumeCurrentRoute(this.props.teqplayApiService, activeRoute)
    }
  }

  public fetchRouteEta = () => {
    if (this.isRouteActive() && this.props.routeSelection.navigationRoute) {
      const currentLocation = this.props.userLocation.currentLocation

      const currentShipLocation: IShipLocationUpdate | null = currentLocation
        ? {
            ...currentLocation,
            teqplayId: this.props.currentUser.teqplayId
          }
        : null

      this.props.updateRouteEta(
        this.props.teqplayApiService,
        this.props.routeSelection.navigationRoute,
        currentShipLocation,
        this.redirectToHomePage
      )
    }
  }

  public fetchBridgeOpenings = () => {
    this.props.updateBridgeOpenings(this.props.teqplayApiService)
  }

  public fetchActiveRoute = async () => {
    this.props.checkAndSetActiveRoute(this.props.teqplayApiService, this.redirectToHomePage)
  }

  public redirectToHomePage = () => {
    this.props.history.push('/')
  }

  public fetchShipNotificationStatus = async (getInitialNotificationStatus?: boolean) => {
    if (this.isRouteActive() || getInitialNotificationStatus === true) {
      try {
        const shipNotificationStatus =
          await this.props.teqplayApiService.getShipNotificationStatus()
        this.setState({ shipNotificationStatus })
      } catch (error) {
        // TODO: Handle error?
        return
      }
    }
  }

  public isRouteActive(): boolean {
    if (
      this.props.routeSelection &&
      this.props.routeSelection.navigationRoute &&
      !this.props.routeSelection.navigationRoute.paused
    ) {
      return true
    }

    return false
  }

  public setFetchIntervals() {
    const INTERVAL_ROUTE_ETA = 1000 * 30
    const INTERVAL_NOTIFICATIONS = 1000 * 120
    const INTERVAL_BRIDGE_OPENINGS = 1000 * 60

    this.intervalFetchETA = window.setInterval(this.fetchRouteEta, INTERVAL_ROUTE_ETA)
    this.intervalFetchNotifications = window.setInterval(
      this.fetchShipNotificationStatus,
      INTERVAL_NOTIFICATIONS
    )
    this.intervalFetchBridgeOpenings = window.setInterval(
      this.fetchBridgeOpenings,
      INTERVAL_BRIDGE_OPENINGS
    )
  }

  public clearFetchIntervals() {
    if (this.intervalFetchETA) {
      window.clearInterval(this.intervalFetchETA)
      this.intervalFetchETA = null
    }
    if (this.intervalFetchNotifications) {
      window.clearInterval(this.intervalFetchNotifications)
      this.intervalFetchNotifications = null
    }
    if (this.intervalFetchBridgeOpenings) {
      window.clearInterval(this.intervalFetchBridgeOpenings)
      this.intervalFetchBridgeOpenings = null
    }
  }

  public handleLogout = () => {
    this.props.teqplayApiService.logoutUser()
  }

  public toggleRouteList = () => {
    this.setState({ listViewActive: !this.state.listViewActive })
  }

  public toggleOptions = () => {
    this.setState({ optionsActive: !this.state.optionsActive })
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    clearAuth: () => dispatch(clearAuth()),
    setAuth: (user: IUserAuth) => dispatch(setAuth(user)),
    clearNavigationRoute: () => dispatch(clearNavigationRoute()),
    setNavigationRoute: (selectedRoute: ISelectedRoute) =>
      dispatch(setNavigationRoute(selectedRoute)),
    pauseCurrentRoute: (teqplayApiService: TeqplayApiService, selectedRoute: ISelectedRoute) =>
      pauseCurrentRoute(dispatch, teqplayApiService, selectedRoute),
    resumeCurrentRoute: (teqplayApiService: TeqplayApiService, selectedRoute: ISelectedRoute) =>
      resumeCurrentRoute(dispatch, teqplayApiService, selectedRoute),
    stopCurrentRoute: (teqplayApiService: TeqplayApiService) =>
      stopCurrentRoute(dispatch, teqplayApiService),
    checkAndSetActiveRoute: (teqplayApiService: TeqplayApiService, redirectToHome: () => void) =>
      checkAndSetActiveRoute(dispatch, teqplayApiService, redirectToHome),
    updateRouteEta: (
      teqplayApiService: TeqplayApiService,
      selectedRoute: ISelectedRoute,
      currentLocation: IShipLocationUpdate | null,
      redirectToHome: () => void
    ) =>
      updateRouteEta(dispatch, teqplayApiService, selectedRoute, currentLocation, redirectToHome),
    updateBridgeOpenings: (teqplayApiService: TeqplayApiService) =>
      updateBridgeOpenings(dispatch, teqplayApiService),
    setActiveMap: (activeMap: MapTypes) => dispatch(setActiveMap(activeMap))
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationPage))
