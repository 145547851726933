import * as MapboxGl from 'mapbox-gl'
import React, { useRef, useState } from 'react'
import { RotationControl } from 'react-mapbox-gl'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MapTypes } from '../../@types/types'
import { useOnClickOutside } from '../../customHooks/useOnClickOutside'
import { mapTypeOptions } from '../../utils/constants'
import { IFullPosition } from '../geoLocationWatcher/GeoLocationWatcherTypes'
import { formatLayerStateName } from '../../utils/general'
import { createAnonymousPopup } from '../shared/confirmPopup/ConfirmPopup'
import './MapBoxController.scss'

interface IMapboxController {
  mapRef: MapboxGl.Map | undefined
  followMode: boolean
  setFollowMode: (follow: boolean) => void
  heading: boolean
  setHeading: (heading: boolean) => void
  setCenter: (coordinates: [number, number] | undefined) => void
  setUserZoom: (zoom: number) => void
  userZoom: number
  currentLocation: IFullPosition | null
  activeMap: MapTypes
  setActiveMap: (map: MapTypes) => void
  activeLayers: string[]
  setActiveLayers: (item: string[]) => void
  rotation?: number | undefined
  isAnonymous: boolean
  routeNavigation?: boolean
}

const layersList = {
  categoryBerths: ['public_berths'],
  categoryFairway: [
    'CEMTClass',
    'fairwayDepth',
    'recreationalClass',
    'AISShips',
    'electricRoutes',
    'crossings',
    'fastRoutes'
  ],
  categoryElements: [
    'inlandHarbours',
    'chargingStations',
    'bridges',
    'kilometre',
    'trailerSlope',
    'wasteWaterStations',
    // 'nodes',
    'winterRestAreas'
  ]
}

const MapBoxController = ({
  mapRef,
  followMode,
  setFollowMode,
  heading,
  setHeading,
  setUserZoom,
  userZoom,
  currentLocation,
  activeMap,
  setActiveMap,
  activeLayers,
  setActiveLayers,
  isAnonymous,
  routeNavigation
}: IMapboxController) => {
  const [showLayer, setShowLayer] = useState<boolean>(false)
  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowLayer(false))
  const history = useHistory()

  return (
    <div ref={ref}>
      <span
        onClick={() =>
          mapRef?.getPitch() !== 0 && (mapRef?.flyTo({ pitch: 0, bearing: 0 }), setHeading(false))
        }
      >
        <RotationControl
          className={`custom-rotation-controll ${routeNavigation ? 'route-navigation' : ''}`}
          style={{ position: 'fixed' }}
        />
      </span>
      <div className="userzoom-wrapper">
        <button onClick={clickHeading} className={heading ? 'active' : ''}>
          <i className="icon-paper-plane" />
        </button>
        <button onClick={() => setShowLayer(!showLayer)}>
          <i className="icon-layers" />
        </button>
        <button onClick={clickFollowMode} className={followMode ? 'active' : ''}>
          <i className="icon-target-1" />
        </button>

        <div className="userzoom">
          <button onClick={() => setUserZoom(userZoom + 1)}>+</button>
          <button onClick={() => setUserZoom(userZoom - 1)}>-</button>
        </div>
      </div>
      {showLayer && (
        <div className="custom-layer-control">
          {Object.keys(layersList).map(
            categoryName =>
              layersList[categoryName].length > 0 && (
                <div className="category" key={categoryName}>
                  <div className="category-name">{I18n.t('map.category.' + categoryName)}</div>
                  <div className="category-buttons">
                    {layersList[categoryName].map((layer: string) => {
                      const translation = I18n.t(`map.${layer}`)
                      const formattedName = formatLayerStateName(translation)
                      return (
                        <button
                          key={layer}
                          className={`layer-button ${activeLayers.includes(layer) ? 'active' : ''}`}
                          name="formattedName"
                          onClick={() => setLayer(layer)}
                        >
                          <span className={`layer-icon ${getLayerIcon(layer)}`} />
                          <label htmlFor={formattedName}>{translation}</label>
                        </button>
                      )
                    })}
                  </div>
                </div>
              )
          )}
          <div className="map-types">
            <div className="category-name">{I18n.t('map.category.Map')}</div>
            {mapTypeOptions.map(mapType => (
              <div
                className={`map-type ${mapType === activeMap && 'active'} ${mapType}`}
                key={mapType}
                onClick={() => clickMapType(mapType)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )

  function clickFollowMode() {
    if (currentLocation && currentLocation.location) {
      setFollowMode(!followMode)
      setHeading(false)
      if (!followMode) {
        if (currentLocation && currentLocation.location) {
          const flyToSettings = {
            center: [currentLocation.location.longitude, currentLocation.location.latitude] as [
              number,
              number
            ],
            zoom: userZoom < 15 ? 15 : userZoom,
            pitch: 0
          }
          mapRef?.flyTo(
            currentLocation.courseOverGround
              ? { ...flyToSettings, ...{ bearing: currentLocation.courseOverGround } }
              : flyToSettings
          )
        }
      } else {
        mapRef?.flyTo({
          pitch: 0
        })
      }
    } else {
      toast.warn(I18n.t('navigationPage.no_user_location_warning'))
    }
  }

  function clickHeading() {
    if (currentLocation && currentLocation.location) {
      setFollowMode(false)
      setHeading(!heading)
      if (!heading) {
        if (currentLocation && currentLocation.location) {
          const flyToSettings = {
            center: [currentLocation.location.longitude, currentLocation.location.latitude] as [
              number,
              number
            ],
            zoom: userZoom < 17 ? 17 : userZoom,
            pitch: 100
          }
          mapRef?.flyTo(
            currentLocation.courseOverGround
              ? { ...flyToSettings, ...{ bearing: currentLocation.courseOverGround } }
              : flyToSettings
          )
        }
      } else {
        mapRef?.flyTo({
          pitch: 0
        })
      }
    } else {
      toast.warn(I18n.t('navigationPage.no_user_location_warning'))
    }
  }

  function clickMapType(mapType: MapTypes) {
    setActiveMap(mapType)
  }
  function setLayer(layer: string) {
    if (layer.includes('AISShips') && isAnonymous) {
      createAnonymousPopup(() => {
        history.push('login')
      })
      return
    }

    const singleLayer = ['CEMTClass', 'fairwayDepth', 'recreationalClass']
    if (!singleLayer.includes(layer)) {
      if (activeLayers.includes(layer)) {
        setActiveLayers(activeLayers.filter(item => item !== layer))
      } else {
        setActiveLayers([...activeLayers, layer])
      }
    } else {
      if (activeLayers.includes(layer)) {
        setActiveLayers(activeLayers.filter(item => item !== layer))
      } else {
        const clickedLayer = singleLayer.filter(e => e !== layer)
        setActiveLayers([...activeLayers.filter(item => !clickedLayer.includes(item)), layer])
      }
    }
  }

  function getLayerIcon(layerName: string) {
    switch (layerName) {
      case 'inlandHarbours':
        return 'icon-anchor'
      case 'crossings':
        return 'icon-anchor'
      case 'bridges':
        return 'icon-bridge-open'
      case 'chargingStations':
        return 'icon-charging-station'
      case 'kilometre':
        return 'icon-kilometer'
      case 'trailerSlope':
        return 'icon-slipway'
      case 'wasteWaterStations':
        return 'icon-droplet'
      case 'winterRestAreas':
        return 'icon-leaf'
      case 'nodes':
        return 'icon-sitemap'
      case 'AISShips':
        return 'icon-ship'
      case 'electricRoutes':
        return 'icon-flash-1'
      case 'public_berths':
        return 'icon-bolder'
      case 'CEMTClass':
        return 'icon-fairway'
      case 'fairwayDepth':
        return 'icon-waves'
      case 'recreationalClass':
        return 'icon-picture-2'
      case 'fastRoutes':
        return 'icon-gauge-1'
      default:
        return ''
    }
  }
}

export default MapBoxController
