import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { decodeRoutePolyline } from '../../../utils/decodeRoutePolyline'
import { IRouteSegment } from '../../../services/TeqplayApiService/TeqplayApi'

interface IProps {
  routeNetwork: IRouteSegment[]
}

/* Color for each Feature */
const CemtClassGet = [
  '0',
  '#8e8e8e',
  '1',
  '#023361',
  '2',
  '#038E47',
  '3',
  '#85BE41',
  '4',
  '#91BEDC',
  '4.1',
  '#D82332',
  '5.1',
  '#D82332',
  '5.2',
  '#9A297D',
  '6.1',
  '#9D6630',
  '6.2',
  '#EB8B2D',
  '6.3',
  '#F6BE5B',
  '#cecece'
]

const CemtClassLayer = (props: IProps) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: props.routeNetwork.map(line => {
          const latLng = decodeRoutePolyline(line)
          return {
            type: 'Feature',
            properties: { cemtClass: line.info.cemtClass?.toString() },
            geometry: {
              type: 'LineString',
              coordinates: latLng
            }
          }
        })
      }}
      linePaint={{
        'line-color': ['match', ['get', 'cemtClass'], ...CemtClassGet],
        'line-width': 3
      }}
    />
  )
}
export default CemtClassLayer
