import React from 'react'
import { I18n } from 'react-redux-i18n'

export function labelAndValue(label?: string, value?: any) {
  if (!value) {
    return null
  }
  return (
    <div className="label-value-wrapper">
      <label>{label}</label>
      <span>{value === true ? I18n.t('yes') : value === false ? I18n.t('no') : value}</span>
    </div>
  )
}
