import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import { IWaterMeterDetails } from '../../services/TeqplayApiService/TeqplayApi'
import LoadingIndicator from '../loadingIndicator/LoadingIndicator'

interface IProps {
  itemDetails: IWaterMeterDetails | null
  isExpanded: boolean
}

const WaterMeterDetails: React.FunctionComponent<IProps> = (props: IProps) => {
  const [isLoading, setLoading] = React.useState(true)

  const itemDetails = props.itemDetails

  if (!itemDetails || !props.isExpanded) {
    return null
  }

  return (
    <div className="item-info-details">
      <div className="route-item-caption">
        {isLoading && (
          <div>
            <LoadingIndicator loading={true} />
          </div>
        )}
        <div
          className="watermeter"
          onClick={() => {
            window.open(itemDetails.linkInteractiveGraph, '_system')
          }}
        >
          <img
            src={itemDetails.linkGraphImg}
            alt={I18n.t('routeList.itemDetails.waterLevel')}
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default WaterMeterDetails
