import { IExtendedShip } from '../../../../../@types/types'

export function detailedShipToGeoJSONPoints(elements: IExtendedShip[]): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: elements.map(ship => ({
      type: 'Feature',
      properties: ship.properties,
      geometry: {
        type: 'Point',
        coordinates: [
          ship.location?.longitude || ship.location?.coordinates?.[0] || 0,
          ship.location?.latitude || ship.location?.coordinates?.[1] || 0
        ]
      }
    }))
  }
}
