import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, setLocale } from 'react-redux-i18n'
import { IRootProps } from '../../@types/types'
import { saveToLocalStorage } from '../../utils/localStorage'

import './LocalePicker.scss'

interface IProps {
  alwaysSelecting?: boolean
  locale?: string
}

const LocalePicker = ({ alwaysSelecting }: IProps) => {
  const locale = useSelector((s: IRootProps) => s.i18n.locale)
  const [selectingLocale, setSelectingLocale] = useState(alwaysSelecting || false)
  const selfRef = useRef<any>(null)
  const dispatch = useDispatch()
  const allLocales = ['nl_NL', 'frl_NL', 'en_GB', 'de_DE'].filter(l => l !== locale)

  // Detect outside check
  React.useEffect(() => {
    function handleOutsideCheck(e: any) {
      if (selfRef?.current?.contains(e.target)) {
        return
      } else if (selectingLocale) {
        setSelectingLocale(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideCheck, false)
  }, [selectingLocale])

  return (
    <div className={`locale-wrapper ${selectingLocale ? 'selecting' : ''}`} ref={selfRef}>
      <div className="locale-item current" onClick={toggleLocale}>
        <div className={`locale-flag ${locale}`} />
        <div className="locale-name">{I18n.t(`locale.${locale}`)}</div>
        <i
          className={
            !alwaysSelecting
              ? selectingLocale
                ? `icon-angle-down ${selectingLocale ? 'rotated' : ''}`
                : ''
              : ''
          }
        />
      </div>

      {(selectingLocale || alwaysSelecting) && (
        <div className={`drop-down-menu${alwaysSelecting ? ' persisted' : ''}`}>
          {allLocales.map(loc => (
            <div key={loc} className="locale-item" onClick={e => selectLocale(e, loc)}>
              <div className={`locale-flag ${loc}`} />
              <div className="locale-name">{I18n.t(`locale.${loc}_native`)}</div>
              <i />
            </div>
          ))}
        </div>
      )}
    </div>
  )

  function toggleLocale(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault()
    e.stopPropagation()

    setSelectingLocale(!selectingLocale)
  }

  function selectLocale(e: React.MouseEvent<HTMLDivElement, MouseEvent>, loc: string) {
    e.preventDefault()
    e.stopPropagation()

    setSelectingLocale(false)
    dispatch(setLocale(loc))
    saveToLocalStorage('locale', loc)
  }
}

export default LocalePicker
