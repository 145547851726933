import { SpeedTypes } from '../../../services/TeqplayApiService/TeqplayApi'

export function setSpeedType(speedType: SpeedTypes) {
  return {
    type: 'SET_SPEED_TYPE',
    speedType
  }
}

export function setUserInputSpeed(userInputSpeed: number) {
  return {
    type: 'SET_USER_INPUT_SPEED',
    userInputSpeed
  }
}
