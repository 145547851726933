import BackgroundGeolocation, { ProviderChangeEvent } from 'cordova-background-geolocation-lt'
import { cloneDeep } from 'lodash'
import React from 'react'

import { IExtendedPropertyLocation } from '../../../@types/types'
import { ISelectedRoute } from '../../../services/TeqplayApiService/TeqplayApi'
import { isCordovaApp } from '../../../utils/cordovaUtils'
import { IFullPosition } from '../GeoLocationWatcherTypes'

interface IProps {
  enabled: boolean
  latestPosition: IFullPosition | null
  activeRoute: ISelectedRoute | null
  userHistory: IExtendedPropertyLocation[]
  setUserHistory: (history: IExtendedPropertyLocation[]) => void
  sessionID: string
}

const TripMonitor = ({
  enabled,
  activeRoute,
  latestPosition,
  userHistory,
  setUserHistory,
  sessionID
}: IProps) => {
  useTrackUserPosition(enabled, latestPosition, activeRoute, userHistory, setUserHistory, sessionID)

  return null
}

function useTrackUserPosition(
  enabled: boolean,
  position: IFullPosition | null,
  activeRoute: ISelectedRoute | null,
  userHistory: IExtendedPropertyLocation[],
  setUserHistory: (history: IExtendedPropertyLocation[]) => void,
  sessionID: string
) {
  const userPositions = cloneDeep(userHistory)

  React.useEffect(() => {
    async function handleProcessLocation() {
      let permissions: null | ProviderChangeEvent = null

      if (isCordovaApp) {
        permissions = await BackgroundGeolocation.getProviderState()
      }

      if (position !== null) {
        userPositions.push({
          position,
          activeRoute: activeRoute && {
            ...activeRoute,
            route: { ...activeRoute.route, routeItems: [] }
          },
          permissions,
          processTime: new Date().valueOf(),
          sessionID
        })
        setUserHistory(userPositions)
      }
    }

    if (enabled) {
      handleProcessLocation()
    }
  }, [enabled, position, activeRoute, userPositions, setUserHistory, sessionID])

  return userPositions
}

export default TripMonitor
