import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { IRoute, IStoredRoute } from '../../../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../../../services/TeqplayApiService/TeqplayApiService'
import DefaultTransition from '../../../shared/defaultTransition/DefaultTransition'
import SavedRoute from './savedRoute/SavedRoute'
import './SavedRoutesList.scss'

interface IProps {
  teqplayApiService: TeqplayApiService
  startRoute: (route: IRoute) => void
}

interface IState {
  savedRoutes: IStoredRoute[]
  showSavedRoutes: boolean
}

export default class SavedRoutesList extends React.PureComponent<IProps> {
  public readonly state: Readonly<IState> = {
    savedRoutes: [],
    showSavedRoutes: false
  }

  public componentDidMount() {
    this.fetchSavedRoutes()
  }

  public render() {
    if (!this.state.savedRoutes.length) {
      return null
    }

    return (
      <div className="saved-routes">
        <button
          className="button large secondary saved-routes-button"
          onClick={this.toggleShowSavedRoutes}
        >
          {I18n.t(
            `routeSelection.${this.state.showSavedRoutes ? 'hideSavedRoutes' : 'showSavedRoutes'}`
          )}
        </button>

        <DefaultTransition active={this.state.showSavedRoutes}>
          {style => (
            <ul style={style} className="saved-routes-wrapper">
              {this.state.savedRoutes
                .sort((a, b) => (a.date > b.date ? -1 : 1))
                .map(storedRoute => (
                  <SavedRoute
                    key={storedRoute.route._id}
                    route={storedRoute.route}
                    date={storedRoute.date}
                    name={storedRoute.key}
                    startRoute={this.props.startRoute}
                    deleteRoute={() => this.deleteStoredRoute(storedRoute)}
                  />
                ))}
            </ul>
          )}
        </DefaultTransition>
      </div>
    )
  }

  private toggleShowSavedRoutes = () => {
    if (this.state.savedRoutes.length) {
      this.setState({ showSavedRoutes: !this.state.showSavedRoutes })
    }
  }

  private fetchSavedRoutes = async () => {
    try {
      const savedRoutes = await this.props.teqplayApiService.getStoredRoutes()
      if (savedRoutes) {
        this.setState({ savedRoutes })
      }
    } catch (err) {
      console.error(err)
    }
  }

  private deleteStoredRoute = async (route: IStoredRoute) => {
    try {
      await this.props.teqplayApiService.deleteStoredRoute(route.key)
      this.fetchSavedRoutes()
    } catch (error) {
      console.error(error)
      toast.error(I18n.t('generalError'))
    }
  }
}
