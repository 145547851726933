import { ISegmentInfo } from '../../services/TeqplayApiService/TeqplayApi'

export const CEMT_CLASS_VALUES: ISegmentInfo['cemtClass'][] = [
  0, 1, 2, 3, 4, 4.1, 5.1, 5.2, 6.1, 6.2, 6.3
]

export function getCemtLegendValues(cemtClass?: ISegmentInfo['cemtClass']): {
  displayName: string
  color: string
} {
  switch (cemtClass) {
    case 0:
      return {
        displayName: '0',
        color: getCemtClassColor(0)
      }
    case 1:
      return {
        displayName: 'I',
        color: getCemtClassColor(1)
      }
    case 2:
      return {
        displayName: 'II',
        color: getCemtClassColor(2)
      }
    case 3:
      return {
        displayName: 'III',
        color: getCemtClassColor(3)
      }
    case 4:
      return {
        displayName: 'IV',
        color: getCemtClassColor(4)
      }
    case 4.1:
      return {
        displayName: 'IVa',
        color: getCemtClassColor(4.1)
      }
    case 5.1:
      return {
        displayName: 'Va',
        color: getCemtClassColor(5.1)
      }
    case 5.2:
      return {
        displayName: 'Vb',
        color: getCemtClassColor(5.2)
      }
    case 6.1:
      return {
        displayName: 'VIa',
        color: getCemtClassColor(6.1)
      }
    case 6.2:
      return {
        displayName: 'VIb',
        color: getCemtClassColor(6.2)
      }
    case 6.3:
      return {
        displayName: 'VIc',
        color: getCemtClassColor(6.3)
      }
    default:
      return {
        displayName: '-',
        color: getCemtClassColor()
      }
  }
}

export function getDepthLegendValues(): { displayName: string; color: string }[] {
  return [
    {
      displayName: '-',
      color: getDepthColor(0)
    },
    {
      displayName: '< 0.8m',
      color: getDepthColor(0.8)
    },
    {
      displayName: '< 1.0m',
      color: getDepthColor(1)
    },
    {
      displayName: '< 1.2m',
      color: getDepthColor(1.2)
    },
    {
      displayName: '< 1.4m',
      color: getDepthColor(1.4)
    },
    {
      displayName: '< 1.6m',
      color: getDepthColor(1.6)
    },
    {
      displayName: '< 1.8m',
      color: getDepthColor(1.8)
    },
    {
      displayName: '< 2.0m',
      color: getDepthColor(2.0)
    },
    {
      displayName: '< 2.5m',
      color: getDepthColor(2.5)
    },
    {
      displayName: '+ 3.0m',
      color: getDepthColor(3)
    }
  ]
}

export function getRecreationalClassLegendValues(): { displayName: string; color: string }[] {
  return [
    {
      displayName: 'f',
      color: getRecreationalColor('f')
    },
    {
      displayName: 'e',
      color: getRecreationalColor('e')
    },
    {
      displayName: 'dm',
      color: getRecreationalColor('dm')
    },
    {
      displayName: 'cm',
      color: getRecreationalColor('cm')
    },
    {
      displayName: 'czm', // E
      color: getRecreationalColor('czm')
    },
    {
      displayName: 'bzm',
      color: getRecreationalColor('bzm')
    },
    {
      displayName: 'azm',
      color: getRecreationalColor('azm')
    },
    {
      displayName: '-',
      color: getRecreationalColor(null)
    }
  ]
}

// am eruit

export function getCemtClassColor(cemtClass?: ISegmentInfo['cemtClass']) {
  switch (cemtClass) {
    case 0:
      return '#8e8e8e'
    case 1:
      return '#023361'
    case 2:
      return '#038E47'
    case 3:
      return '#85BE41'
    case 4:
      return '#91BEDC'
    case 4.1:
      return '#5ca8db'
    case 5.1:
      return '#D82332'
    case 5.2:
      return '#9A297D'
    case 6.1:
      return '#9D6630'
    case 6.2:
      return '#EB8B2D'
    case 6.3:
      return '#F6BE5B'
    default:
      return '#cecece'
  }
}

export function getDepthColor(depth?: ISegmentInfo['minimumDepth']) {
  // Depth value is the negative depth but then positive
  // i.e.: Fairway depth is -4m, then value is 4m

  if (depth && depth >= 0) {
    if (depth >= 3) {
      return '#488f31'
    } else if (depth >= 2.5) {
      return '#45a074'
    } else if (depth >= 2.0) {
      return '#72b97c'
    } else if (depth >= 1.8) {
      return '#88c580'
    } else if (depth >= 1.6) {
      return '#c2e38c'
    } else if (depth >= 1.4) {
      return '#fdd172'
    } else if (depth >= 1.2) {
      return '#f7a258'
    } else if (depth >= 1.0) {
      return '#ea714e'
    } else if (depth >= 0.8) {
      return '#de425b'
    } else {
      return '#cecece'
    }
  } else {
    return '#cecece'
  }
}

export function getRecreationalColor(
  recClassName: 'f' | 'e' | 'dm' | 'cm' | 'czm' | 'bzm' | 'azm' | null
): string {
  switch (recClassName) {
    case 'f':
      return '#9A297D'
    case 'e':
      return '#5ca8db'
    case 'dm':
      return '#85BE41'
    case 'cm':
      return '#038E47'
    case 'czm':
      return '#023361'
    case 'bzm':
      return '#91BEDC'
    case 'azm':
      return '#D82332'
    default:
      return '#cecece'
  }
}

export function getSegmentRecreationalColor(segmentInfo: ISegmentInfo) {
  if (segmentInfo.f) {
    return getRecreationalColor('f')
  } else if (segmentInfo.e) {
    return getRecreationalColor('e')
  } else if (segmentInfo.dm) {
    return getRecreationalColor('dm')
  } else if (segmentInfo.cm) {
    return getRecreationalColor('cm')
  } else if (segmentInfo.czm) {
    return getRecreationalColor('czm')
  } else if (segmentInfo.bzm) {
    return getRecreationalColor('bzm')
  } else if (segmentInfo.azm) {
    return getRecreationalColor('azm')
  } else {
    return getRecreationalColor(null)
  }
}
