import * as React from 'react'
import './LabelWithValue.scss'

interface IProps {
  label: string
  value?: string | number
}

const LabelWithValue: React.FunctionComponent<IProps> = props => (
  <div className="key-value">
    <label>{props.label}</label>
    <span>{props.value}</span>
  </div>
)

export default LabelWithValue
