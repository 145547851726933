import { differenceInHours } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import React, { useState } from 'react'
import Popup from 'react-popup'
import { I18n } from 'react-redux-i18n'

import { BridgeRequestResponse, IUserBridgeRequest } from '../../@types/types'
import {
  IBridgeDetails,
  IBridgeOpeningInfo,
  IRouteItem,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { isCordovaApp } from '../../utils/cordovaUtils'
import { formatTime } from '../../utils/dates'
import { isUserInBridgeRadius, sortByKey } from '../../utils/general'
import { loadFromSessionStorage, saveToSessionStorage } from '../../utils/localStorage'
import { IFullPosition, ILocationPermissions } from '../geoLocationWatcher/GeoLocationWatcherTypes'

import './BridgeOpeningRequests.scss'

interface IProps {
  bridgeRouteItem: IRouteItem | IBridgeDetails
  bridgeOpeningInfo?: IBridgeOpeningInfo
  teqplayApiService: TeqplayApiService
  currentLocation: IFullPosition | null
  isInsidePopup?: boolean
  isInsideRouteItem?: boolean
  watchdogStatus: IWatchDogStatus
  locationPermissions: ILocationPermissions
}

const BRIDGE_STORAGE_KEY = 'FRL-PreviousBridgeOpeningRequests'
const BridgeOpeningRequests: React.FunctionComponent<IProps> = ({
  bridgeRouteItem,
  bridgeOpeningInfo,
  teqplayApiService,
  currentLocation,
  isInsidePopup,
  watchdogStatus,
  isInsideRouteItem,
  locationPermissions
}) => {
  const [bridgeOpeningRequests, setBridgeOpeningRequests] = useState<IUserBridgeRequest[]>(
    loadFromSessionStorage<IUserBridgeRequest[]>(BRIDGE_STORAGE_KEY, [])
  )

  if (!watchdogStatus.enabled) {
    return null
  }

  if ((isRouteItem(bridgeRouteItem) && bridgeRouteItem.type !== 'BRIDGE') || !bridgeOpeningInfo) {
    return null
  }

  if (!bridgeOpeningInfo.isActive) {
    return null
  }

  if (!isCordovaApp) {
    // Notice for users with no App
    return (
      <div
        className={`bridge-request-preview ${isInsidePopup ? 'in-popup' : ''} ${
          isInsideRouteItem ? 'in-routeItem' : ''
        }`}
      >
        {I18n.t('bridgeOpeningRequests.downloadApp')}
      </div>
    )
  }

  if (locationPermissions.approved !== true) {
    return (
      <div className="bridge-request-preview unapproved">
        <span>{I18n.t('bridgeOpeningRequests.needApproval')}</span>
        <button onClick={() => locationPermissions.retry()}>
          {I18n.t('bridgeOpeningRequests.retry')}
        </button>
      </div>
    )
  }

  if (!watchdogStatus.healthy) {
    return (
      <div
        className={`bridge-request-preview unhealthy ${isInsidePopup ? 'in-popup' : ''} ${
          isInsideRouteItem ? 'in-routeItem' : ''
        }`}
      >
        {I18n.t('bridgeOpeningRequests.notHealthy')}
      </div>
    )
  }

  const closeEnoughForBridgeOpening = isUserInBridgeRequestRange(bridgeOpeningInfo, bridgeRouteItem)
  const previousRequests = sortByKey<IUserBridgeRequest>(
    bridgeOpeningRequests
      .filter(req => req.isrsId === bridgeOpeningInfo.isrsId)
      .filter(req => differenceInHours(new Date().valueOf(), req.time) <= 1),
    'time',
    'desc'
  )

  if (!closeEnoughForBridgeOpening) {
    return (
      <div
        className={`bridge-request too-far ${isInsidePopup ? 'in-popup' : ''} ${
          isInsideRouteItem ? 'in-routeItem' : ''
        }`}
      >
        <h3>{I18n.t('bridgeOpeningRequests.tooFar')}</h3>
        {previousRequests.length > 0 && (
          <div className="previous-requests">
            {I18n.t('bridgeOpeningRequests.previousRequest', {
              time: formatTime(previousRequests[0].time),
              amount: previousRequests.length
            })}
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={`bridge-request ${isInsidePopup ? 'in-popup' : ''} ${
        isInsideRouteItem ? 'in-routeItem' : ''
      }`}
    >
      <div className="button-wrapper">
        {!isInsideRouteItem && <h3>{I18n.t('bridgeOpeningRequests.canOpen')}</h3>}
        {closeEnoughForBridgeOpening === true && (
          <div className="request-button-wrapper">
            <button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                requestBridgeOpening(bridgeOpeningInfo.isrsId)
              }}
            >
              {I18n.t('bridgeOpeningRequests.applyForRequest')} <span className="icon-comment" />
            </button>
          </div>
        )}
      </div>
      {previousRequests.length > 0 && (
        <div className="previous-requests">
          {I18n.t('bridgeOpeningRequests.previousRequest', {
            time: formatTime(previousRequests[0].time),
            amount: previousRequests.length
          })}
        </div>
      )}
    </div>
  )

  function isUserInBridgeRequestRange(
    boi: IBridgeOpeningInfo,
    routeItem: IRouteItem | IBridgeDetails
  ): boolean {
    const currentUserLocation =
      currentLocation && currentLocation.location ? currentLocation.location : null
    const routeItemCoords = routeItem.singleLocation.coordinates
      ? (cloneDeep(routeItem.singleLocation.coordinates).reverse() as [number, number])
      : ([0, 0] as [number, number])
    const inBridgeRadius = isUserInBridgeRadius(boi, currentUserLocation, routeItemCoords)
    return inBridgeRadius
  }

  async function requestBridgeOpening(isrsId: IBridgeOpeningInfo['isrsId']) {
    try {
      const res: BridgeRequestResponse = await teqplayApiService.requestBridgeOpening(isrsId)

      if (res === 'ACCEPT') {
        appendNewBridgeOpeningRequest(isrsId)
        Popup.create({
          title: I18n.t('bridgeOpeningRequests.responseTitle.ACCEPT'),
          content: (
            <div className="bridge-opening-response-wrapper">
              <div className="line">{I18n.t('bridgeOpeningRequests.response.ACCEPT')}</div>
              <div className="line">{I18n.t('bridgeOpeningRequests.onlyOnce')}</div>
            </div>
          ),
          buttons: {
            right: [
              {
                text: I18n.t('pushNotification.buttonClose'),
                className: 'button large primary',
                action() {
                  Popup.close()
                }
              }
            ]
          }
        })
      } else {
        Popup.create({
          title: I18n.t(`bridgeOpeningRequests.responseTitle.${res}`),
          className: 'bridge-request',
          content: (
            <div className="bridge-opening-response-wrapper">
              <div className="line">{I18n.t(`bridgeOpeningRequests.response.${res}`)}</div>
            </div>
          ),
          buttons: {
            left: [
              {
                text: (
                  <React.Fragment>
                    <i className="icon-twitter" />
                    {I18n.t('bridgeOpeningRequests.viewTwitter')}
                  </React.Fragment>
                ),
                className: 'button large',
                action() {
                  window.open('https://twitter.com/ScheepvaartFRL', '_system')
                }
              }
            ],
            right: [
              {
                text: I18n.t('pushNotification.buttonClose'),
                className: 'button large primary',
                action() {
                  Popup.close()
                }
              }
            ]
          }
        })
      }
    } catch (error) {
      console.error(error)
      Popup.create({
        title: I18n.t('bridgeOpeningRequests.response.TECH_ERROR'),
        className: 'bridge-request',
        content: (
          <div className="bridge-opening-response-wrapper">
            <div className="line">{I18n.t(`bridgeOpeningRequests.error`)}</div>
          </div>
        ),
        buttons: {
          left: [
            {
              text: I18n.t('bridgeOpeningRequests.viewTwitter'),
              className: 'button large',
              action() {
                window.open('https://twitter.com/ScheepvaartFRL', '_system')
              }
            }
          ],
          right: [
            {
              text: I18n.t('pushNotification.buttonClose'),
              className: 'button large primary',
              action() {
                Popup.close()
              }
            }
          ]
        }
      })
    }
  }

  function appendNewBridgeOpeningRequest(isrsId: IBridgeOpeningInfo['isrsId']) {
    const now = new Date().valueOf()
    const filteredOld = bridgeOpeningRequests.filter(req => differenceInHours(now, req.time) <= 1)

    const newBridgeOpeningRequests: IUserBridgeRequest[] = [
      ...filteredOld,
      {
        isrsId,
        time: new Date().valueOf()
      }
    ]

    setBridgeOpeningRequests(newBridgeOpeningRequests)
    saveToSessionStorage(BRIDGE_STORAGE_KEY, newBridgeOpeningRequests)
  }

  function isRouteItem(item: IRouteItem | IBridgeDetails): item is IRouteItem {
    return (item as IRouteItem).type !== undefined
  }
}

export default React.memo(BridgeOpeningRequests)
