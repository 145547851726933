import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import * as React from 'react'
import Modal from 'react-modal'
import { connect, MapDispatchToPropsFunction } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator'
import RouteSelect from '../../components/routeSelect/RouteSelect'

import { IRootProps, IRouteSearchProps, MapTypes, RouteSelectionFields } from '../../@types/types'
import {
  IFullPosition,
  IGeoLocationWatcherState
} from '../../components/geoLocationWatcher/GeoLocationWatcherTypes'
import { setActiveMap } from '../../components/mapShared/actions'
import { setUserInputSpeed } from '../../components/mapShared/setSpeed/actions'
import {
  IRoute,
  IRouteLocation,
  ISelectedRoute,
  IUserAuth,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { customModalStyles } from '../../utils/constants'
import { clearAuth, setAuth } from '../loginPage/actions'
import { clearNavigationRoute, setNavigationRoute, updateBridgeOpenings } from './actions'

import MapRouteSelection from '../../components/mapRouteSelection/MapRouteSelection'
import { createAnonymousPopup } from '../../components/shared/confirmPopup/ConfirmPopup'
import { IAuthenticationWrapper } from '../../services/AuthenticationWrapper/AuthenticationWrapper'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { INavigationLocationNode } from '../../@types/types'

import './RouteSelectionPage.scss'

interface IDispatchProps {
  clearAuth: () => void
  setAuth: (userAuth: IUserAuth) => void
  setNavigationRoute: (route: ISelectedRoute) => void
  clearNavigationRoute: () => void
  setUserInputSpeed: (speed: number) => void
  setActiveMap: (activeMap: MapTypes) => void
  updateBridgeOpenings: (teqplayApiService: TeqplayApiService) => void
}

interface IProps {
  currentLocation: IFullPosition | null
  isAnonymous: boolean
  watchdogStatus: IWatchDogStatus
  geoLocationWatcherState: IGeoLocationWatcherState
}

interface IState {
  loading: boolean
  routeSelectionFormActive: boolean
  routeSelectionFormFieldActive: boolean
  selectedField: RouteSelectionFields | null
  selectedViaRouteIndex: number | null
  routeSelection: IRouteSearchProps
  navigationLocations: INavigationLocationNode[]
  hideMapControlButtons?: boolean
  departureTime: number
  requestUseActiveRoute: boolean
  selectedRouteSuggestion: IRoute | null
  routeSuggestions: IRoute[] | null
  cruiseSpeed: number
}

class RouteSelectionPage extends React.Component<
  IProps & IRootProps & IDispatchProps & RouteComponentProps<null> & IAuthenticationWrapper,
  IState
> {
  private intervalFetchBridgeOpenings: number | null = null

  public readonly state: Readonly<IState> = {
    loading: false,

    routeSelectionFormActive: false,
    routeSelectionFormFieldActive: false,

    selectedField: null,
    selectedViaRouteIndex: null,
    navigationLocations: [],
    routeSelection: {
      fromLocation: null,
      toLocation: null,
      viaRoutes: []
    },

    departureTime: new Date().getTime(),

    selectedRouteSuggestion: null,
    routeSuggestions: null,
    requestUseActiveRoute: false,
    cruiseSpeed: loadFromLocalStorage('WATERSPORT-cruiseSpeed', 4.32) // knots
  }

  public componentDidMount() {
    if (!this.props.isAnonymous) {
      this.fetchAllNavigationLocations()
      this.checkActiveRoutes()
    }

    this.setFetchIntervals()
    this.fetchBridgeOpenings()
  }

  public componentWillUnmount() {
    this.clearFetchIntervals()
  }

  public render() {
    if (!this.props.currentUser || !this.props.currentUser.token) {
      return <Redirect to="/login" push />
    }

    return (
      <div className="page-wrapper">
        <LoadingIndicator loading={this.state.loading} />
        <Modal
          isOpen={this.state.requestUseActiveRoute}
          style={customModalStyles}
          className="active-route-modal modal"
        >
          <p>{I18n.t('modalSelectPreviousRoute.question')}</p>
          <button
            className="button primary large activate-button"
            onClick={() => {
              this.props.history.push('/navigation')
            }}
          >
            {I18n.t('modalSelectPreviousRoute.yes')}
          </button>
          <button
            className="button large empty border-gray close-button"
            onClick={() => {
              this.setState({ requestUseActiveRoute: false })
              this.props.teqplayApiService.stopRoute()
              this.props.clearNavigationRoute()
            }}
          >
            {I18n.t('modalSelectPreviousRoute.no')}
          </button>
        </Modal>

        <RouteSelect
          // field values
          routeSelection={this.state.routeSelection}
          routeSelectionFormActive={this.state.routeSelectionFormActive}
          routeSelectionFormFieldActive={this.state.routeSelectionFormFieldActive}
          selectedField={this.state.selectedField}
          selectedViaRouteIndex={this.state.selectedViaRouteIndex}
          selectedRouteSuggestion={this.state.selectedRouteSuggestion}
          navigationLocations={this.state.navigationLocations}
          routeSuggestions={this.state.routeSuggestions}
          teqplayApiService={this.props.teqplayApiService}
          currentLocation={this.props.currentLocation}
          departureTime={this.state.departureTime}
          // UI interactions
          searchAvailableRoutes={this.searchAvailableRoutes}
          setrouteSelectionFormActive={this.setrouteSelectionFormActive}
          setRouteSelectionInactive={this.setRouteSelectionInactive}
          setrouteSelectionFormFieldActive={this.setrouteSelectionFormFieldActive}
          setRouteSelectionFieldInactive={this.setRouteSelectionFieldInactive}
          resetAvailableRoutes={this.resetAvailableRoutes}
          // setting fields
          setFromLocation={this.setFromLocation}
          setToLocation={this.setToLocation}
          setViaRoutes={this.setViaRoutes}
          setSelectedRouteSuggestion={this.setSelectedRouteSuggestion}
          setDepartureTime={this.setDepartureTime}
          startRoute={this.startRoute}
          // Menu
          userName={this.props.currentUser.userName}
          activePage={this.props.location.pathname}
          logout={this.handleLogout}
          locale={this.props.i18n.locale}
          cruiseSpeed={this.state.cruiseSpeed}
          setCruiseSpeed={this.handleSetCruiseSpeed}
          locationPermissions={this.props.geoLocationWatcherState.locationPermissions}
        />
        <MapRouteSelection
          currentLocation={this.props.currentLocation}
          routeSelection={this.state.routeSelection}
          selectedField={this.state.selectedField}
          selectedViaRouteIndex={this.state.selectedViaRouteIndex}
          routeSelectionFormActive={this.state.routeSelectionFormActive}
          routeSelectionFormFieldActive={this.state.routeSelectionFormFieldActive}
          setFromLocation={this.setFromLocation}
          setToLocation={this.setToLocation}
          setViaRoutes={this.setViaRoutes}
          routeSuggestions={this.state.routeSuggestions}
          activeMap={this.props.map.activeMap}
          onChangeActiveMap={this.handleChangeActiveMap}
          selectedRouteSuggestion={this.state.selectedRouteSuggestion}
          hideControlButtons={this.state.hideMapControlButtons}
          setSelectedRouteSuggestion={this.setSelectedRouteSuggestion}
          teqplayApiService={this.props.teqplayApiService}
          bridgeOpenings={this.props.routeSelection.bridgeOpenings}
          watchdogStatus={this.props.watchdogStatus}
          userHistory={this.props.userLocation.history}
          geoLocationWatcherState={this.props.geoLocationWatcherState}
        />
      </div>
    )
  }

  public setFetchIntervals() {
    const INTERVAL_BRIDGE_OPENINGS = 1000 * 60

    this.intervalFetchBridgeOpenings = window.setInterval(
      this.fetchBridgeOpenings,
      INTERVAL_BRIDGE_OPENINGS
    )
  }

  public clearFetchIntervals() {
    if (this.intervalFetchBridgeOpenings) {
      window.clearInterval(this.intervalFetchBridgeOpenings)
      this.intervalFetchBridgeOpenings = null
    }
  }

  public fetchBridgeOpenings = () => {
    this.props.updateBridgeOpenings(this.props.teqplayApiService)
  }

  public setFromLocation = (fromLocation: IRouteLocation | null) => {
    const routeSelection = cloneDeep(this.state.routeSelection)
    routeSelection.fromLocation = fromLocation
    this.setState({ routeSelection, routeSelectionFormFieldActive: false })
  }

  public setToLocation = (toLocation: IRouteLocation | null) => {
    const routeSelection = cloneDeep(this.state.routeSelection)
    routeSelection.toLocation = toLocation
    this.setState({ routeSelection, routeSelectionFormFieldActive: false })
  }

  public setViaRoutes = (viaRoutes: IRouteLocation[]) => {
    const routeSelection = cloneDeep(this.state.routeSelection)
    routeSelection.viaRoutes = viaRoutes
    this.setState({
      routeSelection,
      routeSelectionFormFieldActive: false,
      selectedViaRouteIndex: null
    })
  }

  public setrouteSelectionFormActive = () => {
    if (!this.props.teqplayApiService.isAnonymousUser()) {
      this.setState({ routeSelectionFormActive: true, hideMapControlButtons: true })
    } else {
      // User is anonymous, open up register popup
      createAnonymousPopup(() => {
        this.handleLogout()
        this.props.history.push('/register' + this.props.location.search)
      })
    }
  }

  public setRouteSelectionInactive = () => {
    this.setState({ routeSelectionFormActive: false, hideMapControlButtons: false })
  }

  public handleChangeActiveMap = (activeMap: MapTypes) => {
    this.props.setActiveMap(activeMap)
  }

  public setrouteSelectionFormFieldActive = (
    fieldName: RouteSelectionFields,
    viaRouteIndex?: number
  ) => {
    if (fieldName === 'VIA_ROUTES' && viaRouteIndex !== undefined) {
      this.setState({
        routeSelectionFormFieldActive: true,
        selectedField: fieldName,
        selectedViaRouteIndex: viaRouteIndex
      })
    }
    this.setState({ routeSelectionFormFieldActive: true, selectedField: fieldName })
  }

  public setRouteSelectionFieldInactive = () => {
    this.setState({ routeSelectionFormFieldActive: false, selectedField: null })
  }

  public resetAvailableRoutes = () => {
    this.setState({
      routeSuggestions: null,
      routeSelectionFormActive: true
    })
  }

  public setSelectedRouteSuggestion = (routeSuggestion: IRoute | null) => {
    this.setState({
      selectedRouteSuggestion: routeSuggestion
    })
  }

  public setDepartureTime = (arrivalDate: Date) => {
    this.setState({ departureTime: arrivalDate.getTime() })
  }

  public startRoute = async (route: IRoute) => {
    try {
      const activeRoute = await this.props.teqplayApiService.setUsersActiveRoute(
        route,
        this.state.cruiseSpeed
      )
      // Set speed returned from the backend to redux
      if (activeRoute.cruiseSpeed) {
        this.props.setUserInputSpeed(activeRoute.cruiseSpeed)
      }

      this.props.setNavigationRoute(activeRoute)
      this.props.history.push('/navigation')
    } catch (error) {
      // this error returns if the user has never had their GPS enabled -> so we don't have a location for them in the backend.
      // The backend then returns an error indicating that it doesn't know anyhting about the ship.
      // ""Ship has no location, can't start without""
      if (error.status === 412) {
        toast.warn(I18n.t('routeSelection.noLocationWarning'))
      }
    }
  }

  public searchAvailableRoutes = async () => {
    const routeSelection = this.state.routeSelection
    // uses the routeSelection fromLocation, otherwise it tries to use the users current location.
    const fromLocationCoords =
      routeSelection.fromLocation && routeSelection.fromLocation.coordinates
        ? routeSelection.fromLocation.coordinates
        : this.props.currentLocation && this.props.currentLocation.location
        ? {
            lat: this.props.currentLocation.location.latitude,
            lng: this.props.currentLocation.location.longitude
          }
        : null

    const toLocationCoords = routeSelection.toLocation
      ? routeSelection.toLocation.coordinates
      : null
    const currentUser = this.props.currentUser

    if (toLocationCoords && routeSelection.viaRoutes && currentUser) {
      try {
        this.setState({
          loading: true
        })
        const routeSuggestions = await this.props.teqplayApiService.fetchRouteSuggestions(
          fromLocationCoords,
          toLocationCoords,
          routeSelection.viaRoutes,
          this.state.departureTime,
          this.state.cruiseSpeed
        )

        const sortedRouteSuggestions = orderBy(
          routeSuggestions,
          ['shipAllowed', 'canPassBridges', 'distance'],
          ['desc', 'desc', 'asc']
        )

        this.setState({
          routeSuggestions: sortedRouteSuggestions,
          loading: false,
          routeSelectionFormActive: false,
          routeSelectionFormFieldActive: false,
          selectedRouteSuggestion: sortedRouteSuggestions[0] // set the first one as the active selected route
        })
      } catch (error) {
        console.error(error)
        this.setState({ loading: false })
        toast.warn(I18n.t('routeSelection.noRoutesFoundWarning'))
      }
    }
  }

  public checkActiveRoutes = async () => {
    try {
      const selectedRoute = await this.props.teqplayApiService.getSelectedRoute()
      if (selectedRoute) {
        this.setState({
          requestUseActiveRoute: true
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  public fetchAllNavigationLocations = async () => {
    try {
      if (!this.props.currentUser) return
      const navigationLocations = await this.props.teqplayApiService.fetchAllInlandHarbours()
      /* 
      Tempory disabled
      const nodes = await this.props.teqplayApiService.fetchAllNodes()
      const navigationNodes = nodes.map(i => ({
        _id: i._id,
        name: i._id,
        location: i.location,
        isNode: true
      }))
      // User can search for both harbours & node numbers
      const combined = [...navigationLocations, ...navigationNodes] as INavigationLocationNode[]
      */
      this.setState({
        navigationLocations: navigationLocations
      })
    } catch (error) {
      // no nav route found ( which is fine )
    }
  }

  public handleLogout = () => {
    this.props.teqplayApiService.logoutUser()
  }

  public handleSetCruiseSpeed = (speed: number) => {
    this.setState({ cruiseSpeed: speed })
    saveToLocalStorage('WATERSPORT-cruiseSpeed', speed)
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, any> = (
  dispatch: ThunkDispatch<IRootProps, void, Action>
) => {
  return {
    clearAuth: () => dispatch(clearAuth()),
    setAuth: (user: IUserAuth) => dispatch(setAuth(user)),
    setNavigationRoute: (route: ISelectedRoute) => dispatch(setNavigationRoute(route)),
    clearNavigationRoute: () => dispatch(clearNavigationRoute()),
    setUserInputSpeed: (speed: number) => dispatch(setUserInputSpeed(speed)),
    setActiveMap: (activeMap: MapTypes) => dispatch(setActiveMap(activeMap)),
    updateBridgeOpenings: (teqplayApiService: TeqplayApiService) =>
      updateBridgeOpenings(dispatch, teqplayApiService)
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteSelectionPage))
