import * as Sentry from '@sentry/browser'
import BackgroundGeolocation, {
  LocationError,
  ProviderChangeEvent,
  State
} from 'cordova-background-geolocation-lt'
import { I18n } from 'react-redux-i18n'

import { toast } from 'react-toastify'
import { FirebasePlugin } from '../../@types/cordova-plugin-firebasex'
import { ENABLE_TRIP_MONITOR } from '../../utils/constants'
import { IFullPosition } from './GeoLocationWatcherTypes'

declare global {
  interface Window {
    device: any
    FirebasePlugin: FirebasePlugin
    BackgroundGeolocation: BackgroundGeolocation
  }
}

export async function setupGeoLocationPlugin(
  onLocationSuccess: (currentLocation: IFullPosition) => void,
  setLocationAuthorizationStatus: (event: ProviderChangeEvent) => void,
  successCallback?: (state: State) => void,
  exceptionCallback?: (error: string) => void,
  locationErrorCallback?: (error: LocationError) => void
) {
  if (window.cordova && BackgroundGeolocation) {
    // 1.  Listen to events
    BackgroundGeolocation.onLocation(
      location => {
        if (location) {
          const locationToUpdate: IFullPosition = {
            posAccuracyMeters: location.coords.accuracy,
            speedOverGround: location.coords.speed || null,
            courseOverGround: location.coords.heading || null,
            location: {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            },
            timeLastUpdate: new Date().getTime()
          }

          onLocationSuccess(locationToUpdate)
        }
      },
      error => {
        console.log('[onLocation] ERROR: ', JSON.stringify(error))
        if (locationErrorCallback) {
          locationErrorCallback(error)
        }
      }
    )

    // Handles changes in the GPS being turned on / off or in the users permissions
    BackgroundGeolocation.onProviderChange(event => setLocationAuthorizationStatus(event))

    // 2. Execute #ready method:
    await BackgroundGeolocation.ready(
      {
        reset: true,
        debug: false,
        logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        stopTimeout: 1, // Change state to stationary after 1 min with device "still"
        stopOnTerminate: false, // Don't stop tracking when app is terminated.
        foregroundService: true, // Prevent Android from terminating service due to memory pressure from other apps.
        preventSuspend: true, // needed to keep IOS alive
        heartbeatInterval: 60, // <-- heartbeat event every 60s,
        stopAfterElapsedMinutes: undefined,
        disableElasticity: true,
        distanceFilter: 50, // Distance in meters device must move horizontally before an update is generated
        enableHeadless: true, // [ANDROID] Allows responding to events after the app has been terminated
        // locationUpdateInterval: 5000, // [ANDROID] Interval for location updates in milliseconds - when distanceFilter = 0

        // Only disables the alert on Android because of an issue
        // See https://github.com/transistorsoft/cordova-background-geolocation-lt/issues/1149
        disableLocationAuthorizationAlert: window.cordova.platformId === 'android' ? true : false,
        locationAuthorizationRequest: 'WhenInUse',

        notification: {
          title: 'Watersport',
          text: I18n.t('geolocationStrings.androidWhenActive'),
          color: '#1b434b',
          channelName: I18n.t('geolocationStrings.androidChannelName'),
          priority: BackgroundGeolocation.NOTIFICATION_PRIORITY_MIN,
          largeIcon: 'drawable/notification_icon',
          smallIcon: 'drawable/notification_icon'
        },
        locationAuthorizationAlert: {
          instructions: I18n.t('geolocationStrings.instructions'),
          cancelButton: I18n.t('geolocationStrings.cancelButton'),
          settingsButton: I18n.t('geolocationStrings.settingsButton'),
          titleWhenNotEnabled: I18n.t('geolocationStrings.iosTitleWhenNotEnabled'),
          titleWhenOff: I18n.t('geolocationStrings.iosTitleWhenOff')
        },
        backgroundPermissionRationale: {
          title: I18n.t('geolocationStrings.androidAllowAlwaysQuestion'),
          message: I18n.t('geolocationStrings.instructions'),
          positiveAction: I18n.t('geolocationStrings.androidChangeAllTheTime'),
          negativeAction: I18n.t('geolocationStrings.cancelButton')
        }
      },
      state => {
        // Callback function, not actually starting but just readying up the plugin
        console.log('BackgroundGeolocation is configured and ready to use')
        if (successCallback) {
          successCallback(state)
        }
      },
      error => {
        console.error('Failed to setup BackgroundGeolocation', error)
        if (exceptionCallback) {
          exceptionCallback(error)
        }
      }
    )
  }
}

export async function pauseGeoLocationPlugin() {
  if (window.cordova && BackgroundGeolocation) {
    console.log('Stopping the BackgroundGeolocation Service')
    BackgroundGeolocation.stop()
      .then((state: any) => {
        // does not execute when in the background
        // so do not place any logic here
      })
      .catch(() => {
        throw new Error('An error occurred while stopping the BackgroundGeolocation service')
      })
  }
}

export async function resumeGeoLocationPlugin(callbackFunction?: () => void) {
  if (window.cordova && BackgroundGeolocation) {
    BackgroundGeolocation.getState(state => {
      if (!state.enabled) {
        BackgroundGeolocation.start()
          .then(() => {
            console.log('Started the BackgroundGeolocation Service')
            if (callbackFunction) {
              callbackFunction()
            }
          })
          .catch((e: any) => {
            console.error('Error in resumeGeoLocationPlugin', e)
            if (ENABLE_TRIP_MONITOR) {
              toast.error(`Error resumeGeoLocationPlugin ${e}`)
            }
          })
      } else {
        console.warn('BackgroundGeolocation Service is already started')

        if (callbackFunction) {
          callbackFunction()
        }
      }
    })
  }
}

export async function getCurrentLocationOnce(
  setLoading: (state: boolean) => void,
  browserCallback?: PositionCallback,
  locationErrorCallback?: (errorCode: LocationError | PositionError) => void
) {
  if (window.cordova && BackgroundGeolocation) {
    setLoading(true)
    await BackgroundGeolocation.getCurrentPosition(
      {
        timeout: 30, // 30 second timeout to fetch location
        persist: true, // Defaults to state.enabled
        maximumAge: 5000, // Accept the last-known-location if not older than 5000 ms.
        desiredAccuracy: 10, // Try to fetch a location with an accuracy of `10` meters.
        samples: 3 // How many location samples to attempt.
      },
      undefined,
      locationErrorCallback
    )
    setLoading(false)
  } else if (window.navigator.geolocation && browserCallback) {
    setLoading(true)
    await navigator.geolocation.getCurrentPosition(
      position => {
        setLoading(false)
        browserCallback(position)
      },
      error => {
        setLoading(false)
        if (locationErrorCallback) {
          locationErrorCallback(error)
        }
      }
    )
  }
}

export async function setGPSFetchDistance(
  fetchMode:
    | 'DISTANCE_ROUTE_ACTIVE_FOREGROUND'
    | 'DISTANCE_ROUTE_ACTIVE_BACKGROUND'
    | 'DISTANCE_ROUTE_INACTIVE_FOREGROUND'
) {
  const distance = {
    DISTANCE_ROUTE_ACTIVE_FOREGROUND: 5,
    DISTANCE_ROUTE_ACTIVE_BACKGROUND: 75,
    DISTANCE_ROUTE_INACTIVE_FOREGROUND: 5
  }[fetchMode]

  if (window.cordova && BackgroundGeolocation) {
    const state = await BackgroundGeolocation.getState().catch((e: any) => {
      console.error('Error in setGPSFetchDistance getState', e)
      return null
    })

    if (state?.enabled === false) {
      resumeGeoLocationPlugin() // makes sure the plugin is restarted if it was not turned on before this function
    }

    BackgroundGeolocation.setConfig({
      distanceFilter: distance
    })
      .then((s: any) => {
        console.log(`GPSFetchDistance set to ${fetchMode} (${distance}m)`)

        // Also set it inside sentry
        Sentry.setContext('BackgroundGeolocationConfig', s)
      })
      .catch((e: any) => {
        console.error('Error in setConfig', e)
      })
  }
}
