import * as React from 'react'
import DatePicker from 'react-mobile-datepicker'
import { I18n } from 'react-redux-i18n'
interface IProps {
  toggleDatePicker: () => void
  selectTime: (date: Date) => void
}

const DayMonthPicker: React.FunctionComponent<IProps> = props => {
  const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
  }

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1
    },
    month: {
      format: (value: any) => monthMap[value.getMonth() + 1],
      caption: 'Mon',
      step: 1
    },
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1
    }
  }

  return (
    <DatePicker
      isOpen={true}
      onSelect={(date: Date) => {
        props.selectTime(date)
      }}
      headerFormat={'D/MMM/YYYY'}
      onCancel={props.toggleDatePicker}
      confirmText={I18n.t('select')}
      cancelText={I18n.t('routeSelection.cancel')}
      dateConfig={dateConfig}
    />
  )
}

export default DayMonthPicker
