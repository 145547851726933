import { distanceTo, LatLng } from 'geolocation-utils'
import { IExtendedShip } from '../../../../../@types/types'

export function getClosestMarkerToLatLng(
  shipList: IExtendedShip[],
  latLng: LatLng,
  maxRadiusMeters?: number
): IExtendedShip | undefined {
  const closestShip = shipList.reduce((a: IExtendedShip | undefined, b: IExtendedShip) => {
    if (!a || !a.location?.latitude || !a.location?.longitude) {
      return b
    }
    if (!b.location?.latitude || !b.location?.longitude) {
      return a
    }

    const aDistance = distanceTo({ lat: a.location.latitude, lng: a.location.longitude }, latLng)
    const bDistance = distanceTo({ lat: b.location.latitude, lng: b.location.longitude }, latLng)
    return bDistance < aDistance ? b : a
  }, undefined)

  if (maxRadiusMeters && closestShip?.location?.latitude && closestShip.location.longitude) {
    const distance = distanceTo(
      { lat: closestShip?.location.latitude, lng: closestShip.location.longitude },
      latLng
    )
    if (distance > maxRadiusMeters) {
      return undefined
    }
  }
  return closestShip
}
