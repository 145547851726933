import * as MapboxGl from 'mapbox-gl'
import React, { useState, useEffect } from 'react'
import { Layer, Feature, Popup } from 'react-mapbox-gl'
import './layerPopup.scss'

interface IProps<T> {
  fetchCall: (bounds?: MapboxGl.LngLatBounds) => Promise<T[]>
  bounds?: MapboxGl.LngLatBounds
  category: string
  cursor: HTMLCanvasElement | undefined
  getPopup?: (item: T) => React.ReactNode
  iconUrl: string
}

interface ICustomMarker {
  location:
    | {
        coordinates?: [number, number]
        latitude?: number
        longitude?: number
        type?: string
      }
    | undefined
  _id: string
}

const DefaultMarkerLayer = <T extends ICustomMarker>({
  cursor,
  iconUrl,
  category,
  getPopup,
  bounds,
  fetchCall
}: IProps<T>) => {
  const [defaultItems, setDefaultItems] = useState<T[]>([])
  const [singleItem, setSingleItem] = useState<T | null>(null)
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)

  const defaultLayout = {
    'icon-image': category,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true
  }

  const image = new Image(40, 40)
  image.onload = () => {
    setLoadedIcon(true)
  }
  image.src = iconUrl
  const specificIcon = [category, image]

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  return (
    <>
      {loadedIcon && (
        <Layer
          type="symbol"
          id={category}
          source={category}
          layout={defaultLayout}
          images={specificIcon}
          onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
          onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
        >
          {defaultItems.map(
            item =>
              item.location?.coordinates && (
                <Feature
                  coordinates={item.location.coordinates}
                  key={`${item._id}-${category}`}
                  onClick={() => setSingleItem(item)}
                />
              )
          )}
        </Layer>
      )}
      {singleItem && singleItem.location?.coordinates && getPopup && (
        <Popup coordinates={singleItem.location.coordinates}>
          <div className="popup">
            <span className="close icon-cancel-circled" onClick={() => setSingleItem(null)} />
            {getPopup(singleItem)}
          </div>
        </Popup>
      )}
    </>
  )

  async function fetchItems() {
    try {
      const fetchItem = await fetchCall()
      setDefaultItems(fetchItem)
    } catch (error) {
      console.error(error)
    }
  }
}

export default DefaultMarkerLayer
