import { decode } from 'query-params'

/**
 * Test whether we're running as a cordova app
 * (in that case window object should contain cordova property)
 * @type {boolean}
 */
export const isCordovaApp = window.hasOwnProperty('cordova')

export const isMobile =
  // eslint-disable-next-line max-len
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(
    navigator.userAgent
  )

/**
 * Get environment from url
 */
export function getEnvironment() {
  if (isCordovaApp) {
    return 'mobile'
  }

  const route = window.location.hash.slice(1)
  const index = route.indexOf('?')
  const queryParams = index !== -1 ? decode(route.slice(index + 1)) : null
  const env = queryParams && queryParams.environment ? queryParams.environment.toLowerCase() : ''

  return env === 'production'
    ? 'production'
    : env.indexOf('local') === 0 || window.location.host.includes('localhost') // 'local' or 'localhost'
    ? 'localhost'
    : env.indexOf('dev') === 0 || window.location.host.includes('varenfrieslanddev.teqplay.nl') // 'dev', 'develop' or 'development'
    ? 'development'
    : window.location.host.includes('varenfriesland.teqplay.nl') ||
      window.location.host.includes('watersport.fryslan.frl')
    ? 'production'
    : 'development'
}
