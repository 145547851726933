import * as React from 'react'

import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import RouteListItem from './listItems/RouteListItem'
import WaitingTimeItem from './listItems/WaitingTimeItem'

import {
  IBridgeOpeningInfo,
  IRoute,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { IFullPosition, ILocationPermissions } from '../geoLocationWatcher/GeoLocationWatcherTypes'

import './RouteList.scss'

interface IProps {
  selectedRoute: IRoute | null
  teqplayApiService: TeqplayApiService
  bridgeOpenings?: IBridgeOpeningInfo[]
  currentLocation?: IFullPosition | null
  watchdogStatus?: IWatchDogStatus
  locationPermissions: ILocationPermissions
  selectionTime?: number | null
}

const MIN_HIGH_WAITING_TIME = 300000
const MIN_HIGH_WAITING_TIME_LOCK = 2700000

const RouteList = (props: IProps) => {
  const departureTime =
    props.selectionTime || props.selectedRoute?.startTime || new Date().valueOf()

  if (props.selectedRoute) {
    const routeItems = props.selectedRoute.routeItems

    return (
      <>
        <div className="route-line" />
        <div className="route-plan-itemwrapper">
          {routeItems.map((routeItem, index) => {
            if (routeItem.type === 'TRAFFIC_SIGN' || routeItem.type === 'BOTTLENECK') {
              return null
            }
            const maxWaitingTime =
              routeItem.type === 'LOCK' ? MIN_HIGH_WAITING_TIME_LOCK : MIN_HIGH_WAITING_TIME
            const bridgeOpeningInfo =
              routeItem && routeItem.type === 'BRIDGE' && props.bridgeOpenings
                ? props.bridgeOpenings.find(x => x.isrsId === routeItem.refUuid)
                : undefined

            return (
              <React.Fragment key={'routeListItem' + index}>
                {routeItem.waitingTime && routeItem.waitingTime > maxWaitingTime ? (
                  <WaitingTimeItem routeItem={routeItem} />
                ) : null}
                <RouteListItem
                  key={'routeListItem' + index}
                  routeItem={routeItem}
                  bridgeOpeningInfo={bridgeOpeningInfo}
                  getTimeToReach={getTimeToReach}
                  teqplayApiService={props.teqplayApiService}
                  currentLocation={props.currentLocation}
                  watchdogStatus={props.watchdogStatus}
                  locationPermissions={props.locationPermissions}
                  departureTime={departureTime}
                />
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  } else {
    return null
  }

  function getTimeToReach(eta: number): string | null {
    const now = new Date().valueOf()
    let calculateFrom = now

    if (departureTime > now) {
      calculateFrom = departureTime
    }

    let time = ''
    let minutes = Math.round((eta - calculateFrom) / 60000)
    let hours = 0

    if (minutes > 60) {
      hours = Math.floor(minutes / 60)
      minutes -= hours * 60
      time += hours + 'h'
      if (minutes > 0) time += ' '
    }

    if (minutes > 0) {
      time += minutes + 'min'
    }

    return time ? '(' + time + ')' : null
  }
}

export default RouteList
