import React, { useEffect, useState } from 'react'
import { Feature, Layer, Source } from 'react-mapbox-gl'
import { IFullPosition } from '../../geoLocationWatcher/GeoLocationWatcherTypes'
import shipMarkerIconBig from './bigShipMarker.svg'

interface IShipMarker {
  userLocation?: IFullPosition | null
}

const markerLayout = {
  'icon-image': 'ship',
  'icon-rotation-alignment': 'map',
  'icon-rotate': ['get', 'rotation'],
  'icon-size': {
    stops: [
      [10, 0.3],
      [13, 0.5],
      [16, 0.8]
    ]
  },
  'icon-pitch-alignment': 'viewport',
  'icon-ignore-placement': false,
  'icon-allow-overlap': true
}

const ShipMarker = ({ userLocation }: IShipMarker) => {
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)
  const [shipMarker, setShipMarker] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    const image = new Image()
    image.src = shipMarkerIconBig
    image.onload = () => {
      setLoadedIcon(true)
    }
    setShipMarker(image)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const specificIcon = ['ship', shipMarker]
  if (!userLocation?.location) {
    return null
  }
  const { posAccuracyMeters, courseOverGround, location } = userLocation
  const shipHeading =
    courseOverGround === undefined || courseOverGround === null || courseOverGround === -1
      ? null
      : courseOverGround
  const shipLocation = [location.longitude, location.latitude]
  const shipLocationAccuracy = posAccuracyMeters || null

  return (
    <>
      {shipHeading === null || (shipLocationAccuracy && shipLocationAccuracy >= 40) ? (
        <>
          <Source
            id="point"
            geoJsonSource={{
              type: 'geojson',
              data: {
                type: 'Point',
                coordinates: [userLocation.location.longitude, userLocation.location.latitude]
              }
            }}
            aboveLayerId="SelectedRoute"
          />
          <Layer
            id="point"
            type="circle"
            sourceId="point"
            aboveLayerId="SelectedRoute"
            paint={{
              'circle-radius': {
                stops: [
                  [0, 20],
                  [15, shipLocationAccuracy]
                ],
                base: 2
              },
              'circle-color': '#4185f4',
              'circle-opacity': 0.5
            }}
          />
          <Layer
            id="point-blip"
            type="circle"
            sourceId="point"
            paint={{
              'circle-radius': 10,
              'circle-color': '#4185f4'
            }}
            aboveLayerId="SelectedRoute"
          />
        </>
      ) : (
        loadedIcon &&
        shipMarker && (
          <Layer
            type="symbol"
            id="ship"
            source="ship"
            layout={markerLayout}
            images={specificIcon}
            aboveLayerId="SelectedRoute"
          >
            <Feature coordinates={shipLocation} properties={{ rotation: shipHeading || 0 }} />
          </Layer>
        )
      )}
    </>
  )
}

export default ShipMarker
