import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TeqplayApiService from '../TeqplayApiService/TeqplayApiService'

import { IRootProps } from '../../@types/types'
import { clearAuth, setAuth } from '../../pages/loginPage/actions'
import { setCurrentLocation } from '../../routes/actions'
import { BACKEND_URL, GENERAL_HEADERS } from '../../utils/constants'
import { IUserAuth } from '../TeqplayApiService/TeqplayApi'

interface IProps {
  children: (teqplayAPIService: TeqplayApiService) => React.ReactNode
}

const AuthenticationWrapper = (props: IProps) => {
  const dispatch = useDispatch()

  // Redux store gets initial user
  const currentUser = useSelector((reduxState: IRootProps) => reduxState.currentUser)
  const locale = useSelector((reduxState: IRootProps) => reduxState.i18n.locale) || 'nl_NL'

  // Dispatchables
  const setAuthentication = (userAuth?: IUserAuth) => dispatch(setAuth(userAuth))
  const clearAuthentication = () => {
    dispatch(setCurrentLocation(null))
    dispatch(clearAuth())
  }

  const TeqplayAPIService = TeqplayApiService.Instance(
    setAuthentication,
    clearAuthentication,
    currentUser,
    BACKEND_URL,
    GENERAL_HEADERS,
    locale
  )

  return (
    <React.Fragment>{props.children ? props.children(TeqplayAPIService) : null}</React.Fragment>
  )
}

export interface IAuthenticationWrapper {
  teqplayApiService: TeqplayApiService
}

export default AuthenticationWrapper
