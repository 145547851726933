import * as MapboxGl from 'mapbox-gl'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import AISMapboxLayer from './layers/AISLayer/AISLayer'
import CemtClassLayer from './layers/CEMTClassLayer'
import FairwayDepthLayer from './layers/FairwayDepthLayer'
import InlandHarboursLayer from './layers/InlandHarboursLayer'
import CharginStations from './layers/ChargingStationsLayer'
import TrailerSlipwaysLayer from './layers/TrailerSlipwaysLayer'
import WasteWaterStations from './layers/WasteWaterStationsLayer'
import RecreationalClassLayer from './layers/RecreationalClassLayer'
import VDJSCrossingLayer from './layers/VDJSCrossingLayer'
import BerthLayer from './layers/BerthLayer'
import BridgeLayer from './layers/BridgeLayer'
import DefaultPolygonLayer from './layers/DefaultPolygonLayer'
import DefaultPolylineLayer from './layers/DefaultPolylineLayer'
import KilometreringsLayer from './layers/KilometreringsLayer'
// import NodeLayer from './layers/NodeLayer'
import { useRouteNetwork } from '../../customHooks/useRouteNetwork'
import { IRootProps } from '../../@types/types'
import {
  IBridgeOpeningInfo,
  IElectricalRoute,
  IFastSailingRoute,
  IWinterRestArea,
  IWatchDogStatus,
  ISelectedRoute,
  IRouteItem
} from '../../services/TeqplayApiService/TeqplayApi'
import { IFullPosition, ILocationPermissions } from '../geoLocationWatcher/GeoLocationWatcherTypes'

interface IProps {
  teqplayApiService: TeqplayApiService
  bridgeOpenings: IBridgeOpeningInfo[]
  activeLayers: string[]
  userZoom: number
  layerProperties: any
  mapBounds: MapboxGl.LngLatBounds | undefined
  cursor: HTMLCanvasElement | undefined
  isAnonymous: boolean
  currentLocation?: IFullPosition | null
  watchdogStatus: IWatchDogStatus
  locationPermissions: ILocationPermissions
  selectedRouteItem?: IRouteItem | null
  navigationRoute?: ISelectedRoute
}

const MapLayers = (props: IProps) => {
  const routeNetwork = useRouteNetwork(props.teqplayApiService, true)
  const memoNetwork = useMemo(
    () =>
      routeNetwork?.objects.map(item => {
        return item
      }),
    [routeNetwork]
  )

  const locale = useSelector((r: IRootProps) => r.i18n.locale)
  return (
    <>
      {props.activeLayers.includes('wasteWaterStations') && (
        <WasteWaterStations
          teqplayApiService={props.teqplayApiService}
          cursor={props.cursor}
          bounds={props.mapBounds}
        />
      )}
      {/*
      Temporary disabled
      props.activeLayers.includes('nodes') && (
        <NodeLayer teqplayApiService={props.teqplayApiService} />
      ) 
      */}
      {props.activeLayers.includes('trailerSlope') && props.userZoom > 11 && (
        <TrailerSlipwaysLayer
          teqplayApiService={props.teqplayApiService}
          cursor={props.cursor}
          bounds={props.mapBounds}
        />
      )}
      {props.activeLayers.includes('chargingStations') && (
        <CharginStations
          teqplayApiService={props.teqplayApiService}
          cursor={props.cursor}
          bounds={props.mapBounds}
        />
      )}
      {props.activeLayers.includes('inlandHarbours') && props.userZoom > 11 && (
        <InlandHarboursLayer
          teqplayApiService={props.teqplayApiService}
          cursor={props.cursor}
          bounds={props.mapBounds}
        />
      )}
      {props.activeLayers.includes('bridges') && props.userZoom > 11 && (
        <BridgeLayer
          teqplayApiService={props.teqplayApiService}
          cursor={props.cursor}
          bounds={props.mapBounds}
          bridgeOpenings={props.bridgeOpenings}
          currentUserLocation={props.currentLocation}
          watchdogStatus={props.watchdogStatus}
          locationPermissions={props.locationPermissions}
          selectedRouteItem={props.selectedRouteItem}
          navigationRoute={props.navigationRoute}
        />
      )}
      {props.activeLayers.includes('crossings') && (
        <VDJSCrossingLayer cursor={props.cursor} userZoom={props.userZoom} locale={locale} />
      )}
      {props.activeLayers.includes('kilometre') && props.userZoom > 11 && (
        <KilometreringsLayer teqplayApiService={props.teqplayApiService} bounds={props.mapBounds} />
      )}

      {props.activeLayers.includes('CEMTClass') && memoNetwork && (
        <CemtClassLayer routeNetwork={memoNetwork} />
      )}
      {props.activeLayers.includes('fairwayDepth') && memoNetwork && (
        <FairwayDepthLayer routeNetwork={memoNetwork} />
      )}
      {props.activeLayers.includes('recreationalClass') && memoNetwork && (
        <RecreationalClassLayer routeNetwork={memoNetwork} />
      )}

      {props.activeLayers.includes('public_berths') && props.userZoom > 12 && (
        <BerthLayer
          teqplayApiService={props.teqplayApiService}
          bounds={props.mapBounds}
          layerProperties={props.layerProperties}
          cursor={props.cursor}
        />
      )}

      {!props.isAnonymous && props.activeLayers.includes('AISShips') && props.userZoom > 12 && (
        <AISMapboxLayer
          teqplayApiService={props.teqplayApiService}
          zoom={props.userZoom}
          minZoom={12}
          bounds={props.mapBounds}
          locale={locale}
          cursor={props.cursor}
        />
      )}
      {props.activeLayers.includes('electricRoutes') && (
        <DefaultPolylineLayer<IElectricalRoute>
          fetchCall={bounds => props.teqplayApiService.fetchElectricRoutes(bounds)}
          bounds={props.mapBounds}
          color="#3388ff"
          category="electricRoutes"
        />
      )}
      {props.activeLayers.includes('fastRoutes') && (
        <DefaultPolygonLayer<IFastSailingRoute>
          bounds={props.mapBounds}
          fetchCall={() => props.teqplayApiService.fetchFastSailingRoutes(props.mapBounds)}
          category={'fastRoutes'}
          color={'#197ed4'}
          layerProperties={props.layerProperties}
          cursor={props.cursor}
          getPopup={layerProperties => {
            if (layerProperties.locatableType !== 'FAST_SAILING_AREA') {
              return
            }
            return <p>{layerProperties.comment}</p>
          }}
        />
      )}
      {props.activeLayers.includes('winterRestAreas') && (
        <DefaultPolygonLayer<IWinterRestArea>
          bounds={props.mapBounds}
          fetchCall={() => props.teqplayApiService.fetchWinterRestAreas(props.mapBounds)}
          category={'winterRestAreas'}
          color={'#17a75f'}
          layerProperties={props.layerProperties}
          cursor={props.cursor}
          getPopup={layerProperties => {
            if (layerProperties.locatableType !== 'WINTER_REST_AREA') {
              return
            }
            return (
              <>
                <h3>{layerProperties.area}</h3>
                <div className="info">{layerProperties.info}</div>
                <button
                  className="winter-button"
                  onClick={() => window.open(layerProperties.url, '_system')}
                >
                  {I18n.t('routeList.itemDetails.moreInfo')}
                </button>
              </>
            )
          }}
        />
      )}
    </>
  )
}

export default MapLayers
