import React from 'react'
import { useHistory } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import './policyPage.scss'

function PolicyPage() {
  const history = useHistory()
  return (
    <div className="policy-page">
      <div className="policy-container">
        <span onClick={navigateBack} className="back">
          <i className="icon icon-left" /> {I18n.t('policy.back')}
        </span>
        <h1>{I18n.t('policy.title.watersport')}</h1>
        <p>{I18n.t('policy.permission')}</p>
        <p>{I18n.t('policy.storage')}</p>
        <p>{I18n.t('policy.dataCollection')}</p>
        <p>{I18n.t('policy.privacyManagement')}</p>
        <p>{I18n.t('policy.dataProtection')}</p>

        <p>{I18n.t('policy.lastUpdated')}</p>
        <ul>
          <li>
            <p>{I18n.t('policy.personal')}</p>
          </li>
          <li>
            <p>{I18n.t('policy.device')}</p>
          </li>
          <li>
            <p>{I18n.t('policy.account')}</p>
          </li>
        </ul>
        <p>
          <h4>{I18n.t('policy.title.children')}</h4>
          <p>{I18n.t('policy.ageRestriction')}</p>
          <h4>{I18n.t('policy.title.cookies')}</h4>
          <p>{I18n.t('policy.cookies')}</p>
          <h4>{I18n.t('policy.title.security')}</h4>
          <p>{I18n.t('policy.security')}</p>
          <h4>{I18n.t('policy.title.changes')}</h4>
          <p>{I18n.t('policy.changesToPrivacyPolicy')}</p>
        </p>
        <h4>{I18n.t('policy.title.contactUs')}</h4>
        <p>
          {I18n.t('policy.contactUs')}{' '}
          <a href="mailto:provincie@fryslan.frl">provincie@fryslan.frl</a>
        </p>
      </div>
    </div>
  )
  function navigateBack() {
    if (window.history.state) {
      history.goBack()
    } else {
      history.push('/', { replace: true })
    }
  }
}

export default PolicyPage
