import * as MapboxGl from 'mapbox-gl'
import React, { useEffect, useState } from 'react'
import { GeoJSONLayer, Popup } from 'react-mapbox-gl'

import { findCenterPolygon } from '../../../utils/findCenterPolygon'
import './layerPopup.scss'

interface IProps<T> {
  bounds: MapboxGl.LngLatBounds | undefined
  fetchCall: (bounds?: MapboxGl.LngLatBounds) => Promise<T[]>
  category: string
  color: string
  layerProperties: any
  cursor: HTMLCanvasElement | undefined
  getPopup?: (item: T) => React.ReactNode
}

interface ICustomPolygon {
  polygon: {
    coordinates: [[[number, number]]]
  }
  _id: string
}

const DefaultPolygonLayer = <T extends ICustomPolygon>({
  category,
  color,
  cursor,
  layerProperties,
  getPopup,
  bounds,
  fetchCall
}: IProps<T>) => {
  const [defaultItems, setDefaultItems] = useState<T[]>([])
  const [showPopup, setShowPopup] = useState<boolean>(false)

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  useEffect(() => {
    if (layerProperties) {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerProperties])

  return (
    <>
      <GeoJSONLayer
        data={{
          type: 'FeatureCollection',
          features: defaultItems.map(item => {
            return {
              type: 'Feature',
              properties: item,
              geometry: {
                type: 'Polygon',
                coordinates: [item.polygon.coordinates[0]]
              }
            }
          })
        }}
        fillPaint={{
          'fill-color': color,
          'fill-opacity': 0.2
        }}
        source={category}
        id={category}
        fillOnMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
        fillOnMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
      />
      <GeoJSONLayer
        data={{
          type: 'FeatureCollection',
          features: defaultItems.map(item => {
            return {
              type: 'Feature',
              properties: { item },
              geometry: {
                type: 'LineString',
                coordinates: item.polygon.coordinates[0]
              }
            }
          })
        }}
        linePaint={{
          'line-color': color,
          'line-width': 3
        }}
      />
      {defaultItems.length > 0 && showPopup && layerProperties.polygon && getPopup && (
        <Popup coordinates={findCenterPolygon(layerProperties.polygon.coordinates[0])}>
          <div className="popup">
            <span className="close icon-cancel-circled" onClick={() => setShowPopup(false)} />
            {getPopup(layerProperties)}
          </div>
        </Popup>
      )}
    </>
  )

  async function fetchItems() {
    try {
      const fetchItem = await fetchCall()
      setDefaultItems(fetchItem)
    } catch (error) {
      console.error(error)
    }
  }
}

export default DefaultPolygonLayer
