import get from 'lodash/get'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import RouteList from '../../routeList/RouteList'
import DefaultTransition from '../../shared/defaultTransition/DefaultTransition'
import RouteDetailedInfo from './routeDetailedInfo/RouteDetailedInfo'
import RouteOptionsList from './routeOptionsList/RouteOptionsList'
import SaveRouteModal from './SaveRouteModal'

import { IRouteSearchProps } from '../../../@types/types'
import { IRoute } from '../../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import { SCREEN_WIDTH_TO_SPLIT_VIEW } from '../../../utils/constants'
import { ILocationPermissions } from '../../geoLocationWatcher/GeoLocationWatcherTypes'

interface IProps {
  routeSelection: IRouteSearchProps
  routeSuggestions: IRoute[]
  selectedRouteSuggestion: IRoute | null
  teqplayApiService: TeqplayApiService
  resetAvailableRoutes: () => void
  setSelectedRouteSuggestion: (routeSuggestion: IRoute | null) => void
  startRoute: (route: IRoute) => void
  locale: string
  locationPermissions: ILocationPermissions
  departureTime: number | null
}

interface IState {
  listViewActive: boolean
  routeDetailsActive: boolean
  saveRouteModalActive: boolean
  savedRouteName: string
}

class RouteOptionsSelect extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    listViewActive: window.innerWidth > SCREEN_WIDTH_TO_SPLIT_VIEW ? true : false,
    saveRouteModalActive: false,
    routeDetailsActive: false,
    savedRouteName: ''
  }

  public render() {
    const activeListOrDetails = this.state.routeDetailsActive || this.state.listViewActive

    return (
      <>
        <SaveRouteModal
          isOpen={this.state.saveRouteModalActive}
          closeModal={this.toggleSaveRouteModal}
          onNameChange={this.handleChangeRouteName}
          savedRouteName={this.state.savedRouteName}
          saveRoute={this.handleSaveRoute}
        />

        <div className={`content-wrapper-top ${activeListOrDetails && 'active-route-list'}`}>
          <div className="route-select-wrapper">
            <RouteOptionsList
              routeSuggestions={this.props.routeSuggestions}
              setSelectedRouteSuggestion={this.props.setSelectedRouteSuggestion}
              selectedRouteSuggestion={this.props.selectedRouteSuggestion}
              toggleListView={this.toggleListView}
              toggleRouteDetails={this.toggleRouteDetails}
              listViewActive={this.state.listViewActive}
              locale={this.props.locale}
            />

            <DefaultTransition active={activeListOrDetails} slideFrom="left" offset={500}>
              {props => (
                <div style={props} className="route-list-wrapper">
                  {this.state.routeDetailsActive ? (
                    <RouteDetailedInfo
                      route={this.props.selectedRouteSuggestion || undefined}
                      closeModal={this.toggleRouteDetails}
                    />
                  ) : (
                    <RouteList
                      selectedRoute={this.props.selectedRouteSuggestion}
                      teqplayApiService={this.props.teqplayApiService}
                      locationPermissions={this.props.locationPermissions}
                      selectionTime={this.props.departureTime}
                    />
                  )}
                </div>
              )}
            </DefaultTransition>
          </div>
        </div>

        <div className={`content-wrapper-bottom ${activeListOrDetails ? 'active-route-list' : ''}`}>
          <div className="route-options-button-wrapper">
            <button
              type="button"
              className="button list-button"
              onClick={this.props.resetAvailableRoutes}
            >
              <i className="icon-left-big close-button" /> {I18n.t('routeSelection.cancel')}
            </button>
            <button type="button" className="button primary" onClick={this.openSaveRouteModal}>
              <i className="icon-floppy" /> {I18n.t('routeSelection.saveRoute')}
            </button>
            <button type="button" className="button primary small" onClick={this.handleStartRoute}>
              <i className="icon-location-1" /> {I18n.t('routeSelection.startRoute')}
            </button>
          </div>
        </div>
      </>
    )
  }

  public handleStartRoute = () => {
    if (this.props.selectedRouteSuggestion) {
      this.props.startRoute(this.props.selectedRouteSuggestion)
    }
  }

  public toggleSaveRouteModal = () => {
    this.setState({ saveRouteModalActive: !this.state.saveRouteModalActive })
  }

  public handleChangeRouteName = (name: string) => {
    this.setState({ savedRouteName: name })
  }

  public openSaveRouteModal = () => {
    const fromName = get(this.props.routeSelection, 'fromLocation.displayName', '...')
    const toName = get(this.props.routeSelection, 'toLocation.displayName', '...')
    this.handleChangeRouteName(I18n.t('routeSelection.fromTo', { from: fromName, to: toName }))
    this.toggleSaveRouteModal()
  }

  public handleSaveRoute = async () => {
    if (this.props.selectedRouteSuggestion) {
      this.toggleSaveRouteModal()
      try {
        await this.props.teqplayApiService.addToStoredRoutes(
          this.props.selectedRouteSuggestion,
          this.state.savedRouteName
        )
        toast.success(I18n.t('routeSelection.routeStoredSucces'))
      } catch (err) {
        console.error(err)
        toast.error(I18n.t('generalError'))
      }
    }
  }

  public toggleListView = () => {
    this.setState({ listViewActive: !this.state.listViewActive, routeDetailsActive: false })
  }

  public toggleRouteDetails = () => {
    this.setState({ routeDetailsActive: !this.state.routeDetailsActive, listViewActive: false })
  }
}

export default RouteOptionsSelect
