import { useEffect, useState } from 'react'
import { IRouteScoutNetwork } from '../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../services/TeqplayApiService/TeqplayApiService'

export function useRouteNetwork(apiService: TeqplayApiService, isChecked?: boolean) {
  const [routeNetwork, setRouteNetwork] = useState<IRouteScoutNetwork | null>(null)

  async function fetchRouteNetwork() {
    try {
      const rn = await apiService.fetchRouteNetwork()
      setRouteNetwork(rn)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    if (!isChecked === false) {
      fetchRouteNetwork()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  return routeNetwork
}
