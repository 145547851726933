import { AnyAction } from 'redux'
import { IMapState } from '../../@types/types'

// Set inital login state
const initialState: IMapState = {
  activeMap: 'DEFAULT'
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function mapReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case 'SET_ACTIVE_MAP': {
      return Object.assign({}, state, { activeMap: action.map })
    }

    default:
      return state
  }
}
