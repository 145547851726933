import * as React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'

import { IRootProps } from '../../../@types/types'
import { updateNavigationRoute } from '../../../pages/routeSelectionPage/actions'
import { SpeedTypes } from '../../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import { activeClass, kmhToKnots, knotsToKmh } from '../../../utils/general'
import SwitchButton from '../../shared/switchButton/SwitchButton'
import { setSpeedType, setUserInputSpeed } from './actions'

import './SetSpeed.scss'

interface IDispatchProps {
  setSpeedType: (speedType: SpeedTypes) => void
  setUserInputSpeed: (speed: number) => void
  updateNavigationRoute: (
    teqplayApiService: TeqplayApiService,
    speedType: SpeedTypes,
    speed: number
  ) => void
}

interface IProps {
  teqplayApiService: TeqplayApiService
}

interface IState {
  unsavedSpeed: number | null // knots
  unsavedSpeedType: SpeedTypes | null
}

class SetSpeed extends React.Component<IProps & IRootProps & IDispatchProps> {
  public readonly state: Readonly<IState> = {
    unsavedSpeed: null,
    unsavedSpeedType: null
  }

  public render() {
    const speedType = this.state.unsavedSpeedType || this.props.speed.speedType
    const speed =
      this.state.unsavedSpeed !== null ? this.state.unsavedSpeed : this.props.speed.userInputSpeed
    const useShipSpeed = speedType === 'SHIP'

    return (
      <div className="set-speed-wrapper">
        <div className="">
          <h2>{I18n.t('map.speed.title')}</h2>

          <SwitchButton>
            <button
              className={`button large use-ship-speed ${activeClass(useShipSpeed, 'primary')}`}
              onClick={this.onSelectShipSpeed}
            >
              {I18n.t('map.speed.useShip')}
            </button>
            <button
              className={`button large ${activeClass(!useShipSpeed, 'primary')}`}
              onClick={this.onSelectCustomSpeed}
            >
              {I18n.t('map.speed.useCustom')}
            </button>
          </SwitchButton>

          {!useShipSpeed && (
            <div className="form-row">
              <input
                type="number"
                value={knotsToKmh(speed)}
                onChange={this.handleSpeedChange}
                className="input speed-input"
                step="1"
              />
              <span>{I18n.t('units.kmh')}</span>

              <div className="button-container">
                <button className="button large primary" onClick={this.handleClickSave}>
                  {I18n.t('save')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  private handleSpeedChange = (e: React.ChangeEvent<any>) => {
    const value = kmhToKnots(Number(e.target.value))

    this.setState({ unsavedSpeed: value })
  }

  private onSelectShipSpeed = async () => {
    if (this.state.unsavedSpeedType !== 'SHIP') {
      await this.setState({ unsavedSpeedType: 'SHIP' })
      this.handleClickSave()
    }
  }

  private onSelectCustomSpeed = () => {
    this.setState({ unsavedSpeedType: 'CUSTOM' })
  }

  private handleClickSave = () => {
    const { unsavedSpeed, unsavedSpeedType } = this.state

    const speedType = unsavedSpeedType || this.props.speed.speedType
    if (speedType === 'CUSTOM') {
      // Save user speed to redux
      const speed = unsavedSpeed !== null ? unsavedSpeed : this.props.speed.userInputSpeed
      if (knotsToKmh(speed) > 1 && knotsToKmh(speed) < 50) {
        this.props.setUserInputSpeed(speed)
        this.setState({ unsavedSpeed: null })
      } else {
        toast.error(I18n.t('map.speed.setRealisticSpeed'))
        return
      }
    }

    // Save speed type to redux
    if (unsavedSpeedType) {
      this.props.setSpeedType(unsavedSpeedType)
    }

    // Done saving, close popup
    if (unsavedSpeed || unsavedSpeedType) {
      const typeSpeed = unsavedSpeedType || this.props.speed.speedType
      const speed = unsavedSpeed !== null ? unsavedSpeed : this.props.speed.userInputSpeed
      this.props.updateNavigationRoute(this.props.teqplayApiService, typeSpeed, speed)
      this.setState({ unsavedSpeedType: null, unsavedSpeed: null })
      toast.success(I18n.t('map.speed.saved'))
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSpeedType: (speedType: SpeedTypes) => dispatch(setSpeedType(speedType)),
    setUserInputSpeed: (speed: number) => dispatch(setUserInputSpeed(speed)),
    updateNavigationRoute: (
      teqplayApiService: TeqplayApiService,
      speedType: SpeedTypes,
      speed: number
    ) => updateNavigationRoute(dispatch, teqplayApiService, speedType, speed)
  }
}

const mapStateToProps = (state: IRootProps, ownProps: {}) => {
  return state
}

export default connect(mapStateToProps, mapDispatchToProps)(SetSpeed)
