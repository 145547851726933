import BackgroundGeolocation, { ProviderChangeEvent } from 'cordova-background-geolocation-lt'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import './GPSNotification.scss'

interface IProps {
  active: boolean
  providerConfig: { enabled: false } | ProviderChangeEvent
  retryCallback: () => void
}

const GPSNotification: React.FunctionComponent<IProps> = props => {
  const [collapsed, setCollapsed] = React.useState(false)

  if (
    props.active &&
    props.providerConfig.enabled &&
    props.providerConfig.status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED
  ) {
    return (
      <div className={`gps-notification-warning`}>
        <div className="icon-wrapper">
          <div className="notification-icon">
            <i className="icon-attention" />
          </div>
          <span>{I18n.t('geolocationStrings.warning')}</span>
        </div>
        <div className="notification-tag" onClick={() => retryPermissions()}>
          <span>{I18n.t('geolocationStrings.deniedPermission')}</span>
          <span>
            {I18n.t(
              window.cordova?.platformId === 'android' ? '' : 'geolocationStrings.iosInstructions'
            )}
          </span>
          {window.cordova?.platformId === 'android' && (
            <span className="retry">{I18n.t('geolocationStrings.clickToRetry')}</span>
          )}
        </div>
      </div>
    )
  } else if (
    props.active &&
    props.providerConfig.enabled &&
    props.providerConfig.status === BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE
  ) {
    return (
      <div
        className={`gps-notification-warning info${collapsed ? ' collapsed' : ''}`}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="icon-wrapper">
          <div className="notification-icon">
            <i className="icon-info-1" />
          </div>
          <span>{I18n.t('geolocationStrings.info')}</span>
        </div>
        <div className="notification-tag">
          <span>{I18n.t('geolocationStrings.whenInUse')}</span>
          <span className="retry">{I18n.t('geolocationStrings.clickToMinimize')}</span>
        </div>
      </div>
    )
  } else {
    return null
  }

  /**
   * Function to let the app retry its permissions for Android only
   * Needed manually in order to fix issue regarding deadlock loop
   */
  function retryPermissions() {
    if (window.cordova?.platformId === 'android') {
      BackgroundGeolocation.requestPermission()
        .then(() => props.retryCallback())
        .catch(() => {
          // Show prompt to user that permissions are still not active
          toast.error(I18n.t('geolocationStrings.stillDenied'))
        })
    }
  }
}

export default GPSNotification
