import React, { useEffect, useRef, useState } from 'react'
import ReactMapboxGl from 'react-mapbox-gl'
import * as MapboxGl from 'mapbox-gl'
import { debounce } from 'lodash'

import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import LayerZoomWarning from '../mapShared/layers/legends/LayerZoomWarning'
import RouteNetworkLegend from '../mapShared/RouteNetworkLegend'
import BridgeLayerLegend from '../mapShared/layers/legends/BridgeLayerLegend'
import MapBoxController from '../newMapController/MapBoxController'
import MapLayers from '../mapShared/MapLayers'
import SelectedRouteItem from './selectedRouteItem/SelectedRouteItem'

import { IExtendedPropertyLocation, MapTypes } from '../../@types/types'
import {
  IBridgeOpeningInfo,
  IRouteItem,
  ISelectedRoute,
  IWatchDogStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { useLocalStorage } from '../../customHooks/useLocalStorage'
import { FRIESLAND_LOCATION, MAPBOX_TOKEN } from '../../utils/constants'
import { mapTypeToTileURL } from '../../utils/mapTypeToTileURL'
import { mapClickedLayer } from '../../utils/mapClickedLayer'
import {
  IFullPosition,
  IGeoLocationWatcherState
} from '../geoLocationWatcher/GeoLocationWatcherTypes'
import ShipMarker from '../mapShared/shipMarker/ShipMarker'
import StartEndLayer from '../mapShared/layers/StartEndLayer'
import { layersWithZoomLevelRestrictions } from '../mapShared/layersWithZoomLevelRestrictions'
import { SelectedDottedLine } from '../mapShared/SelectedDottedLine'
import { SelectedRoute } from '../mapShared/SelectedRoute'
import { RouteItemMarkers } from './markers/RouteItemMarkers'

import './MapNavigation.scss'

interface IProps {
  token: string
  hideControlButtons?: boolean
  navigationRoute: ISelectedRoute
  bridgeOpenings: IBridgeOpeningInfo[]
  currentLocation: IFullPosition | null
  activeMap: MapTypes
  teqplayApiService: TeqplayApiService
  onChangeActiveMap: (activeMap: MapTypes) => void
  watchdogStatus: IWatchDogStatus
  userHistory: IExtendedPropertyLocation[]
  geoLocationWatcherState: IGeoLocationWatcherState
  toggleOptions: () => void
  totalNotificationsCount: number
}

const Map = ReactMapboxGl({
  accessToken: MAPBOX_TOKEN
})

const MapNavigation = (props: IProps) => {
  const [userLocation, setUserLocation] = useState<IFullPosition | null>(props.currentLocation)
  const [retrievedUserLocation, setRetrievedUserLocation] = useState<boolean>(false)
  const [activeMap, setActiveMap] = useLocalStorage<MapTypes>('WATERSPORT-mapType', 'DEFAULT')
  const [followMode, setFollowMode] = useLocalStorage<boolean>('WATERSPORT-followMode', false)
  const [userZoom, setUserZoom] = useLocalStorage<number>('WATERSPORT-mapZoomLevel', 7)
  const [center, setCenter] = useLocalStorage<[number, number] | undefined>('WATERSPORT-center', [
    FRIESLAND_LOCATION.lng,
    FRIESLAND_LOCATION.lat
  ])
  const [selectedRouteItem, setSelectedRouteItem] = useState<IRouteItem | null>()
  const [activeLayers, setActiveLayers] = useLocalStorage<string[]>('WATERSPORT-layers', [])
  const [mapBounds, setMapBounds] = useState<MapboxGl.LngLatBounds>()
  const [heading, setHeading] = useState<boolean>(false)
  const [layerProperties, setLayerProperties] = useState<any>([])

  const fromCoordinates = splitCoordinates(props.navigationRoute.route.from)
  const toCoordinates = splitCoordinates(props.navigationRoute.route.to)
  const mapRef = useRef<MapboxGl.Map>()

  useEffect(() => {
    if (props.currentLocation) {
      setUserLocation(props.currentLocation)
      setRetrievedUserLocation(true)
      if ((followMode || heading) && props.currentLocation.location) {
        if (heading) {
          const flyToSettings = {
            center: [
              props.currentLocation.location.longitude,
              props.currentLocation.location.latitude
            ] as [number, number],
            zoom: userZoom < 15 ? 15 : userZoom,
            pitch: props.currentLocation.courseOverGround ? 100 : 0
          }
          mapRef.current?.flyTo(
            props.currentLocation.courseOverGround
              ? { ...flyToSettings, ...{ bearing: props.currentLocation.courseOverGround } }
              : flyToSettings
          )
        } else {
          setCenter([
            props.currentLocation.location.longitude,
            props.currentLocation.location.latitude
          ])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentLocation])

  useEffect(() => {
    if (retrievedUserLocation && props.currentLocation?.location) {
      setTimeout(() => {
        // When we first retrive the location, fly to the area rotated with the first coordinate
        if (props.currentLocation && props.currentLocation.location) {
          const flyToSettings = {
            center: [
              props.currentLocation.location.longitude,
              props.currentLocation.location.latitude
            ] as [number, number],
            zoom: userZoom < 15 ? 15 : userZoom,
            pitch: props.currentLocation.courseOverGround ? 100 : 0,
            bearing: getFirstCoordinateRotation(
              props.currentLocation,
              props.navigationRoute.route.routeItems
            )
          }
          mapRef.current?.flyTo(flyToSettings)
          setFollowMode(true)
        }
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedUserLocation])

  useEffect(() => {
    /* Instead of always setting things above, this will move the shipMarker always to prio 1 */
    if (mapRef.current && mapRef.current?.getStyle()) {
      if (mapRef.current.getSource('point')) {
        mapRef.current.moveLayer('point')
        if (mapRef.current.getSource('point-blip')) {
          mapRef.current.moveLayer('point', 'point-blip')
        }
      }
      if (mapRef.current.getSource('ship')) {
        mapRef.current.moveLayer('ship')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCoordinates])

  useEffect(() => {
    setLayerProperties([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLayers])

  return (
    <Map
      style={mapTypeToTileURL(activeMap)}
      zoom={[userZoom]}
      onZoomEnd={map => setUserZoom(map.getZoom())}
      center={center}
      onStyleLoad={handleStyleLoad}
      onClick={(map, evt) => setLayerProperties(mapClickedLayer(map, evt))}
      onMoveEnd={debounce(onMoveEnd, 250)}
      onDragEnd={() => {
        setFollowMode(false)
        setHeading(false)
      }}
      onRotateEnd={() => (!props.currentLocation?.courseOverGround ? setHeading(false) : undefined)}
    >
      <LayerZoomWarning
        mapZoomLevel={userZoom}
        layersWithZoomLevelRestrictions={layersWithZoomLevelRestrictions}
        activeLayers={activeLayers}
      />
      <div>
        <RouteNetworkLegend activeLayers={activeLayers} />
        <BridgeLayerLegend activeLayers={activeLayers} />
        <MapLayers
          teqplayApiService={props.teqplayApiService}
          activeLayers={activeLayers}
          mapBounds={mapBounds}
          cursor={mapRef.current?.getCanvas()}
          layerProperties={layerProperties}
          userZoom={userZoom}
          bridgeOpenings={props.bridgeOpenings}
          isAnonymous={false}
          currentLocation={props.currentLocation}
          watchdogStatus={props.watchdogStatus}
          locationPermissions={props.geoLocationWatcherState.locationPermissions}
          selectedRouteItem={selectedRouteItem}
          navigationRoute={props.navigationRoute}
        />

        {selectedRouteItem && (
          <SelectedRouteItem
            selectedRouteItem={selectedRouteItem}
            setSelectRouteItem={setSelectedRouteItem}
            teqplayApiService={props.teqplayApiService}
            currentLocation={props.currentLocation}
            watchdogStatus={props.watchdogStatus}
            locationPermissions={props.geoLocationWatcherState.locationPermissions}
            bridgeOpeningInfo={props.bridgeOpenings.find(
              bridge => bridge.isrsId === selectedRouteItem?.refUuid
            )}
          />
        )}

        <div className="options-toggle" onClick={props.toggleOptions}>
          <div className="options-button">
            <div className="options-circle">
              <span className=" icon-list-bullet" />
            </div>
            {props.totalNotificationsCount > 0 && (
              <div className="notification-count">{props.totalNotificationsCount}</div>
            )}
          </div>
        </div>

        {props.navigationRoute && props.navigationRoute.route && (
          <SelectedRoute selectedRouteSuggestion={props.navigationRoute.route} />
        )}
        <RouteItemMarkers
          teqplayApiService={props.teqplayApiService}
          bridgeOpenings={props.bridgeOpenings}
          navigationRoute={props.navigationRoute}
          selectedRouteItem={selectedRouteItem}
          setSelectedRouteItem={setSelectedRouteItem}
          mapCursor={mapRef.current?.getCanvas()}
          currentUserLocation={props.currentLocation}
          locationPermissions={props.geoLocationWatcherState.locationPermissions}
          moveFinishLayersToTop={moveFinishLayersToTop}
        />
        <SelectedDottedLine navigationRoute={props.navigationRoute} />
      </div>
      <MapBoxController
        userZoom={userZoom}
        followMode={followMode}
        setFollowMode={setFollowMode}
        heading={heading}
        setHeading={setHeading}
        setCenter={setCenter}
        currentLocation={props.currentLocation}
        setUserZoom={setUserZoom}
        activeMap={activeMap}
        setActiveMap={setActiveMap}
        activeLayers={activeLayers}
        setActiveLayers={setActiveLayers}
        isAnonymous={false}
        routeNavigation={true}
        mapRef={mapRef?.current}
      />
      <StartEndLayer fromCoordinates={fromCoordinates} toCoordinates={toCoordinates} />
      <ShipMarker userLocation={userLocation} />
    </Map>
  )

  function handleStyleLoad(map: MapboxGl.Map) {
    mapRef.current = map
  }

  function onMoveEnd(map?: MapboxGl.Map, evt?: any) {
    const b = map?.getBounds()
    if (map?.getZoom()) {
      setUserZoom(map.getZoom())
      if (map.getZoom() < 13 && mapRef.current) {
        mapRef.current.getCanvas().style.cursor = ''
      }
    }
    setMapBounds(b)

    const distanceWhenZoom = map?.getCenter().distanceTo({
      lng: props.currentLocation?.location?.longitude,
      lat: props.currentLocation?.location?.latitude
    } as MapboxGl.LngLat)

    if (
      mapRef !== undefined &&
      distanceWhenZoom &&
      distanceWhenZoom > 5000 &&
      (followMode || heading)
    ) {
      setFollowMode(false)
      setHeading(false)
    }
  }

  function splitCoordinates(coordinates: string) {
    return coordinates.split(',').map(Number).reverse() as [number, number]
  }

  function getFirstCoordinateRotation(
    currentLocation: IFullPosition | null,
    routeItems: IRouteItem[]
  ) {
    if (routeItems.length === 0 || !currentLocation?.location) {
      return undefined
    }

    const startLongitude = currentLocation.location.longitude
    const startLatitude = currentLocation.location.latitude
    const stopLatitude = routeItems[0].location[0].coordinates[0]
    const stopLongitude = routeItems[0].location[0].coordinates[1]

    const y = Math.sin(stopLongitude - startLongitude) * Math.cos(stopLatitude)
    const x =
      Math.cos(startLongitude) * Math.sin(stopLatitude) -
      Math.sin(startLatitude) * Math.cos(stopLatitude) * Math.cos(stopLongitude - startLongitude)
    const calculatedRotation = (Math.atan2(y, x) * 180) / Math.PI

    // This is setting the to calculate the bearing between user coordinates & the first stop
    return calculatedRotation
  }

  function moveFinishLayersToTop() {
    if (
      mapRef?.current &&
      mapRef?.current.getLayer('fromLayer') &&
      mapRef.current.getLayer('toLayer')
    ) {
      mapRef.current.moveLayer('fromLayer')
      mapRef.current.moveLayer('toLayer')
    }
  }
}

export default MapNavigation
