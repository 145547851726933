import { IShipInfo } from '../../../../../services/TeqplayApiService/TeqplayApi'

export function getShipColor(shipType?: IShipInfo['shipType'] | string): {
  code: string
} {
  if (!shipType) {
    return { code: `rgb(110, 110, 110)` }
  }
  // GRAY
  if (shipType.indexOf('CARGO') >= 0) {
    return { code: `rgb(8, 187, 0)` }
  }
  // GREEN
  if (shipType.indexOf('TANKER') >= 0) {
    return { code: `rgb(187, 0, 0)` }
  }
  // RED
  if (
    shipType === 'TUG' ||
    shipType === 'NOT_PARTIES_TO_AN_ARMED_CONFLICT' ||
    shipType === 'FISHING' ||
    shipType === 'PORT_TENDER' ||
    shipType.indexOf('TOWING') >= 0 ||
    shipType.indexOf('DREDGING') >= 0 ||
    shipType.indexOf('ANTI_POLLUTION') >= 0 ||
    shipType.indexOf('PILOT_VESSEL') >= 0
  ) {
    return { code: `rgb(61, 223, 255)` }
  }
  // LIGHT BLUE
  if (shipType === 'HIGHSPEED') {
    return { code: `rgb(240, 179, 0)` }
  }
  // YELLOW
  if (
    shipType.indexOf('PASSENGER') >= 0 ||
    shipType.indexOf('DIVING') >= 0 ||
    shipType.indexOf('SEARCH_AND_RESCUE') >= 0 ||
    shipType.indexOf('MILITARY') >= 0 ||
    shipType === 'LAW_ENFORCEMENT_VESSEL'
  ) {
    return { code: `rgb(27, 53, 77)` }
  }
  // DARK BLUE
  if (shipType === 'WING_IN_GROUND' || shipType === 'PLEASURE_CRAFT' || shipType === 'SAILING') {
    return { code: `rgb(189, 54, 157)` }
  }
  // PINK
  if (
    shipType.indexOf('OTHER_TYPE') >= 0 ||
    shipType === 'SPARE' ||
    shipType === 'NOT_AVAILABLE' ||
    shipType === 'UNDEFINED' ||
    shipType === 'BASESTATION'
  ) {
    return { code: `rgb(110, 110, 110)` }
  }
  // GREY

  return { code: `rgb(110, 110, 110)` }
}
