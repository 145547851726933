import { ILoggedInUser, INewUser } from '../services/TeqplayApiService/TeqplayApi'
import { getEnvironment } from './cordovaUtils'
import { MapTypes } from '../@types/types'

export const BACKEND_URL =
  getEnvironment() === 'production' || getEnvironment() === 'mobile'
    ? 'https://backendfriesland.teqplay.nl'
    : 'https://backendfrieslanddev.teqplay.nl'

export const ANONYMOUS_TOKEN = 'Basic cHJvbnRvaGFtaXM6cmFjIXdhckFkdSFBNmE='
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || ''
export const MAP_BOX_DEFAULT = 'mapbox://styles/teqplay/cjx4h589s2jnr1cpalir4sv0d'

/**
 * Switch to enable the trip monitor for debug purposes
 * Enabled status means that ALL of the users location points
 * are saved to the users' device.
 *
 * ONLY USE FOR INTERNAL DEBUGGING!
 */
export const ENABLE_TRIP_MONITOR = false

export const GENERAL_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'TEQPLAY-APP': 'VARENFRIESLAND'
}

export const GENERAL_HEADERS_FORM_DATA = {
  'Cache-Control': 'no-cache',
  'TEQPLAY-APP': 'VARENFRIESLAND'
}

export const SMALL_SHIP_SIZE = 40

export const FRIESLAND_LOCATION = { lat: 53.124964, lng: 5.925235 }

export const SCREEN_WIDTH_TO_SPLIT_VIEW = 1000

// TIME
export const TIME_FORMAT = 'HH:mm'
// DATE FORMATS
export const SHORT_DATE_FORMAT = 'd MMM yyyy'
// DATE + TIME FORMATS
export const DATE_TIME_FORMAT = 'd MMMM yyyy HH:mm'
export const SHORT_DATE_TIME_FORMAT = 'd MMM HH:mm'

// Bridge times
export const BRIDGE_DATE_FORMAT = 'EEEE d MMMM' // weekday day month
export const BRIDGE_SHORT_DATE_FORMAT = 'd MMMM' // day month

// Interval times
export const INTERVAL_LOCATION_PLUGGED_IN = 3 * 1000
export const INTERVAL_INACTIVE_ROUTE = 30 * 1000
// IOS
export const INTERVAL_LOCATION_IOS_FORGROUND = 5 * 1000
export const INTERVAL_LOCATION_IOS_BACKGROUND = 30 * 1000

// ANDROID
export const INTERVAL_LOCATION_ANDROID_FORGROUND = 5 * 1000
export const INTERVAL_LOCATION_ANDROID_BACKGROUND = 30 * 1000
export const CHECK_INTERVAL_PASSED_ANDROID = 10 * 1000
export const TIMEOUT_LOCATION_WATCH = 10 * 1000

export const FETCH_BRIDGE_OPENINGS = 10 * 1000
export const CHECK_USER_OFFLINE = 60 * 1000
export const FETCH_CURRENT_MAX_SPEED = 60 * 1000
export const INTERVAL_FETCH_CURRENT = 60 * 1000
export const INTERVAL_FETCH_ETA = 60 * 1000

// refresh interval to retrieve the actual weather information
export const UPDATE_WEATHER_INTERVAL = 5 * 60 * 1000 // 5 min

export const AISSHIPS_ZOOM_LEVEL = 13

export const mapTypeOptions: MapTypes[] = ['DEFAULT', 'SATELLITE', 'ECDIS', 'OPENSTREETMAP']

export const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

export const EMPTY_USER_AUTH: INewUser & ILoggedInUser = {
  token: '',
  anonymous: undefined,
  teqplayId: null,
  userName: null,
  emailAddress: null,
  shipType: null,
  shipName: null,
  length: null,
  height: null,
  width: null,
  draught: null,
  createdAt: null,
  expiresInSeconds: null,
  refreshToken: null,
  timestamp: null
}

export const recreationalClasses = [
  'OTHER_TYPE',
  'LAW_ENFORCEMENT_VESSEL',
  'FISHING',
  'MOTOR_YACHT',
  'SPEEDBOAT',
  'SAILING_WITH_MOTOR',
  ' SPORT_FISHERMEN_VESSEL',
  'SAILING_MOTOR_BIG',
  'OTHER_RECREATIONAL_VESSEL',
  'SAILING',
  'PLEASURE_CRAFT',
  'NOT_AVAILABLE',
  'SPARE',
  'UNDEFINED',
  'BASESTATION'
]
