export function offsetCrossingMeridian(coordinates: number[][]) {
  let latitudeOffset = 0
  return coordinates.map((set, i) => {
    if (i > 0) {
      const previousLat = coordinates[i - 1][0]
      const diff = set[0] - previousLat
      if (diff > 180) {
        latitudeOffset = -360
        return [set[0] + latitudeOffset, set[1]]
      }
      if (diff < -180) {
        latitudeOffset = 360
        return [set[0] + latitudeOffset, set[1]]
      }
      return [set[0] + latitudeOffset, set[1]]
    }
    return set
  })
}
