import React, { useState } from 'react'
import { isCordovaApp } from '../../utils/cordovaUtils'
import { sendMessageToSentry } from '../../utils/sentry'

const CordovaWrapper = (props: { children: () => React.ReactNode }) => {
  const waitFor = (ms: number) => new Promise(r => setTimeout(r, ms))
  const [initialized, setInitialized] = useState(false)

  // Apply any cordova related plugin changes here
  React.useEffect(() => {
    const initialize = async () => {
      try {
        if (window.cordova) {
          await waitFor(1500)
          // Android notification specifics
          if (window.cordova?.plugins?.notification?.local) {
            window.cordova.plugins.notification.local.setDefaults({
              icon: '@drawable/notification_icon',
              smallIcon: 'res://notification_icon.png',
              color: '#c73326',
              led: { color: '#c73326', on: 500, off: 500 }
            })
          } else {
            throw new Error('Cordova local notifications plugin is not yet loaded')
          }

          // Needed so apps can open their browsers
          if (window.cordova.InAppBrowser) {
            window.open = window.cordova.InAppBrowser.open
          } else {
            throw new Error('Cordova InAppBrowser plugin is not yet loaded')
          }
        } else if (isCordovaApp) {
          throw new Error('Cordova is not yet loaded')
        }

        setInitialized(true)
      } catch (error) {
        sendMessageToSentry(error)
      }
    }

    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!initialized) {
    return null
  }

  return <React.Fragment>{props.children()}</React.Fragment>
}

export default CordovaWrapper
