import React, { useState, useEffect } from 'react'
import { Popup } from 'react-mapbox-gl'
import * as MapboxGl from 'mapbox-gl'
import { I18n } from 'react-redux-i18n'
import { formatDistanceToNow } from 'date-fns/esm'
import TeqplayApiService from '../../../../services/TeqplayApiService/TeqplayApiService'
import { getShipWidth, getShipLength } from '../../../../utils/ship'
import { returnDateFNSLocale } from '../../../../services/rest'
import { getClosestMarkerToLatLng } from './utils/getClosestMarkerToLatLng'
import { filterShipsSmartly } from './utils/filterShipsSmartly'
import { ZOOM_TYPES } from './utils/zoomTypes'
import AISContourLayer from './AISContourLayer'
import AISCircleLayer from './AISCircleLayer'
import AISFixedLayer from './AISFixedLayer'
import { labelAndValue } from '../../../../utils/labelAndValue'
import { IShipInfo } from '../../../../services/TeqplayApiService/TeqplayApi'
import { AISMarkerLayer } from './AISMarkerLayer'

interface IProps {
  teqplayApiService: TeqplayApiService
  zoom: number | undefined
  minZoom?: number
  bounds: MapboxGl.LngLatBounds | undefined
  locale: string
  cursor: HTMLCanvasElement | undefined
}

const AISMapboxLayer = ({ teqplayApiService, zoom, bounds, locale, cursor }: IProps) => {
  const [selectedShipID, setSelectedShipID] = useState<IShipInfo | null>(null)
  const [ships, setShips] = useState<IShipInfo[]>([])

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
      fetchData()
    }, 30000)
    // Update the Popup if the we get update
    if (selectedShipID) {
      const foundShip = ships.find(item => item._id === selectedShipID._id)
      if (foundShip && foundShip.timeLastUpdate !== selectedShipID.timeLastUpdate) {
        setSelectedShipID(foundShip)
      }
    }
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  if (!ships) {
    return null
  }
  const filteredShips = filterShipsSmartly(filterSizeShip(ships).bigShips, zoom, selectedShipID)

  return (
    <>
      <AISCircleLayer
        ships={filteredShips.circleIcons}
        handleLayerClick={handleLayerClick}
        onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
        onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
        minZoom={ZOOM_TYPES.circle[0]}
        maxZoom={ZOOM_TYPES.circle[1]}
      />
      <AISContourLayer
        ships={filteredShips.detailedShips}
        handleLayerClick={handleLayerClick}
        onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
        onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
        minZoom={ZOOM_TYPES.contour[0]}
        maxZoom={ZOOM_TYPES.contour[1]}
      />
      <AISFixedLayer
        ships={filteredShips.normalIcons}
        handleLayerClick={handleLayerClick}
        onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
        onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
        minZoom={ZOOM_TYPES.fixed[0]}
        maxZoom={ZOOM_TYPES.fixed[1]}
      />
      {zoom && zoom > 13 && (
        <AISMarkerLayer
          ships={filterSizeShip(ships).smallShips}
          category="ships"
          setSelectedShipID={setSelectedShipID}
          cursor={cursor}
        />
      )}
      {selectedShipID && (
        <Popup coordinates={selectedShipID.location.coordinates}>
          <div className="popup ship-popup">
            <span className="close icon-cancel-circled" onClick={() => setSelectedShipID(null)} />
            {labelAndValue(I18n.t('register.width'), getShipWidth(selectedShipID))}
            {labelAndValue(I18n.t('register.length'), getShipLength(selectedShipID))}
            {labelAndValue(
              I18n.t('mapLayers.last_updated'),
              `${formatDistanceToNow(selectedShipID.timeLastUpdate, {
                locale: returnDateFNSLocale(locale)
              })} ${I18n.t('mapLayers.past')}`
            )}
          </div>
        </Popup>
      )}
    </>
  )

  async function fetchData() {
    try {
      const data = await teqplayApiService.fetchShipsInBounds(bounds)
      setShips(data)
    } catch (err) {
      console.error(err)
    }
  }

  function handleLayerClick(event: MapboxGl.MapMouseEvent) {
    const closestMarkerToClickPos = getClosestMarkerToLatLng(
      [...filteredShips.circleIcons, ...filteredShips.detailedShips, ...filteredShips.normalIcons],
      event.lngLat
    )

    if (closestMarkerToClickPos) {
      setSelectedShipID(closestMarkerToClickPos)
    }
  }

  function filterSizeShip(shipList: IShipInfo[]) {
    const smallShips: IShipInfo[] = []
    const bigShips: IShipInfo[] = []
    shipList.forEach(item => {
      if (
        item.positionOfTransponder &&
        (item.speedOverGround > 2 || item.trueHeading !== 511) &&
        item.shipType !== 'BASESTATION' &&
        (getShipLength(item) || item.length || false) > 25
      ) {
        bigShips.push(item)
      } else {
        smallShips.push(item)
      }
    })
    return { smallShips: smallShips, bigShips: bigShips }
  }
}

export default AISMapboxLayer
