import { AnyAction } from 'redux'
import { loadFromLocalStorage, saveToLocalStorage } from '../../../utils/localStorage'
import { ISpeedState } from '../../geoLocationWatcher/GeoLocationWatcherTypes'

// Set inital login state
const initialState: ISpeedState = {
  speedType: 'CUSTOM',
  userInputSpeed: loadFromLocalStorage('WATERSPORT-cruiseSpeed', 4.32)
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function speedReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case 'SET_SPEED_TYPE': {
      return Object.assign({}, state, { speedType: action.speedType })
    }

    case 'SET_USER_INPUT_SPEED': {
      saveToLocalStorage('WATERSPORT-cruiseSpeed', action.userInputSpeed)
      return Object.assign({}, state, { userInputSpeed: action.userInputSpeed })
    }

    default:
      return state
  }
}
