import {
  addDays,
  differenceInDays,
  format,
  getHours,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  isTomorrow,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds
} from 'date-fns'
import Holidays, { Holiday } from 'date-holidays'
import { I18n } from 'react-redux-i18n'

import { IExtendedOperatingRule, IOperatingTimes } from '../@types/types'
import { IOperatingPeriod, IOperatingRule } from '../services/TeqplayApiService/TeqplayApi'
import { formatWeekdayDateTime, formatWeekdayDateTimeShort } from './dates'

interface IDateBasedRuleset {
  validDates: { date: Date; textFormatted: string; isHoliday: false | Holiday }[]
  isHoliday: false | Holiday
  validRulesForToday: IExtendedOperatingRule[]
}

interface IActiveOperatingPeriod {
  start: {
    day: number
    month: number
  }
  end: {
    day: number
    month: number
  }
  rwsId: number
  rules: IExtendedOperatingRule[]
  note: string
}

export function determineOperatingRulesForDaySet(
  operatingTimes: IOperatingTimes | undefined,
  locale: string,
  start?: Date,
  end?: Date
): { dateBasedRules: IDateBasedRuleset[]; activeOperatingPeriods: IActiveOperatingPeriod[] } {
  const dateBasedRules: IDateBasedRuleset[] = []
  const operatingPeriods =
    operatingTimes?.operatingPeriods?.map((value, key) => ({
      ...determineOperatingPeriodRules(value),
      start: value.start,
      end: value.end,
      rwsId: value.rwsId,
      note: value.note
    })) || []

  const activeOperatingPeriods: IActiveOperatingPeriod[] = []

  if (operatingTimes) {
    const fromDate = start || new Date()
    const toDate = end || addDays(fromDate, 7)
    const dayAmount = differenceInDays(toDate, fromDate)

    if (fromDate.valueOf() >= toDate.valueOf()) {
      throw new Error('End date passed to function is earlier or equal to the start date')
    } else if (fromDate.valueOf() === toDate.valueOf()) {
      console.error('Start date and end date are the same')
    }

    const holidays = new Holidays({ country: 'NL' })
    // Setting this to NL since it can only be filtered out by names
    const holidaysNL = new Holidays('NL', { languages: 'nl' })
    const operativeHolidays = ['Koningsdag', 'Goede Vrijdag', 'Bevrijdingsdag', 'O.L.H. Hemelvaart']
    for (let i = 0; i < dayAmount; i++) {
      const thisDay = addDays(fromDate, i)
      const isHoliday = holidays.isHoliday(thisDay)
      const isHolidayNL = holidaysNL.isHoliday(thisDay)
      const isNonOperativeHoliday = isHolidayNL && !operativeHolidays.includes(isHolidayNL.name)
      const isValidHoliday = isHoliday && isHoliday.type !== 'observance' && isNonOperativeHoliday
      const validRulesForToday: IExtendedOperatingRule[] = []

      operatingPeriods.forEach(period => {
        if (
          inRange(
            period.start.day,
            period.start.month,
            period.end.day,
            period.end.month,
            period.rwsId,
            format(thisDay, 'yyyy/MM/dd HH:mm:ss'),
            { min: fromDate.getFullYear(), max: toDate.getFullYear() }
          )
        ) {
          period.rules.forEach(r => {
            if (
              (r.daysArray.includes(thisDay.getDay()) && !isValidHoliday) ||
              (r.isHoliday && isValidHoliday)
            ) {
              validRulesForToday.push(r)
            }
          })
          activeOperatingPeriods.push(period)
        }
      })

      dateBasedRules.push({
        validDates: [
          {
            date: thisDay,
            isHoliday,
            textFormatted: isToday(thisDay)
              ? `${I18n.t(
                  'routeList.itemDetails.bridge_operating_rules.today'
                )} ${formatWeekdayDateTimeShort(thisDay.toISOString(), locale)}`
              : isTomorrow(thisDay)
              ? `${I18n.t(
                  'routeList.itemDetails.bridge_operating_rules.tomorrow'
                )} ${formatWeekdayDateTimeShort(thisDay.toISOString(), locale)}`
              : formatWeekdayDateTime(thisDay.toISOString(), locale)
          }
        ],
        isHoliday,
        validRulesForToday
      })
    }
  }

  // Unused piece of code, allows to merge days with consecutive rules into a single item
  // Detection if rules, notes are all the same for consecutive days
  // let mergedRuleIndicies: number[] = []

  // const mergedDayRules: IDateBasedRuleset[] = dateBasedRules.map((rule, i) => {
  //   let consecutive = false

  //   if (!mergedRuleIndicies.includes(i)) {
  //     const validDates = [...rule.validDates]
  //     const subMergedIndicies = [i]

  //     dateBasedRules.forEach((r, j) => {
  //       consecutive = r.validDates[0].date.valueOf() - validDates[validDates.length - 1].date.valueOf() === (24 * 60 * 60 * 1000)

  //       if (i === j || mergedRuleIndicies.includes(j)) {
  //         return
  //       } else if (consecutive && isDeepEqual(r.validRulesForToday, rule.validRulesForToday)) {
  //         subMergedIndicies.push(j)
  //         validDates.push(r.validDates[0])
  //       } else {
  //         consecutive = false
  //       }
  //     })

  //     if (
  //       consecutive === false
  //       || subMergedIndicies.length === differenceInDays(end || addDays(start || new Date(), 7), start || new Date())  // checks if rule counts all days
  //       || mergedRuleIndicies.concat(subMergedIndicies).length === differenceInDays(end || addDays(start || new Date(), 7), start || new Date())
  //     ) {
  //       mergedRuleIndicies = mergedRuleIndicies.concat(subMergedIndicies)
  //       return { ...rule, validDates: validDates.sort((a, b) => a.date.valueOf() - b.date.valueOf()) }
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return null
  //   }
  // }).filter(x => x) as IDateBasedRuleset[]

  return { dateBasedRules, activeOperatingPeriods }
}

/** Determines whether specified Date is in range of operating period */
function inRange(
  startDay: number,
  startMonth: number,
  endDay: number,
  endMonth: number,
  rwsId?: number,
  checkDate?: string,
  yearMinMax?: { min: number; max: number }
) {
  const originalDate = checkDate ? new Date(checkDate) : null
  // Check date needs to be 1999 OR 2000
  const date =
    yearMinMax?.min === yearMinMax?.max || originalDate?.getFullYear() === yearMinMax?.min
      ? setHours(
          setMinutes(
            setSeconds(
              setMilliseconds(
                new Date(
                  checkDate ? new Date(checkDate).setFullYear(2000) : new Date().setFullYear(2000)
                ),
                0
              ),
              0
            ),
            0
          ),
          0
        )
      : setHours(
          setMinutes(
            setSeconds(
              setMilliseconds(
                new Date(
                  checkDate ? new Date(checkDate).setFullYear(2001) : new Date().setFullYear(2001)
                ),
                0
              ),
              0
            ),
            0
          ),
          0
        )

  const currentDay = date.getDate()
  const currentMonth = date.getMonth() + 1 // JS month is 0 indexed but data is not

  let startYear = 2000
  let endYear = 2000

  // This encapsulates rules which extend into a new year
  if (startMonth > endMonth) {
    // Check if current month is inside window
    if (
      currentMonth === startMonth || // Current date is inside starting month
      currentMonth > startMonth || // end month is next year since that is smaller than start month, inside window
      (currentMonth < startMonth && currentMonth > endMonth) // end month is next year since it still has to take place in this year, outside window
    ) {
      endYear += 1
    } else if (
      (currentMonth === endMonth && currentDay <= endDay) || // Current date is inside ending month, before end day
      currentMonth < endMonth // start month was last year since that is greater than endMonth, since startMonth is greater than endMonth
    ) {
      // Check if max year is equal to originalDate year and min/max years not equal to eachother
      if (yearMinMax?.max === originalDate?.getFullYear() && yearMinMax?.min !== yearMinMax?.max) {
        endYear += 1
      } else {
        startYear -= 1
      }
    } else {
      // TO-DO: Send directly to sentry
      console.error(`Uncaught Bridge range date conversion for RWS ID ${rwsId}`, {
        start: { startDay, startMonth },
        current: { currentDay, currentMonth },
        end: { endDay, endMonth }
      })
    }
  } else if (startMonth === endMonth && startDay > endDay) {
    // Example: xxxx-01-13 until xxxx-01-12
    if (currentDay > endDay && currentMonth >= endMonth) {
      endYear += 1
    } else {
      startYear -= 1
    }
  }

  const startDate = new Date(`${startYear}/${startMonth}/${startDay} 00:00:00`)
  const endDate = new Date(`${endYear}/${endMonth}/${endDay} 23:59:59`)

  if (
    (isAfter(date, startDate) && isBefore(date, endDate)) ||
    isEqual(date, startDate) ||
    isEqual(date, endDate)
  ) {
    return true
  } else {
    return false
  }
}

function determineOperatingPeriodRules(operatingPeriod: IOperatingPeriod): {
  rules: IExtendedOperatingRule[]
} {
  const ruleArray: IExtendedOperatingRule[] = []

  operatingPeriod.operatingRules?.forEach((value, key) => {
    const extractRule = extractOperatingRules(value)
    if (extractRule) {
      ruleArray.push(extractRule)
    }
  })

  const ruleSorted = mergeOperatingRules(ruleArray).sort((a, b) => {
    if (a.days === b.days && a.from && b.from) {
      // Ruleset days are equal
      return a.from - b.from
    } else {
      return 1
    }
  })

  return {
    //  period: period,
    rules: ruleSorted
  }
}

function extractOperatingRules(rule: IOperatingRule): IExtendedOperatingRule | null {
  if (rule.from && rule.to) {
    const operatingDays = []

    if (rule.isHoliday) {
      operatingDays.push(-1)
    }
    if (rule.isSunday) {
      operatingDays.push(0)
    }
    if (rule.isMonday) {
      operatingDays.push(1)
    }
    if (rule.isTuesday) {
      operatingDays.push(2)
    }
    if (rule.isWednesday) {
      operatingDays.push(3)
    }
    if (rule.isThursday) {
      operatingDays.push(4)
    }
    if (rule.isFriday) {
      operatingDays.push(5)
    }
    if (rule.isSaturday) {
      operatingDays.push(6)
    }

    if (operatingDays.length > 0) {
      return {
        ...rule,
        daysArray: operatingDays,
        startTime: getHours(rule.from),
        endTime: getHours(rule.to)
      } as IExtendedOperatingRule
    }
  }

  return null
}

/** log n^2 */
function mergeOperatingRules(rules: IExtendedOperatingRule[]): IExtendedOperatingRule[] {
  const mergedRules: IExtendedOperatingRule[] = []
  const ignorableIndicies: number[] = [] // index-values of rules array which have been merged and can be ignored

  rules.forEach((r, i) => {
    if (!ignorableIndicies.includes(i)) {
      // Set temporary values for start/end time
      let startTime = r.from
      let endTime = r.to
      const notes: (string | undefined)[] = [
        r.Recommendation
          ? I18n.t(`routeList.itemDetails.bridgeRecommendation.${r.Recommendation}`)
          : undefined,
        r.note
      ].filter(n => n)
      const mergedRuleIds: number[] = [r.rwsId]

      rules.forEach((iterableRule, irIndex) => {
        if (iterableRule.note !== r.note || iterableRule.Recommendation !== r.Recommendation) {
          // Notes differ or Recommendation differs, thus rule should not be merged since different side effects might apply
          return
        }

        if (
          !ignorableIndicies.includes(irIndex) &&
          irIndex !== i &&
          startTime &&
          endTime &&
          iterableRule.daysArray.join() === r.daysArray.join()
        ) {
          if (
            iterableRule.from &&
            iterableRule.to &&
            (iterableRule.to === startTime || // End time of iterableRule is equal to start time of r
              (iterableRule.from < startTime &&
                iterableRule.to < endTime &&
                iterableRule.to > startTime)) // iterableRule extends rule backwards in time
          ) {
            // iterableRule starts outside and ends inside
            startTime = iterableRule.from
            notes.push(iterableRule.note)
            ignorableIndicies.push(irIndex)
            mergedRuleIds.push(iterableRule.rwsId)
          } else if (
            iterableRule.from &&
            iterableRule.to &&
            (iterableRule.from === endTime || // Start time of iterableRule is equal to end time of r
              (iterableRule.from > startTime &&
                iterableRule.from < endTime &&
                iterableRule.to > endTime)) // iterableRule extends rule forwards in time
          ) {
            // iterableRule starts inside and ends outside
            endTime = iterableRule.to
            notes.push(iterableRule.note)
            ignorableIndicies.push(irIndex)
            mergedRuleIds.push(iterableRule.rwsId)
          } else if (
            iterableRule.from &&
            iterableRule.to &&
            startTime <= iterableRule.from &&
            endTime >= iterableRule.to
          ) {
            // iterableRule is fully encapsulated by other rule
            notes.push(iterableRule.note)
            ignorableIndicies.push(irIndex)
            mergedRuleIds.push(iterableRule.rwsId)
          }

          // In theory, rule is now deemed unmergable
        }
      })

      mergedRules.push({
        ...r,
        startTime: startTime ? new Date(startTime).getHours() : r.startTime,
        endTime: endTime ? new Date(endTime).getHours() : r.endTime,
        from: startTime,
        to: endTime,
        note: undefined,
        notes: notes.filter(n => n) as string[],
        mergedRuleIds,
        timespan:
          startTime && endTime
            ? `${format(startTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`
            : 'N/A'
      })
    }
  })

  return mergedRules
}
