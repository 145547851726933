import React, { useState } from 'react'
import { Feature, Layer } from 'react-mapbox-gl'
import { IShipInfo } from '../../../../services/TeqplayApiService/TeqplayApi'
import { recreationalClasses } from '../../../../utils/constants'
import orangeIcon from './orange.svg'
import blue from './blue.svg'

interface IProps {
  ships: IShipInfo[]
  category: string
  setSelectedShipID: (ship: IShipInfo) => void
  cursor: HTMLCanvasElement | undefined
}

interface IIconlayer extends IProps {
  specificIcon: string
  recreational?: boolean
}

interface ICreateIcon {
  title: string
  image: string
}

export const AISMarkerLayer = (props: IProps) => {
  const [loadedIcon, setLoadedIcon] = useState<boolean>(false)

  const create = createIcons([
    { image: orangeIcon, title: 'recreational' },
    { image: blue, title: 'non-recreational' }
  ])
  Promise.all(create.map(item => item[1]).map(imageIsLoaded)).then(() => {
    setLoadedIcon(true)
  })
  return (
    <>
      {loadedIcon && (
        <>
          <Iconlayer
            {...props}
            specificIcon={create[0]}
            category="recreational"
            ships={props.ships.filter(item => recreationalClasses.includes(item.shipType))}
            recreational
          />
          <Iconlayer
            {...props}
            specificIcon={create[1]}
            category="non-recreational"
            ships={props.ships.filter(item => !recreationalClasses.includes(item.shipType))}
          />
        </>
      )}
    </>
  )
  function imageIsLoaded(image: HTMLImageElement) {
    return new Promise((resolve: any) => {
      image.onload = () => resolve()
      image.onerror = () => resolve()
    })
  }
  function createIcons(images: ICreateIcon[]) {
    const allImages: any[] = []
    images.forEach(item => {
      const image = new Image(40, 40)
      image.src = item.image
      allImages.push([item.title, image])
    })
    return allImages
  }
}

const Iconlayer = ({
  category,
  recreational,
  specificIcon,
  cursor,
  ships,
  setSelectedShipID
}: IIconlayer) => {
  const defaultLayout = {
    'icon-image': category,
    'icon-allow-overlap': true,
    'icon-rotate': ['get', 'rotation'],
    'icon-ignore-placement': true,
    'icon-size': recreational ? 0.9 : 0.75
  }
  return (
    <Layer
      type="symbol"
      id={category}
      source={category}
      layout={defaultLayout}
      images={specificIcon}
      onMouseEnter={() => (cursor ? (cursor.style.cursor = 'pointer') : '')}
      onMouseLeave={() => (cursor ? (cursor.style.cursor = '') : '')}
    >
      {ships.map(item => {
        const shipHeading =
          item.trueHeading > 0 && item.trueHeading <= 360 ? item.trueHeading : item.courseOverGround
        return (
          <Feature
            coordinates={item.location.coordinates}
            key={item._id}
            properties={{ rotation: shipHeading }}
            onClick={() => setSelectedShipID(item)}
          />
        )
      })}
    </Layer>
  )
}
