import React, { useEffect } from 'react'

import { useInterval } from '../../customHooks/useInterval'
import { IWatchDogStatus } from '../../services/TeqplayApiService/TeqplayApi'
import TeqplayApiService from '../../services/TeqplayApiService/TeqplayApiService'
import { isCordovaApp } from '../../utils/cordovaUtils'

export function useBridgeWatchdog(api: TeqplayApiService) {
  const [status, setStatus] = React.useState<IWatchDogStatus>({ enabled: false, healthy: false })
  const WATCHDOG_FETCH_INTERVAL = 1000 * 60 * 5 // 5 min

  useEffect(() => {
    const fetchIntialWatchdogStatus = async () => {
      try {
        const newStatus = await api.getBridgeWatchdogStatus()
        setStatus(newStatus)
      } catch (error) {
        console.error(
          `[useBridgeWatchdog] ${error.status}: ${error.message} (statusCode: ${error.statusCode})`,
          error
        )
        setStatus({ enabled: false, healthy: false })
      }
    }

    fetchIntialWatchdogStatus()
  }, [api])

  useInterval(() => {
    if (isCordovaApp) {
      fetchWatchdogStatus()
    }
  }, WATCHDOG_FETCH_INTERVAL)

  const fetchWatchdogStatus = async () => {
    try {
      const newStatus = await api.getBridgeWatchdogStatus()
      setStatus(newStatus)
    } catch (error) {
      console.error(
        `[useBridgeWatchdog] ${error.status}: ${error.message} (statusCode: ${error.statusCode})`,
        error
      )
      setStatus({ enabled: false, healthy: false })
    }
  }

  return status
}

export const BridgeWatchdogContext = React.createContext({
  status: { enabled: false, healthy: false }
})

BridgeWatchdogContext.displayName = 'BridgeWatchdogContext'

interface IBridgeWatchDogComponentProps {
  api: TeqplayApiService
  children: (status: IWatchDogStatus) => React.ReactNode
}

export const BridgeWatchdogComponent = ({ api, children }: IBridgeWatchDogComponentProps) => {
  const status = useBridgeWatchdog(api)

  return <React.Fragment>{children(status)}</React.Fragment>
}
