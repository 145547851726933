import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import { IRouteItem } from '../../../services/TeqplayApiService/TeqplayApi'
import '../../itemDetails/ItemDetails.scss'

interface IProps {
  routeItem: IRouteItem
}

class WaitingTimeItem extends React.PureComponent<IProps> {
  public render() {
    const routeItem = this.props.routeItem
    if (!routeItem.waitingTime) return null
    let waitingTimeMinutes = Math.round(routeItem.waitingTime / 60000) // From miliseconds to minutes
    let waitingTimeHours = 0

    if (waitingTimeMinutes > 60) {
      const fullHours = Math.floor(waitingTimeMinutes / 60)
      waitingTimeMinutes -= fullHours * 60
      waitingTimeHours = fullHours
    }

    let waitingTimeString = waitingTimeHours ? waitingTimeHours + 'h, ' : ''
    waitingTimeString += waitingTimeMinutes + 'm'

    const objectType = I18n.t('routeList.objectTypes.' + routeItem.type)

    return (
      <div id={routeItem._id} className="route-list-item waiting-item-item">
        <div className={'item-info'}>
          <div className="info-wrapper">
            <span className={'icon-clock orange item-detail-icon'} />
            <span className="item-title">
              {I18n.t('routeList.itemDetails.waitingTime', {
                waitingTime: waitingTimeString,
                objectType
              })}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default WaitingTimeItem
