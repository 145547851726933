import React from 'react'
import * as MapboxGl from 'mapbox-gl'
import { I18n } from 'react-redux-i18n'

import DefaultMarkerLayer from './DefaultMarkerLayer'
import TeqplayApiService from '../../../services/TeqplayApiService/TeqplayApiService'
import ChargingStationIcon from '../icons/chargingStations.svg'

import { labelAndValue } from '../../../utils/labelAndValue'
import { IChargingStation } from '../../../services/TeqplayApiService/TeqplayApi'

interface IProps {
  bounds?: MapboxGl.LngLatBounds
  teqplayApiService: TeqplayApiService
  cursor: HTMLCanvasElement | undefined
}

const CharginStations: React.FunctionComponent<IProps> = props => {
  return (
    <DefaultMarkerLayer<IChargingStation>
      fetchCall={() => props.teqplayApiService.fetchChargingStations(props.bounds)}
      bounds={props.bounds}
      category="CharginStation"
      cursor={props.cursor}
      iconUrl={ChargingStationIcon}
      getPopup={(chargingStation: IChargingStation) => {
        return (
          <>
            <h3>{chargingStation.name}</h3>
            {labelAndValue(I18n.t('mapLayers.chargingStations.city'), chargingStation.city)}
            {labelAndValue(
              I18n.t('mapLayers.chargingStations.chargingPointAvailable'),
              chargingStation.chargingPointAvailable
            )}
            {labelAndValue(
              I18n.t('mapLayers.chargingStations.publicAvailable'),
              chargingStation.publicAvailable
            )}
            {labelAndValue(
              I18n.t('mapLayers.chargingStations.greenPower'),
              chargingStation.greenPower
            )}
            {labelAndValue(
              I18n.t('mapLayers.chargingStations.paymentMethod'),
              I18n.t(
                `mapLayers.chargingStations.paymentMethods.${
                  chargingStation.paymentMethod || 'UNKNOWN'
                }`
              )
            )}
            {labelAndValue(I18n.t('mapLayers.chargingStations.comments'), chargingStation.comments)}

            {((chargingStation.power230V && chargingStation.power230V.enabled) ||
              (chargingStation.power400V && chargingStation.power400V.enabled)) && (
              <div>
                <h4>{I18n.t('mapLayers.chargingStations.chargingPossibilities')}</h4>
                {labelAndValue(
                  '230V',
                  chargingStation.power230V && chargingStation.power230V.enabled
                    ? `${
                        chargingStation.power230V.TotalInKW
                          ? chargingStation.power230V.TotalInKW + I18n.t('units.kilowatt')
                          : null
                      } - ${
                        chargingStation.power230V.AmountOfAmpere
                          ? chargingStation.power230V.AmountOfAmpere + I18n.t('units.amps')
                          : null
                      }`
                    : I18n.t('mapLayers.chargingStations.unavailable')
                )}
                {chargingStation.power230V &&
                  chargingStation.power230V.enabled &&
                  chargingStation.power230V.connectionPoints &&
                  labelAndValue(
                    `230V ${I18n.t('mapLayers.chargingStations.greenPower')}`,
                    chargingStation.power230V.connectionPoints
                  )}

                <div className="label-value-wrapper">
                  {labelAndValue(
                    '400V',
                    chargingStation.power400V && chargingStation.power400V.enabled
                      ? `${
                          chargingStation.power400V.TotalInKW &&
                          chargingStation.power400V.TotalInKW + I18n.t('units.kilowatt')
                        } - ${
                          chargingStation.power400V.AmountOfAmpere &&
                          chargingStation.power400V.AmountOfAmpere + I18n.t('units.amps')
                        }`
                      : I18n.t('mapLayers.chargingStations.unavailable')
                  )}
                </div>
                {chargingStation.power400V &&
                  chargingStation.power400V.enabled &&
                  chargingStation.power400V.connectionPoints &&
                  labelAndValue(
                    `400v ${I18n.t('mapLayers.chargingStations.connections')}`,
                    chargingStation.power400V.connectionPoints
                  )}
              </div>
            )}
          </>
        )
      }}
    />
  )
}

export default CharginStations
