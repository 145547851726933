import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'

import { IRouteSegment } from '../../../services/TeqplayApiService/TeqplayApi'
import { decodeRoutePolyline } from '../../../utils/decodeRoutePolyline'

interface IProps {
  routeNetwork: IRouteSegment[]
}

/* Color for each Feature Depth */
const fairwayDepth = [
  'case',
  ['all', ['>=', ['get', 'fairwayDepth'], 3]],
  '#488f31',
  ['>=', ['get', 'fairwayDepth'], 2.5],
  '#45a074',
  ['>=', ['get', 'fairwayDepth'], 2.0],
  '#72b97c',
  ['>=', ['get', 'fairwayDepth'], 1.8],
  '#c2e38c',
  ['>=', ['get', 'fairwayDepth'], 1.6],
  '#72b97c',
  ['>=', ['get', 'fairwayDepth'], 1.4],
  '#fdd172',
  ['>=', ['get', 'fairwayDepth'], 1.2],
  '#f7a258',
  ['>=', ['get', 'fairwayDepth'], 1.0],
  '#ea714e',
  ['>=', ['get', 'fairwayDepth'], 0.8],
  '#de425b',
  '#cecece'
]

const FairwayDepthLayer = (props: IProps) => {
  return (
    <GeoJSONLayer
      data={{
        type: 'FeatureCollection',
        features: props.routeNetwork.map(line => {
          const latlng = decodeRoutePolyline(line)
          return {
            type: 'Feature',
            properties: { fairwayDepth: line.info.minimumDepth || 0 },
            geometry: {
              type: 'LineString',
              coordinates: latlng
            }
          }
        })
      }}
      linePaint={{
        'line-color': [...fairwayDepth],
        'line-width': 3
      }}
    />
  )
}
export default FairwayDepthLayer
