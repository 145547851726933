import * as Sentry from '@sentry/browser'
import BackgroundGeolocation, {
  LocationError,
  ProviderChangeEvent
} from 'cordova-background-geolocation-lt'
import { isAfter, subMinutes } from 'date-fns'
import { headingDistanceTo, LonLatTuple, toLatLon } from 'geolocation-utils'
import cloneDeep from 'lodash/cloneDeep'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import pointInPolygon from 'point-in-geopolygon'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { toast } from 'react-toastify'

import GPSNotification from './gpsNotification/GPSNotification'
import LocationPermissionDialog from './locationPermissionDialog/LocationPermissionDialog'
import TripMonitor from './tripMonitor/TripMonitor'

import { IExtendedPropertyLocation } from '../../@types/types'
import { ENABLE_TRIP_MONITOR } from '../../utils/constants'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/localStorage'
import { VDJS_INNER_RADIUS, VDJS_OUTER_RADIUS } from './GeoLocationWatcherConfig'
import {
  IChannelCrossing,
  IFullPosition,
  IGeoLocationWatcherState,
  IRouteEtaUpdate,
  ISpeedState,
  IVDJSCollection,
  IVDJSCrossing
} from './GeoLocationWatcherTypes'
import {
  getCurrentLocationOnce,
  pauseGeoLocationPlugin,
  resumeGeoLocationPlugin,
  setGPSFetchDistance,
  setupGeoLocationPlugin
} from './MobileGeoLocationUtils'
import { ISelectedRoute } from '../../services/TeqplayApiService/TeqplayApi'

interface IState {
  speedNotificationSend: boolean
  lastLocations: IFullPosition[]
  fiveLastSpeeds: number[]
  channelCrossing: IChannelCrossing | null
  vdjsCrossing: IVDJSCrossing | null

  // variables to check whether the route has been paused while in the background
  appInBackground: boolean
  lastRoutePausedCheck: Date | null

  // Notification messages
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null

  // Plugin status, saved locally only for debugging
  pluginPermissions: { enabled: false } | ProviderChangeEvent
  approved: boolean | 'inactive'
  fetchingPosition: boolean
  locationError: LocationError | undefined
}

interface IProps {
  routeSelection: ISelectedRoute | null // current selected route
  isNavigating: boolean // wether the user is navigating or not, based on a url, or some kind of 'navigating mode'
  VDJSCrossings?: IVDJSCollection // optional: A document with VDJS Crossings the user needs to be warned for.
  channelCrossings?: IChannelCrossing[] // optional: A document with dangerous channels the user needs to be warned for.
  currentLocation: IFullPosition | null

  setCurrentLocation: (location: IFullPosition, appInBackground?: boolean) => void
  getSelectedRouteEtaUpdate: () => Promise<IRouteEtaUpdate | undefined>
  children: (
    VDJSNotificationMessage: string | null,
    channelApproachNotificationMessage: string | null,
    geoLocationWatcherState: IGeoLocationWatcherState
  ) => React.ReactNode
  isAnonymous: boolean

  // Trip monitor specific
  userHistory: IExtendedPropertyLocation[]
  setUserHistory: (history: IExtendedPropertyLocation[]) => void
  sessionID: string
}

class GeoLocationWatcher extends React.PureComponent<IProps, IState> {
  private intervalRestartPlugin: number | null = null
  public readonly state: Readonly<IState> = {
    speedNotificationSend: false,
    lastLocations: [],
    fiveLastSpeeds: [],
    channelCrossing: null,
    vdjsCrossing: null,
    appInBackground: false,
    lastRoutePausedCheck: null,
    VDJSNotificationMessage: null,
    channelApproachNotificationMessage: null,
    pluginPermissions: {
      enabled: false
    },
    approved: loadFromLocalStorage('APPROVED-LOCATION', false),
    fetchingPosition: false,
    locationError: undefined
  }

  public componentDidMount() {
    // If the user is on mobile ( cordova ) launch the mobile location service
    // Otherwise we use the browsers location.
    // Make sure user has previously approved the usage of its location inside the app
    if (this.state.approved === true) {
      // But not on Android because of https://github.com/transistorsoft/cordova-background-geolocation-lt/issues/1149
      if (window.cordova && window.cordova?.platformId !== 'android') {
        this.bootupPlugin(true)
      } else if (window.cordova && window.cordova.platformId === 'android') {
        // Somehow check if plugin is started without deadlocking
        setupGeoLocationPlugin(
          this.onLocationSuccess,
          e => this.savePluginPermissions(e),
          // Safe to bootup the rest
          s => this.bootupPlugin(false),
          e => {
            throw new Error(e)
          },
          e => this.handleSetLocationError(e)
        )

        BackgroundGeolocation.getProviderState(e => this.savePluginPermissions(e))
      } else if (!window.cordova) {
        // Create desktopGeoLocationService only for browsers
        this.getBrowserLocation()
      }

      if (window.cordova) {
        // Activate the interval kicking-restart mechanism
        this.setKickPluginIntervals()
      }
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (window.cordova) {
      if (
        // user is switching pages or route paused / unpaused changes
        this.props.isNavigating !== prevProps.isNavigating ||
        this.isRouteActive(this.props.routeSelection) !==
          this.isRouteActive(prevProps.routeSelection) ||
        (this.state.approved === true && prevState.approved !== true)
      ) {
        // Check if user has approved location service usage
        if (this.state.approved === true) {
          console.log('[GeoLocationWatcher] Reconfiguring service')
          this.reconfigureMobileGeoLocationService() // checks and changes the gps fetch distance
        } else if (this.state.approved === 'inactive') {
          // User has not approved location usage earlier, but wants to use it again so reshow prompt
          console.log(
            '[GeoLocationWatcher] Location service needed again but inactive, reshowing prompt'
          )
          this.setState({ approved: false })
        }
      }
    }

    if (this.state.locationError && !isEqual(this.state.locationError, prevState.locationError)) {
      toast.error(I18n.t(`geolocationStrings.errors.${this.state.locationError}`))
    }
  }

  public componentWillUnmount() {
    this.clearFetchIntervals()
    this.removeEventListeners()
  }

  public render() {
    const { VDJSNotificationMessage, channelApproachNotificationMessage, approved } = this.state
    return (
      <React.Fragment>
        <LocationPermissionDialog
          show={this.state.approved === false}
          onApprove={() => this.handleApprovedPermissions()}
          onCancel={() => this.handleDeniedPermissions()}
        />
        <GPSNotification
          active={window.cordova ? true : false}
          retryCallback={() => this.handleShowPermissionsDialogAgain()}
          providerConfig={this.state.pluginPermissions}
        />
        <TripMonitor
          enabled={ENABLE_TRIP_MONITOR}
          activeRoute={this.props.routeSelection}
          latestPosition={this.props.currentLocation}
          userHistory={this.props.userHistory}
          setUserHistory={this.props.setUserHistory}
          sessionID={this.props.sessionID}
        />
        {this.props.children(VDJSNotificationMessage, channelApproachNotificationMessage, {
          locationPermissions: {
            approved,
            retry: () => this.handleShowPermissionsDialogAgain()
          },
          fetchingPosition: this.state.fetchingPosition,
          locationError: this.state.locationError,
          getCurrentLocationOnce: () => {
            this.handleSetLocationError(undefined)
            getCurrentLocationOnce(
              state => this.handleSetFetchingPosition(state),
              position => this.setBrowserPosition(position),
              error => this.handleSetLocationError(error)
            )
          }
        }) || null}
      </React.Fragment>
    )
  }

  public handleApprovedPermissions() {
    // Save value to local storage so this popup only shown once
    saveToLocalStorage('APPROVED-LOCATION', true)
    this.setState({ approved: true })

    if (window.cordova) {
      this.bootupPlugin(true)
    } else {
      this.getBrowserLocation()
    }
  }

  public handleDeniedPermissions() {
    // Save inactive value to local storage, this way it is not shown to the user every time
    // it opens the app and only when it requests to start using geolocation
    saveToLocalStorage('APPROVED-LOCATION', 'inactive')
    this.setState({ approved: 'inactive' })
  }

  public handleShowPermissionsDialogAgain() {
    // Whenever user already rejected permissions to location services and later wants to enable these,
    // execute this function to show the prompt again.
    this.setState({ approved: false })
  }

  public async bootupPlugin(setup?: boolean, justResume?: boolean) {
    if (setup) {
      // Wait until setup is completed before doing the rest
      await setupGeoLocationPlugin(
        this.onLocationSuccess,
        e => this.savePluginPermissions(e),
        async () => BackgroundGeolocation.getProviderState(e => this.savePluginPermissions(e)),
        e => {
          if (ENABLE_TRIP_MONITOR) {
            toast.error(e)
          }
          throw new Error(e)
        },
        e => this.handleSetLocationError(e)
      )
    } else if (justResume) {
      // Plugin should just start, get location once and return
      console.log('[BOOTUP] Periodic kick triggered')
      await BackgroundGeolocation.start()

      getCurrentLocationOnce(
        state => this.handleSetFetchingPosition(state),
        position => this.setBrowserPosition(position),
        error => this.handleSetLocationError(error)
      )

      return
    }

    getCurrentLocationOnce(
      state => this.handleSetFetchingPosition(state),
      position => this.setBrowserPosition(position),
      error => this.handleSetLocationError(error)
    )

    // Get status again in real time to ensure live permissions, the ones in the state
    const status = await BackgroundGeolocation.getProviderState()

    console.log('[BOOTUP]', status)

    // Only set pause/resume listeners once permission has been given initially
    if (status.status !== BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED) {
      this.addEventListeners()
    }

    this.savePluginPermissions(status)
    resumeGeoLocationPlugin()
  }

  public addEventListeners() {
    console.log('Enabling event listeners')
    document.addEventListener('resume', () => this.onResumeApp(), false)
    document.addEventListener('pause', () => this.onPauseApp(), false)
  }

  /**
   * Removes event listeners so plugin does not keep listening in
   */
  public removeEventListeners() {
    console.log('Removing event listeners')
    document.removeEventListener('resume', () => this.onResumeApp(), false)
    document.removeEventListener('pause', () => this.onPauseApp(), false)
    this.clearFetchIntervals()
  }

  public savePluginPermissions(event: ProviderChangeEvent) {
    // Translate eventstatus to debuggable value
    // Removed "authorization" since sentry filters that
    const eventStatus =
      event.status === BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED
        ? 'NOT_DETERMINED' // iOS only
        : event.status === BackgroundGeolocation.AUTHORIZATION_STATUS_RESTRICTED
        ? 'RESTRICTED' // iOS only
        : event.status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED
        ? 'DENIED'
        : event.status === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS
        ? 'ALWAYS'
        : event.status === BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE
        ? 'WHEN_IN_USE' // iOS only
        : event.status

    if (
      this.state.pluginPermissions.enabled &&
      this.state.pluginPermissions.status !== event.status
    ) {
      console.warn(`[BackgroundGeolocation] Permissions change to ${eventStatus}`)
    }

    this.setState({ pluginPermissions: event })

    // Ensure Sentry also has this information so debugging extra issues may become easier
    Sentry.setContext('backgroundgeolocation', {
      ...event,
      status: eventStatus
    })
  }

  /**
   * Function which receives the geoLocation from the cordova
   * background geolocation plugin. Then uses this location to
   * fire various events and sets the current user location.
   */
  public onLocationSuccess = (geoLocation: IFullPosition) => {
    const previousLocations = cloneDeep(this.state.lastLocations)
    const previousLocation: IFullPosition | undefined =
      previousLocations[previousLocations.length - 1]
    previousLocations.push(geoLocation)

    // Filter out any locations which have appeared less than half a second after the previous location
    // BEFORE processing them in the app
    if (
      geoLocation &&
      previousLocation &&
      geoLocation.timeLastUpdate - previousLocation.timeLastUpdate < 500
    ) {
      return
    }

    // syncs the location with redux and saves it to the api
    this.props.setCurrentLocation(geoLocation, this.state.appInBackground)

    // Make sure that there is always 2 minutes minimum of local trace saved or 20 items
    const MIN_LENGTH = 120 / 5 // 120 seconds / 5s interval
    if (previousLocations.length > Math.max(MIN_LENGTH, 20)) {
      previousLocations.shift()
    }

    this.setState({ lastLocations: previousLocations })

    this.checkRoutePausedInBackground(this.state.appInBackground, this.state.lastRoutePausedCheck)

    // handles any notifications which are fired based on the location / speed
    if (this.isRouteActive(this.props.routeSelection)) {
      this.checkSpeedOverride(geoLocation.speedOverGround)
      if (geoLocation.location) {
        const channelCrossings = this.props.channelCrossings
        const vdjsCrossings = this.props.VDJSCrossings

        if (channelCrossings) {
          this.checkChannelCrossings(geoLocation.location, channelCrossings)
        }

        if (vdjsCrossings) {
          this.checkVDJSCrossing(geoLocation.location, vdjsCrossings)
        }
      }
    }
  }

  public checkRoutePausedInBackground = async (
    appInBackground: boolean,
    lastRoutePausedCheck: Date | null
  ) => {
    if (appInBackground === true) {
      const PAUSED_CHECK_INTERVAL = 5
      if (
        lastRoutePausedCheck === null ||
        isAfter(subMinutes(new Date(), PAUSED_CHECK_INTERVAL), lastRoutePausedCheck)
      ) {
        const routeUpdate = await this.props.getSelectedRouteEtaUpdate()
        if (routeUpdate) {
          if (routeUpdate.paused === true) {
            pauseGeoLocationPlugin()
          }
          this.setState({ lastRoutePausedCheck: new Date() })
        }
      }
    }
  }

  /**
   * Returns a boolean value indicating if the user is currently actively
   * navigating a route.
   */
  public isRouteActive = (route: ISelectedRoute | null): boolean => {
    if (route && route !== null && route.paused === false) {
      return true
    } else {
      return false
    }
  }

  /**
   * Reconfigures the distance set in the geolocation background plugin ( cordova )
   * Based on the values given it increases/decreased the range in which the gps location is being triggered
   */
  public reconfigureMobileGeoLocationService = () => {
    // based on the page, change the gps fetch distance
    if (this.props.isNavigating) {
      // make a distinction between fetch distance based on wether the route is active
      if (this.isRouteActive(this.props.routeSelection)) {
        setGPSFetchDistance('DISTANCE_ROUTE_ACTIVE_FOREGROUND')
      } else {
        setGPSFetchDistance('DISTANCE_ROUTE_INACTIVE_FOREGROUND')
      }
    } else {
      setGPSFetchDistance('DISTANCE_ROUTE_INACTIVE_FOREGROUND')
    }
  }

  /**
   * Pauses the retrieval of gps locations by the background geolocation plugin.
   */
  public onPauseApp = () => {
    this.setState({ appInBackground: true })
    this.clearFetchIntervals()

    // Check for Android if permission is granted
    if (
      window.cordova?.platformId === 'android' &&
      this.state.pluginPermissions.enabled &&
      this.state.pluginPermissions.status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED
    ) {
      // Should most likely remove event listeners, when testing changing permissions on Android
      // caused the app to kill itself and reboot when going into it again
      this.removeEventListeners()
    } else {
      if (!this.isRouteActive(this.props.routeSelection)) {
        pauseGeoLocationPlugin()
      } else {
        // make fetch distance larger, there's an active route, but app not active in the foreground
        setGPSFetchDistance('DISTANCE_ROUTE_ACTIVE_BACKGROUND')
      }
    }
  }

  public onResumeApp = () => {
    const resumePlugin = () =>
      resumeGeoLocationPlugin(() => {
        // Set fetch distance based on wether the route is currently active
        if (this.isRouteActive(this.props.routeSelection)) {
          setGPSFetchDistance('DISTANCE_ROUTE_ACTIVE_FOREGROUND')
        } else {
          setGPSFetchDistance('DISTANCE_ROUTE_INACTIVE_FOREGROUND')
        }
      })

    this.setState({ appInBackground: false })
    this.setKickPluginIntervals()

    // Check if its Android and its not enabled
    if (
      window.cordova?.platformId === 'android' &&
      this.state.pluginPermissions.enabled &&
      this.state.pluginPermissions.status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED
    ) {
      // Ask permission to Android OS
      BackgroundGeolocation.requestPermission()
        .then(() => {
          // Permission granted
          // Set event listener to also listen when paused
          this.addEventListeners()
          resumePlugin()
        })
        .catch(() => {
          // No permission given
          // Should most likely remove event listeners, when testing changing permissions on Android
          // caused the app to kill itself and reboot when going into it again
          console.error('[onResumeApp] No permission given')
          this.removeEventListeners()
        })
    } else {
      resumePlugin()
    }
  }

  /**
   * Sends a mobile notification to the user
   */
  public notifyUser = (title: string, text: string) => {
    if (window.cordova) {
      window.cordova.plugins.notification.local.schedule({
        title,
        text,
        foreground: true
      })
    }
  }

  /**
   * Turns on the browser watchPosition system to get the users current browser location.
   */
  public getBrowserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setBrowserPosition)
      navigator.geolocation.watchPosition(this.setBrowserPosition)
    }
  }

  /**
   * Gets a position supplied by the browser, transforms it into a format suitable for the
   * teqplay backend and syncs it with the app.
   */
  public setBrowserPosition = (position: Position) => {
    // change the position into a data format which is used by the backend
    const positionUpdate: IFullPosition = {
      posAccuracyMeters: position.coords.accuracy,
      speedOverGround: position.coords.speed,
      courseOverGround: position.coords.heading,
      location: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      },
      timeLastUpdate: new Date().valueOf()
    }

    const isSameAsPrevious = isEqual(
      { ...this.props.currentLocation, timeLastUpdate: undefined },
      { ...positionUpdate, timeLastUpdate: undefined }
    )
    if (!isSameAsPrevious) {
      this.props.setCurrentLocation(positionUpdate)
    }
  }

  public setVDJSNotification = (notificationMessage: string | null) => {
    this.setState({
      VDJSNotificationMessage: notificationMessage
    })
  }

  public setChannelApproachNotificationMessage = (notificationMessage: string | null) => {
    this.setState({
      channelApproachNotificationMessage: notificationMessage
    })
  }

  /**
   * Checks wether the users ship is moving too fast.
   * If so it sends the user a notification
   * @param speed
   */
  private checkSpeedOverride(speed: ISpeedState['userInputSpeed'] | null) {
    if (!speed) return

    const speedLimit = 80
    const speedKmh = speed / 0.539956803
    const fiveLastSpeeds = cloneDeep(this.state.fiveLastSpeeds)
    fiveLastSpeeds.push(speedKmh)

    // If longer than 5 remove first item
    if (fiveLastSpeeds.length > 5) {
      fiveLastSpeeds.shift()
    }

    // Check if minimal 4/5 are higher than 80 km/h
    if (
      !this.state.speedNotificationSend &&
      fiveLastSpeeds.filter(s => s >= speedLimit).length >= 4
    ) {
      // Send speed override notification
      this.notifyUser(I18n.t('mobile.high_speed_title'), I18n.t('mobile.high_speed_text'))
      this.setState({ speedNotificationSend: true })
    } else if (
      this.state.speedNotificationSend &&
      fiveLastSpeeds.filter(s => s < speedLimit).length >= 4
    ) {
      // Reset speed notification, so new one is send when user again overrides the speed
      this.setState({ speedNotificationSend: false })
    }

    this.setState({
      fiveLastSpeeds
    })
  }

  /**
   * Checks if the user is approaching a channel of which he has to be notified of.
   * @param location
   */
  private checkChannelCrossings(
    location: { latitude: number; longitude: number },
    channelCrossings: IChannelCrossing[]
  ) {
    channelCrossings.forEach(channel => {
      const insideInnerPolygon =
        pointInPolygon.feature(channel.innerPolygon, [location.longitude, location.latitude]) === -1
          ? false
          : true
      const insideOuterPolygon =
        pointInPolygon.feature(channel.outerPolygon, [location.longitude, location.latitude]) === -1
          ? false
          : true

      const notificationMessage = I18n.t('map.notification.nearingChannelNotification', {
        channelName: channel.name
      })

      if (!this.state.channelCrossing) {
        if (insideInnerPolygon) {
          this.setState({ channelCrossing: channel })
          this.setChannelApproachNotificationMessage(notificationMessage)
          this.notifyUser(I18n.t('map.notification.channelNotificationHeader'), notificationMessage)
        }
      } else {
        if (!insideOuterPolygon) {
          this.setState({ channelCrossing: null })
          this.setChannelApproachNotificationMessage(null)
        }
      }
    })
  }

  /**
   * Checks if the users is approaching a VDJS crossing and notifies the user if required
   * @param location
   */
  private checkVDJSCrossing(
    location: { latitude: number; longitude: number },
    vdjsCrossings: IVDJSCollection
  ) {
    const prevCrossing = this.state.vdjsCrossing
    if (prevCrossing) {
      // see whether we left the crossing
      const distance = headingDistanceTo(location, prevCrossing.location).distance

      if (distance > VDJS_OUTER_RADIUS) {
        // we left the crossing
        this.setState({ vdjsCrossing: null })
      } else {
        this.setState({
          vdjsCrossing: Object.assign({}, this.state.vdjsCrossing, { distance })
        })
      }
    } else {
      // see if we entered a crossing
      const crossing = this.findVDJSCrossing(location, VDJS_INNER_RADIUS, vdjsCrossings)
      if (crossing) {
        // we entered a crossing
        this.setState({ vdjsCrossing: crossing })

        const message = I18n.t('map.notification.approachingVdjsCrossing', {
          crossingName: crossing.name
        })

        const userNotification = I18n.t('map.notification.approachingVdjsCrossingShort', {
          crossingName: crossing.name
        })

        this.notifyUser(I18n.t('map.notification.approachVdjsHeader'), userNotification)

        this.setVDJSNotification(message)
      }
    }
  }

  /**
   * Finds VDJS Crossings based on a given latitude, longitude and maximum distance
   * The function will return crossings if a crossing is found within the { maxDistance } away from the latitude and longitude
   * @param location
   * @param maxDistance
   */
  private findVDJSCrossing(
    location: { latitude: number; longitude: number },
    maxDistance: number,
    VDJSCrossings: IVDJSCollection
  ): IVDJSCrossing | null {
    const crossings = VDJSCrossings.features
      .map(feature => {
        const crossingLocation = toLatLon(feature.geometry.coordinates as any as LonLatTuple)
        const distance = headingDistanceTo(location, crossingLocation).distance

        const crossing: IVDJSCrossing = {
          id: feature.properties.F1,
          name: feature.properties.FID_,
          description: feature.properties['Uitlegs-NL'],
          location: crossingLocation,
          distance,
          website: feature.properties.Website,
          pdf: feature.properties.PDF
        }

        return crossing
      })
      .filter(crossing => crossing.distance <= (maxDistance || 2500))

    return first(sortBy(crossings, 'distance')) || null
  }

  private handleSetFetchingPosition(loadingState: boolean) {
    this.setState({ fetchingPosition: loadingState })
  }

  private handleSetLocationError(locationError: LocationError | PositionError | undefined) {
    if (typeof locationError === 'number' || typeof locationError === 'undefined') {
      this.setState({ locationError })
    } else {
      // Translate browser error into geolocation error
      const errorCode =
        locationError.code === locationError.PERMISSION_DENIED
          ? 1
          : locationError.code === locationError.POSITION_UNAVAILABLE
          ? 0
          : locationError.code === locationError.TIMEOUT
          ? 408
          : 0

      this.setState({ locationError: errorCode })
    }
  }

  private setKickPluginIntervals() {
    const INTERVAL_KICK_RESTART_PLUGIN = 1000 * 60

    this.intervalRestartPlugin = window.setInterval(
      () => this.bootupPlugin(false, true),
      INTERVAL_KICK_RESTART_PLUGIN
    )
  }

  private clearFetchIntervals() {
    if (this.intervalRestartPlugin) {
      window.clearInterval(this.intervalRestartPlugin)
      this.intervalRestartPlugin = null
    }
  }
}

export default GeoLocationWatcher
