import * as React from 'react'
import { Spring } from 'react-spring'
import { RouteSelectionFields } from '../../../../@types/types'

interface IProps {
  displayName?: string
  value: string | null
  placeholder?: string
  fieldName: RouteSelectionFields
  viaRouteIndex?: number
  className?: string
  setrouteSelectionFormFieldActive: (field: RouteSelectionFields, viaRouteIndex?: number) => void
  removeViaRoute?: (index: number) => void
  clearLocation?: () => void
}

const RouteSelectField: React.FunctionComponent<IProps> = props => {
  const routeSelectField = (
    <div className="input-wrapper">
      <label htmlFor="" className="form-label">
        {props.displayName}
      </label>
      <div
        className={
          props.className ? 'input-field-wrapper ' + props.className : 'input-field-wrapper'
        }
      >
        <div
          className="textfield textfield-location"
          onClick={!props.value ? handleOnRouteSelectFieldClick : undefined}
        >
          {props.value ? (
            <>
              {checkIfCoordinates(props.value)}
              <i className="icon-minus-circled remove-icon" onClick={props.clearLocation} />
            </>
          ) : (
            props.placeholder || ''
          )}
        </div>

        {props.removeViaRoute && props.viaRouteIndex !== undefined && (
          <button
            type="button"
            className="button remove-via-route-button"
            onClick={() => {
              if (props.removeViaRoute && props.viaRouteIndex !== undefined) {
                props.removeViaRoute(props.viaRouteIndex)
              }
            }}
          >
            <i className="remove-icon" />
          </button>
        )}
      </div>
    </div>
  )

  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {springProps => <div style={springProps}>{routeSelectField}</div>}
    </Spring>
  )

  function checkIfCoordinates(value: string) {
    //eslint-disable-next-line
    const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi
    if (regexExp.test(value)) {
      const coordinates = value.split(',')
      return `${coordinates[0].substring(0, 6)},  ${coordinates[1].substring(0, 6)} `
    }
    return value
  }

  function handleOnRouteSelectFieldClick() {
    if (props.fieldName === 'VIA_ROUTES' && props.viaRouteIndex !== null) {
      props.setrouteSelectionFormFieldActive(props.fieldName, props.viaRouteIndex)
    } else {
      props.setrouteSelectionFormFieldActive(props.fieldName)
    }
  }
}

export default RouteSelectField
