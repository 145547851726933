import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { IRootProps } from '../../../@types/types'

const DynamicSave = () => {
  // To force editing locale
  // eslint-disable-next-line
  const locale = useSelector((s: IRootProps) => s.i18n.locale)

  return (
    <div className="save-inner">
      <i className="icon-floppy" />
      {I18n.t('save')}
    </div>
  )
}

export default DynamicSave
