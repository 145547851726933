import MapboxGL from 'mapbox-gl'
import React from 'react'
import { GeoJSONLayer } from 'react-mapbox-gl'
import { IExtendedShip } from '../../../../@types/types'
import { detailedShipToGeoJSONPoints } from './utils/detailedShipToGeoJSONPoints'

interface IProps {
  ships: IExtendedShip[]
  handleLayerClick: (event: MapboxGL.MapMouseEvent) => void
  onMouseEnter: (event: MapboxGL.MapMouseEvent) => void
  onMouseLeave: () => void
  minZoom?: number
  maxZoom?: number
}

const AISFixedLayer: React.FC<IProps> = ({
  ships,
  handleLayerClick,
  onMouseEnter,
  onMouseLeave,
  minZoom,
  maxZoom
}) => {
  const labelSymbolLayout: MapboxGL.SymbolLayout = {
    'text-anchor': 'top-left',
    'text-justify': 'left',
    'text-offset': [1, 0],
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 0, 12, 13],
    'text-font': ['Metropolis Semi Bold']
  }
  const labelPaint: MapboxGL.SymbolPaint = {
    'icon-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8],
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
    'text-halo-width': 1.4
  }

  const fixedSymbolPaint: MapboxGL.SymbolPaint = {
    'icon-opacity': ['case', ['boolean', ['get', 'isSelected'], false], 1, 0.8],
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
    'text-halo-width': 1.4
  }

  const fixedSymbolLayout: MapboxGL.SymbolLayout = {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-allow-overlap': true,
    'icon-image': ['get', 'shipColor'],
    'icon-size': ['interpolate', ['linear'], ['zoom'], 6, 0.4, 13, 1],
    'text-anchor': 'top-left',
    'text-justify': 'left',
    'text-offset': [1, 0],
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 0, 12, 13],
    'text-font': ['Metropolis Semi Bold'],
    'icon-pitch-alignment': 'map'
  }

  return (
    <>
      <GeoJSONLayer
        data={detailedShipToGeoJSONPoints(ships)}
        symbolPaint={fixedSymbolPaint}
        symbolLayout={fixedSymbolLayout}
        symbolOnClick={handleLayerClick}
        symbolOnMouseEnter={onMouseEnter}
        symbolOnMouseLeave={onMouseLeave}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
      <GeoJSONLayer
        data={detailedShipToGeoJSONPoints(ships)}
        symbolLayout={labelSymbolLayout}
        symbolPaint={labelPaint}
        symbolOnClick={handleLayerClick}
        symbolOnMouseEnter={onMouseEnter}
        symbolOnMouseLeave={onMouseLeave}
        layerOptions={{
          minZoom,
          maxZoom
        }}
      />
    </>
  )
}

export default AISFixedLayer
