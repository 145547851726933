// import { knotsToKmPerHour } from 'geolocation-utils'
import * as React from 'react'
import { I18n } from 'react-redux-i18n'

import SideMenu from '../sideMenu/SideMenu'
import ShipSpeedBox from './shipSpeedBox/ShipSpeedBox'

import {
  IRouteItem,
  ISelectedRoute,
  IShipNotificationStatus
} from '../../services/TeqplayApiService/TeqplayApi'
import { formatTime } from '../../utils/dates'
import { shortenStringToMax } from '../../utils/general'
import { getIconNameByType } from '../../utils/iconUtils'
import { IFullPosition, ISpeedState } from '../geoLocationWatcher/GeoLocationWatcherTypes'

import './NavigationHeader.scss'

export interface IProps {
  navigationRoute: ISelectedRoute
  userName: string
  activePage: string
  speed: ISpeedState
  shipNotificationStatus: IShipNotificationStatus | null
  isRoutePaused: boolean
  userLocation: IFullPosition | null
  logout: () => void
  toggleOptions: () => void
  isAnonymous: boolean
}

export default class NavigationHeader extends React.PureComponent<IProps> {
  public render() {
    const { activePage, userName, logout, navigationRoute } = this.props
    const currentRoute = this.props.navigationRoute
    const nextItem = navigationRoute.route.routeItems.find(
      item => !!item.eta && routeItemIsAnObject(item)
    )
    const firstItem = nextItem ? null : navigationRoute.route.routeItems[0]
    // const userSpeed = this.props.speed ? Math.round(knotsToKmPerHour(this.props.speed.userInputSpeed)) : null
    const arrivalTime = currentRoute.route.eta
    const vhfChannel = this.props.shipNotificationStatus
      ? this.props.shipNotificationStatus.vhfChannel
      : null
    const nextVhfChannel = this.getNextVhfChannel(this.props.navigationRoute)
    const userLocation = this.props.userLocation

    return (
      <div className="navigation-header">
        <div
          className={this.props.isRoutePaused ? 'top-navigation-bar paused' : 'top-navigation-bar'}
        >
          <SideMenu
            activePage={activePage}
            userName={userName}
            logout={logout}
            isAnonymous={this.props.isAnonymous}
          />

          <div className="next-item" onClick={this.props.toggleOptions}>
            {this.props.isRoutePaused ? (
              <span>{I18n.t('navigationPage.route_paused_warning')}</span>
            ) : (
              <>
                {nextItem && (
                  <span
                    className={`${getIconNameByType(nextItem.type, nextItem)} next-item-icon`}
                  />
                )}
                {!nextItem && firstItem && (
                  <span
                    className={`${getIconNameByType(firstItem.type, firstItem)} next-item-icon`}
                  />
                )}
                <div>
                  <label>{I18n.t('navigationPage.next_object')}</label>
                  {nextItem && <span>{shortenStringToMax(nextItem.name)}</span>}
                  {!nextItem && firstItem && <span>{shortenStringToMax(firstItem.name)}</span>}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bottom-navigation-bar">
          <div className="nav-box vhf-box">
            {vhfChannel ? (
              nextVhfChannel ? (
                <div>
                  <div className="vhf-wrapper">
                    VHF <span className=" current-vhf"> {vhfChannel}</span>
                    <span className="header-icon icon-forward vhf-next-icon" />
                    {nextVhfChannel}
                  </div>
                </div>
              ) : (
                <div className="vhf-wrapper">
                  <span className="">VHF {vhfChannel}</span>
                </div>
              )
            ) : null}
          </div>

          {userLocation && (
            <div className="nav-box heading-box">
              {userLocation.courseOverGround && userLocation.courseOverGround >= 0
                ? Math.round(userLocation.courseOverGround)
                : 0}
              <span className="header-icon icon-user-location" />
            </div>
          )}

          <ShipSpeedBox userLocation={userLocation} />

          <div className="nav-box time-box">
            {formatTime(arrivalTime, 'HH:mm')} <span className="header-icon icon-flag" />
          </div>
        </div>
      </div>
    )
  }

  public getNextVhfChannel = (navigationRoute: ISelectedRoute) => {
    let nextVHFChannel = null

    if (navigationRoute && navigationRoute.route.routeItems) {
      for (const routeItem of navigationRoute.route.routeItems) {
        if (routeItem.eta && routeItem.vhfChannel && routeItem.type === 'VHFSECTOR') {
          nextVHFChannel = routeItem.vhfChannel
          break
        }
      }
    }

    return nextVHFChannel
  }
}

function routeItemIsAnObject(routeItem: IRouteItem) {
  return (
    routeItem.type === 'BRIDGE' || routeItem.type === 'LOCK' || routeItem.type === 'NOTIFICATION'
  )
}
