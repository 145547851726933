import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { INotificationDetails } from '../../services/TeqplayApiService/TeqplayApi'

interface IProps {
  itemDetails: INotificationDetails | null
  isExpanded: boolean
}

class NotificationDetails extends React.PureComponent<IProps> {
  public render() {
    const itemDetails = this.props.itemDetails
    if (!itemDetails || this.props.isExpanded === false) return null

    return (
      <div className="item-info-details">
        <div className="notification-inner">
          {itemDetails.origin ? (
            <div className="route-item-details-item">{itemDetails.origin}</div>
          ) : null}
          {itemDetails.fromDateTime && itemDetails.toDateTime ? (
            <div className="route-item-details-item">
              {I18n.t('item_details.from')} {itemDetails.fromDateTime} {I18n.t('item_details.to')}{' '}
              {itemDetails.toDateTime}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default NotificationDetails
