import React from 'react'
import { I18n } from 'react-redux-i18n'
import { sendMessageToSentry } from '../utils/sentry'

export type IRoleType = 'ANONYMOUS' | 'REGISTERED'

interface IProtectedRouteProps {
  path: string
  redirectHome: () => void
  allowedRole: IRoleType
  role: IRoleType
}

class ProtectedRoute extends React.Component<IProtectedRouteProps> {
  public render() {
    const userIsAllowed = isUserAllowed(this.props.allowedRole, this.props.role)

    if (!userIsAllowed) {
      sendMessageToSentry(
        `${this.props.role} USER ended up in the "You are not allowed to view this page" and is redirected back to the homepage`,
        { path: this.props.path, allowedRole: this.props.allowedRole, role: this.props.role }
      )
      this.props.redirectHome()
      return <h1>{I18n.t('anonymous.notAllowed')}</h1>
    }

    return this.props.children
  }
}

export default ProtectedRoute

function isUserAllowed(allowedRole: IRoleType, userRole: IRoleType): boolean {
  if (allowedRole === 'ANONYMOUS') {
    return true
  } else {
    return allowedRole === userRole
  }
}
