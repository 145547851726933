import React from 'react'
import { I18n } from 'react-redux-i18n'
import { IRouteNotification } from '../../services/TeqplayApiService/TeqplayApi'
import './NotificationMonitor.scss'

interface IProps {
  notifications: IRouteNotification[] | null
  VDJSNotificationMessage: string | null
  channelApproachNotificationMessage: string | null
}

const NotifcationMonitor: React.FunctionComponent<IProps> = props => {
  return (
    <div className="notification-monitor-wrapper">
      <div className="notifications-wrapper">
        <div className="notification-list">
          <div className="list-header">
            <h4>{I18n.t('map.notification.routeNotifications')}</h4>
          </div>
          <ul>
            {props.notifications &&
              props.notifications.map((notification, index) => {
                return (
                  <div key={index} className="notification-wrapper-item ">
                    <span className={'icon-attention ' + notification.color} />
                    {notification.description}
                  </div>
                )
              })}

            {props.VDJSNotificationMessage && (
              <div className="notification-wrapper-item">
                <span className={'icon-attention '} />
                {props.VDJSNotificationMessage}
              </div>
            )}

            {props.channelApproachNotificationMessage && (
              <div className="notification-wrapper-item">
                <span className={'icon-attention '} />
                {props.channelApproachNotificationMessage}
              </div>
            )}

            {(!props.notifications || props.notifications.length === 0) &&
            !props.VDJSNotificationMessage ? (
              <div className="notification-wrapper-item ">
                {I18n.t('navigationPage.no_notifications')}
              </div>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default NotifcationMonitor
