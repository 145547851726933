import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { IRootProps } from '../../@types/types'
import { ILockDetails } from '../../services/TeqplayApiService/TeqplayApi'
import { parseStringForID } from '../../utils/general'
import { determineOperatingRulesForDaySet } from '../../utils/openingHours'

interface IProps {
  itemDetails: ILockDetails | null
  isExpanded: boolean
  departureTime?: number | null
}

const LockDetails = ({ itemDetails, isExpanded, departureTime }: IProps) => {
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string

  if (!itemDetails || isExpanded === false) return null

  const { dateBasedRules, activeOperatingPeriods } = determineOperatingRulesForDaySet(
    itemDetails.operatingTimes,
    locale,
    departureTime ? new Date(departureTime) : undefined
  )
  const notes: string[] = [itemDetails.note, itemDetails?.operatingTimes?.note]
    .concat(activeOperatingPeriods.map(x => x.note))
    .filter(x => x)
    .filter((v, i, s) => s.indexOf(v) === i) as string[]

  return (
    <div className="item-info-details">
      <section className="details-section">
        {itemDetails.name && (
          <b>
            <span>
              {itemDetails.name}
              {itemDetails.city && itemDetails.name !== itemDetails.city && `, ${itemDetails.city}`}
            </span>
          </b>
        )}
        {itemDetails.fairwayName && (
          <div>
            <b>
              <span> {itemDetails.fairwayName}</span>
            </b>
          </div>
        )}
      </section>

      {(itemDetails.vhfChannel || itemDetails.phoneNumber) && (
        <section className="details-section">
          {itemDetails.vhfChannel ? (
            <div className="route-item-details-item">
              <b>{I18n.t('routeList.itemDetails.vhf')}:</b> {itemDetails.vhfChannel}
            </div>
          ) : null}
          {itemDetails.phoneNumber ? (
            <div className="route-item-details-item">
              <b>{I18n.t('routeList.itemDetails.phoneNumber')}: </b>
              {itemDetails.phoneNumber}
              <a href={'tel:' + itemDetails.phoneNumber}>
                <i className="icon-phone" />
              </a>
            </div>
          ) : null}
        </section>
      )}

      <section className="details-section">
        {dateBasedRules?.length > 0 && (
          <div className="route-item-caption">
            <b>{I18n.t('routeList.itemDetails.control_times')}:</b>
          </div>
        )}

        {dateBasedRules.length > 0 && (
          <div className="opening-item" id="lock-details-opening-hours">
            <div className="route-item-caption">
              <b>{I18n.t('routeList.itemDetails.control_times')}:</b>
            </div>
            {dateBasedRules.map((time, key) => (
              <div
                key={key}
                className="opening-time-wrapper"
                id={`lock-details-opening-hour-${parseStringForID(
                  time.validDates[0].date.toDateString()
                )}`}
              >
                <div className="opening-time">
                  <div className="day-row">
                    <div className="dates">
                      {time.validDates.map((d, i) => (
                        <div className="date" key={i}>
                          {i !== 0 ? '& ' : ''}
                          {d.textFormatted} {d.isHoliday && `(${d.isHoliday.name})`}
                        </div>
                      ))}
                    </div>
                    <div className="rules">
                      {time.validRulesForToday.length === 0 && (
                        <div
                          className="rule"
                          id={`lock-details-opening-hour-${parseStringForID(
                            time.validDates[0].date.toDateString()
                          )}-no-service`}
                        >
                          {I18n.t('routeList.itemDetails.bridge_operating_rules.noService')}
                        </div>
                      )}
                      {time.validRulesForToday.map((rule, ruleKey) => (
                        <div
                          className="rule"
                          key={ruleKey}
                          id={`lock-details-opening-hour-${parseStringForID(
                            time.validDates[0].date.toDateString()
                          )}-${ruleKey}`}
                        >
                          <div className="time">
                            {rule.timespan === '00:00 - 23:59'
                              ? I18n.t('routeList.itemDetails.wholeDay')
                              : rule.timespan}
                          </div>
                          <div className="note">{rule.notes}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {notes.map((note, noteIndex) => (
          <div key={noteIndex} id={`lock-details-note-${noteIndex}`}>
            <b>
              {I18n.t('routeList.itemDetails.note')}
              {notes.length > 1 ? ' ' + (noteIndex + 1) : ''}
            </b>
            : {note}
          </div>
        ))}
      </section>

      {itemDetails.chambers && (
        <section className="details-section">
          {itemDetails.chambers
            .sort((a, b) => (a.number || 0) - (b.number || 0))
            .map((chamber, key) => {
              return (
                <div key={key}>
                  {chamber.number && (
                    <div className="route-item-caption">
                      <b>
                        {I18n.t('routeList.itemDetails.lock_chamber')} {chamber.number}:
                      </b>
                    </div>
                  )}
                  {chamber.length && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.length')}:</b> {chamber.length}m
                    </div>
                  )}
                  {chamber.width && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.width')}:</b> {chamber.width}m
                    </div>
                  )}
                  {chamber.gateWidth && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.max_width')}: </b> {chamber.gateWidth}m
                    </div>
                  )}
                  {chamber.sillDepthBeBu && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.threshold_outside')}:</b>{' '}
                      {chamber.sillDepthBeBu}m, [{itemDetails.referenceLevelBeBu}]
                    </div>
                  )}
                  {chamber.sillDepthBoBi && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.threshold_inside')}:</b>{' '}
                      {chamber.sillDepthBoBi}m, [{itemDetails.referenceLevelBoBi}]
                    </div>
                  )}
                  {chamber.note && (
                    <div className="route-item-details-item">
                      <b>{I18n.t('routeList.itemDetails.chamberNote')}:</b> {chamber.note}
                    </div>
                  )}
                </div>
              )
            })}
        </section>
      )}

      {(itemDetails.address || itemDetails.postalCode) && (
        <section className="details-section">
          {itemDetails.address ? (
            <p className="route-item-details-item">{itemDetails.address} </p>
          ) : null}
          {itemDetails.postalCode && itemDetails.city ? (
            <p className="route-item-details-item">
              {itemDetails.postalCode}, {itemDetails.city}
            </p>
          ) : null}
        </section>
      )}
    </div>
  )
}

export default LockDetails
