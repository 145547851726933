import { IExtendedPropertyLocation } from '../@types/types'
import { IFullPosition } from '../components/geoLocationWatcher/GeoLocationWatcherTypes'

export type ILocationAction = ISetCurrentLocationAction | ISetLocationHistoryAction

interface ISetCurrentLocationAction {
  type: 'SET_CURRENT_LOCATION'
  currentLocation: IFullPosition | null
}
export function setCurrentLocation(
  currentLocation: IFullPosition | null
): ISetCurrentLocationAction {
  return {
    type: 'SET_CURRENT_LOCATION',
    currentLocation
  }
}

interface ISetLocationHistoryAction {
  type: 'SET_LOCATION_HISTORY'
  history: IExtendedPropertyLocation
}
export function setLocationHistory(history: IExtendedPropertyLocation[]) {
  return {
    type: 'SET_LOCATION_HISTORY',
    history
  }
}
