import { format } from 'date-fns'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'

import DayMonthPicker from './DateTimePickers/DayMonthPicker'
import TimePicker from './DateTimePickers/TimePicker'

import { IRootProps } from '../../../../@types/types'
import { formatDateTimeShort, formatTime } from '../../../../utils/dates'
import './TimeOptions.scss'

interface IProps {
  showTimeOptions: boolean
  toggleTimeOptions: () => void
  setDepartureTime: (arrivalDate: Date) => void
  departureTime: number
}

const TimeOptions = ({ showTimeOptions, setDepartureTime, departureTime }: IProps) => {
  const locale = useSelector<IRootProps>(p => p.i18n.locale) as string

  const [datePickerActive, setDatePickerActive] = React.useState(false)
  const [timePickerActive, setTimePickerActive] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(new Date(departureTime))
  const [selectedTime, setSelectedTime] = React.useState(new Date(departureTime))

  if (!showTimeOptions) {
    return null
  }

  return (
    <div className="time-options-wrapper">
      <div className="option-label">
        <Translate value="routeSelection.departure" className="form-label" />
      </div>

      {datePickerActive && (
        <DayMonthPicker toggleDatePicker={toggleDatePicker} selectTime={handleSetSelectedDate} />
      )}
      {timePickerActive && (
        <TimePicker toggleTimePicker={toggleTimePicker} selectTime={handleSetSelectedTime} />
      )}

      <div className="time-inputs">
        <div className="time-option" onClick={toggleDatePicker}>
          <i className=" icon-calendar" />
          <p>{formatDateTimeShort(selectedDate, locale)}</p>
        </div>

        <div className="time-option" onClick={toggleTimePicker}>
          <i className="icon-clock-1" />
          <p>{formatTime(selectedTime, locale)}</p>
        </div>

        <button type="button" className="now-button" onClick={setDateAndTimeToNow}>
          {I18n.t('now')}
        </button>
      </div>
    </div>
  )

  function toggleDatePicker() {
    setDatePickerActive(!datePickerActive)
  }

  function toggleTimePicker() {
    setTimePickerActive(!timePickerActive)
  }

  function setDateAndTimeToNow() {
    const now = new Date()
    setSelectedDate(now)
    setSelectedTime(now)
    setDepartureTime(now)
  }

  function handleSetSelectedDate(date: Date) {
    setSelectedDate(date)
    setDatePickerActive(false)
    setDepartureDateAndTime(date, selectedTime)
  }

  function handleSetSelectedTime(date: Date) {
    setSelectedTime(date)
    setTimePickerActive(false)
    setDepartureDateAndTime(selectedDate, date)
  }

  function setDepartureDateAndTime(depDate: Date, depTime: Date) {
    // formats the dates so they can be combined into one new date with the selected date and time
    // from the day Day/Month Picker and the Time Picker
    const formattedDepartureDate = format(depDate, 'MM/dd/yyyy')
    const formattedDepartureTime = format(depTime, 'H:mm')
    const combinedDateAndTime = new Date(formattedDepartureDate + ' ' + formattedDepartureTime)

    setDepartureTime(combinedDateAndTime)
  }
}

export default TimeOptions
