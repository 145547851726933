import * as React from 'react'
import './LoadingIndicator.scss'

interface IProps {
  loading: boolean
}

class Loader extends React.Component<IProps> {
  public render() {
    if (!this.props.loading) {
      return null
    }

    return (
      <div className="loader-wrapper">
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    )
  }
}

export default Loader
