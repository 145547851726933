import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { createLocalePopup } from '../../shared/confirmPopup/ConfirmPopup'

import './Menu.scss'
import MenuItem from './menuItem/MenuItem'

interface IProps {
  toggleSidebarOpen: () => void
  activePage: string
  userName: string
  logout: () => void
  isAnonymous: boolean
}

const Menu: React.FunctionComponent<IProps> = ({
  toggleSidebarOpen,
  activePage,
  userName,
  logout,
  isAnonymous
}) => {
  const history = useHistory()
  const logoutAndRegister = () => {
    logout()
    history.push('/register')
  }

  return (
    <div className="menu">
      <div className="menu-header">
        <i className="icon-close_privacy" onClick={toggleSidebarOpen} />
        <h2>{I18n.t('pages.welcome')}</h2>
        <span>{userName}</span>
      </div>

      <div className="menu-items">
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.navigation')}
          iconClass="icon-map-o"
          url="/"
          activePage={activePage}
          anonymousAllowed
          isAnonymous={isAnonymous}
          logoutAndRegister={logoutAndRegister}
        />
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.myProfile')}
          iconClass="icon-cog-1"
          url="/myProfile"
          activePage={activePage}
          anonymousAllowed={false}
          isAnonymous={isAnonymous}
          logoutAndRegister={logoutAndRegister}
        />
        <MenuItem
          onClick={toggleSidebarOpen}
          pageName={I18n.t('pages.feedback')}
          iconClass="icon-comment-empty"
          url="/feedback"
          activePage={activePage}
          anonymousAllowed={false}
          isAnonymous={isAnonymous}
          logoutAndRegister={logoutAndRegister}
        />

        <div
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()

            createLocalePopup()
          }}
          className="menu-item"
        >
          <i className="icon-globe" />
          <span>{I18n.t('locale.selectLocale')}</span>
        </div>

        <div className="line" />

        {!isAnonymous ? (
          <>
            <MenuItem
              onClick={toggleSidebarOpen}
              pageName={I18n.t('pages.policy')}
              iconClass="icon-doc-text"
              url="/policy"
              anonymousAllowed
              isAnonymous={isAnonymous}
              logoutAndRegister={logoutAndRegister}
            />
            <MenuItem
              onClick={logout}
              pageName={I18n.t('pages.logout')}
              iconClass="icon-logout"
              url="/login"
              anonymousAllowed={false}
              isAnonymous={isAnonymous}
              logoutAndRegister={logoutAndRegister}
            />
          </>
        ) : (
          <>
            <MenuItem
              onClick={toggleSidebarOpen}
              pageName={I18n.t('pages.loginLink')}
              iconClass="icon-user"
              url="/login"
              anonymousAllowed
              isAnonymous={isAnonymous}
              logoutAndRegister={logoutAndRegister}
            />
            <MenuItem
              onClick={toggleSidebarOpen}
              pageName={I18n.t('pages.register')}
              iconClass="icon-user-plus"
              url="/register"
              anonymousAllowed
              isAnonymous={isAnonymous}
              logoutAndRegister={logoutAndRegister}
            />
            <MenuItem
              onClick={toggleSidebarOpen}
              pageName={I18n.t('pages.policy')}
              iconClass="icon-doc-text"
              url="/policy"
              anonymousAllowed
              isAnonymous={isAnonymous}
              logoutAndRegister={logoutAndRegister}
            />
          </>
        )}
      </div>
      <div className="menu-footer">
        <div className="logo_fryslan" />
      </div>
    </div>
  )
}

export default Menu
